<div ngbDropdown #customerDropdown="ngbDropdown" class="ddl-control d-inline-block">
    <button class="btn btn-customers" id="ddlCustomers" [disabled]="!loadCompleted" ngbDropdownToggle>{{!loadCompleted ?
        'Loading...' : selectedCustomer ?
        selectedCustomer.MasterCustomerName : 'Client Name'}}</button>
    <div ngbDropdownMenu aria-labelledby="ddlCustomers" class="ddl-content">
        <app-search-input placeholder="Search for Client" (searchEvent)="onSearchTextChanged($event)">
        </app-search-input>
        <app-virtual-list-dropdown [items]="customers | filterArray : searchText : 'MasterCustomerName'"
            displayAttr="MasterCustomerName" valueAttr="MasterCustomerNumber" [selectedItem]="selectedCustomer"
            (itemSelectionChangeEvent)="onChangeCustomer($event)" [refresh]="refreshEvent.asObservable()">
        </app-virtual-list-dropdown>
        <div *ngIf="(customers | filterArray : searchText : 'MasterCustomerName').length === 0">
            <button ngbDropdownItem>
                <span>No records found</span>
            </button>
        </div>
    </div>
</div>