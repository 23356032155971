import { assign } from 'lodash';

import { BoardResponseItem } from 'src/app/shared/services/entities/board-response';

class ShareBoardModalConfigSchema {
  board: BoardResponseItem;
}

export class ShareBoardModalConfig extends ShareBoardModalConfigSchema {
  constructor(filterAttributes?: ShareBoardModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
