import { StorageType } from '../constants/storage.constants';

export class StorageUtils {
  static set(type: StorageType, key: string, value: any): void {
    value = typeof value === 'string' ? value : JSON.stringify(value);
    switch (type) {
      case StorageType.LocalStorage:
        localStorage.setItem(key, value);
        break;
      case StorageType.SessionStorage:
        sessionStorage.setItem(key, value);
        break;
    }
  }

  static get(type: StorageType, key: string): string {
    switch (type) {
      case StorageType.LocalStorage:
        return localStorage.getItem(key);
      case StorageType.SessionStorage:
        return sessionStorage.getItem(key);
    }
  }

  static remove(type: StorageType, key: string): void {
    switch (type) {
      case StorageType.LocalStorage:
        localStorage.removeItem(key);
        break;
      case StorageType.SessionStorage:
        sessionStorage.removeItem(key);
        break;
    }
  }
}
