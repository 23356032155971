import { assign } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { OptionItem } from './option-item';

class OptionsListConfigSchema {
  items: Array<OptionItem>;
  multipleSelect?: boolean = false;
  groupName?: string;
  hasSelectAll?: boolean = false;
}

export class OptionsListConfig extends OptionsListConfigSchema {
  constructor(filterAttributes?: OptionsListConfigSchema) {
    super();
    assign(this, filterAttributes);

    if (!this.multipleSelect) {
      this.groupName = uuidv4();
    }
  }
}
