import moment from 'moment';

export class DateUtils {
  static normalizeDate(date: Date): string {
    return date
      ? new Date(date.toUTCString().slice(0, -13)).toISOString()
      : undefined;
  }

  static normalizeDateString(date: string): string {
    return date && date.length > 0
      ? DateUtils.normalizeDate(new Date(date))
      : undefined;
  }

  static getClientSideTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  static dateToTimeId(date: Date): number {
    const zero: moment.Moment = moment.utc('2002-01-01', 'YYYY-MM-DD');
    const year: number = date.getFullYear();
    const month: string = ('00' + (date.getMonth() + 1)).slice(-2);
    let newDate: moment.Moment = moment.utc(
      `${year}-${month}-${1}`,
      'YYYY-MM-DD'
    );
    newDate = newDate.add(1, 'M');
    newDate = newDate.set('date', newDate.daysInMonth());
    const monthDiff: number = newDate.diff(zero, 'months', false);
    return monthDiff;
  }
}
