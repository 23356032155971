import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';

import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  TabControlNames,
  TabFilters,
} from '../../constants/actual-sales-tab.constants';
import {
  TabConfig,
  TabDropdown,
  TabSwitch,
} from '../../../tabs-picker/components/tab/entities/tab-config';
import {
  BoardTab,
  BoardTabControl,
} from 'src/app/shared/services/entities/board-response';
import { TabsObservables } from '../../../tabs-picker/entities/tabs-observables';
import { CurrencyChanged } from 'src/app/shared/components/currency/entities/currency-events';
import { TimeframeChanged } from 'src/app/shared/components/timeframe/entities/timeframe-events';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { Filters } from 'src/app/shared/services/entities/filters/filters';

import { FiltersService } from 'src/app/shared/services/filters.service';

@Component({
  selector: 'app-board-actual-sales-tab',
  templateUrl: './board-actual-sales-tab.component.html',
  styleUrls: ['./board-actual-sales-tab.component.scss'],
})
export class BoardActualSalesTabComponent implements OnInit {
  @Input() startSelectedTab: BoardTab;
  @Input() tabsObservables: TabsObservables;
  @Output() selectionChangeEvent = new EventEmitter<BoardTab>();

  subscription = new Subscription();
  filters: Filters;
  selectedFilters: SelectedFilters;
  tabConfig: TabConfig;

  expandValue: boolean;
  noActivityValue: boolean;

  params: Params;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.filters = x;
        this.selectedFilters = y;
        this.initializeTab();
        this.addSubscriptions();
        this.selectionChangeEvent.emit(this.getSelectedValues());
      })
    );
  }

  addSubscriptions(): void {
    if (this.tabsObservables?.selectedFiltersChanged) {
      this.subscription.add(
        this.tabsObservables.selectedFiltersChanged.subscribe(
          (x: SelectedFilters) => {
            console.log(x);
          }
        )
      );
    }

    if (this.tabsObservables?.currencyChanged) {
      this.subscription.add(
        this.tabsObservables.currencyChanged.subscribe((x: CurrencyChanged) => {
          this.selectedFilters.currency = x.currency;
          this.validateFilterSelection();
        })
      );
    }

    if (this.tabsObservables?.timeframeChanged) {
      this.subscription.add(
        this.tabsObservables.timeframeChanged.subscribe(
          (x: TimeframeChanged) => {
            this.selectedFilters.timeframe = x.timeframe;
            this.validateFilterSelection();
          }
        )
      );
    }
  }

  initializeTab(): void {
    const targets: Array<TextValuePair> = TabFilters.TargetOptions(
      this.selectedFilters
    );
    let selectedPeriod: TextValuePair = this.params?.period
      ? TabFilters.Periods.find(
          (x: TextValuePair) => x.value === this.params.period
        )
      : TabFilters.Periods[0];

    let selectedTarget: TextValuePair = this.params?.target
      ? targets.find((x: TextValuePair) => x.value === this.params.target)
      : targets[0];

    if (
      this.startSelectedTab?.SubTabName === TabControlNames.actualSales &&
      this.startSelectedTab.Controls?.length > 0
    ) {
      const periodDropdown: BoardTabControl =
        this.startSelectedTab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.periods
        );
      const targetDropdown: BoardTabControl =
        this.startSelectedTab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.startSelectedTab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      const noActivitySwitch = this.startSelectedTab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      );

      selectedPeriod = TabFilters.Periods.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === periodDropdown.value.toLowerCase()
      );

      selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.expandValue = expandSwitch?.value;
      this.noActivityValue = noActivitySwitch?.value;
    }

    this.tabConfig = new TabConfig({
      name: TabControlNames.actualSales,
      controls: [
        new TabDropdown({
          controlName: TabControlNames.periods,
          items: TabFilters.Periods,
          onChange: this.onPeriodChanged.bind(this),
          selected: selectedPeriod,
        }),
        new TabDropdown({
          controlName: TabControlNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: selectedTarget,
        }),
        new TabSwitch({
          controlName: TabControlNames.expand,
          text: 'Expand Grid',
          switchValue: this.expandValue,
          onSwitch: this.onExpandGridSwitchChanged.bind(this),
        }),
        new TabSwitch({
          controlName: TabControlNames.noActivity,
          text: 'No Activity',
          switchValue: this.noActivityValue,
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
        }),
      ],
    });
  }

  getSelectedValues(): BoardTab {
    const periodsDropdown: TabDropdown = this.tabConfig.findControl(
      TabControlNames.periods
    );
    const targetsDropdown: TabDropdown = this.tabConfig.findControl(
      TabControlNames.targets
    );
    const noActivitySwitch: TabSwitch = this.tabConfig.findControl(
      TabControlNames.noActivity
    );
    const expandGridSwitch: TabSwitch = this.tabConfig.findControl(
      TabControlNames.expand
    );

    return {
      Name: '',
      SubTabName: TabControlNames.actualSales,
      Controls: [
        {
          key: TabControlNames.periods,
          value: periodsDropdown.selected.value,
        },
        {
          key: TabControlNames.targets,
          value: targetsDropdown.selected.value,
        },
        {
          key: TabControlNames.expand,
          value: expandGridSwitch.switchValue,
        },
        {
          key: TabControlNames.noActivity,
          value: noActivitySwitch.switchValue,
        },
      ],
    } as BoardTab;
  }

  validateFilterSelection(): void {
    const periodDropdown: TabDropdown = this.tabConfig?.findControl(
      TabControlNames.periods
    );

    const targetsDropdown: TabDropdown = this.tabConfig?.findControl(
      TabControlNames.targets
    );

    if (periodDropdown) {
      GridValidationsUtils.validatePeriodSelection(
        periodDropdown,
        this.selectedFilters,
        () => {}
      );
    }

    if (targetsDropdown) {
      GridValidationsUtils.validateSubMetricSelection(
        targetsDropdown,
        this.filters,
        this.selectedFilters,
        () => {}
      );
    }
  }

  onPeriodChanged(selected: TextValuePair, dropdown: TabDropdown): void {
    dropdown.selected = selected;
    this.selectionChangeEvent.emit(this.getSelectedValues());
  }

  onTargetChanged(selected: TextValuePair, dropdown: TabDropdown): void {
    dropdown.selected = selected;
    this.selectionChangeEvent.emit(this.getSelectedValues());
  }

  onExpandGridSwitchChanged(value: boolean, switchControl: TabSwitch): void {
    switchControl.switchValue = value;
    this.selectionChangeEvent.emit(this.getSelectedValues());
  }

  onNoActivitySwitchChanged(value: boolean, switchControl: TabSwitch): void {
    switchControl.switchValue = value;
    this.selectionChangeEvent.emit(this.getSelectedValues());
  }
}
