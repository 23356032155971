import { assign } from 'lodash';

class PlanSchema {
  year: number;
  monthLabel: string;
  IfsLabel: string;
  sapCode: string;
  timeId: number;
  applyBeforeMMBFilter?: boolean = false;
}

export class Plan extends PlanSchema {
  constructor(filterAttributes?: PlanSchema) {
    super();
    assign(this, filterAttributes);
  }

  getText(): string {
    return this.monthLabel;
  }
}
