import { assign } from 'lodash';
import { ReplaySubject } from 'rxjs';

import { CurrencyChanged } from 'src/app/shared/components/currency/entities/currency-events';
import { TimeframeChanged } from 'src/app/shared/components/timeframe/entities/timeframe-events';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

class TabsObservablesSchema {
  selectedFiltersChanged?: ReplaySubject<SelectedFilters>;
  timeframeChanged?: ReplaySubject<TimeframeChanged>;
  currencyChanged?: ReplaySubject<CurrencyChanged>;
}

export class TabsObservables extends TabsObservablesSchema {
  constructor(filterAttributes?: TabsObservablesSchema) {
    super();
    assign(this, filterAttributes);
  }
}
