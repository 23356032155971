<ul ngbNav #timeframeNav="ngbNav" class="nav-tabs">
  <li *ngFor="let t of timeframes, let tabId = index" [ngbNavItem]="tabId + 1" class="nav-tab">
      <a ngbNavLink>{{t.title}}</a>
      <ng-template ngbNavContent>
          <div *ngFor="let s of t.subGroups" class="timeframe-subgroup">
              <label *ngIf="s.title" class="subgroup-title">{{s.title}}</label>
              <ul class="subgroup-items">
                  <li *ngFor="let i of s.items" class="subgroup-item" (click)="onTimeframeChanged(i, true)">
                      <div class="timeframe-item" [ngClass]="{'not-selected' : selectedTimeframe?.code !== i.code}">
                          <span>
                              <fa-icon [icon]="faCheckCircle" *ngIf="selectedTimeframe?.code === i.code">
                              </fa-icon>
                              {{i.title}}
                          </span>
                          <span>{{i.getDescription()}}</span>
                      </div>
                  </li>
              </ul>
          </div>
      </ng-template>
  </li>

  <div [ngbNavItem]="4" class="nav-tab">
    <a ngbNavLink>Custom</a>
    <ng-template ngbNavContent>
      <div class="custom-content pt-2 pb-2">
        <div class="row m-0 p-0">
          <div class="col-3 label-col">
            <label class="custom-title">From:</label>
          </div>
          <div class="col-3 p-0 max-width-20">
            <div ngbDropdown #ddlStartMonth="ngbDropdown">
              <button class="btn btn-secondary" id="ddlStartMonth" ngbDropdownToggle>{{ customStartMonth?.text }}</button>
              <ul ngbDropdownMenu aria-labelledby="ddlStartMonth">
                  <li class="headers-list" *ngFor="let m of months" (click)="onChangeSelectedCustom(m, 'startMonth'); ddlStartMonth.close();">
                      <fa-icon [icon]="faCheckCircle" *ngIf="customStartMonth.value === m.value"></fa-icon>
                      <span [ngClass]="{'not-selected' : customStartMonth.value !==  m.value, 'selected' : customStartMonth.value ===  m.value}">
                        {{ m.text }}
                      </span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="col-3 p-0">
            <div ngbDropdown #ddlStartYear="ngbDropdown">
              <button class="btn btn-secondary" id="ddlStartYear" ngbDropdownToggle>{{ customStartYear?.text }}</button>
              <ul ngbDropdownMenu aria-labelledby="ddlStartYear">
                  <li class="headers-list" *ngFor="let y of fiscalYears" (click)="onChangeSelectedCustom(y, 'startYear'); ddlStartYear.close();">
                    <fa-icon [icon]="faCheckCircle" *ngIf="customStartYear.value === y.value"></fa-icon>
                      <span [ngClass]="{'not-selected' : customStartYear.value !==  y.value, 'selected' : customStartYear.value ===  y.value}">
                        {{ y.text }}
                      </span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="col-3"></div>
        </div>
        <div class="row m-0 mt-2 p-0">
          <div class="col-3 label-col">
            <label class="custom-title">To:</label>
          </div>
          <div class="col-3 p-0 max-width-20">
            <div ngbDropdown #ddlEndMonth="ngbDropdown">
              <button class="btn btn-secondary" id="ddlEndMonth" ngbDropdownToggle>{{ customEndMonth?.text }}</button>
              <ul ngbDropdownMenu aria-labelledby="ddlEndMonth">
                  <li class="headers-list" *ngFor="let m of months" (click)="onChangeSelectedCustom(m, 'endMonth'); ddlEndMonth.close();">
                    <fa-icon [icon]="faCheckCircle" *ngIf="customEndMonth.value === m.value"></fa-icon>
                      <span [ngClass]="{'not-selected' : customEndMonth.value !==  m.value, 'selected' : customEndMonth.value ===  m.value}">
                        {{ m.text }}
                      </span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="col-3 p-0">
            <div ngbDropdown #ddlEndYear="ngbDropdown">
              <button class="btn btn-secondary" id="ddlEndYear" ngbDropdownToggle>{{ customEndYear?.text }}</button>
              <ul ngbDropdownMenu aria-labelledby="ddlEndYear">
                  <li class="headers-list" *ngFor="let y of fiscalYears" (click)="onChangeSelectedCustom(y, 'endYear'); ddlEndYear.close();">
                    <fa-icon [icon]="faCheckCircle" *ngIf="customEndYear.value === y.value"></fa-icon>
                      <span [ngClass]="{'not-selected' : customEndYear.value !==  y.value, 'selected' : customEndYear.value ===  y.value}">
                        {{ y.text }}
                      </span>
                  </li>
              </ul>
            </div>
          </div>
          <div class="col-3 max-width-30">
            <div *ngIf="isModal" class="button-custom-content">
              <button class="btn btn-primary" [disabled]="!validTimeframe" (click)="onApplyCustomTimeframe()">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</ul>
<div [ngbNavOutlet]="timeframeNav" class="timeframe-container"></div>
