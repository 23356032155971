import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { combineLatest, ReplaySubject, Subject, Subscription } from 'rxjs';
import { cloneDeep, difference } from 'lodash';

import { ColDef } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarIcon,
  GridToolbarSwitch,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  ComponentNames,
  GridColDefs,
  GridFilters,
} from './constants/wins-grid.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import {
  GridConfig,
  GridConfigFeatures,
} from 'src/app/shared/components/base/grid/entities/grid-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { GridObservables } from 'src/app/shared/components/base/grid/entities/grid-observables';
import { WinsResponse } from 'src/app/shared/services/entities/grids/wins-response';
import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';
import { GridValidationsUtils } from 'src/app/shared/utils/grid-validations.utils';
import { TabControlNames } from 'src/app/shared/components/board-modal/components/board-projection-tab/constants/wins-tab-constants';

import { WinsGridService } from 'src/app/shared/services/grids/wins-grid.service';
import { FiltersService } from 'src/app/shared/services/filters.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { BoardService } from 'src/app/shared/services/board.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faExpand,
  faCompress,
} from '@fortawesome/free-solid-svg-icons';
import { BoardTabControl } from 'src/app/shared/services/entities/board-response';

@Component({
  selector: 'app-wins-grid',
  templateUrl: './wins-grid.component.html',
  styleUrls: ['./wins-grid.component.scss'],
})
export class WinsGridComponent implements OnInit, OnDestroy {
  @Output() expandChangedEvent = new EventEmitter<boolean>();
  @Input() isCloud: boolean;

  subscription = new Subscription();
  loadChangedEvent = new Subject<boolean>();
  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);
  filtersChangedEvent = new Subject<string>();

  filters: Filters;
  selectedFilters: SelectedFilters;
  params: Params;
  gridToolbarConfig: GridToolbarConfig;
  gridConfig: GridConfig;
  opportunities: Array<WinsResponse>;
  isExpanded: boolean;
  selectedTarget: TextValuePair;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private filtersService: FiltersService,
    private winsGridService: WinsGridService,
    private appMessagesService: AppMessagesService,
    private activatedRoute: ActivatedRoute,
    private boardService: BoardService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
        this.activatedRoute.queryParams,
      ]).subscribe(([x, y, z]: [Filters, SelectedFilters, Params]) => {
        this.filters = x;
        const timeframeChanged: boolean =
          (this.selectedFilters &&
            this.selectedFilters.timeframe.code !==
              y.timeframe.getDescription()) ||
          false;
        this.selectedFilters = y;
        const paramsChanged: boolean =
          (this.params && difference([this.params], [z])?.length > 0) || false;
        this.params = z;
        const boardChanged: boolean =
          this.boardService.selectedBoard?.tab?.SubTabName ===
          TabControlNames.wins;

        if (!this.gridToolbarConfig || paramsChanged || boardChanged) {
          this.initializeToolbar();
          this.filtersService.updateTarget(this.selectedTarget);
          this.initializeGridConfig();
        }

        this.validateFilterSelection(timeframeChanged);

        this.getData();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeToolbar(): void {
    const targets: Array<TextValuePair> = GridFilters.TargetOptions(
      this.selectedFilters
    );
    let selectedMetric: TextValuePair = GridFilters.Metrics[0];

    this.selectedTarget = this.params?.target
      ? targets.find((x: TextValuePair) => x.value === this.params.target)
      : this.selectedFilters.target ?? targets[0];

    let isNoActivity: boolean;
    let isMsaIndicator: boolean;

    if (
      this.boardService.selectedBoard?.tab?.SubTabName === TabControlNames.wins
    ) {
      const metricDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.metrics
        );
      const targetDropdown: BoardTabControl =
        this.boardService.selectedBoard.tab.Controls.find(
          (x: BoardTabControl) => x.key === TabControlNames.targets
        );
      const expandSwitch = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.expand
      );
      isNoActivity = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.noActivity
      )?.value;
      isMsaIndicator = this.boardService.selectedBoard.tab.Controls.find(
        (x: BoardTabControl) => x.key === TabControlNames.msaIndicator
      )?.value;

      selectedMetric = GridFilters.Metrics.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === metricDropdown.value.toLowerCase()
      );

      this.selectedTarget = targets.find(
        (x: TextValuePair) =>
          x.value.toLowerCase() === targetDropdown.value.toLowerCase()
      );

      this.isExpanded = expandSwitch?.value;
      this.expandChangedEvent.emit(expandSwitch?.value);

      this.boardService.selectedBoard = null;
    }

    this.gridToolbarConfig = new GridToolbarConfig({
      componentName: ComponentNames.wins,
      controls: [
        new GridToolbarDropdown({
          controlName: ComponentNames.metrics,
          items: GridFilters.Metrics,
          onChange: this.onMetricChanged.bind(this),
          selected: selectedMetric,
        }),
        new GridToolbarDropdown({
          controlName: ComponentNames.targets,
          items: targets,
          onChange: this.onTargetChanged.bind(this),
          selected: this.selectedTarget,
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.export,
          icon: faDownload as IconProp,
          onClick: this.onFileExport.bind(this),
          text: 'EXPORT',
          isDisabled: (x: GridToolbarConfig) => {
            const targetsDropdown: GridToolbarDropdown =
              this.gridToolbarConfig.findControl(ComponentNames.targets);

            return (
              targetsDropdown.selected.value === ViewOptions.CompareProjection
            );
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.expand,
          icon: faExpand as IconProp,
          onClick: () => {
            this.isExpanded = true;
            this.expandChangedEvent.emit(true);
          },
          text: 'EXPAND',
          isVisible: () => {
            return !this.isExpanded;
          },
        }),
        new GridToolbarIcon({
          controlName: ComponentNames.compress,
          icon: faCompress as IconProp,
          onClick: () => {
            this.isExpanded = false;
            this.expandChangedEvent.emit(false);
          },
          text: 'EXIT',
          isVisible: () => {
            return this.isExpanded;
          },
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.noActivity,
          text: 'NO ACTIVITY',
          onSwitch: this.onNoActivitySwitchChanged.bind(this),
          switchValue: isNoActivity,
        }),
        new GridToolbarSwitch({
          controlName: ComponentNames.msaIndicator,
          text: 'MSA CVF',
          onSwitch: this.onMsaIndicatorSwitchChanged.bind(this),
          switchValue: isMsaIndicator,
        }),
      ],
    });
  }

  initializeGridConfig(): void {
    this.gridConfig =
      this.gridConfig ??
      new GridConfig({
        columns: [],
        features: new GridConfigFeatures({
          hasTotalsRow: true,
        }),
        options: GridColDefs.getExtraGridOptions(),
        observables: new GridObservables({
          loadChanged: this.loadChangedEvent.asObservable(),
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
          filterChanged: this.filtersChangedEvent.asObservable(),
          expandChanged: this.expandChangedEvent.asObservable(),
        }),
      });

    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.gridToolbarConfig,
        this.filters,
        this.selectedFilters
      )
    );
  }

  getData(): void {
    this.loadChangedEvent.next(true);
    const metricsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.metrics);
    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    const extraParams: Record<string, any> = {
      groupBy: 'Opportunity,Month',
      dataSet: targetsDropdown.selected.value,
      thisMonth: (
        metricsDropdown.selected.value === MetricsOptions.WinsThisMonth
      ).toString(),
      sapCode: '-1',
    };

    this.winsGridService
      .getOpporunities(
        RequestPayload.createRequest(this.selectedFilters, extraParams),
        this.isCloud
      )
      .then((x: Array<WinsResponse>) => {
        this.opportunities = x;
        this.updateRowDataEvent.next(this.mapToGridData(x));
        this.loadChangedEvent.next(false);
        this.expandChangedEvent.emit(this.isExpanded);
      });
  }

  mapToGridData(response: Array<WinsResponse>): GridData {
    let opportunities: Array<WinsResponse> = cloneDeep(response);

    const noActivitySwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.noActivity);

    const msaIndicatorSwitch: GridToolbarSwitch =
      this.gridToolbarConfig.findControl(ComponentNames.msaIndicator);

    if (noActivitySwitch.switchValue) {
      opportunities = opportunities.filter(
        (x: WinsResponse) => !x.isInactive()
      );
    }

    if (msaIndicatorSwitch.switchValue) {
      opportunities = opportunities.filter((x: WinsResponse) => x.msaIndicator);
    }

    return new GridData({
      rows: opportunities,
      pinnedTop: [],
    });
  }

  validateFilterSelection(timeframeChanged?: boolean): void {
    let colDefsUpdated = false;

    const targetsDropdown: GridToolbarDropdown =
      this.gridToolbarConfig.findControl(ComponentNames.targets);

    GridValidationsUtils.validateSubMetricSelection(
      targetsDropdown,
      this.filters,
      this.selectedFilters,
      () => {
        this.updateColDefs();
        colDefsUpdated = true;
      }
    );

    if (timeframeChanged && !colDefsUpdated) {
      this.updateColDefs();
    }
  }

  onMetricChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.updateColDefs();
    this.getData();
  }

  onTargetChanged(
    selected: TextValuePair,
    dropdown: GridToolbarDropdown
  ): void {
    dropdown.selected = selected;
    this.filtersService.updateTarget(selected);
    this.updateColDefs();
    this.getData();
  }

  onFileExport(): void {
    new Promise(
      (
        resolve: (x: Record<string, boolean>) => void,
        reject: () => void
      ): void => {
        const sgMessage: AppMessage = MessageTemplates.ExportSgBreakdown;
        const wmuMessage: AppMessage = MessageTemplates.ExportWmuBreakdown;
        let isSgBreakdown = false;
        let isWmuBreakdown = false;
        sgMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isSgBreakdown = x.text === 'Yes';
            this.appMessagesService.close(sgMessage.id);
            this.appMessagesService.show(wmuMessage, { centered: true });
          };
        });
        wmuMessage.buttons.forEach((x: AppMessageButton) => {
          x.action = (): void => {
            isWmuBreakdown = x.text === 'Yes';
            this.appMessagesService.close(wmuMessage.id);
            resolve({
              isSgBreakdown,
              isWmuBreakdown,
            });
          };
        });
        this.appMessagesService.show(sgMessage, { centered: true });
      }
    ).then((x: Record<string, boolean>) => {
      this.appMessagesService.show(MessageTemplates.FileExporterInit, {
        centered: true,
      });
      this.winsGridService
        .export(this.selectedFilters, this.gridToolbarConfig, x, this.isCloud)
        .then((x: boolean) => {
          if (!x) {
            this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
          }
        });
    });
  }

  onSearchTextChanged(searchText: string): void {
    this.filtersChangedEvent.next(searchText);
  }

  onNoActivitySwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }

  onMsaIndicatorSwitchChanged(
    value: boolean,
    switchControl: GridToolbarSwitch
  ): void {
    switchControl.switchValue = value;
    this.updateRowDataEvent.next(this.mapToGridData(this.opportunities));
  }
}
