import { assign } from 'lodash';

import { ValueType } from 'src/app/shared/constants/common.constants';
import { TileItem } from './tile-item';

class TileSchema {
  title: string;
  subtitle?: string;
  totals: Array<TileItem>;
  items: Array<TileItem>;
}

export class Tile extends TileSchema {
  constructor(filterAttributes?: TileSchema) {
    super();
    assign(this, filterAttributes);
  }

  getTotal(type: ValueType): TileItem {
    return this.totals
      ? this.totals.find((x: TileItem) => x.type === type)
      : null;
  }

  getItems(type: ValueType): Array<TileItem> {
    return this.items
      ? this.items.filter((x: TileItem) => x.type === type)
      : [];
  }
}
