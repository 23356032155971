import { assign } from 'lodash';

import { SelectedFilters } from './filters/selected-filters';
import { ProductHierarchy } from './filters/product-hierarchy';
import { ServiceGroupValues } from './filters/service-group';
import { Wmu } from './filters/wmu';
import { ClientGroup } from './filters/client-group';
import { Industry } from './filters/industry';
import { FinancialCustomer } from './filters/financial-customer';
import { Location } from './filters/location';

class RequestPayloadSchema {
  Payload: Record<string, any>;
}

export class RequestPayload extends RequestPayloadSchema {
  constructor(filterAttributes?: RequestPayloadSchema) {
    super();
    assign(this, filterAttributes);
  }

  static createRequest(
    selectedFilters: SelectedFilters,
    extraParams?: Record<string, any>
  ): RequestPayload {
    const request = new RequestPayload({
      Payload: {
        customerNumber: selectedFilters.customer.MasterCustomerNumber,
        portfolioId: '',
        serviceGroupList: this.getServiceGroups(selectedFilters),
        organizationList: this.getMarkets(selectedFilters),
        targetCurrency: selectedFilters.getTargetCurrency(),
        currencyType: selectedFilters.getCurrencyType(),
        rolledUpWmuList: this.getWmus(selectedFilters),
        clientServiceGroupList: this.getClientGroups(selectedFilters),
        industryFilter: this.getIndustries(selectedFilters),
        financialCustomer: this.getFinancialCustomers(selectedFilters),
        isCumulative: '',
        attributesList: this.getAttributes(selectedFilters),
        view: '',
        startTmFrmId: selectedFilters.timeframe.start,
        endTmFrmId: selectedFilters.timeframe.end,
        tmFrmPeriodCode: selectedFilters.timeframe.mmbPeriod,
        sapCode: '-1',
        iFSlabel: '',
        planSapCode: selectedFilters.plan.timeId.toString(),
        isMSACvf: selectedFilters.isMSACvf
          ? selectedFilters.isMSACvf.toString()
          : 'false',
        fullyWeighted: selectedFilters.fullyWeighted
          ? selectedFilters.fullyWeighted.toString()
          : 'false',
      },
    });

    if (extraParams) {
      request.Payload = Object.assign({}, request.Payload, extraParams);
    }

    return request;
  }

  private static getAttributes(selectedFilters: SelectedFilters): string {
    const attributes: Array<ProductHierarchy> = [
      ...(selectedFilters.alliances ? selectedFilters.alliances : []),
      ...(selectedFilters.functions ? selectedFilters.functions : []),
      ...(selectedFilters.growthPriorities
        ? selectedFilters.growthPriorities
        : []),
    ];

    return (
      attributes.map((x: ProductHierarchy) => x.ProductCode).join(',') || ''
    );
  }

  private static getServiceGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.serviceGroups
        ?.map((x: ServiceGroupValues) => x.ServiceGroupCd)
        .join(',') || ''
    );
  }

  private static getWmus(selectedFilters: SelectedFilters): string {
    return selectedFilters.wmus?.map((x: Wmu) => x.ID).join(',') || '';
  }

  private static getClientGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.clientGroups
        ?.map((x: ClientGroup) => x.ClientGroupCd)
        .join(',') || ''
    );
  }

  private static getIndustries(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.industries
        ?.map((x: Industry) => x.EntityCode)
        .join(',') || ''
    );
  }

  private static getFinancialCustomers(
    selectedFilters: SelectedFilters
  ): string {
    return (
      selectedFilters.financialCustomers
        ?.map((x: FinancialCustomer) => x.CustomerNumber)
        .join(',') || ''
    );
  }

  private static getMarkets(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.markets
        ?.map((x: Location) => x.OrganizationId)
        .join(',') || ''
    );
  }
}
