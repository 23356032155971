import { Inject, Injectable } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import {
  MsalService,
  MSAL_INTERCEPTOR_CONFIG,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  InteractionType,
  SilentRequest,
  StringUtils,
  UrlString,
} from '@azure/msal-browser';
import {
  MatchingResources,
  MsalInterceptorAuthRequest,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular/msal.interceptor.config';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import * as uuid from 'uuid';

import { environment } from 'src/environments/environment';

import { ContentTypes, Headers } from '../constants/http.constants';
import { StorageKeys } from '../constants/storage.constants';
import { BaseUrls } from '../../shared/constants/endpoints.constants';

import { RebarAuthService } from '../rebarauth/rebar.auth.service';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private _document?: Document;

  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private msalInterceptorConfig: MsalInterceptorConfiguration,
    private msalService: MsalService,
    private location: Location,
    private authService: RebarAuthService,
    @Inject(DOCUMENT) document?: any
  ) {
    this._document = document as Document;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.fixUrl(request);

    const scopes: Array<string> = this.getScopesForEndpoint(
      request.url,
      request.method
    );

    if (!scopes || scopes.length === 0) {
      return next.handle(request);
    }

    let account: AccountInfo;

    if (this.authService.getUser()) {
      account = this.authService.getUser();
    } else {
      const accounts: Array<AccountInfo> =
        this.msalService.instance.getAllAccounts();
      account = accounts[0];
    }

    const tokenRequest: SilentRequest = {
      scopes,
      account,
    };

    const authRequest: MsalInterceptorAuthRequest =
      typeof this.msalInterceptorConfig.authRequest === 'function'
        ? this.msalInterceptorConfig.authRequest(this.msalService, request, {
            account: account,
          })
        : { ...this.msalInterceptorConfig.authRequest, account };

    return this.msalService.acquireTokenSilent(tokenRequest).pipe(
      catchError(() => {
        return this.acquireTokenInteractively(authRequest, tokenRequest.scopes);
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.accessToken) {
          return this.acquireTokenInteractively(
            authRequest,
            tokenRequest.scopes
          );
        }
        return of(result);
      }),
      switchMap((result: AuthenticationResult) => {
        request = this.injectToken(request, result.accessToken);
        request = this.addHeaderList(request, account);
        return next.handle(request);
      })
    );
  }

  private acquireTokenInteractively(
    authRequest: MsalInterceptorAuthRequest,
    scopes: string[]
  ): Observable<AuthenticationResult> {
    if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
      return this.msalService.acquireTokenPopup({ ...authRequest, scopes });
    }
    const redirectStartPage: string = window.location.href;
    this.msalService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage,
    });
    return EMPTY;
  }

  private getScopesForEndpoint(
    endpoint: string,
    httpMethod: string
  ): Array<string> | null {
    // Ensures endpoints and protected resources compared are normalized
    const normalizedEndpoint: string = this.location.normalize(endpoint);
    const protectedResourcesArray: Array<string> = Array.from(
      this.msalInterceptorConfig.protectedResourceMap.keys()
    );
    const matchingProtectedResources: MatchingResources =
      this.matchResourcesToEndpoint(
        protectedResourcesArray,
        normalizedEndpoint
      );

    // Check absolute urls of resources first before checking relative to prevent incorrect matching where multiple resources have similar relative urls
    if (matchingProtectedResources.absoluteResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.absoluteResources,
        httpMethod
      );
    } else if (matchingProtectedResources.relativeResources.length > 0) {
      return this.matchScopesToEndpoint(
        this.msalInterceptorConfig.protectedResourceMap,
        matchingProtectedResources.relativeResources,
        httpMethod
      );
    }

    return null;
  }

  private matchResourcesToEndpoint(
    protectedResourcesEndpoints: string[],
    endpoint: string
  ): MatchingResources {
    const matchingResources: MatchingResources = {
      absoluteResources: [],
      relativeResources: [],
    };

    protectedResourcesEndpoints.forEach((key: string) => {
      // Normalizes and adds resource to matchingResources.absoluteResources if key matches endpoint. StringUtils.matchPattern accounts for wildcards
      const normalizedKey: string = this.location.normalize(key);
      if (StringUtils.matchPattern(normalizedKey, endpoint)) {
        matchingResources.absoluteResources.push(key);
      }

      // Get url components for relative urls
      const absoluteKey: string = this.getAbsoluteUrl(key);
      const keyComponents: any = new UrlString(absoluteKey).getUrlComponents();
      const absoluteEndpoint: string = this.getAbsoluteUrl(endpoint);
      const endpointComponents: any = new UrlString(
        absoluteEndpoint
      ).getUrlComponents();

      // Normalized key should include query strings if applicable
      const relativeNormalizedKey: string = keyComponents.QueryString
        ? `${keyComponents.AbsolutePath}?${keyComponents.QueryString}`
        : this.location.normalize(keyComponents.AbsolutePath);

      // Add resource to matchingResources.relativeResources if same origin, relativeKey matches endpoint, and is not empty
      if (
        keyComponents.HostNameAndPort === endpointComponents.HostNameAndPort &&
        StringUtils.matchPattern(relativeNormalizedKey, absoluteEndpoint) &&
        relativeNormalizedKey !== '' &&
        relativeNormalizedKey !== '/*'
      ) {
        matchingResources.relativeResources.push(key);
      }
    });

    return matchingResources;
  }

  private getAbsoluteUrl(url: string): string {
    const link: any = this._document.createElement('a');
    link.href = url;
    return link.href;
  }

  private matchScopesToEndpoint(
    protectedResourceMap: Map<
      string,
      Array<string | ProtectedResourceScopes> | null
    >,
    endpointArray: string[],
    httpMethod: string
  ): Array<string> | null {
    const allMatchedScopes: (string[] | null)[] = [];

    // Check each matched endpoint for matching HttpMethod and scopes
    endpointArray.forEach((matchedEndpoint: string) => {
      const scopesForEndpoint: string[] = [];
      const methodAndScopesArray: (string | ProtectedResourceScopes)[] =
        protectedResourceMap.get(matchedEndpoint);

      // Return if resource is unprotected
      if (methodAndScopesArray === null) {
        allMatchedScopes.push(null);
        return;
      }

      methodAndScopesArray.forEach(
        (entry: string | ProtectedResourceScopes) => {
          // Entry is either array of scopes or ProtectedResourceScopes object
          if (typeof entry === 'string') {
            scopesForEndpoint.push(entry);
          } else {
            // Ensure methods being compared are normalized
            const normalizedRequestMethod: string = httpMethod.toLowerCase();
            const normalizedResourceMethod: string =
              entry.httpMethod.toLowerCase();

            // Method in protectedResourceMap matches request http method
            if (normalizedResourceMethod === normalizedRequestMethod) {
              entry.scopes.forEach((scope: string) => {
                scopesForEndpoint.push(scope);
              });
            }
          }
        }
      );

      // Only add to all scopes if scopes for endpoint and method is found
      if (scopesForEndpoint.length > 0) {
        allMatchedScopes.push(scopesForEndpoint);
      }
    });

    if (allMatchedScopes.length > 0) {
      if (allMatchedScopes.length > 1) {
        // Returns scopes for first matching endpoint
        return allMatchedScopes[0];
      }

      return allMatchedScopes[0];
    }

    return null;
  }

  private injectToken(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    request = this.addHeader(
      request,
      Headers.AUTHORIZATION_TOKEN_HEADER,
      `Bearer ${token}`
    );
    return request;
  }

  private addHeader(
    request: HttpRequest<any>,
    headerName: string,
    headerValue: string
  ): HttpRequest<any> {
    request = request.clone({
      headers: request.headers.set(headerName, headerValue),
    });
    return request;
  }

  private addHeaderList(
    request: HttpRequest<any>,
    account: AccountInfo
  ): HttpRequest<any> {
    if (!request.headers.has(Headers.CONTENT_TYPE_HEADER)) {
      request = this.addHeader(
        request,
        Headers.CONTENT_TYPE_HEADER,
        ContentTypes.CONTENT_TYPE_JSON
      );
    }

    const toAdd: string[] = request.headers.get(Headers.TO_ADD_HEADER)
      ? request.headers.get(Headers.TO_ADD_HEADER).split(',')
      : [];

    toAdd.forEach((h) => {
      switch (h) {
        case Headers.CLIENTAPPID_HEADER:
          request = this.addHeader(
            request,
            Headers.CLIENTAPPID_HEADER,
            environment.clientId
          );
          break;
        case Headers.IMPERSONATION_USER_HEADER:
          let user: string;
          const impersonatedUser: string = localStorage.getItem(
            StorageKeys.IMPERSONATED_USER_KEY
          );
          if (impersonatedUser) {
            user = impersonatedUser;
          } else {
            user = account.username.split('@')[0];
          }
          const isCloud = request.url.includes('isCloud=')
            ? request.url.split('isCloud=')[1].toLocaleLowerCase()
            : 'false';
          if (
            !(
              isCloud === 'true' &&
              (impersonatedUser === null || impersonatedUser === undefined)
            )
          ) {
            request = this.addHeader(
              request,
              Headers.IMPERSONATION_USER_HEADER,
              user
            );
          }
          break;
        case Headers.CORRELATION_ID_HEADER:
          request = this.addHeader(
            request,
            Headers.CORRELATION_ID_HEADER,
            uuid.v4()
          );
          break;
      }
    });

    request = request.clone({ headers: request.headers.delete('toAdd') });
    return request;
  }

  private fixUrl(request: HttpRequest<any>): HttpRequest<any> {
    const base: string = request.url.split('#')[0] + '#';

    switch (base) {
      case BaseUrls.CBP:
        request = request.clone({
          url: request.url.replace(BaseUrls.CBP, environment.baseServiceUrl),
        });
        break;
      case BaseUrls.FILE_EXPORTER:
        request = request.clone({
          url: request.url.replace(
            BaseUrls.FILE_EXPORTER,
            environment.fileExporterBaseServiceUrl
          ),
        });
        break;
    }

    return request;
  }
}
