import { assign } from 'lodash';

class TimeframeSchema {
  Name?: string;
  TimePeriodCode: string;
  Type?: string;
  StartTimeId: number;
  EndTimeId: number;
  FiscalYear?: number;
  Description?: string;
}

export class Timeframe extends TimeframeSchema {
  constructor(filterAttributes?: TimeframeSchema) {
    super();
    assign(this, filterAttributes);
  }
}
