import { assign } from 'lodash';

import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import {
  OpportunityPhasing,
  PhasingTab,
} from '../../../entities/opportunity-phasing';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';

class PhasingEditGridConfigSchema {
  opportunity: OpportunityPhasing;
  phasingTab: PhasingTab;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  isReadOnly: boolean;
  isAdmin: boolean;
  phasingMonths?: GridData;
  originalPhasingMonths?: GridData;
}

export class PhasingEditGridConfig extends PhasingEditGridConfigSchema {
  constructor(filterAttributes?: PhasingEditGridConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
