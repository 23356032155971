import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { Endpoints } from 'src/app/shared/constants/endpoints.constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private http: HttpClient) {}

  handleError(error: HttpErrorResponse): void {
    if (environment.production) {
      this.http
        .post<boolean>(
          Endpoints.logs,
          JSON.stringify({
            name: error?.name,
            message: error?.message,
            stack: (error?.error as Error)?.stack,
          })
        )
        .subscribe();
    } else {
      // eslint-disable-next-line no-console
      console.error('An unhandled error has ocurred.', error);
    }
  }
}
