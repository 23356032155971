import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';

export const TabControlNames = {
  backlog: AppModulePaths.backlog.path,
  metrics: 'metric',
  periods: 'period',
  targets: 'target',
  expand: 'expand',
  noActivity: 'noActivity',
};

export const TabFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.Summary,
      value: MetricsOptions.Summary,
    }),
    new TextValuePair({
      text: MetricsOptions.Revenue,
      value: MetricsOptions.Revenue,
    }),
    new TextValuePair({
      text: MetricsOptions.Cci,
      value: MetricsOptions.Cci,
    }),
    new TextValuePair({
      text: MetricsOptions.CciPercentage,
      value: MetricsOptions.CciPercentage,
    }),
  ],
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};
