import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendTileDefinition } from '../../../../entities/trend-tile-definition';
import { TrendTileResponse } from '../../../../entities/trend-tile-response';
import { TrendFilters } from '../../trend-filters/entities/trend-filters';

class TrendChartConfigSchema {
  id: string;
  type: MetricType;
  response: TrendTileResponse;
  trendFilters: TrendFilters;
  selectedFilters: SelectedFilters;
  filters: Filters;
  definition: TrendTileDefinition;
  width?: number = 500;
  height?: number = 150;
  isExpanded: boolean;
}

export class TrendChartConfig extends TrendChartConfigSchema {
  constructor(filterAttributes?: TrendChartConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
