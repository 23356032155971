import { Component } from '@angular/core';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';

@Component({
  selector: 'app-percentage-cell',
  templateUrl: './percentage-cell.component.html',
  styleUrls: ['./percentage-cell.component.scss'],
})
export class PercentageCellComponent {
  params: any;
  data: Record<string, any> = {
    roundDecimal: false,
    numberPipe: new CustomNumberPipe(),
    cellStyleTextAlign: GridCellItemAlign.Right,
    cellStyleFontWeight: 'normal',
    cellClass: '',
    value: '',
  };

  // hasIcon = false;
  // iconCursor = [];
  // iconStyle = [];
  // iconAction = [];
  // iconClass = [];
  // iconName = [];
  // iconTooltip = [];

  agInit(params: any): void {
    this.params = params;

    if (this.params) {
      // if (this.params.icon) {
      //   this.hasIcon = true;

      //   this.iconCursor = [];
      //   this.iconStyle = [];
      //   this.iconAction = [];
      //   this.iconClass = [];
      //   this.iconName = [];
      //   this.iconTooltip = [];

      //   this.params.icon.forEach((icon) => {
      //     if (icon.cursor && icon.cursor !== '') {
      //       this.iconCursor.push(icon.cursor);
      //     }
      //     if (icon.action && icon.action !== '') {
      //       this.iconAction.push(icon.action);
      //     }
      //     if (icon.class && icon.class !== '') {
      //       this.iconClass.push(icon.class);
      //     }
      //     if (icon.name) {
      //       this.iconName.push(CellUtils.decodeIcon(icon.name));
      //       this.iconTooltip.push(CellUtils.decodeTooltip(icon.name));
      //     }
      //   });
      // }

      if (this.params.round) {
        this.data.roundDecimal = params.round;
      }

      if (this.params.align) {
        this.data.cellStyleTextAlign = this.params.align;
      }

      if (this.params.isBold) {
        this.data.cellStyleFontWeight = 'bold';
      }

      if (this.params.cellClass) {
        this.data.cellClass = this.params.cellClass;
      }

      this.data.value = this.format(params.value);
    }
  }

  refresh(params: any): void {
    this.agInit(params);
  }

  private format(value: any): string {
    let result = this.formatNumber(value);

    if (!result) {
      result = '-';
    }

    return result;
  }

  private formatNumber(value: any) {
    if (!value) {
      return undefined;
    }

    if (typeof value === 'number') {
      value = this.data.roundDecimal ? Math.round(value) : value;
      return this.data.numberPipe.transform(
        value / 100,
        ValueType.Percentage,
        '-'
      );
    }

    return value;
  }
}
