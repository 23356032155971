<table *ngIf="trendTable" class="trend-table">
    <thead>
        <tr class="header">
            <th class="first">

            </th>
            <th *ngFor="let p of trendTable.periods" [ngClass]="{'quarter' : isQuarterly(), 'month' : !isQuarterly()}"
                class="painted">
                {{p}}
            </th>
            <th class="painted" [ngClass]="{'quarter' : isQuarterly(), 'month' : !isQuarterly()}">
                TOTAL
            </th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let s of trendTable.sets">
            <tr class="sub-header">
                <td class="title">
                    {{s.title}}
                </td>
                <td *ngFor="let t of s.totalsByPeriod" class="value" [ngClass]="{'negative' : t < 0}">
                    {{t | customNumber : s.valueType : '-'}} {{t && s.valueSymbol ? s.valueSymbol : ''}}
                </td>
                <td class="value total" [ngClass]="{'negative' : s.total < 0}">
                    {{s.total | customNumber : s.valueType : '-'}} {{s.total && s.valueSymbol ? s.valueSymbol : ''}}
                </td>
            </tr>
            <tr *ngFor="let r of s.records" class="item-detail">
                <td class="title">
                    {{r.title}}
                </td>
                <td *ngFor="let v of r.valuesByPeriod" class="value" [ngClass]="{'negative' : v < 0}">
                    {{v | customNumber : s.valueType : '-'}} {{v && s.valueSymbol ? s.valueSymbol : ''}}
                </td>
                <td class="value total" [ngClass]="{'negative' : r.total < 0, 'linkable' : r.link}"
                    (click)="onLinkClicked(r)">
                    {{r.total | customNumber : s.valueType : '-'}} {{r.total && s.valueSymbol ? s.valueSymbol : ''}}
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>