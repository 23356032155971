export const BaseUrls = {
  CBP: 'CBP_BASE#',
  FILE_EXPORTER: 'CBP_FILE_EXPORTER#',
};

export const Endpoints = {
  //#region filters
  accountPlanRole: BaseUrls.CBP + 'account-plan-role',
  logs: BaseUrls.CBP + 'metrics/logs',
  profilePic: BaseUrls.CBP + 'profilepic',
  tabMetrics: BaseUrls.CBP + 'metrics/tabs',
  environmentManager: BaseUrls.CBP + 'environment',
  appstate: BaseUrls.CBP + 'environment/appstate',
  dataRefresh: BaseUrls.CBP + 'data-refresh',
  updateDataRefresh: BaseUrls.CBP + 'data-refresh/update',
  customer: BaseUrls.CBP + 'filters/customer',
  customerFilters: BaseUrls.CBP + 'filters/customerFilters',
  globalFilters: BaseUrls.CBP + 'filters',
  filterDefaults: BaseUrls.CBP + 'filters/defaults',
  filterDefaultsSave: BaseUrls.CBP + 'filters/defaults/save',
  fileExporterDispatch: BaseUrls.FILE_EXPORTER + 'exporter/dispatch/',
  fileExporterStatus: BaseUrls.FILE_EXPORTER + 'exporter/status/',
  fileExporterDownload: BaseUrls.FILE_EXPORTER + 'exporter/download/',
  tile: BaseUrls.CBP + 'tile',
  targetsV2: BaseUrls.CBP + 'targets/v2',
  targetsV2Cache: BaseUrls.CBP + 'targets/v2/cache',
  trend: BaseUrls.CBP + 'table',
  backlogContracts: BaseUrls.CBP + 'backlog',
  actualSalesContracts: BaseUrls.CBP + 'actuals/v2/contracts',
  wins: BaseUrls.CBP + 'wins',
  opportunities: BaseUrls.CBP + 'v2/opportunities',
  opportunitiesSave: BaseUrls.CBP + 'opportunities',
  phasingOpportunities: BaseUrls.CBP + 'phasing/opportunities',
  phasingOpportunity: BaseUrls.CBP + 'phasing/opportunity',
  phasingOpportunitySave: BaseUrls.CBP + 'phasing/opportunity/save',
  locationFilters: BaseUrls.CBP + 'filters/locations',
  // planningWindow: BaseUrls.CBP + 'filters/planningwindow',
  // openPlanningWindow: BaseUrls.CBP + 'filters/openplanningwindow',
  // closePlanningWindow: BaseUrls.CBP + 'filters/closeplanningwindow',
  analytics: BaseUrls.CBP + 'analytics',
  favorites: BaseUrls.CBP + 'analytics/favorites',
  favoritesCustomer: BaseUrls.CBP + 'analytics/favorites/customer',
  speculativeDetail: BaseUrls.CBP + 'speculative',
  speculativeList: BaseUrls.CBP + 'speculative/list',
  speculativeCreate: BaseUrls.CBP + 'speculative/create',
  speculativeS3PresignedUrl:
    BaseUrls.CBP + 'speculative/uploadfile/getsignedurl',
  speculativeUploadFile: BaseUrls.CBP + 'speculative/uploadfile',
  speculativeUploadFileStatus: BaseUrls.CBP + 'speculative/uploadfile/status/',
  speculativeUpdate: BaseUrls.CBP + 'speculative/update',
  speculativeDelete: BaseUrls.CBP + 'speculative/delete',
  speculativeProjected: BaseUrls.CBP + 'speculative/projected',
  speculativeReasonCode: BaseUrls.CBP + 'speculative/reasoncode',
  specultiveGetGcpUpdateStatus: BaseUrls.CBP + 'speculative/getGcpUpdateStatus',
  specultiveGetGcpUpdateMultiStatus:
    BaseUrls.CBP + 'speculative/getGcpUpdateMultiStatus',
  peoplePicker: BaseUrls.CBP + 'peoplepicker/search/',
  boards: BaseUrls.CBP + 'boards',
  boardsCustomer: BaseUrls.CBP + 'boards/customer',
  boardsName: BaseUrls.CBP + 'boards/name',
  boardsDelete: BaseUrls.CBP + 'boards/delete',
  cleanUpNotificator: BaseUrls.CBP + 'cleanup-notificator/customer',
  customerSettings: BaseUrls.CBP + 'customersettings',
  customerSettingsUpdate: BaseUrls.CBP + 'customersettings/update',
  postgreUpdatePhasingStatus: BaseUrls.CBP + 'phasing/getGcpUpdateStatus',
  postgreUpdateProjectionStatus:
    BaseUrls.CBP + 'opportunities/getGcpUpdateStatus',
  getOnlyEndpoint: (x: string) =>
    x.replace(BaseUrls.CBP, '').replace(BaseUrls.FILE_EXPORTER, ''),
};
