import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { ClientGroup } from 'src/app/shared/services/entities/filters/client-group';
import { OptionItem } from '../../../base/options-list/entities/option-item';
import { OptionsListConfig } from '../../../base/options-list/entities/options-list-config';
import { OptionsListUtils } from '../../../base/options-list/utils/options-list.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-client-group',
  templateUrl: './client-group.component.html',
  styleUrls: ['./client-group.component.scss'],
})
export class ClientGroupComponent implements OnChanges {
  @Input() clientGroups: Array<ClientGroup> = [];
  @Input() selectedClientGroups: Array<ClientGroup> = [];
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  selectAllChangedEvent = new Subject<boolean>();

  searchText: string;
  cgsOptionsListConfig: OptionsListConfig;
  startSelection: Array<OptionItem>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientGroups && changes.clientGroups.currentValue) {
      this.cgsOptionsListConfig = new OptionsListConfig({
        items: OptionsListUtils.getItems<ClientGroup>(
          this.clientGroups,
          'ClientGroupCd',
          'ClientGroupDesc'
        ),
        multipleSelect: true,
      });
    }

    if (
      changes.selectedClientGroups &&
      changes.selectedClientGroups.currentValue
    ) {
      this.startSelection = this.selectedClientGroups.map(
        (x: ClientGroup) =>
          new OptionItem({
            value: x.ClientGroupCd,
            text: x.ClientGroupDesc,
          })
      );
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
  }

  onItemSelection(selectedCgs: Array<OptionItem>): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.ClientGroup,
        keyValues: selectedCgs.map(
          (x: OptionItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedCgs.length,
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
