import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import packageJson from '../package.json';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppConfigService } from './app/core/services/app-config.service';

import { LicenseManager } from 'ag-grid-enterprise';
import 'hammerjs';
import 'chartjs-plugin-zoom';

// eslint-disable-next-line max-len
LicenseManager.setLicenseKey(
  'SHI_International_Corp_-_UK_on_behalf_of_Accenture_Spain_client_business_projection_2Devs4_May_2019__MTU1NjkyNDQwMDAwMA==ceca40a0e3a325f7e066fbba824b7ac8'
);

if (environment.production) {
  enableProdMode();
}

function bootstrapApp(): void {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
}

function validateAppVersion(): void {
  window.document.title = `CBP [${packageJson.version}]`;

  try {
    if (!(window !== window.parent && !window.opener)) {
      const cacheKey = '__version__cache';
      const emptyVersion = '0.0.0';
      const version: Array<number> = (new RegExp(/(\d+\.)(\d+\.)(\d)/).exec(
        window.document.title
      ) || [emptyVersion])[0]
        .split('.')
        .map((x: string) => {
          return Number(x) || 0;
        });

      if (version[0] !== 0) {
        const storedVersion: Array<number> = (
          window.localStorage.getItem(cacheKey) || emptyVersion
        )
          .split('.')
          .map((x: string) => {
            return Number(x) || 0;
          });

        if (
          storedVersion[0] !== version[0] ||
          (storedVersion[0] === version[0] && storedVersion[1] !== version[1])
        ) {
          window.localStorage.clear();
          window.sessionStorage.clear();
          document.cookie.split(';').forEach((x: string) => {
            document.cookie =
              (x.indexOf('=') > -1 ? x.substring(0, x.indexOf('=')) : x) +
              '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
          });
          window.localStorage.setItem(cacheKey, version.join('.'));
          window.location.href = '/';
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // We bootstraping app from browser, we need fetch config.json.
  fetch(AppConfigService.configPath)
    .then((response) => {
      return response.json();
    })
    .then((jsonData) => {
      sessionStorage[AppConfigService.configPath] = JSON.stringify(jsonData);
      validateAppVersion();
      bootstrapApp();
    })
    .catch((err) => {
      console.warn('caught when bootstrapping app');
      console.error(err);
    });
});
