import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendTileResponse } from '../../entities/trend-tile-response';
import { TrendFilters } from '../../trend-tile/components/trend-filters/entities/trend-filters';

class TrendModalConfigSchema {
  tileParentId: string;
  isChartView?: boolean = false;
  type: MetricType;
  response: TrendTileResponse;
  trendFilters: TrendFilters;
  filters: Filters;
  selectedFilters: SelectedFilters;
}

export class TrendModalConfig extends TrendModalConfigSchema {
  constructor(filterAttributes?: TrendModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
