import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { ComponentNames } from 'src/app/modules/ieopportunity/wins/components/wins-grid/constants/wins-grid.constants';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarSwitch,
} from '../../components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Endpoints } from '../../constants/endpoints.constants';
import {
  FileExporterFileNames,
  FileExporterType,
} from '../../constants/file-exporter.constants';
import { FileExportRequestPayload } from '../entities/file-export-request-payload';
import { SelectedFilters } from '../entities/filters/selected-filters';
import { WinsResponse } from '../entities/grids/wins-response';
import { RequestPayload } from '../entities/request-payload';

import { FileExporterService } from '../file-exporter.service';

@Injectable({
  providedIn: 'root',
})
export class WinsGridService {
  constructor(
    private http: HttpClient,
    private fileExporterService: FileExporterService
  ) {}

  getOpporunities(
    request: RequestPayload,
    isCloud: boolean
  ): Promise<Array<WinsResponse>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Array<WinsResponse>>(
        Endpoints.wins + '?isCloud=' + isCloud,
        request,
        options
      )
      .pipe(
        switchMap((x: Array<WinsResponse>) => {
          return of(x.map((y: WinsResponse) => new WinsResponse(y)));
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  export(
    selectedFilters: SelectedFilters,
    toolbarConfig: GridToolbarConfig,
    breakdowns: Record<string, boolean>,
    isCloud: boolean
  ): Promise<boolean> {
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );
    const noActivitySwitch: GridToolbarSwitch = toolbarConfig.findControl(
      ComponentNames.noActivity
    );

    const extraParams: Record<string, any> = {
      breakdownSG: breakdowns.isSgBreakdown,
      breakdownWMU: breakdowns.isWmuBreakdown,
      target: targetsDropdown.selected.value,
      noActivity: noActivitySwitch.switchValue, // Exclude No Activity
      stage: 'opportunity',
    };

    const payload: FileExportRequestPayload =
      FileExportRequestPayload.createRequest(selectedFilters, extraParams);

    const fileName: string =
      breakdowns.isSgBreakdown && breakdowns.isWmuBreakdown
        ? FileExporterFileNames.OpportunitySgWmu
        : breakdowns.isSgBreakdown
        ? FileExporterFileNames.OpportunitySg
        : breakdowns.isWmuBreakdown
        ? FileExporterFileNames.OpportunityWmu
        : FileExporterFileNames.Opportunity;

    return this.fileExporterService.export(
      FileExporterType.wins,
      payload,
      fileName,
      isCloud
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (WinsGridService)');
  }
}
