<div class="tab-picker-container">
  <h6>Tabs:</h6>
  <button *ngFor="let t of tabs" (click)="onTabChanged(t)" ngbDropdownItem>
    <fa-icon [icon]="faCheckCircle" *ngIf="selectedNavBarTab?.id === t.id"></fa-icon>
    <span [ngClass]="{'not-selected' : selectedNavBarTab?.id !== t.id, 'selected' : selectedNavBarTab?.id === t.id}">{{
      t.text
      }}</span>
  </button>
  <div class="controls-container">
    <app-board-overview-tab *ngIf="selectedNavBarTab?.id === appModulePaths.overview.id"
      (selectionChangeEvent)="getSelectedValues($event)"></app-board-overview-tab>
    <app-board-backlog-tab *ngIf="selectedNavBarTab?.id === appModulePaths.backlog.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables">
    </app-board-backlog-tab>
    <app-board-projection-tab *ngIf="selectedNavBarTab?.id === appModulePaths.ieopportunity.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables">
    </app-board-projection-tab>
    <app-board-phasing-tab *ngIf="selectedNavBarTab?.id === appModulePaths.phasing.id"
    (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
    [tabsObservables]="tabsObservables">
    </app-board-phasing-tab>
    <app-board-speculative-tab *ngIf="selectedNavBarTab?.id === appModulePaths.speculative.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables"></app-board-speculative-tab>
    <app-board-analytics-tab *ngIf="selectedNavBarTab?.id === appModulePaths.analytics.id"
      (selectionChangeEvent)="getSelectedValues($event)"></app-board-analytics-tab>
  </div>
</div>
