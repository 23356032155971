import { assign } from 'lodash';

class ProjectionSchema {
  MonthLabel: string;
  StartTimeId: number;
  EndTimeId: number;
}

export class Projection extends ProjectionSchema {
  constructor(filterAttributes?: ProjectionSchema) {
    super();
    assign(this, filterAttributes);
  }

  getText(): string {
    return `${this.MonthLabel} Projection`;
  }

  getAbreviatedText(): string {
    return `${this.MonthLabel} PROJ`;
  }
}
