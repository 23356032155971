import { EventEmitter, Injectable } from '@angular/core';

import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';
import { AppMessageContentComponent } from '../components/app-message-content/app-message-content.component';
import { AppMessage } from './entities/app-message';

@Injectable({
  providedIn: 'root',
})
export class AppMessagesService {
  modals = new Array<NgbModalRef>();
  messages = new Array<AppMessage>();
  showMessageTimeout: any;
  messageListChanged = new EventEmitter<Array<AppMessage>>();

  constructor(private modalService: NgbModal) {}

  show(message: AppMessage, options?: NgbModalOptions, timeout = 500): void {
    if (!message.showOnce || (message.showOnce && !this.hasShown(message))) {
      clearTimeout(this.showMessageTimeout);
      this.showMessageTimeout = setTimeout(() => {
        this.saveIfOnce(message);
        this.messages.push(message);
        options = options ?? {};
        options.windowClass = options.windowClass ?? '';
        options.windowClass += 'app-message-dialog';

        const modal: NgbModalRef = this.modalService.open(
          AppMessageContentComponent,
          options
        );
        modal.componentInstance.message = message;
        modal.componentInstance.id = message.id;
        this.modals.push(modal);
      }, timeout);
    }
  }

  close(id: string): void {
    const index: number = this.modals.findIndex(
      (x: NgbModalRef) => x.componentInstance?.id === id
    );

    if (index > -1) {
      this.modals[index].close();
      this.modals.splice(index, 1);
    } else {
      this.modalService.dismissAll();
      this.modals = [];
    }
  }

  saveIfOnce(message: AppMessage): void {
    if (message.showOnce) {
      StorageUtils.set(
        StorageType.SessionStorage,
        `message-${message.id}`,
        true
      );
    }
  }

  hasShown(message: AppMessage): boolean {
    return !!StorageUtils.get(
      StorageType.SessionStorage,
      `message-${message.id}`
    );
  }
}
