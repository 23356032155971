<!-- <div class="ag-custom-cell" [ngClass]="params.rowIndex === 0 ? 'totals-cell ' + cellClass : '' + cellClass"
    [style.justify-content]="params.align">
    <span [style.font-weight]="params.rowIndex === 0 ? 500 : 'normal'" [ngClass]="{'negative': params.value < 0}"
        *ngIf="params">{{format(params.value) || '-'}}</span>
    <div *ngFor="let item of iconName; let i = index" class="delay-tooltip" data-placement="top" ngbTooltip="Tooltip"
        [title]="iconTooltip[i]">
        <span id="cell-icon" *ngIf="hasIcon" [style.cursor]="iconCursor[i]"
            [attr.data-action-type]="iconAction[i]!==''?iconAction[i]:null"
            [class]="iconClass[i] + ' ' + iconName[i]"></span>
    </div>
</div> -->

<div class="ag-custom-cell" [ngClass]="data.cellClass" [style.justify-content]="data.align">
    <span [style.font-weight]="params.rowIndex === 0 ? 500 : 'normal'" [ngClass]="{'negative': params.value < 0}"
        *ngIf="params">{{format(params.value) || '-'}}</span>
</div>