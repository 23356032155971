import { GridToolbarDropdown } from '../components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { TabDropdown } from '../components/board-modal/components/tabs-picker/components/tab/entities/tab-config';
import {
  CustomCurrencies,
  Periods,
  TimePeriodCodes,
} from '../constants/filters.constants';
import { ViewOptions } from '../constants/grid.constants';
import { TextValuePair } from '../services/entities/common/key-value';
import { MmbDate } from '../services/entities/filters/date';
import { Filters } from '../services/entities/filters/filters';
import { SelectedFilters } from '../services/entities/filters/selected-filters';

export class GridValidationsUtils {
  static validatePeriodSelection(
    periodsDropdown: GridToolbarDropdown | TabDropdown,
    selectedFilters: SelectedFilters,
    callback?: () => void
  ): void {
    const isSingleMonth: boolean =
      selectedFilters.timeframe.code === TimePeriodCodes.CurrentMonth ||
      selectedFilters.timeframe.code === TimePeriodCodes.MonthToDate;

    const quarterlyOption: TextValuePair = periodsDropdown.items.find(
      (x: TextValuePair) => x.value === Periods.Quarter.id
    );

    const isQuarterlyDisabled: boolean = isSingleMonth;

    if (quarterlyOption) {
      quarterlyOption.params = {
        isDisabled: (): boolean => {
          return isQuarterlyDisabled;
        },
      };
    }

    if (
      (isQuarterlyDisabled &&
        periodsDropdown.selected.value === Periods.Quarter.id) ||
      selectedFilters.timeframe.code === TimePeriodCodes.TwelveRollingMonths
    ) {
      periodsDropdown.selected = periodsDropdown.items.find(
        (x: TextValuePair) => x.value === Periods.Month.id
      );
      if (callback) {
        callback();
      }
    }

    if (
      selectedFilters.timeframe.code === TimePeriodCodes.FourRollingQuarters
    ) {
      periodsDropdown.selected = periodsDropdown.items.find(
        (x: TextValuePair) => x.value === Periods.Quarter.id
      );
      if (callback) {
        callback();
      }
    }

    callback = null;
  }

  static validateSubMetricSelection(
    targetsDropdown: GridToolbarDropdown | TabDropdown,
    filters: Filters,
    selectedFilters: SelectedFilters,
    callback: () => void
  ): void {
    const projectionDate: MmbDate = filters.dates.find(
      (x: MmbDate) => x.Id === selectedFilters.projection.StartTimeId
    );

    const isProjectionDisabled: boolean =
      [CustomCurrencies.FxAdjusted.id, CustomCurrencies.Constant.id].includes(
        selectedFilters.currency.Id
      ) || selectedFilters.timeframe.fiscalYear < projectionDate.FiscalYearNbr;

    targetsDropdown.items
      .filter((x: TextValuePair) => x.value !== ViewOptions.Current)
      .forEach((x: TextValuePair) => {
        x.params = {
          isDisabled: (): boolean => {
            return isProjectionDisabled;
          },
        };
      });

    if (
      isProjectionDisabled &&
      targetsDropdown.selected.value !== ViewOptions.Current
    ) {
      targetsDropdown.selected = targetsDropdown.items.find(
        (x: TextValuePair) => x.value === ViewOptions.Current
      );
      if (callback) {
        callback();
      }
    }

    callback = null;
  }
}
