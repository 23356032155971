import {
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

export const TabControlNames = {
  opportunities: AppModulePaths.ieopportunity.subtabs.opportunities.path,
  types: 'oppType',
  targets: 'target',
  expand: 'expand',
  noActivity: 'noActivity',
  msaIndicator: 'msaIndicator',
};

export const TabFilters = {
  Types: [
    new TextValuePair({
      text: OpportunityTypes.All,
      value: OpportunityTypes.All,
    }),
    new TextValuePair({
      text: OpportunityTypes.Qualified,
      value: OpportunityTypes.Qualified,
    }),
    new TextValuePair({
      text: OpportunityTypes.Unqualified,
      value: OpportunityTypes.Unqualified,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};
