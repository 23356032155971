<div class="trend-tile card">
    <div class="trend-tile-content card-body">
        <div class="trend-tile-header">
            <div class="trend-tile-title">
                <app-drop-down [items]="types" [selectedItem]="selectedType"
                    (itemSelectionChangeEvent)="onTypeChanged($event)"></app-drop-down>
            </div>
            <app-trend-filters [config]="trendFiltersConfig"
                (filtersSelectionChangedEvent)="onFiltersSelectionChanged($event)"
                (expandSelectedEvent)="onExpandSelected()" (loadingCompletedEvent)="onFiltersLoadingCompleted($event)">
            </app-trend-filters>
            <span class="beta-icon" class="droplet_position"  *ngIf="isCloud"><app-mmb-feature-toggle></app-mmb-feature-toggle></span>  
        </div>
        <div *ngIf="loaded && !hasErrors" class="trend-tile-content">
            <app-trend-chart [config]="trendChartConfig">
            </app-trend-chart>
        </div>
        <div *ngIf="loaded && !hasErrors" class="trend-tile-footer">
            <button class="btn btn-link link-table" (click)="onOpenTrendModalByViewType(false)">TABLE VIEW</button>
        </div>
        <div *ngIf="loaded && hasErrors" class="tile-error text-center">
            <span class="tile-error-header">Sorry!</span>
            <span class="tile-error-body d-block">{{errorMessage}}</span>
        </div>
        <app-spinner name="trend-tile-spinner-{{id}}" [showSpinner]="!loaded" [fullScreen]="false"
            spinnerColor="#3e4851">
        </app-spinner>
    </div>
</div>