import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-selection-control',
  templateUrl: './selection-control.component.html',
  styleUrls: ['./selection-control.component.scss'],
})
export class SelectionControlComponent {
  @Input() showSelectAll = true;
  @Input() showDeselectAll = true;
  @Output() selectionChangeEvent = new EventEmitter<boolean>();

  onSelection(selectAll: boolean): void {
    this.selectionChangeEvent.emit(selectAll);
  }
}
