import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RequestPayload } from '../entities/request-payload';
import { Headers } from 'src/app/core/constants/http.constants';
import { BoardResponseItem } from '../entities/board-response';
import { BoardModalConfig } from '../../components/board-modal/entities/board-modal-config';
import { ShareBoardModalConfig } from '../../components/board-picker/components/share-board-modal/entities/share-board-modal-config';

@Injectable({
  providedIn: 'root',
})
export class BoardModalService {
  private openBoardModalEvent = new EventEmitter<BoardModalConfig>();
  private openShareBoardModalEvent = new EventEmitter<ShareBoardModalConfig>();

  public get openBoardModalEmitted(): EventEmitter<BoardModalConfig> {
    return this.openBoardModalEvent;
  }

  public get openShareBoardModalEmitted(): EventEmitter<ShareBoardModalConfig> {
    return this.openShareBoardModalEvent;
  }

  constructor(private http: HttpClient) {}

  getBoard(uri: string, request: RequestPayload): Promise<BoardResponseItem> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<BoardResponseItem>(uri, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (BoardModalService)');
  }
}
