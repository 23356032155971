<button class="btn footer-btn" (click)="toggleFooterPicker()">
    {{showFooter ? 'Hide' : 'Show'}}
</button>

<footer *ngIf="showFooter" class="feedback" id="feed">
    <a href="{{privacyStatementLink}}" title="{{privacyStatementLink}}" target="_blank">Privacy Statement</a>
    <a href="{{termsOfUseLink}}" title="{{termsOfUseLink}}" target="_blank">Terms of Use</a>
    <a href="{{feedbackLink}}">Feedback</a>
    <p>&copy; 2020 Accenture All Rights Reserved. Accenture Confidential. For Internal Use Only.</p>
</footer>
