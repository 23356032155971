import { assign } from 'lodash';

class FilterDefaultsSchema {
  id?: string;
  customerNbr: string;
  componentName: string;
  fields: Array<FilterDefaultField>;
}

export class FilterDefaults extends FilterDefaultsSchema {
  constructor(filterAttributes?: FilterDefaultsSchema) {
    super();
    assign(this, filterAttributes);
  }

  getFieldValue(fieldName: string): any {
    if (this.fields && this.fields.length > 0) {
      const field: FilterDefaultField = this.fields.find(
        (x: FilterDefaultField) => x.fieldName === fieldName
      );
      return field ? field.fieldValue : null;
    }

    return null;
  }

  setFieldValue(fieldName: string, fieldValue: any): void {
    this.fields = this.fields ?? [];
    const field: FilterDefaultField = this.fields.find(
      (x: FilterDefaultField) => x.fieldName === fieldName
    );

    if (field) {
      field.fieldValue = fieldValue;
    } else {
      this.fields.push(
        new FilterDefaultField({
          fieldName,
          fieldValue,
        })
      );
    }
  }
}

class FilterDefaultFieldSchema {
  fieldName: string;
  fieldValue: any;
}

export class FilterDefaultField extends FilterDefaultFieldSchema {
  constructor(filterAttributes?: FilterDefaultFieldSchema) {
    super();
    assign(this, filterAttributes);
  }
}
