import { assign } from 'lodash';

class TextValuePairSchema {
  text: string;
  value: any;
  params?: Record<string, any> = {};
}

export class TextValuePair extends TextValuePairSchema {
  constructor(filterAttributes?: TextValuePairSchema) {
    super();
    assign(this, filterAttributes);
  }

  static findValueOrDefault(
    source: Array<TextValuePair>,
    value: string,
    defaultValue?: string
  ): TextValuePair {
    if (source) {
      return (
        source.find((y: TextValuePair) => y.value === value) ||
        source.find((y: TextValuePair) => y.value === defaultValue)
      );
    }

    return null;
  }
}
