import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { assign } from 'lodash';

class GridCellIconSchema {
  cursor?: string = 'pointer';
  icon: IconProp;
  tooltip?: string;
  onClick: (params?: any) => void;
  showIconByRow?: (params?: any) => boolean;
}

export class GridCellIcon extends GridCellIconSchema {
  constructor(filterAttributes?: GridCellIconSchema) {
    super();
    assign(this, filterAttributes);
  }
}
