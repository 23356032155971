import { cloneDeep } from 'lodash';
import { GroupsBy } from 'src/app/shared/constants/filters.constants';

import { CustomOptions } from 'src/app/shared/constants/grid.constants';
import { AnalyticsResponseItem } from 'src/app/shared/services/entities/grids/analytics-response';
import { RecordTypes } from '../constants/analytics-grid.constants';

export class AnalyticsGridUtils {
  static mapData(
    source: Array<AnalyticsResponseItem>
  ): Array<Record<string, any>> {
    return source.map((x: AnalyticsResponseItem) => {
      const row: Record<string, any> = {
        groupKey1: x.groupKey1,
        groupKey2: x.groupKey2,
        groupKey3: x.groupKey3,
        subGroupKey1: x.subGroupKey1,
        subGroupKey2: x.subGroupKey2,
        subGroupKey3: x.subGroupKey3,
        referenceId: x.referenceId,
        sales: x.sales,
        revenue: x.revenue,
        cci: x.cci,
        cciPercent: x.cciPercent,
        wonCci: x.wonCci,

        salesDataset1: x.salesDataset1,
        revenueDataset1: x.revenueDataset1,
        cciDataset1: x.cciDataset1,
        cciPercentDataset1: x.cciPercentDataset1,
        wonCciDataset1: x.wonCciDataset1,

        salesDataset2: x.salesDataset2,
        revenueDataset2: x.revenueDataset2,
        cciDataset2: x.cciDataset2,
        cciPercentDataset2: x.cciPercentDataset2,
        wonCciDataset2: x.wonCciDataset2,
      };
      return row;
    });
  }

  static adjustDataByRecord(
    source: Array<AnalyticsResponseItem>,
    group1: string,
    group2: string
  ): Array<AnalyticsResponseItem> {
    const sourceCopy: Array<AnalyticsResponseItem> = cloneDeep(source);
    const group: string =
      group1 === GroupsBy.ByRecord.id
        ? 'groupKey1'
        : group2 === GroupsBy.ByRecord.id
        ? 'groupKey2'
        : 'groupKey3';

    sourceCopy.forEach(
      (x: AnalyticsResponseItem) =>
        (x.subGroupKey1 = x[group].startsWith(RecordTypes.Contract)
          ? RecordTypes.Contract
          : x[group].startsWith(RecordTypes.Wins)
          ? RecordTypes.Wins
          : x[group].startsWith(RecordTypes.Opportunity)
          ? RecordTypes.Opportunity
          : RecordTypes.Speculative)
    );

    sourceCopy.forEach((x: AnalyticsResponseItem) => {
      if (x.subGroupKey1 !== RecordTypes.Contract) {
        x.subGroupKey2 = x[group].replace(x[group].split('-', 2)[0] + '-', '');
      } else {
        if (x[group].includes(CustomOptions.NotAssigned.text)) {
          x.subGroupKey2 = CustomOptions.NotAssigned.text;
        } else {
          const name: string = x[group].replace(x.subGroupKey1, '');
          const id: string = name.slice(
            name.indexOf('9'),
            name.indexOf('9') + 11
          );
          x.subGroupKey2 = name.replace(id, '').replace('-', '');
        }
      }
    });

    sourceCopy.forEach((x: AnalyticsResponseItem) => {
      if (x.subGroupKey1 !== RecordTypes.Contract) {
        let id: string = x[group].replace(x.subGroupKey1, '');
        id = id.replace(x.subGroupKey2, '');
        x.subGroupKey3 = id.replace('-', '');
      } else {
        if (x[group].includes(CustomOptions.NotAssigned.text)) {
          x.subGroupKey3 = CustomOptions.NotAssigned.id;
        } else {
          let id: string = x[group].replace(x.subGroupKey1, '');
          id = id.slice(id.indexOf('9'), id.indexOf('9') + 11);
          x.subGroupKey3 = id.replace('-', '');
        }
      }
    });

    sourceCopy.forEach((x: AnalyticsResponseItem) => {
      if (
        x.groupKey1.includes(' / 11') ||
        x.groupKey2.includes(' / 11') ||
        x.groupKey3.includes(' / 11') ||
        x.groupKey1.includes(' / 99') ||
        x.groupKey2.includes(' / 99') ||
        x.groupKey3.includes(' / 99') ||
        x.groupKey1.includes(' / Multiple') ||
        x.groupKey2.includes(' / Multiple') ||
        x.groupKey3.includes(' / Multiple')
      ) {
        x.referenceId = x.subGroupKey2.slice(
          x.subGroupKey2.indexOf('/') + 2,
          x.subGroupKey2.indexOf('/') + 12
        );
        x.subGroupKey2 = x.subGroupKey2.slice(0, x.subGroupKey2.indexOf('/'));
      } else {
        x.referenceId = '-';
      }
    });

    return sourceCopy;
  }
}
