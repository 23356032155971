<ng-template #boardModal let-modal>
  <div class="modal-header">
    <div class="d-flex justify-content-between">
      <div class="form-group d-flex mb-0">
        <label for="boardName" class="d-flex align-items-center mr-3 mb-0"><strong>Board Name:*</strong></label>
        <input type="board" class="form-control mr-3" id="boardName" [(ngModel)]="boardName"
            aria-describedby="boardName" placeholder="Insert Board Name" maxlength="50">
        <small class="font-weight-bold d-flex align-items-center">{{ boardName.length }}/50</small>
      </div>
      <button class="btn btn-link close-icon" (click)="onCloseModal()">
          <span>
              Close
              <fa-icon [icon]="faTimes"></fa-icon>
          </span>
      </button>
    </div>
    <div class="breadcrumb-container mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item ml-2">Filters ({{ totalFilters ?? 0 }})</li>
          <li class="breadcrumb-item">{{ selectedTimeframe.title }}</li>
          <li class="breadcrumb-item">{{ selectedCurrency.Description }}</li>
          <li class="breadcrumb-item">{{ selectedTab.Name | titlecase }}</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-between container">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical" [destroyOnHide]="false">
        <li ngbNavItem="filters" class="options-wrapper">
          <a class="modal-options w-100" ngbNavLink>Filter/s</a>
          <ng-template ngbNavContent style="width: 410px !important;">
            <app-filters-accordion
              (filtersSelectionChangedEvent)="onFiltersSelectionChanged($event)">
            </app-filters-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="timeframe" class="options-wrapper">
          <a class="modal-options w-100" ngbNavLink>Timeframe</a>
          <ng-template ngbNavContent>
            <app-timeframe
              (selectionChangeEvent)="onTimeframeChanged($event)"
              [startSelectedTimeframe]="selectedTimeframe"
              [isModal]="false">
            </app-timeframe>
          </ng-template>
        </li>
        <li ngbNavItem="currency" class="options-wrapper">
          <a class="modal-options w-100" ngbNavLink>Currency</a>
          <ng-template ngbNavContent>
            <app-currency
              (selectionChangeEvent)="onCurrencyChanged($event)"
              [startSelectedCurrency]="selectedCurrency">
            </app-currency>
          </ng-template>
        </li>
        <li ngbNavItem="tabs" class="options-wrapper">
          <a class="modal-options w-100" ngbNavLink>Default Tab</a>
          <ng-template ngbNavContent>
            <div class="tabs-picker-wrapper">
              <app-tabs-picker
                (selectionChangeEvent)="onTabChanged($event)"
                [startSelectedTab]="selectedTab"
                [tabsObservables]="tabsObservables">
              </app-tabs-picker>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="ms-4"></div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="d-flex justify-content-end align-items-center m-1">
      <button class="btn btn-primary ml-3" [disabled]="boardName.length === 0" (click)="onSave()">Save</button>
      <button class="btn btn-primary ml-3" [disabled]="boardName.length === 0" (click)="onSaveAndClose()">Save and close</button>
    </div>
  </div>
</ng-template>
