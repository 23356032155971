import { assign, every } from 'lodash';

import { Periods } from 'src/app/shared/constants/filters.constants';
import { MetricsOptions } from 'src/app/shared/constants/grid.constants';

class BacklogResponseItemSchema {
  GroupKey: string;
  Id: string;
  Name: string;
  Link: string;
  Source: string;
  LastPublished: Date;
  LastSubmitted: Date;
  currentActualRevenueAmt: number;
  currentActualCciAmt: number;
  currentActualCciPct: Record<string, number>;
  currentBacklogRevenueAmt: number;
  currentBacklogCciAmt: number;
  currentBacklogCciPct: Record<string, number>;
  currentTotalRevenueAmt: number;
  currentTotalCciAmt: number;
  currentTotalCciPct: Record<string, number>;
  targetActualRevenueAmt: number;
  targetActualCciAmt: number;
  targetActualCciPct: Record<string, number>;
  targetBacklogRevenueAmt: number;
  targetBacklogCciAmt: number;
  targetBacklogCciPct: Record<string, number>;
  targetTotalRevenueAmt: number;
  targetTotalCciAmt: number;
  targetTotalCciPct: Record<string, number>;
  actualRevenueAmt: number;
  actualCciAmt: number;
  backlogCciAmt: number;
  backlogRevenueAmt: number;
  totalRevenueAmt: number;
  totalCciAmt: number;
  actualCciPct: Record<string, number>;
  backlogCciPct: Record<string, number>;
  totalCciPct: Record<string, number>;
}

export class BacklogResponseItem extends BacklogResponseItemSchema {
  constructor(filterAttributes?: BacklogResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class BacklogResponseSchema extends BacklogResponseItemSchema {
  quarterly: Array<BacklogResponseItem>;
  monthly: Array<BacklogResponseItem>;
}

export class BacklogResponse extends BacklogResponseSchema {
  constructor(filterAttributes?: BacklogResponseSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(metric: string, period: string): boolean {
    const attrName: string =
      period === Periods.Month.id ? 'monthly' : 'quarterly';

    switch (metric) {
      case MetricsOptions.Summary:
        return this.isSummaryInactive(attrName);
      case MetricsOptions.Revenue:
        return this.isRevenueInactive(attrName);
      case MetricsOptions.Cci:
        return this.isCciInactive(attrName);
      case MetricsOptions.CciPercentage:
        return this.isCciPercentageInactive(attrName);
    }

    return false;
  }

  private isRevenueInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: BacklogResponse) => {
        return x.totalRevenueAmt === 0 || !x.totalRevenueAmt;
      }
    );
    return (
      (this.totalRevenueAmt === 0 || !this.totalRevenueAmt) &&
      (this.actualRevenueAmt === 0 || !this.actualRevenueAmt) &&
      (this.backlogRevenueAmt === 0 || !this.backlogRevenueAmt) &&
      hasAllDataZero
    );
  }

  private isCciInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: BacklogResponse) => {
        return x.totalCciAmt === 0 || x.totalCciAmt;
      }
    );
    return (
      (this.totalCciAmt === 0 || !this.totalCciAmt) &&
      (this.actualCciAmt === 0 || !this.actualCciAmt) &&
      (this.backlogCciAmt === 0 || !this.backlogCciAmt) &&
      hasAllDataZero
    );
  }

  private isCciPercentageInactive(attrName: string): boolean {
    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: BacklogResponse) => {
        return x.totalCciPct.cciPercent === 0 || !x.totalCciPct.cciPercent;
      }
    );
    return (
      (this.totalCciPct.cciPercent === 0 || !this.totalCciPct.cciPercent) &&
      (this.actualCciPct.cciPercent === 0 || !this.actualCciPct.cciPercent) &&
      (this.backlogCciPct.cciPercent === 0 || !this.backlogCciPct.cciPercent) &&
      hasAllDataZero
    );
  }

  private isSummaryInactive(attrName: string): boolean {
    return (
      this.isRevenueInactive(attrName) &&
      this.isCciInactive(attrName) &&
      this.isCciPercentageInactive(attrName)
    );
  }
}
