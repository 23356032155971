<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row mt-3">
        <div class="col-auto">
            <app-customer-picker></app-customer-picker>
        </div>
        <div class="col-auto mt-1">
            <app-filters-picker></app-filters-picker>
        </div>
        <div class="col-auto mt-1">
            <app-board-picker></app-board-picker>
        </div>
        <div class="col-auto mt-1 pr-0">
            <app-msa-filter></app-msa-filter>
        </div>
        <div class="col-auto mt-1 pl-0">
            <app-favorite-picker></app-favorite-picker>
        </div>
        <div class="col-auto mt-1">
            <app-admin-indicator></app-admin-indicator>
        </div>
        <div class="col-auto mt-1">
            <app-readonly-mode-indicator></app-readonly-mode-indicator>
        </div>
        <div class="col mt-2 text-right">
            <app-actuals-indicator></app-actuals-indicator>
        </div>
    </div>
    <router-outlet *ngIf="loadingCompleted"></router-outlet>
</div>
<app-toasts></app-toasts>
<notificationframework-universal-header></notificationframework-universal-header>
<notificationframework-modal></notificationframework-modal>
<notificationframework-alert></notificationframework-alert>
<notificationframework-toast></notificationframework-toast>
<notificationframework-chat></notificationframework-chat>
<app-impersonation-indicator></app-impersonation-indicator>
<app-training-mode-indicator></app-training-mode-indicator>
<app-trend-modal></app-trend-modal>
<app-opportunity-phasing-modal></app-opportunity-phasing-modal>
<app-speculative-modal></app-speculative-modal>
<app-board-modal></app-board-modal>
<app-favorite-modal></app-favorite-modal>
<app-spinner name="app-spinner" bdColor="rgba(0, 0, 0, 0.2)" size="medium" [showSpinner]="!loadingCompleted">
</app-spinner>
<div id="app-footer" class="app-footer">
    <app-footer></app-footer>
</div>