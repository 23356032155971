<div class="subtabs-picker-container">
  <h6>Sub-tab:</h6>
  <button *ngFor="let s of subtabs" (click)="onSubtabChanged(s)" ngbDropdownItem>
    <fa-icon [icon]="faCheckCircle" *ngIf="selectedSubtab?.id === s.id"></fa-icon>
    <span [ngClass]="{'not-selected' : selectedSubtab?.id !== s.id, 'selected' : selectedSubtab?.id === s.id}">{{ s.text
      }}</span>
  </button>
  <div class="controls-container">
    <app-board-opportunities-tab *ngIf="selectedSubtab?.id === appModulePaths.ieopportunity.subtabs.opportunities.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables"></app-board-opportunities-tab>
    <app-board-actual-sales-tab *ngIf="selectedSubtab?.id === appModulePaths.ieopportunity.subtabs.actualsales.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables"></app-board-actual-sales-tab>
    <app-board-wins-tab *ngIf="selectedSubtab?.id === appModulePaths.ieopportunity.subtabs.wins.id"
      (selectionChangeEvent)="getSelectedValues($event)" [startSelectedTab]="startSelectedTab"
      [tabsObservables]="tabsObservables"></app-board-wins-tab>
  </div>
</div>
