import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Endpoints } from '../constants/endpoints.constants';
import { FilterDefaults } from './entities/filters/filter-defaults';
import {
  FilterDefaultsResponse,
  FilterDefaultsSaveResponse,
} from './entities/filters/filter-defaults-response';

@Injectable({
  providedIn: 'root',
})
export class FilterDefaultsService {
  private filterDefaultsObservable = new ReplaySubject<Array<FilterDefaults>>(
    1
  );

  private newFilterDefaultsObservable = new ReplaySubject<FilterDefaults>();

  public get filterDefaultsChanged(): ReplaySubject<Array<FilterDefaults>> {
    return this.filterDefaultsObservable;
  }

  public get newFilterDefaultsAdded(): ReplaySubject<FilterDefaults> {
    return this.newFilterDefaultsObservable;
  }

  constructor(private http: HttpClient) {}

  getAllByCustomer(customerNbr: string): void {
    const body = {
      customerNumber: customerNbr,
    };
    this.http
      .post<FilterDefaultsResponse>(Endpoints.filterDefaults, body)
      .toPromise()
      .then(
        (x: FilterDefaultsResponse) => {
          this.filterDefaultsObservable.next(
            x.data.map((y: FilterDefaults) =>
              Object.assign(new FilterDefaults(), y)
            )
          );
        },
        (err: any) => this.errorHandler(err)
      );
  }

  save(filterDefaults: FilterDefaults): Promise<FilterDefaultsSaveResponse> {
    return this.http
      .post<FilterDefaults>(Endpoints.filterDefaultsSave, filterDefaults)
      .pipe(
        switchMap((x: any) => {
          const filterDefaults: FilterDefaults = x.isSucessfull
            ? Object.assign(new FilterDefaults(), x.data[0])
            : null;

          if (x.isSucessfull) {
            this.newFilterDefaultsObservable.next(filterDefaults);
          }

          return of(
            new FilterDefaultsSaveResponse({
              isSucessfull: x.isSucessfull,
              filterDefaults: filterDefaults,
            })
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (FilterDefaultsService)');
  }
}
