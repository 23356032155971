import { DatePipe } from '@angular/common';
import moment from 'moment';
import { cloneDeep, minBy, sumBy } from 'lodash';

import {
  MetricsOptions,
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import {
  ActiveDates,
  Periods,
} from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { DateUtils } from 'src/app/core/utils/date.utils';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';

import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

export const ComponentNames = {
  opportunities: 'phasing',
  metrics: 'metricName',
  periods: 'period',
  types: 'oppType',
  targets: 'target',
  export: 'export',
  expand: 'expand',
  compress: 'compress',
  noActivity: 'noActivity',
  msaIndicator: 'msaIndicator',
};

export const GridFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.Revenue,
      value: MetricsOptions.Revenue,
    }),
    new TextValuePair({
      text: MetricsOptions.Cci,
      value: MetricsOptions.Cci,
    }),
    new TextValuePair({
      text: MetricsOptions.CciPercentage,
      value: MetricsOptions.CciPercentage,
    }),
  ],
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  Types: [
    new TextValuePair({
      text: OpportunityTypes.All,
      value: OpportunityTypes.All,
    }),
    new TextValuePair({
      text: OpportunityTypes.Qualified,
      value: OpportunityTypes.Qualified,
    }),
    new TextValuePair({
      text: OpportunityTypes.Unqualified,
      value: OpportunityTypes.Unqualified,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters,
    icons?: Array<GridCellIcon>
  ): Array<ColDef> => {
    let columnDefs: Array<ColDef> = [];

    const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.metrics
    );
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    const isCustomTimeframe: boolean =
      selectedFilters.timeframe.title === 'Custom';

    const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
    // timeframe.end = customTimeframe ? timeframe.end : timeframe.start + 11;

    if (periodsDropdown.selected.value === Periods.Quarter.id) {
      columnDefs = columnDefs.concat(
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getQuarterlyHeaders(
            timeframe,
            filters.dates,
            isCustomTimeframe
          ),
          metricsDropdown.selected.value,
          targetsDropdown.selected.value
        )
      );
    } else {
      columnDefs = columnDefs.concat(
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getMonthlyHeaders(timeframe, filters.dates),
          metricsDropdown.selected.value,
          targetsDropdown.selected.value
        )
      );
    }

    return GridColDefs.Shared.getBaseGridColDefs(toolbarConfig, icons).concat(
      columnDefs
    );
  },
  Shared: {
    getBaseGridColDefs: (
      toolbarConfig: GridToolbarConfig,
      cellIcons?: Array<GridCellIcon>
    ): Array<ColDef> => {
      const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.metrics
      );
      const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.targets
      );

      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(metricsDropdown.selected.value);

      return [
        {
          headerName: 'OPPORTUNITY ID',
          colId: 'opportunityId',
          field: 'OpportunityLink',
          type: 'linkColumn',
          minWidth: 150,
          pinned: true,
          sort: GridColDefs.Shared.paramSort('opportunityId'),
          valueGetter: (params: any) => {
            return params.data.Id && params.data.Name
              ? params.data.Id
              : undefined;
          },
          pinnedRowCellRendererParams: { forceTotals: true },
          cellRendererParams: (params: any) => {
            let icons: Array<GridCellIcon> = [];

            if (cellIcons) {
              icons = cellIcons.filter(
                (x: GridCellIcon) =>
                  !x.showIconByRow || x.showIconByRow(params.data)
              );
            }

            return {
              icons,
            };
          },
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: true });
          },
        },
        {
          headerName: 'OPPORTUNITY NAME',
          colId: 'opportunityName',
          field: 'OpportunityLink',
          type: 'linkColumn',
          minWidth: 250,
          pinned: true,
          sort: GridColDefs.Shared.paramSort('opportunityName'),
          valueGetter: (params: any) => {
            return params.data.Id && params.data.Name
              ? params.data.Name
              : undefined;
          },
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: false });
          },
        },
        {
          headerName: `TOTAL PROJ ${metricsDropdown.selected.value.toUpperCase()}`,
          pinned: true,
          colId: 'TotalProj',
          field: `TotalProj.${attrs.attrName}`,
          minWidth: 100,
          menuTabs: ['filterMenuTab'],
          sort: GridColDefs.Shared.paramSort('TotalProj'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
          type: attrs.columnType,
          filter: '',
        },
        {
          headerName: `PROJECTED ${metricsDropdown.selected.value.toUpperCase()}`,
          colId: 'Total',
          field: `Total.${attrs.attrName}`,
          type: attrs.columnType,
          minWidth: 100,
          pinned: true,
          menuTabs: ['filterMenuTab'],
          filter: '',
          sortedAt: 10,
          sort: GridColDefs.Shared.paramSort('Total'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
        },
        {
          headerName: 'STATUS',
          colId: 'STATUS',
          field: 'Indicator',
          type: 'iconColumn',
          sort: GridColDefs.Shared.paramSort('STATUS'),
          sortedAt: 0,
          minWidth: 100,
          width: 100,
          pinned: true,
          comparator: (
            valueA: any,
            valueB: any,
            nodeA: any,
            nodeB: any
          ): number => {
            if (nodeA && nodeB) {
              if (
                nodeA.data.IsCompleteFinancials ===
                nodeB.data.IsCompleteFinancials
              ) {
                return (
                  GridColDefs.Shared.getIndicatorValue(nodeA.data.Indicator) -
                  GridColDefs.Shared.getIndicatorValue(nodeB.data.Indicator)
                );
              } else {
                return nodeA.data.IsCompleteFinancials ? 1 : -1;
              }
            }

            return 0;
          },
          filterValueGetter: (params: any) => {
            switch (params.data.Indicator) {
              case 'overwritten':
                return 'Red';
              case 'adjusted':
                return 'Green';
              case 'blank':
                return 'Gray';
              default:
                return '';
            }
          },
          valueGetter: (params: any) => {
            return faCircle as IconProp;
          },
          cellRendererParams: (params: any) => {
            return {
              cellClass: `opp-phasing-status ${params.data.Indicator}`,
            };
          },
        },
      ] as Array<ColDef>;
    },
    addCustomRendererConfigs: (
      periodColDefs: Array<ColDef>,
      type: string,
      view: string
    ): Array<ColDef> => {
      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(type);

      periodColDefs = periodColDefs.map((x: ColDef) => ({
        ...x,
        colId: x.field,
        minWidth: 65,
        field: `${x.field}.${attrs.attrName}`,
        sort: GridColDefs.Shared.paramSort(x.field),
        aggFunc:
          type === MetricsOptions.CciPercentage
            ? view === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage
            : 'sum',
        type: attrs.columnType,
      }));

      return periodColDefs;
    },
    paramSort: (colId: string): string => {
      const sortColumsConfig: Array<SortColum> = [
        { colId: 'Total', sort: 'desc' },
        { colId: 'STATUS', sort: 'desc' },
      ];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
    getMetricAttributes: (type: string): Record<string, string> => {
      const result: Record<string, string> = {
        attrName: '',
        columnType: 'numberColumn',
      };

      switch (type) {
        case MetricsOptions.Revenue:
          result.attrName = 'NetRevenue';
          break;
        case MetricsOptions.Cci:
          result.attrName = 'Cci';
          break;
        case MetricsOptions.CciPercentage:
          result.attrName = 'CciPercent.cciPercent';
          result.columnType = 'percentColumn';
          break;
      }

      return result;
    },
    getOppDateId: (filters: Filters, date: string): number => {
      let startTimeId: number;

      if (date && date.length > 0) {
        const zero: MmbDate = minBy(filters.dates, 'Id');
        const dateFormat = new DatePipe('en-US');
        const dateAux: string = dateFormat.transform(
          DateUtils.normalizeDateString(date),
          'MM/dd/yyyy'
        );
        const splitDate: Array<string> = dateAux.split('/');
        const year: string = splitDate[2];
        const month = Number(splitDate[0]);

        const monthDiff: number = moment([year, month - 1, 1]).diff(
          moment([zero.CalendarYearNbr, zero.MonthNbr - 1, 1]),
          'months',
          true
        );
        startTimeId = zero.Id + monthDiff;
      }

      return startTimeId;
    },
    getIndicatorValue: (indicator: string): number => {
      switch (indicator) {
        case 'overwritten':
          return 3;
        case 'adjusted':
          return 2;
        case 'blank':
          return 1;
        default:
          return 0;
      }
    },
    sumCciPercentage: (values: Array<number>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.cci`
        );
        const totalRevenueAmount: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.revenue`
        );

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentageCompare: (
      values: Array<number>,
      colDef?: ColDef
    ): number => {
      let result = 0;

      if (colDef) {
        const currentCci: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.cciCurrent`
        );
        const currentRevenue: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.revenueCurrent`
        );
        const targetCci: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.cciTarget`
        );
        const targetRevenue: number = sumBy(
          values,
          `${colDef.colId}.CciPercent.revenueTarget`
        );

        if (
          typeof (
            currentCci ||
            currentRevenue ||
            targetCci ||
            targetRevenue
          ) === 'number'
        ) {
          let currentPercentage: number =
            currentCci / Math.abs(currentRevenue) || 0;
          let targetPercentage: number =
            targetCci / Math.abs(targetRevenue) || 0;
          currentPercentage = isFinite(currentPercentage)
            ? currentPercentage
            : 0;
          targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

          result = (currentPercentage - targetPercentage) * 100;
        }
      }

      return result;
    },
  },
  getExtraGridOptions: (filters: Filters): GridOptions => {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    return {
      defaultExportParams: {
        sheetName: 'Opportunities',
        fileName: 'CBP Opportunities',
      } as CsvExportParams,
      rowClassRules: {
        'incomplete-stage': (params: any) => {
          return params.data.Id && !params.data.IsCompleteFinancials;
        },
        'opp-elapsed-dates': (params: any) => {
          return (
            GridColDefs.Shared.getOppDateId(
              filters,
              params.data.StatusSinceDate
            ) < appDate.StartTimeId ||
            GridColDefs.Shared.getOppDateId(filters, params.data.StartDate) <
              appDate.StartTimeId
          );
        },
      },
    } as GridOptions;
  },
};
