import { EventEmitter, Injectable } from '@angular/core';
import { FavoriteFilterDefaults } from 'src/app/modules/analytics/components/favorite-modal/entities/favorite-filter-defaults';
import { FavoriteModalConfig } from 'src/app/modules/analytics/components/favorite-modal/entities/favorite-modal-config';
import { ShareFavoriteModalConfig } from 'src/app/modules/analytics/components/favorite-picker/share-favorite-modal/entities/share-favorite-modal-config';
import { FavoriteResponseItem } from '../entities/favorite-response';

@Injectable({
  providedIn: 'root',
})
export class FavoriteModalService {
  private openFavoriteModalEvent = new EventEmitter<FavoriteModalConfig>();
  private openShareFavoriteModalEvent =
    new EventEmitter<ShareFavoriteModalConfig>();
  private applyFavoriteEvent = new EventEmitter<FavoriteResponseItem>();
  private setFavoriteModalParamsEvent =
    new EventEmitter<FavoriteFilterDefaults>();

  public get openFavoriteModalEmitted(): EventEmitter<FavoriteModalConfig> {
    return this.openFavoriteModalEvent;
  }

  public get openShareFavoriteModalEmitted(): EventEmitter<ShareFavoriteModalConfig> {
    return this.openShareFavoriteModalEvent;
  }

  public get applyFavoriteEmitted(): EventEmitter<FavoriteResponseItem> {
    return this.applyFavoriteEvent;
  }

  public get setFavoriteModalParamsEmitted(): EventEmitter<FavoriteFilterDefaults> {
    return this.setFavoriteModalParamsEvent;
  }

  constructor() {}
}
