import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BetaMessageService {
  private isCloudSubject = new BehaviorSubject<boolean>(false);
  isCloudSubject$ = this.isCloudSubject.asObservable();
  constructor() {}

  updateBetaMessage(value: boolean) {
    this.isCloudSubject.next(value);
  }
}
