import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';

import { Currency } from '../../services/entities/filters/currency';
import {
  BoardFilters,
  BoardResponseItem,
  BoardTab,
} from '../../services/entities/board-response';
import { TimeframeItem } from '../timeframe/entities/timeframe';
import { BoardModalConfig } from './entities/board-modal-config';
import { FiltersManager } from '../filters/entities/filters-manager';
import { BoardSaveModel } from './entities/board-save-model';
import { BoardMapper } from './utils/board-modal-utils';
import { TimeframeUtils } from '../timeframe/utils/timeframe.utils';
import { CurrencyChanged } from '../currency/entities/currency-events';
import { TimeframeChanged } from '../timeframe/entities/timeframe-events';
import { MessageTemplates } from '../../constants/messages.constants';
import { TabsObservables } from './components/tabs-picker/entities/tabs-observables';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';

import { BoardModalService } from '../../services/modals/board-modal.service';
import { FiltersService } from '../../services/filters.service';
import { BoardService } from '../../services/board.service';
import { AppMessagesService } from '../../services/app-messages.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-board-modal',
  templateUrl: './board-modal.component.html',
  styleUrls: ['./board-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BoardModalComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  @ViewChild('boardModal', { static: false }) boardModal: NgbModalRef;

  currentModal: NgbModalRef;
  isOpen = false;

  config: BoardModalConfig;
  selectedTimeframe: TimeframeItem;
  selectedCurrency: Currency;
  selectedTab: BoardTab;
  filtersManager: FiltersManager;
  board: BoardResponseItem;
  boardFilters: BoardFilters;
  boardName: string;
  totalFilters: number;

  tabsObservables = new TabsObservables({
    selectedFiltersChanged: new ReplaySubject<SelectedFilters>(),
    timeframeChanged: new ReplaySubject<TimeframeChanged>(),
    currencyChanged: new ReplaySubject<CurrencyChanged>(),
  });

  faTimes = faTimes as IconProp;

  constructor(
    private filtersService: FiltersService,
    private modalService: NgbModal,
    private boardModalService: BoardModalService,
    private boardService: BoardService,
    private appMessagesService: AppMessagesService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.boardModalService.openBoardModalEmitted.subscribe(
        (x: BoardModalConfig) => {
          this.config = x;
          this.board = this.board ?? new BoardResponseItem();
          Object.setPrototypeOf(this.board, BoardResponseItem.prototype);

          this.setFormData();
          this.initBoard();
          this.openModal();
        }
      )
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setFormData(): void {
    this.boardName = this.config.name;
    this.boardFilters = this.config.boardFilters;
    this.selectedTab = this.config.tab;

    if (this.selectedTab) {
      Object.setPrototypeOf(this.selectedTab, BoardTab.prototype);
    }

    this.selectedCurrency =
      this.config.boardFilters.Currency ?? this.config.selectedFilters.currency;

    this.selectedTimeframe =
      this.config.boardFilters.Timeframe &&
      this.config.boardFilters.Timeframe.TimePeriodCode !== 'Custom'
        ? TimeframeUtils.getTimeframeItemByCode(
            this.config.filters,
            this.config.boardFilters.Timeframe.TimePeriodCode
          )
        : this.config.selectedFilters.timeframe;
  }

  initBoard(): void {
    this.board.boardId = this.config.boardId;
    this.board.name = this.config.name;
    this.board.filters = this.boardFilters;
    this.filtersService.modalFilters(
      this.board.name === '',
      this.mapBoardFilterSelectedFilter(this.board.filters)
    );
  }

  openModal() {
    if (!this.isOpen) {
      this.currentModal = this.modalService.open(this.boardModal, {
        windowClass: 'board-modal',
        centered: true,
        size: 'lg',
      });

      this.isOpen = true;

      this.subscription.add(
        this.currentModal.dismissed.subscribe(() => {
          this.isOpen = false;
        })
      );
    }
  }

  onCloseModal(): void {
    this.currentModal.dismiss();
  }

  onFiltersSelectionChanged(event: FiltersManager): void {
    this.filtersManager = event;

    this.totalFilters =
      (this.filtersManager.wmu ? this.filtersManager.wmu?.count : 0) +
      (this.filtersManager.fc ? this.filtersManager.fc?.count : 0) +
      (this.filtersManager.market ? this.filtersManager.market?.count : 0) +
      (this.filtersManager.cg ? this.filtersManager.cg?.count : 0) +
      (this.filtersManager.sg ? this.filtersManager.sg?.count : 0) +
      (this.filtersManager.industry ? this.filtersManager.industry?.count : 0) +
      (this.filtersManager.functions
        ? this.filtersManager.functions?.count
        : 0) +
      (this.filtersManager.gp ? this.filtersManager.gp?.count : 0) +
      (this.filtersManager.alliances
        ? this.filtersManager.alliances?.count
        : 0);

    this.tabsObservables.selectedFiltersChanged.next(
      event.applySelection(
        this.config.selectedFilters,
        this.config.filters,
        this.config.customerFilters
      )
    );
  }

  onTimeframeChanged(event: TimeframeChanged): void {
    this.selectedTimeframe = event.timeframe;
    this.tabsObservables.timeframeChanged.next(event);
  }

  onCurrencyChanged(event: CurrencyChanged): void {
    this.selectedCurrency = event.currency;
    this.tabsObservables.currencyChanged.next(event);
  }

  onTabChanged(event: BoardTab): void {
    this.selectedTab = event;
  }

  onSave(close?: boolean) {
    this.config.selectedFilters = this.filtersManager.applySelection(
      this.config.selectedFilters,
      this.config.filters,
      this.config.customerFilters
    );

    this.config.selectedFilters.currency = this.selectedCurrency;
    this.config.selectedFilters.timeframe = this.selectedTimeframe;

    this.board.name = this.boardName;

    this.board.tab = this.selectedTab;

    if (this.board.filters) {
      Object.setPrototypeOf(this.board.filters, BoardFilters.prototype);
      this.board.filters.setFilters(this.config.selectedFilters);
    }

    if (this.board.tab) {
      Object.setPrototypeOf(this.board.tab, BoardTab.prototype);
    }

    const payload: BoardSaveModel = BoardMapper.getSaveModel(this.board);

    (this.board.isNew()
      ? this.boardService.createBoard(payload)
      : this.boardService.updateBoard(payload)
    )
      .then((x: boolean) => {
        this.boardService.selectedBoard = this.board;
        this.filtersService.updateSelectedFilters(this.config.selectedFilters);

        if (this.board.tab) {
          this.router.navigate([this.board.tab.getUrl()]).then(() => {
            this.filtersService.applyFilters();
          });
        } else {
          this.filtersService.applyFilters();
        }

        if (close) {
          this.currentModal.dismiss();
        }
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      });
  }

  onSaveAndClose() {
    this.onSave(true);
  }

  private mapBoardFilterSelectedFilter(
    boardFilters: BoardFilters
  ): SelectedFilters {
    const selectedFilters = new SelectedFilters();
    const timeframe = new TimeframeItem();
    const copy: BoardFilters = cloneDeep(boardFilters);

    timeframe.end = copy.Timeframe.EndTimeId;
    timeframe.start = copy.Timeframe.StartTimeId;
    timeframe.code = copy.Timeframe.TimePeriodCode;
    timeframe.title = copy.Timeframe.Name;

    selectedFilters.customer = copy.Customer;
    selectedFilters.currency = copy.Currency;
    selectedFilters.growthPriorities = copy.Sgi;
    selectedFilters.functions = copy.Functions;
    selectedFilters.alliances = copy.Alliances;
    selectedFilters.industries = copy.Industries;
    selectedFilters.serviceGroups = copy.ServiceGroups;
    selectedFilters.markets = copy.Locations;
    selectedFilters.clientGroups = copy.ClientServiceGroups;
    selectedFilters.wmus = copy.Wmus;
    selectedFilters.financialCustomers = copy.FinancialCustomer;
    selectedFilters.timeframe = timeframe;

    return selectedFilters;
  }
}
