import { quadtree } from 'd3';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import {
  Periods,
  TimePeriodCodes,
} from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import {
  ActualSalesResponse,
  ActualSalesResponseItem,
} from 'src/app/shared/services/entities/grids/actualsales-response';
import { ComponentNames } from '../constants/actualsales-grid.constants';

export class ActualSalesGridUtils {
  static mapData(
    source: Array<ActualSalesResponse>,
    filters: Filters,
    toolbarConfig: GridToolbarConfig,
    isCustom: boolean
  ): Array<Record<string, any>> {
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );

    return source.map((x: ActualSalesResponse) => {
      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        return this.mapQuarterlyData(x, isCustom, filters);
      } else {
        return this.mapMonthlyData(x, filters.dates);
      }
    });
  }

  private static mapMonthlyData(
    source: ActualSalesResponse,
    dates: Array<MmbDate>
  ): Record<string, any> {
    let row: Record<string, any> = {
      ContractId: source.ContractId,
      ContractName: source.ContractName,
      ContractLink: source.ContractLink,
      ActualsSales: source.ActualsSales,
    };

    source.MonthlyData.forEach((x: ActualSalesResponseItem) => {
      const period: MmbDate = dates.find((y: MmbDate) => y.Id === x.Id);
      row = {
        ...row,
        [period.Id]: x,
      };
    });

    return row;
  }

  private static mapQuarterlyData(
    source: ActualSalesResponse,
    isCustom: boolean,
    filters: Filters
  ): Record<string, any> {
    let row: Record<string, any> = {
      ContractId: source.ContractId,
      ContractName: source.ContractName,
      ContractLink: source.ContractLink,
      ActualsSales: source.ActualsSales,
    };

    source.QuarterlyData.forEach((x: ActualSalesResponseItem) => {
      let fy: number = filters.timeframe.find(
        (x: any) => x.Name === 'Current FY'
      ).FiscalYear;
      let Id: number;
      if (isCustom) {
        Id =
          x.Id > 4 && x.Id < 9
            ? x.Id - 4
            : x.Id > 8 && x.Id < 13
            ? x.Id - 8
            : x.Id;

        fy =
          x.Id > 4 && x.Id < 9 ? fy : x.Id > 8 && x.Id < 13 ? fy + 1 : fy - 1;
      }
      const quarter: string = isCustom
        ? `q${Id}'${fy.toString().slice(-2)}`
        : `q${x.Id}`;
      row = {
        ...row,
        [quarter]: x,
      };
    });

    return row;
  }
}
