import { sortBy } from 'lodash';

import { OptionItem } from '../entities/option-item';

export class OptionsListUtils {
  static getItems<T>(
    source: Array<T>,
    valueAttr: string,
    textAttr: string,
    callback?: (x: OptionItem, y: T) => void
  ): Array<OptionItem> {
    let result: Array<OptionItem> = [];

    if (source) {
      result = sortBy(
        source.map((x: T) => {
          const item = new OptionItem({
            text: (x as any)[textAttr],
            value: (x as any)[valueAttr],
          });

          if (callback) {
            callback(item, x);
          }

          return item;
        }),
        textAttr
      );
    }

    callback = null;
    return result;
  }
}
