import { assign } from 'lodash';

class NavBarTabSchema {
  id: string;
  text: string;
  url: string;
  subtabs?: Array<NavBarTab>;
  enable?: boolean;
}

export class NavBarTab extends NavBarTabSchema {
  constructor(filterAttributes?: NavBarTabSchema) {
    super();
    assign(this, filterAttributes);
    this.enable = true;
  }
}
