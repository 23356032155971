import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TargetsTileTarget } from './targets-tile-item';
import { TargetsTileProgress } from './targets-tile-progress';

class TargetsTileSchema {
  title: string;
  type: MetricType;
  progress: Array<TargetsTileProgress>;
  targets: Array<TargetsTileTarget>;
}

export class TargetsTile extends TargetsTileSchema {
  constructor(filterAttributes?: TargetsTileSchema) {
    super();
    assign(this, filterAttributes);
  }
}
