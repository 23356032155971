import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isUndefined, remove } from 'lodash';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Subscription } from 'rxjs';
import { StorageType } from 'src/app/core/constants/storage.constants';
import { StorageUtils } from 'src/app/core/utils/storage.utils';

import { environment } from 'src/environments/environment';
import { AppNavBarTabs } from '../../constants/navbar.constants';
import { Customer } from '../../services/entities/filters/customer';
import { CustomerFilters } from '../../services/entities/filters/customer-filters';
import { FinancialCustomer } from '../../services/entities/filters/financial-customer';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { NavBarTab } from '../../services/entities/navbar-tab';

import { FiltersService } from '../../services/filters.service';
import { SharedService } from '../../services/shared.service';

import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { BetaMessageService } from '../../services/beta-message.service';
import { AppStateService } from '../../services/app-state.service';
import { AppState } from '../../services/entities/app-state/app-state';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  tabs: Array<NavBarTab>;
  selectedTab: NavBarTab;
  logOutPath = environment.logOutPath;
  dropdownOpen = false;

  faSignOutAlt = faSignOutAlt as IconProp;
  isCloud = false;
  betaAccessUserList = [];
  isBetaUser: boolean;
  updateClass: boolean;
  constructor(
    private router: Router,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private el: ElementRef,
    private betaMessage: BetaMessageService,
    private appStateService: AppStateService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.appStateService.appStateChanged.subscribe((x: AppState) => {
        this.betaAccessUserList = x.betaAccessUserList;
        this.isBetaUser = this.betaAccessUserList.includes(
          this.sharedService.getEnterpriseId()
        );
      })
    );
    this.getNavTabs();
    this.getActiveNavTab();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getNavTabs(): void {
    this.tabs = AppNavBarTabs;

    this.tabs.forEach((t: NavBarTab) => {
      const entry: [string, boolean] = Object.entries(
        environment.tabsAvailability
      ).find((x: [string, boolean]) => x[0] === t.id);
      t.enable = entry ? entry[1] : false;
    });
  }

  getActiveNavTab(): void {
    this.subscription.add(
      this.router.events.subscribe((e) => {
        if (e instanceof NavigationEnd) {
          this.changeSelectedTab(
            this.tabs.find((x: NavBarTab) => e.url.startsWith(x.url)),
            this.isCloud ? 'GCP' : ''
          );
          this.saveTabForMetrics(e);
        }
      })
    );
  }

  saveTabForMetrics(navigation: NavigationEnd): void {
    this.subscription.add(
      this.filtersService.customerFiltersChanged.subscribe(
        (x: CustomerFilters) => {
          const path: Array<string> = navigation.urlAfterRedirects.split('/');
          remove(path, (y: string) => isUndefined(y) || y.trim().length === 0);
          let result: string;
          let tab: NavBarTab;

          path.forEach((y: string) => {
            if (!tab) {
              tab = this.tabs.find((z: NavBarTab) => z.url === `/${y}`);
              result = tab?.text;
            } else if (tab && tab.subtabs?.length > 0) {
              tab = tab.subtabs.find((z: NavBarTab) => z.url === y);
              result += ` - ${tab?.text}`;
            }
          });

          const cachedFilters: string = StorageUtils.get(
            StorageType.LocalStorage,
            'selectedFilters'
          );

          if (cachedFilters) {
            const selectedFilters = new SelectedFilters(
              JSON.parse(cachedFilters)
            );

            this.sharedService.saveTabForMetrics(
              result,
              selectedFilters.customer
            );
          }
        }
      )
    );
  }

  changeSelectedTab(t: NavBarTab, gcp?: string): void {
    const rep = gcp === 'GCP' ? true : false;
    if (this.isCloud !== rep) {
      this.isCloud = rep;
      this.betaMessage.updateBetaMessage(this.isCloud);
    }
    this.updateClass = t.id == 'ieopportunity' && !this.isCloud ? true : false;
    this.selectedTab = t;
    this.dropdownOpen = false;
    if (rep) {
      this.tabs.forEach((t: NavBarTab) => {
        const myTag = this.el.nativeElement.querySelector('#' + t.id);
        myTag.classList.remove('navbar-tab-active');
      });
    }
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }
}
