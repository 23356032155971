import { assign } from 'lodash';

class GridDataSchema {
  rows: Array<Record<string, any>>;
  pinnedTop: Array<Record<string, any>>;
}

export class GridData extends GridDataSchema {
  constructor(filterAttributes?: GridDataSchema) {
    super();
    assign(this, filterAttributes);
  }
}
