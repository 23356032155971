<div class="filter-container">
  <div class="d-flex justify-content-between">
    <ul ngbNav #nav="ngbNav" class="nav-pills options-container" orientation="vertical" [destroyOnHide]="false">
      <li ngbNavItem="wmu-fc" [disabled]="isFilterBlocked(filterTypes.WMU) && isFilterBlocked(filterTypes.FinancialCustomer)">
        <a class="modal-options" ngbNavLink>WMU / FC ({{ (filtersManager.wmu || filtersManager.fc)?.count ?? 0 }})</a>
        <ng-template ngbNavContent style="width: 410px !important;">
          <app-wmu-fc [wmu]="customerFilters?.wmu" [financialCustomer]="customerFilters?.financialCustomer"
            [selectedWmus]="selectedFilters?.wmus" [selectedFinancialCustomers]="selectedFilters?.financialCustomers"
            (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText" (searchEvent)="getSearchEvent($event)">
          </app-wmu-fc>
        </ng-template>
      </li>
      <li ngbNavItem="market" [disabled]="isFilterBlocked(filterTypes.Market)">
        <a class="modal-options" ngbNavLink>Market ({{ filtersManager.market?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-market [markets]="filters?.locations" [selectedMarkets]="selectedFilters?.markets"
          (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText" (searchEvent)="getSearchEvent($event)">
        </app-market>
        </ng-template>
      </li>
      <li ngbNavItem="cg" [disabled]="isFilterBlocked(filterTypes.ClientGroup)">
        <a class="modal-options" ngbNavLink>CG ({{ filtersManager.cg?.count ?? 0}})</a>
        <ng-template ngbNavContent>
          <app-client-group [clientGroups]="customerFilters?.csg" [selectedClientGroups]="selectedFilters?.clientGroups"
            (itemSelectionChangeEvent)="onItemSelection($event)">
          </app-client-group>
        </ng-template>
      </li>
      <li ngbNavItem="sg" [disabled]="isFilterBlocked(filterTypes.ServiceGroup)">
        <a class="modal-options" ngbNavLink>Service Group ({{ filtersManager.sg?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-service-group [groups]="filters?.serviceGroups?.Groups"
            [serviceGroups]="filters?.serviceGroups?.ServiceGroups"
            [selectedServiceGroups]="selectedFilters?.serviceGroups" (itemSelectionChangeEvent)="onItemSelection($event)">
          </app-service-group>
        </ng-template>
      </li>
      <li ngbNavItem="industry" [disabled]="isFilterBlocked(filterTypes.Industry)">
        <a class="modal-options" ngbNavLink>Industry ({{ filtersManager.industry?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-industry [industries]="filters?.industry" [selectedIndustries]="selectedFilters?.industries"
            (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText" (searchEvent)="getSearchEvent($event)">
          </app-industry>
        </ng-template>
      </li>
      <li ngbNavItem="functions" [disabled]="isFilterBlocked(filterTypes.Function)">
        <a class="modal-options" ngbNavLink>Functions ({{ filtersManager.functions?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-functions [functions]="filters?.functions" [selectedFunctions]="selectedFilters?.functions"
            (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText" (searchEvent)="getSearchEvent($event)">
          </app-functions>
        </ng-template>
      </li>
      <li ngbNavItem="gp" [disabled]="isFilterBlocked(filterTypes.GrowthPriority)">
        <a class="modal-options" ngbNavLink>Growth Engines ({{ filtersManager.gp?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-growth-priorities [growthPriorities]="filters?.sgi"
            [selectedGrowthPriorities]="selectedFilters?.growthPriorities"
            (itemSelectionChangeEvent)="onItemSelection($event)" [searchText]="searchText" (searchEvent)="getSearchEvent($event)">
          </app-growth-priorities>
        </ng-template>
      </li>
      <li ngbNavItem="alliance" [disabled]="isFilterBlocked(filterTypes.Alliance)">
        <a class="modal-options" ngbNavLink>Alliances ({{ filtersManager.alliances?.count ?? 0 }})</a>
        <ng-template ngbNavContent>
          <app-alliances [alliances]="filters?.alliances" [selectedAlliances]="selectedFilters?.alliances"
            (itemSelectionChangeEvent)="onItemSelection($event)">
          </app-alliances>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="ms-4"></div>
  </div>
</div>
