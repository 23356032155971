<ul *ngIf="config?.items" class="tree-view">
  <ng-container *ngIf="config.multipleSelect">
    <app-tree-view-check-item *ngFor="let item of (searchItems || config.items)" [item]="item" [config]="config"
      (itemSelectionChangeEvent)="onItemSelection($event)" [isSearch]="searchItems?.length > 0">
    </app-tree-view-check-item>
  </ng-container>
  <ng-container *ngIf="!config.multipleSelect">
    <app-tree-view-radio-item *ngFor="let item of (searchItems || config.items)" [item]="item" [config]="config"
      (itemSelectionChangeEvent)="onItemSelection($event)"
      [onSelectAllChangedObs]="onSelectAllChangedObs" [isSearch]="searchItems?.length > 0">
    </app-tree-view-radio-item>
  </ng-container>
</ul>