import { clone, cloneDeep, sortBy } from 'lodash';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendTileDefinition } from '../entities/trend-tile-definition';
import {
  TrendTileResponseData,
  TrendTileResponseValue,
} from '../entities/trend-tile-response';
import { TrendFilters } from '../trend-tile/components/trend-filters/entities/trend-filters';
import { TrendTileConstants } from '../trend-tile/constants/trend-tile.constants';

export class TrendUtils {
  static getDefinition(type: MetricType): TrendTileDefinition {
    switch (type) {
      case MetricType.Sales:
        return TrendTileConstants.Sales;
      case MetricType.Revenue:
        return TrendTileConstants.Revenue;
      case MetricType.Cci:
        return TrendTileConstants.Cci;
    }

    return null;
  }

  static transformLabels<
    T extends TrendTileResponseData | TrendTileResponseValue
  >(
    values: Array<T>,
    key: string,
    trendFilters: TrendFilters,
    dates: Array<MmbDate>
  ): Array<T> {
    let valuesCopy: Array<T> = sortBy(cloneDeep(values), key);
    const isCustom: boolean = trendFilters.timeframe.title === 'Custom';

    switch (trendFilters.period.value) {
      case Periods.Quarter.id:
        valuesCopy = this.renameQuartersPeriods(
          valuesCopy,
          key,
          dates,
          isCustom
        );
        break;
      case Periods.Month.id:
        valuesCopy = this.renameMonthsPeriods(valuesCopy, key, dates);
        break;
    }

    return valuesCopy;
  }

  static transformData(
    target: string,
    value: number,
    type: MetricType,
    trendFilters: TrendFilters,
    selectedFilters: SelectedFilters
  ): number {
    const nullToZero = true;

    if (type === MetricType.Cci && trendFilters.isPercentage) {
      value = value ? value * 100 : value === 0 ? null : value;
    }

    // Old
    // if ((this.currency === AppSettings.currencyTypes.FxAdjusted &&
    //   (this.target === AppSettings.Snapshot.Projection || this.type === 'cci' ||
    //     this.type === 'delivered cci' || this.type === 'cci%')) ||
    //   (target === AppSettings.Snapshot.Forecast && !this.filter.Forecast.IfsLabel) ||
    //   (target === AppSettings.Snapshot.Plan && (isTimeframeInFuture(this.filter) || (this.filter.Plan.timeId < 0))) ||
    //   (this.breakdown === 'Industry' && target === AppSettings.Snapshot.Plan) ||
    //   (this.breakdown === 'ReasonCode' && target === AppSettings.Snapshot.Plan)
    //   || isNoShowDataPreviousFY(this.filter)) {
    //   nullToZero = false;
    // }

    return nullToZero ? value || 0 : value;
  }

  private static renameQuartersPeriods<
    T extends TrendTileResponseData | TrendTileResponseValue
  >(
    values: Array<T>,
    key: string,
    dates: Array<MmbDate>,
    custom?: boolean
  ): Array<T> {
    values.forEach((x: T) => {
      const date: MmbDate = dates.find(
        (d) =>
          d.FiscalYearNbr.toString() === (x as any)[key].toString().slice(0, 4)
      );
      const quarter: string = (x as any)[key].split('-')[1];
      (x as any)[key] =
        (custom && quarter === '1') ||
        (values.indexOf(x) === 0 && custom && quarter !== '1')
          ? `Q${quarter}` + "'" + date.FiscalYearNbr.toString().slice(-2)
          : `Q${quarter}`;
    });

    return values;
  }

  private static renameMonthsPeriods<
    T extends TrendTileResponseData | TrendTileResponseValue
  >(values: Array<T>, key: string, dates: Array<MmbDate>): Array<T> {
    let startYear: string = null;

    values.forEach((x: T) => {
      const date: MmbDate = dates.find(
        (d) => d.Id.toString() === (x as any)[key].toString()
      );
      (x as any)[key] = date ? date.MonthNm.slice(0, 3) : '';

      if (!startYear || date.MonthNbr === 1) {
        startYear = date.CalendarYearNbr.toString().slice(-2);
        (x as any)[key] = (x as any)[key].concat("'" + startYear);
      }
    });

    return values;
  }

  static needsMultiply(name: string): boolean {
    return name === 'Qual Proj Opps' || name === 'Unqual Proj Opps';
  }

  static getSubMetricKpiKey(type: MetricType, isPercentage: boolean) {
    switch (type) {
      case MetricType.Sales:
        return '__SALES';
      case MetricType.Revenue:
        return '__REVENUE';
      case MetricType.Cci:
        return isPercentage ? '__PERCENTAGE_CCI' : '__CCI';
      default:
        return '';
    }
  }
}
