<app-checkbox-groups-header [headers]="config?.headers" (headerSelectionChangeEvent)="onHeaderSelection($event)"
    [onSelectAllChangedObs]="onSelectAllChangedObs"
    [onValueSelectionChangedObs]="internalValuesSelectionChangeEvent.asObservable()">
</app-checkbox-groups-header>
<ng-container *ngTemplateOutlet="template">
</ng-container>
<app-checkbox-groups-values [values]="config?.values"
    [onHeaderSelectionChangedObs]="headerSelectionChangeEvent.asObservable()"
    (valuesSelectionChangeEvent)="onValuesSelection($event)"
    (internalValuesSelectionChangeEvent)="onInternalValuesSelection($event)">
</app-checkbox-groups-values>