import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import {
  FilterType,
  getTreeViewItemIconCssName,
} from 'src/app/shared/constants/filters.constants';
import { FinancialCustomer } from 'src/app/shared/services/entities/filters/financial-customer';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';
import { OptionItem } from '../../../base/options-list/entities/option-item';
import { OptionsListConfig } from '../../../base/options-list/entities/options-list-config';
import { OptionsListUtils } from '../../../base/options-list/utils/options-list.utils';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-wmu-fc',
  templateUrl: './wmu-fc.component.html',
  styleUrls: ['./wmu-fc.component.scss'],
})
export class WmuFcComponent implements OnChanges {
  @Input() wmu: Array<Wmu> = [];
  @Input() financialCustomer: Array<FinancialCustomer> = [];
  @Input() selectedWmus: Array<Wmu> = [];
  @Input() selectedFinancialCustomers: Array<FinancialCustomer> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();
  wmuSelectAllChangedEvent = new Subject<boolean>();
  fcSelectAllChangedEvent = new Subject<boolean>();

  selectedFilter = FilterType.WMU;
  wmuTreeviewConfig: TreeViewConfig;
  fcOptionsListConfig: OptionsListConfig;
  startSelectionWmus: TreeViewSelection;
  startSelectionFinancialCustomers: Array<OptionItem>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wmu && changes.wmu.currentValue) {
      this.wmuTreeviewConfig = new TreeViewConfig({
        parentAttr: 'PID',
        textAttr: 'UN',
        valueAttr: 'ID',
        countByNodes: true,
        selectByNodes: true,
        decoupleChildrenFromParent: true,
        items: TreeViewUtils.getItems<Wmu>(
          this.wmu,
          'ID',
          'UN',
          'PID',
          (item: TreeViewItem, wmu: Wmu) => {
            item.iconCssName = getTreeViewItemIconCssName(wmu.type);
            item.hasCheckBox = wmu.hc;

            if (wmu.status === 'RT') {
              item.cssList.push('strikethrough');
            }
          }
        ),
      });
    }

    if (changes.financialCustomer && changes.financialCustomer.currentValue) {
      this.fcOptionsListConfig = new OptionsListConfig({
        items: OptionsListUtils.getItems<FinancialCustomer>(
          this.financialCustomer,
          'CustomerNumber',
          'CustomerName',
          (x: OptionItem, y: FinancialCustomer) => {
            x.text = `${y.CustomerNumber} - ${y.CustomerName}`;
          }
        ),
        multipleSelect: true,
      });
    }

    if (changes.selectedWmus && changes.selectedWmus.currentValue) {
      this.startSelectionWmus = new TreeViewSelection({
        selectedItems: this.selectedWmus.map(
          (x: Wmu) =>
            new TreeViewItem({
              value: x.ID,
              text: x.UN,
            })
        ),
      });
    }

    if (
      changes.selectedFinancialCustomers &&
      changes.selectedFinancialCustomers.currentValue
    ) {
      this.startSelectionFinancialCustomers =
        this.selectedFinancialCustomers.map(
          (x: FinancialCustomer) =>
            new OptionItem({
              value: x.CustomerNumber,
              text: x.CustomerName,
            })
        );
    }
  }

  onTabChanged(): void {
    this.searchText = '';

    switch (this.selectedFilter) {
      case FilterType.WMU:
        this.onFcSelectionChanged(false);
        this.startSelectionFinancialCustomers = [];
        break;
      case FilterType.FinancialCustomer:
        this.onWmuSelectionChanged(false);
        this.startSelectionWmus = null;
        break;
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onWmuItemSelection(selectedWmus: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.WMU,
        keyValues: selectedWmus.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedWmus.count,
      })
    );
  }

  onFcItemSelection(selectedFcs: Array<OptionItem>): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.FinancialCustomer,
        keyValues: selectedFcs.map(
          (x: OptionItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedFcs.length,
      })
    );
  }

  onWmuSelectionChanged(selectAll: boolean): void {
    this.wmuSelectAllChangedEvent.next(selectAll);
  }

  onFcSelectionChanged(selectAll: boolean): void {
    this.fcSelectAllChangedEvent.next(selectAll);
  }
}
