import { assign } from 'lodash';

class FavoriteFilterDefaultsSchema {
  metric: string;
  period: string;
  group1: string;
  group2: string;
  group3: string;
  target1: string;
  target2: string;
}

export class FavoriteFilterDefaults extends FavoriteFilterDefaultsSchema {
  constructor(filterAttributes?: FavoriteFilterDefaultsSchema) {
    super();
    assign(this, filterAttributes);
  }
}
