import { assign } from 'lodash';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import { ValueType } from 'src/app/shared/constants/common.constants';

class TileDefinitionSchema {
  title: Record<string, string>;
  subtitle: string;
  subAttrFnc: (params: Dictionary<any>) => string;
  items: Array<TileItemDefinition>;
}

export class TileDefinition extends TileDefinitionSchema {
  constructor(filterAttributes?: TileDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TileItemDefinitionSchema {
  id: string;
  title: string;
  typeFnc: (params: Dictionary<any>) => ValueType;
  valueFnc?: (response: any) => number;
  isTotal: boolean;
  negativeStyleEnabled = true;
  positiveStyleEnabled = false;
  link?: string;
}

export class TileItemDefinition extends TileItemDefinitionSchema {
  constructor(filterAttributes?: TileItemDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}
