import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

import { CheckBoxGroupsConfig } from './entities/checkbox-groups-config';
import { CheckBoxGroupHeader } from './entities/checkbox-groups-header';
import { CheckBoxGroupValue } from './entities/checkbox-groups-value';

@Component({
  selector: 'app-checkbox-groups',
  templateUrl: './checkbox-groups.component.html',
  styleUrls: ['./checkbox-groups.component.scss'],
})
export class CheckboxGroupsComponent implements OnChanges {
  subscription = new Subscription();
  @Input() config: CheckBoxGroupsConfig;
  @Input() startSelection: Array<CheckBoxGroupValue>;
  @Input() template: TemplateRef<any>;
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Output() valuesSelectionChangeEvent = new EventEmitter<
    Array<CheckBoxGroupValue>
  >();
  headerSelectionChangeEvent = new Subject<Array<any>>();
  internalValuesSelectionChangeEvent = new Subject<Array<any>>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.startSelection && changes.startSelection.currentValue) ||
      (changes.config && changes.config.currentValue)
    ) {
      this.onSelectedItemsChanged();
    }
  }

  onHeaderSelection(values: Array<any>): void {
    this.headerSelectionChangeEvent.next(values);
  }

  onValuesSelection(values: Array<CheckBoxGroupValue>): void {
    this.valuesSelectionChangeEvent.emit(values);
  }

  onInternalValuesSelection(values: Array<CheckBoxGroupValue>): void {
    this.internalValuesSelectionChangeEvent.next(values);
    this.onValuesSelection(values);
  }

  onSelectedItemsChanged(): void {
    if (this.config) {
      if (this.config.values && this.config.values.length > 0) {
        this.config.values.forEach((c: CheckBoxGroupValue) => {
          c.checked = this.startSelection?.some(
            (x: CheckBoxGroupValue) => x.value === c.value
          );
        });
      }

      if (this.config.headers && this.config.headers.length > 0) {
        this.config.headers.forEach((h: CheckBoxGroupHeader) => {
          const total: number = h.values.length;
          const checkedCount: number = this.config.values.filter(
            (v) => h.values.includes(v.value) && v.checked
          ).length;
          h.checked = total === checkedCount;
        });
      }

      this.onValuesSelection(
        this.config.values.filter((x: CheckBoxGroupHeader) => x.checked)
      );
    }
  }
}
