import { Component } from '@angular/core';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  isCloud: boolean;
}
