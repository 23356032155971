import { assign, max, min } from 'lodash';
import { CalcUtils } from 'src/app/core/utils/calc.utils';

class TrendChartSchema {
  stack: TrendStack;
  lines: TrendLines;
}

export class TrendChart extends TrendChartSchema {
  constructor(filterAttributes?: TrendChartSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TrendStackSchema {
  items: Array<LabeledData>;
  periods: Array<string>;
  totals: LabeledData;
}

export class TrendStack extends TrendStackSchema {
  constructor(filterAttributes?: TrendStackSchema) {
    super();
    assign(this, filterAttributes);
  }

  getMaxValue(totals: LabeledData): number {
    if (totals) {
      const values: Array<number> = [];
      totals.data.forEach((x: number) => {
        values.push(x);
      });

      const result: number = CalcUtils.roundAxisValue(max(values));
      return result < 0 ? 0 : result;
    }

    return 0;
  }

  getMinValue(): number {
    if (this.items) {
      let values: Array<number> = [];
      this.items.forEach((x: LabeledData) => {
        values = values.concat(...x.data);
      });

      const result: number = CalcUtils.roundAxisValue(min(values));
      return result > 0 ? 0 : result;
    }

    return 0;
  }
}

class TrendLinesSchema {
  items: Array<TrendLineLabeledData>;
  periods: Array<string>;
}

export class TrendLines extends TrendLinesSchema {
  constructor(filterAttributes?: TrendLinesSchema) {
    super();
    assign(this, filterAttributes);
  }

  getMaxValue(): number {
    if (this.items) {
      let values: Array<number> = [];
      this.items.forEach((x: TrendLineLabeledData) => {
        values = values.concat(...x.data);
      });

      const result: number = CalcUtils.roundAxisValue(max(values));
      return result < 0 ? 0 : result;
    }

    return 0;
  }

  getMinValue(): number {
    if (this.items) {
      let values: Array<number> = [];
      this.items.forEach((x: TrendLineLabeledData) => {
        values = values.concat(...x.data);
      });

      const result: number = CalcUtils.roundAxisValue(min(values));
      return result > 0 ? 0 : result;
    }

    return 0;
  }
}

class LabeledDataSchema {
  data: Array<number>;
  label: string;
}

export class LabeledData extends LabeledDataSchema {
  constructor(filterAttributes?: LabeledDataSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TrendLineLabeledDataSchema extends LabeledDataSchema {
  borderDash: [number, number];
}

export class TrendLineLabeledData extends TrendLineLabeledDataSchema {
  constructor(filterAttributes?: TrendLineLabeledDataSchema) {
    super();
    assign(this, filterAttributes);
  }
}
