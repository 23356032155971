import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent {
  openSalesTrendModalEmittedEvent = new Subject<boolean>();
  openRevenueTrendModalEmittedEvent = new Subject<boolean>();
  openCciTrendModalEmittedEvent = new Subject<boolean>();

  subscription = new Subscription();

  constructor() {}

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    switch (type) {
      case MetricType.Sales:
        this.openSalesTrendModalEmittedEvent.next(isChartView);
        break;
      case MetricType.Revenue:
        this.openRevenueTrendModalEmittedEvent.next(isChartView);
        break;
      case MetricType.Cci:
        this.openCciTrendModalEmittedEvent.next(isChartView);
        break;
    }
  }
}
