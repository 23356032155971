import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

class TrendTileDefinitionSchema {
  title: string;
  type: MetricType;
  allowPercentage?: boolean = false;
}

export class TrendTileDefinition extends TrendTileDefinitionSchema {
  constructor(filterAttributes?: TrendTileDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}
