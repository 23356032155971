import { assign } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

class AppMessageSchema {
  id?: string;
  header: string;
  close?: boolean;
  type: MessageType;
  subtype?: string;
  body: Array<string>;
  buttons?: Array<AppMessageButton>;
  icon?: IconProp;
  showOnce?: boolean;
}

export class AppMessage extends AppMessageSchema {
  constructor(filterAttributes?: AppMessageSchema) {
    super();
    assign(this, filterAttributes);
    this.id = uuidv4();
  }
}

class AppMessageButtonSchema {
  text: string;
  action?: Function;
  type?: ButtonType;
}

export class AppMessageButton extends AppMessageButtonSchema {
  constructor(filterAttributes?: AppMessageButtonSchema) {
    super();
    assign(this, filterAttributes);
  }
}

export enum MessageType {
  error = 1,
  warning = 2,
  info = 3,
  confirm = 4,
}

export enum ButtonType {
  primary = 1,
  secondary = 2,
}
