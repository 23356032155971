<div class="ddl-control d-inline-block" ngbDropdown #filtersDropdown="ngbDropdown" placement="bottom-center">
    <div class="filter-icon" id="ddl-filters" ngbDropdownAnchor (click)="filtersDropdown.toggle()"
        [ngbPopover]="appliedContent" triggers="mouseenter:mouseleave" placement="right" container="body"
        [disablePopover]="selectedFilters?.getCount() === 0">
        <fa-icon [icon]="faFilter">
        </fa-icon>
        <span>FILTER ({{selectedFilters ? selectedFilters.getCount() : 0}})</span>
    </div>
    <div ngbDropdownMenu aria-labelledby="ddl-filters" class="ddl-content">
        <div class="ddl-inputs mb-2">
            <div>
                <button class="btn btn-link" [disabled]="selectedFilters?.getCount() === 0"
                    (click)="onClearFilters()">Clear All</button>
            </div>
            <div>
                <button class="btn btn-primary" [disabled]="!hasChanged" (click)="onApplyFilters()">Apply</button>
            </div>
        </div>
        <div class="scrollbar">
            <app-filters (hasChangedEvent)="hasChanged = $event"
                (filtersSelectionChangedEvent)="filtersManager = $event"
                [searchText]="searchText"
                (searchEvent)="getSearchEvent($event)">
            </app-filters>
        </div>
    </div>
</div>
<ng-template #appliedContent>
    <app-applied-filters-tooltip [appliedFilters]="selectedFilters"></app-applied-filters-tooltip>
</ng-template>
