import { ChangeDetectorRef, Component, OnDestroy, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { RequestPayload } from 'src/app/shared/services/entities/request-payload';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { Tile } from '../entities/tile';
import { TileItemDefinition } from '../entities/tile-definition';
import { TileItem } from '../entities/tile-item';
import { GilcTileErrorMessages } from './constants/gilc-tile.constants';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ErrorHandlerService } from 'src/app/core/services/error-handler.service';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';

@Component({
  selector: 'app-gilc-tile',
  template: '',
})
export class GilcTileComponent implements OnDestroy {
  subscription = new Subscription();

  loaded: boolean;
  hasErrors: boolean;
  errorMessage: string;
  type: MetricType;
  gilcTile: Tile;
  tmpTitle: string;
  tmpItems: Array<string>;
  symbol: string;
  @Input() isCloud: boolean;

  constructor(
    private baseGilcTileService: GilcTileService,
    private basefilterService: FiltersService,
    private baseErrorHandlerService: ErrorHandlerService,
    private baseChangeDetector: ChangeDetectorRef,
    type: MetricType,
    private betaMessage: BetaMessageService
  ) {
    this.type = type;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeComponent(callback: (x: RequestPayload) => void): void {
    this.betaMessage.isCloudSubject$.subscribe((value) => {
      this.isCloud = value;
      this.subscription.add(
        this.basefilterService.selectedFiltersChanged.subscribe(
          (x: SelectedFilters) => {
            callback(RequestPayload.createRequest(x));
          }
        )
      );
    });
  }

  loadStarted(): void {
    this.loaded = false;
    this.baseChangeDetector.detectChanges();
  }

  loadCompleted(): void {
    this.loaded = true;
    this.baseChangeDetector.detectChanges();
  }

  handleTileError(error: HttpErrorResponse): void {
    this.hasErrors = true;

    switch (error.status as any) {
      case 504:
        this.errorMessage = GilcTileErrorMessages.Service504;
        break;
      default:
        this.errorMessage = GilcTileErrorMessages.ServiceDefault;
        break;
    }

    this.baseErrorHandlerService.handleError(error);
    this.baseChangeDetector.detectChanges();
  }

  getTotal(): TileItem {
    return this.gilcTile &&
      this.gilcTile.totals &&
      this.gilcTile.totals.length > 0
      ? this.gilcTile.totals[0]
      : null;
  }

  setTempTitle(title: string): void {
    this.tmpTitle = title;
  }

  setTempItems(items: Array<TileItemDefinition>): void {
    this.tmpItems = items
      .filter((x: TileItemDefinition) => !x.isTotal)
      .map((x: TileItemDefinition) => x.title);
  }

  clearTile(): void {
    this.gilcTile = null;
    this.hasErrors = false;
    this.errorMessage = '';
  }
}
