import { assign } from 'lodash';

import { ValueType } from 'src/app/shared/constants/common.constants';

class TargetsTileProgressSchema {
  value: number;
  type: ValueType = ValueType.Numeric;
}

export class TargetsTileProgress extends TargetsTileProgressSchema {
  constructor(filterAttributes?: TargetsTileProgressSchema) {
    super();
    assign(this, filterAttributes);
  }
}
