import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Headers } from 'src/app/core/constants/http.constants';
import { Endpoints } from '../constants/endpoints.constants';
import { BoardResponseItem } from './entities/board-response';
import { Filters } from './entities/filters/filters';
import { BoardMapper } from '../components/board-modal/utils/board-modal-utils';
import { BoardSaveModel } from '../components/board-modal/entities/board-save-model';
import { SelectedFilters } from './entities/filters/selected-filters';

@Injectable({
  providedIn: 'root',
})
export class BoardService {
  private urlSharedBoardEvent = new BehaviorSubject<BoardResponseItem>(null);

  selectedBoard: BoardResponseItem;

  constructor(private http: HttpClient) {}

  public get urlSharedBoard(): BehaviorSubject<BoardResponseItem> {
    return this.urlSharedBoardEvent;
  }

  getBoards(
    selectedFilters: SelectedFilters,
    filters: Filters
  ): Promise<Array<BoardResponseItem>> {
    const body = {
      customerNumber: selectedFilters.customer.MasterCustomerNumber,
    };
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Array<BoardResponseItem>>(Endpoints.boardsCustomer, body, options)
      .pipe(
        switchMap((x: any) => {
          const z = x.data;
          return of(
            z.map((y: BoardResponseItem) => BoardMapper.mapContract(y, filters))
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  getBoardsById(
    peopleKey: string,
    customerNumber: string,
    boardId: string
  ): Promise<BoardResponseItem> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);
    const endpoint = `${Endpoints.boards}/${peopleKey}/${boardId}`;

    return this.http
      .post<BoardResponseItem>(endpoint, { customerNumber }, options)
      .toPromise();
  }

  markDefault(board: BoardResponseItem): Promise<boolean> {
    board.default = !board.default;

    return this.http
      .put<BoardResponseItem>(Endpoints.boards, board)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        })
      )
      .toPromise();
  }

  createBoard(board: BoardSaveModel): Promise<boolean> {
    return this.http
      .post<BoardSaveModel>(Endpoints.boards, board)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  updateBoard(board: BoardSaveModel): Promise<boolean> {
    return this.http
      .put<BoardResponseItem>(Endpoints.boards, board)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  deleteBoard(boardId: string, customerNumber: string): Promise<boolean> {
    const payload: Record<string, any> = {
      boardId,
      customerNumber,
    };

    return this.http
      .post<BoardResponseItem>(Endpoints.boardsDelete, payload)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (BoardService)');
  }
}
