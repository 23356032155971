<div class="filter-container">
  <ngb-accordion [closeOthers]="true" [destroyOnHide]="false" class="filter-panels">
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.WMU) && isFilterBlocked(filterTypes.FinancialCustomer)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">WMU / FC ({{(filtersManager.wmu || filtersManager.fc)?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-wmu-fc [wmu]="customerFilters?.wmu" [financialCustomer]="customerFilters?.financialCustomer"
          [selectedWmus]="selectedFilters?.wmus" [selectedFinancialCustomers]="selectedFilters?.financialCustomers"
          (itemSelectionChangeEvent)="onItemSelection($event)"></app-wmu-fc>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.Market)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Market ({{filtersManager.market?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-market [markets]="filters?.locations" [selectedMarkets]="selectedFilters?.markets"
          (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-market>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.ClientGroup)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">CG ({{filtersManager.cg?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-client-group [clientGroups]="customerFilters?.csg" [selectedClientGroups]="selectedFilters?.clientGroups"
          (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-client-group>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.ServiceGroup)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Service Group ({{filtersManager.sg?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-service-group [groups]="filters?.serviceGroups?.Groups"
          [serviceGroups]="filters?.serviceGroups?.ServiceGroups"
          [selectedServiceGroups]="selectedFilters?.serviceGroups" (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-service-group>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.Industry)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Industry ({{filtersManager.industry?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-industry [industries]="filters?.industry" [selectedIndustries]="selectedFilters?.industries"
          (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-industry>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.Function)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Functions ({{filtersManager.functions?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-functions [functions]="filters?.functions" [selectedFunctions]="selectedFilters?.functions"
          (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-functions>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.GrowthPriority)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Growth Engines ({{filtersManager.gp?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-growth-priorities [growthPriorities]="filters?.sgi"
          [selectedGrowthPriorities]="selectedFilters?.growthPriorities"
          (itemSelectionChangeEvent)="onItemSelection($event)"></app-growth-priorities>
      </ng-template>
    </ngb-panel>
    <ngb-panel [disabled]="isFilterBlocked(filterTypes.Alliance)">
      <ng-template ngbPanelHeader let-opened="opened">
        <div class="d-flex align-items-center justify-content-between">
          <button ngbPanelToggle class="btn btn-link container-fluid text-left pl-0">
            <div class="panel-header">
              <span class="arrow">
                <fa-icon [icon]="opened ? faAngleDown : faAngleRight">
                </fa-icon>
              </span>
              <span class="title-text">Alliances ({{filtersManager.alliances?.count
                ?? 0}})</span>
            </div>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <app-alliances [alliances]="filters?.alliances" [selectedAlliances]="selectedFilters?.alliances"
          (itemSelectionChangeEvent)="onItemSelection($event)">
        </app-alliances>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
