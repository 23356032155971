import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { sortBy, cloneDeep } from 'lodash';

import { DataRefresh } from '../../services/entities/app-state/data-refresh';
import { StateOfDataItem } from '../../services/entities/app-state/update-frequency-item';

import { RebarAuthService } from 'src/app/core/rebarauth/rebar.auth.service';
import { AppStateService } from '../../services/app-state.service';
import { SecurityService } from '../../services/security.service';
import { AppToastsService } from '../../services/app-toasts.service';

import { ToastTemplates } from '../../constants/toasts.constants';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faSync,
  faPencilAlt,
  faSave,
  faWindowClose,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-data-refresh',
  templateUrl: './data-refresh.component.html',
  styleUrls: ['./data-refresh.component.scss'],
})
export class DataRefreshComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  dataRefresh: DataRefresh;
  completedStateOfDataCount: number;

  cloneDataRefresh: DataRefresh;
  ordinal: number;
  isAdmin: boolean;
  btnDisabled = true;

  faClock = faClock as IconProp;
  faCheck = faCheck as IconProp;
  faSync = faSync as IconProp;
  faPencilAlt = faPencilAlt as IconProp;
  faSave = faSave as IconProp;
  faWindowClose = faWindowClose as IconProp;

  constructor(
    private appStateService: AppStateService,
    private authService: RebarAuthService,
    private securityService: SecurityService,
    private appToastsService: AppToastsService
  ) {}

  ngOnInit(): void {
    this.getDataRefresh();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getDataRefresh(): void {
    if (this.authService.isUserAuthenticated()) {
      Promise.all([
        this.appStateService.getDataRefresh(),
        this.securityService.isAdmin(),
      ]).then(([x, y]) => {
        this.dataRefresh = x;
        this.isAdmin = y;
        this.cloneDataRefresh = cloneDeep(this.dataRefresh);
        this.dataRefresh.stateOfDataItems = sortBy(
          this.dataRefresh.stateOfDataItems,
          'ordinal'
        );
        this.dataRefresh.updateFrequencyItems = sortBy(
          this.dataRefresh.updateFrequencyItems,
          'ordinal'
        );
        this.completedStateOfDataCount =
          this.dataRefresh.stateOfDataItems.filter(
            (i: StateOfDataItem) => i.isCompleted
          ).length;
      });
    }
  }

  onChange(event: string): void {
    this.btnDisabled = this.cloneDataRefresh.stateOfDataItems.find(
      (x) =>
        x.name === event ||
        x.completionDate === event ||
        x.childCompletionDate === event
    )
      ? true
      : false;
  }

  onEditState(state: StateOfDataItem): void {
    this.ordinal = state.ordinal;
    const cloneState = this.cloneDataRefresh.stateOfDataItems.find((x) => {
      return x.ordinal === state.ordinal;
    });

    if (
      state.name != cloneState.name ||
      state.completionDate != cloneState.completionDate ||
      state.childCompletionDate != cloneState.childCompletionDate
    ) {
      state.name = cloneState.name;
      state.completionDate = cloneState.completionDate;
      state.childCompletionDate = cloneState.childCompletionDate;
      this.btnDisabled = true;
    }
  }

  onCancelState(state: StateOfDataItem): void {
    this.ordinal = 0;

    const cloneState = this.cloneDataRefresh.stateOfDataItems.find((x) => {
      return x.ordinal === state.ordinal;
    });

    if (
      state.name != cloneState.name ||
      state.completionDate != cloneState.completionDate ||
      state.childCompletionDate != cloneState.childCompletionDate
    ) {
      state.name = cloneState.name;
      state.completionDate = cloneState.completionDate;
      state.childCompletionDate = cloneState.childCompletionDate;
      this.btnDisabled = true;
      this.ordinal = 0;
    }
  }

  onSaveState(state: StateOfDataItem): void {
    this.appStateService
      .updateDataRefresh(state)
      .then((result: boolean) => {
        if (result) {
          this.appToastsService.show(ToastTemplates.stateDataItemUpdated);
          this.getDataRefresh();
        }
      })
      .catch((error) => console.log('Error: ', error));
    this.ordinal = 0;
    this.btnDisabled = true;
  }
}
