import { assign } from 'lodash';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';

class OpportunityPhasingChangedSchema {
  phasingMonths: GridData;
  originalPhasingMonths: GridData;
  selectedSg: string;
  changes: Array<OpportunityPhasingMetricChanged>;
}

export class OpportunityPhasingChanged extends OpportunityPhasingChangedSchema {
  constructor(filterAttributes?: OpportunityPhasingChangedSchema) {
    super();
    assign(this, filterAttributes);
  }

  isValid(): boolean {
    return !this.hasErrors() && this.hasChanges();
  }

  hasChanges(): boolean {
    return this.changes?.some(
      (x: OpportunityPhasingMetricChanged) => x.hasChanged
    );
  }

  hasErrors(): boolean {
    return this.changes?.some(
      (x: OpportunityPhasingMetricChanged) => x.hasErrors
    );
  }

  hasVariancesAllocated(): boolean {
    return this.changes?.some(
      (x: OpportunityPhasingMetricChanged) => x.hasVarianceAllocated
    );
  }
}

class OpportunityPhasingMetricChangedSchema {
  hasChanged: boolean;
  hasErrors: boolean;
  hasVarianceAllocated: boolean;
  metricName: string;
}

export class OpportunityPhasingMetricChanged extends OpportunityPhasingMetricChangedSchema {
  constructor(filterAttributes?: OpportunityPhasingMetricChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
