import { assign, merge } from 'lodash';

import { SelectedFilters } from './filters/selected-filters';
import { ProductHierarchy } from './filters/product-hierarchy';
import { ServiceGroupValues } from './filters/service-group';
import { Wmu } from './filters/wmu';
import { ClientGroup } from './filters/client-group';
import { Industry } from './filters/industry';
import { FinancialCustomer } from './filters/financial-customer';
import { Location } from './filters/location';
import { DateUtils } from 'src/app/core/utils/date.utils';

class FileExportRequestPayloadSchema {
  stage: string;
  breakdownSG: boolean;
  breakdownWMU: boolean;
  customer: Record<string, string>;
  currency: Record<string, string>;
  Timeframe: Record<string, any>;
  wmus: Array<Record<string, string>>;
  locations: Array<Record<string, string>>;
  attributesList: Array<Record<string, string>>;
  industries: Array<Record<string, string>>;
  serviceGroups: Array<Record<string, string>>;
  clientServiceGroups: Array<Record<string, string>>;
  fc: Record<string, string>;
  period: string;
  planView: string;
  planSapCode: string;
  plan: Record<string, any>;
  target: string;
  labelTarget: Array<string>;
  projection: Record<string, any>;
  timezone: string;
  noActivity: boolean;
  sapCode: string;
  IFSlabel: string;
  isMSACvf: string;
  fullyWeighted: string;
}

export class FileExportRequestPayload extends FileExportRequestPayloadSchema {
  constructor(filterAttributes?: FileExportRequestPayloadSchema) {
    super();
    assign(this, filterAttributes);
  }

  static createRequest(
    selectedFilters: SelectedFilters,
    extraParams?: Record<string, any>
  ): FileExportRequestPayload {
    let request = new FileExportRequestPayload({
      stage: '',
      customer: {
        id: selectedFilters.customer.MasterCustomerNumber,
        value: selectedFilters.customer.MasterCustomerName,
      },
      breakdownSG: false,
      breakdownWMU: false,
      currency: {
        id: selectedFilters.currency.Id,
        value: selectedFilters.currency.Description,
      },
      Timeframe: {
        StartTimeId: selectedFilters.timeframe.start,
        EndTimeId: selectedFilters.timeframe.end,
        TimePeriodCode: selectedFilters.timeframe.mmbPeriod,
        Name: selectedFilters.timeframe.title,
        FiscalYear: selectedFilters.timeframe.fiscalYear,
      },
      wmus: this.getWmus(selectedFilters),
      serviceGroups: this.getServiceGroups(selectedFilters),
      attributesList: this.getAttributes(selectedFilters),
      clientServiceGroups: this.getClientGroups(selectedFilters),
      fc: {
        id: this.getFinancialCustomers(selectedFilters),
        value: this.getFinancialCustomers(selectedFilters),
      },
      industries: this.getIndustries(selectedFilters),
      locations: this.getMarkets(selectedFilters),
      noActivity: false,
      period: '',
      plan: selectedFilters.plan,
      planSapCode: selectedFilters.plan.timeId.toString(),
      planView: selectedFilters.planView,
      projection: selectedFilters.projection,
      sapCode: '-1',
      IFSlabel: '',
      target: '',
      labelTarget: [
        '',
        selectedFilters.projection.MonthLabel,
        selectedFilters.plan.monthLabel,
      ],
      timezone: DateUtils.getClientSideTimeZone(),
      isMSACvf: selectedFilters.isMSACvf
        ? selectedFilters.isMSACvf.toString()
        : 'false',
      fullyWeighted: selectedFilters.fullyWeighted
        ? selectedFilters.fullyWeighted.toString()
        : 'false',
    });

    if (extraParams) {
      request = Object.assign({}, request, extraParams);
    }

    return request;
  }

  private static getAttributes(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    const attributes: Array<ProductHierarchy> = [
      ...(selectedFilters.alliances ? selectedFilters.alliances : []),
      ...(selectedFilters.functions ? selectedFilters.functions : []),
      ...(selectedFilters.growthPriorities
        ? selectedFilters.growthPriorities
        : []),
    ];

    return (
      attributes.map((x: ProductHierarchy) => {
        return { id: x.ProductCode, value: x.ProductName };
      }) || []
    );
  }

  private static getServiceGroups(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.serviceGroups?.map((x: ServiceGroupValues) => {
        return { id: x.ServiceGroupCd, value: x.ServiceGroupNm };
      }) || []
    );
  }

  private static getWmus(
    selectedFilters: SelectedFilters
  ): Array<Record<string, any>> {
    return (
      selectedFilters.wmus?.map((x: Wmu) => {
        return { id: x.ID, value: x.UN };
      }) || []
    );
  }

  private static getClientGroups(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.clientGroups?.map((x: ClientGroup) => {
        return { id: x.ClientGroupCd, value: x.ClientGroupDesc };
      }) || []
    );
  }

  private static getIndustries(
    selectedFilters: SelectedFilters
  ): Array<Record<string, string>> {
    return (
      selectedFilters.industries?.map((x: Industry) => {
        return { id: x.EntityCode, value: x.EntityDescription };
      }) || []
    );
  }

  private static getFinancialCustomers(
    selectedFilters: SelectedFilters
  ): string {
    return (
      selectedFilters.financialCustomers
        ?.map((x: FinancialCustomer) => x.CustomerNumber)
        .join(',') || ''
    );
  }

  private static getMarkets(
    selectedFilters: SelectedFilters
  ): Array<Record<string, any>> {
    return (
      selectedFilters.markets?.map((x: Location) => {
        return { id: x.OrganizationId, value: x.OrganizationName };
      }) || []
    );
  }
}
