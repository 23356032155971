import { cloneDeep, minBy, round, sumBy } from 'lodash';
import { CalcUtils } from 'src/app/core/utils/calc.utils';

import { DateUtils } from 'src/app/core/utils/date.utils';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { ActiveDates } from 'src/app/shared/constants/filters.constants';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import {
  PhasingPeriod,
  PhasingTab,
} from '../../../entities/opportunity-phasing';
import { GridColDefs } from '../constants/phasing-edit-grid.constants';

export class PhasingEditGridUtils {
  static mapData(
    filters: Filters,
    selectedFilters: SelectedFilters,
    phasingTab: PhasingTab
  ): Array<Record<string, any>> {
    const rows: Array<Record<string, any>> = [];
    const periods: Array<Record<string, any>> = this.getPeriods(
      filters,
      selectedFilters,
      phasingTab
    );

    rows.push({
      projection: 'Revenue',
      attrName: 'NetRevenue',
      total: round(phasingTab.ProjectedRevenue, 3),
      variance: 0,
    });
    rows.push({
      projection: 'CCI$',
      attrName: 'Cci',
      total: round(phasingTab.ProjectedCci, 3),
      variance: 0,
    });
    rows.push({
      projection: 'CCI%',
      attrName: 'CciPercent',
      total: round(phasingTab.ProjectedCciPct, 1),
      variance: null,
    });

    periods.forEach((x: Record<string, any>) => {
      const phasingPeriod: PhasingPeriod = phasingTab.MonthlyData.find(
        (y: PhasingPeriod) => y.Id === x.id
      );

      let revenue = 0;
      let cci = 0;
      let cciPercentage = 0;

      if (x.id === GridColDefs.Shared.beyondColId) {
        const maxTimeId: number = periods.find(
          (x: Record<string, any>) => x.isLatest
        ).id;
        const beyondPhasingPeriods: Array<PhasingPeriod> =
          phasingTab.MonthlyData.filter((x: PhasingPeriod) => x.Id > maxTimeId);
        revenue =
          beyondPhasingPeriods.length > 0
            ? sumBy(beyondPhasingPeriods, 'NetRevenue')
            : null;
        cci =
          beyondPhasingPeriods.length > 0
            ? sumBy(beyondPhasingPeriods, 'Cci')
            : null;
        cciPercentage = CalcUtils.calculateCustomPercentage(cci, revenue);
      } else {
        revenue =
          phasingPeriod && !isNaN(phasingPeriod.NetRevenue)
            ? round(phasingPeriod.NetRevenue, 3)
            : null;
        cci =
          phasingPeriod && !isNaN(phasingPeriod.Cci)
            ? round(phasingPeriod.Cci, 3)
            : null;
        cciPercentage =
          phasingPeriod && !isNaN(phasingPeriod.CciPercent)
            ? round(phasingPeriod.CciPercent, 1)
            : null;
      }

      rows[0][x.id] = revenue;
      rows[0].extra = rows[0].extra ?? [];
      rows[0].extra.push({
        Id: x.id,
        isOutOfPeriod: x.isOutOfPeriod,
        isLatest: x.isLatest,
      });

      rows[1][x.id] = cci;
      rows[1].extra = rows[1].extra ?? [];
      rows[1].extra.push({
        Id: x.id,
        isOutOfPeriod: x.isOutOfPeriod,
        isLatest: x.isLatest,
      });

      rows[2][x.id] = cciPercentage;
      rows[2].extra = rows[2].extra ?? [];
      rows[2].extra.push({
        Id: x.id,
        isOutOfPeriod: x.isOutOfPeriod,
        isLatest: x.isLatest,
      });
    });

    return rows;
  }

  static getOppPhasingTimeframe(
    filters: Filters,
    selectedFilters: SelectedFilters,
    phasingTab: PhasingTab
  ): TimeframeItem {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );

    const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
    timeframe.start = minBy(phasingTab.MonthlyData, 'Id').Id;
    timeframe.end = appDate.StartTimeId + 23;

    return timeframe;
  }

  private static getPeriods(
    filters: Filters,
    selectedFilters: SelectedFilters,
    phasingTab: PhasingTab
  ): Array<Record<string, any>> {
    const rows: Array<Record<string, any>> = [];
    const timeframe: TimeframeItem =
      PhasingEditGridUtils.getOppPhasingTimeframe(
        filters,
        selectedFilters,
        phasingTab
      );

    const endTimeId: number = DateUtils.dateToTimeId(
      new Date(DateUtils.normalizeDateString(phasingTab?.EndDate))
    );

    const latestTimeId: number =
      endTimeId < timeframe.end && endTimeId > timeframe.start
        ? endTimeId
        : endTimeId < timeframe.start
        ? timeframe.start
        : timeframe.end;

    for (let i = timeframe.start; i <= timeframe.end; i++) {
      // const hasPhasingPeriod: boolean = phasingTab.MonthlyData.some(
      //   (x: PhasingPeriod) => x.Id === i
      // );
      const isOutOfPeriod: boolean = i > endTimeId;

      rows.push({
        id: i,
        // isOutOfPeriod: !hasPhasingPeriod || isOutOfPeriod,
        isOutOfPeriod: isOutOfPeriod,
        isLatest: i === latestTimeId,
      });
    }

    // Beyond
    rows.push({
      id: GridColDefs.Shared.beyondColId,
      isOutOfPeriod: !phasingTab.MonthlyData.some(
        (x: PhasingPeriod) => x.Id > timeframe.end
      ),
      isLatest: false,
    });

    return rows;
  }
}
