import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { MessageTemplates } from 'src/app/shared/constants/messages.constants';
import {
  AppMessage,
  AppMessageButton,
} from 'src/app/shared/services/entities/app-message';
import { BoardResponseItem } from 'src/app/shared/services/entities/board-response';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { BoardSaveModel } from '../../../board-modal/entities/board-save-model';
import { BoardMapper } from '../../../board-modal/utils/board-modal-utils';

import { FiltersService } from 'src/app/shared/services/filters.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppMessagesService } from 'src/app/shared/services/app-messages.service';
import { BoardService } from 'src/app/shared/services/board.service';

@Component({
  selector: 'app-share-board',
  templateUrl: './share-board.component.html',
  styleUrls: ['./share-board.component.scss'],
})
export class ShareBoardComponent implements OnInit {
  subscription = new Subscription();

  board: BoardResponseItem;
  boards: Array<BoardResponseItem>;

  filters: Filters;
  selectedFilters: SelectedFilters;

  customerNumberUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private boardService: BoardService,
    private appMessagesService: AppMessagesService,
    private sharedService: SharedService,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.filters = x;
        this.selectedFilters = y;
      })
    );

    this.getAllBoards(() => {
      this.getUrlBoardById();
    });
  }

  getAllBoards(callback?: () => void): void {
    this.boardService
      .getBoards(this.selectedFilters, this.filters)
      .then((x: Array<BoardResponseItem>) => {
        this.boards = x;

        if (callback) {
          callback();
          callback = null;
        }
      });
  }

  getUrlBoardById(): void {
    this.customerNumberUrl = this.route.snapshot.paramMap.get('customer');
    const peopleKeyUrl = this.route.snapshot.paramMap.get('peopleKey');
    const boardIdUrl = this.route.snapshot.paramMap.get('boardId');

    this.boardService
      .getBoardsById(peopleKeyUrl, this.customerNumberUrl, boardIdUrl)
      .then((x: any) => {
        this.board = x.data[0];
        let existingBoard: BoardResponseItem;

        if (!this.board) {
          this.showErrorLinkMessage();
        } else {
          existingBoard = this.boards.find(
            (x: BoardResponseItem) =>
              x.name ===
              `${this.board.name} shared by ${this.board.enterpriseId}`
          );

          if (!existingBoard) {
            this.openSharedBoardConfirmation(this.board);
          } else {
            this.router.navigate(['/overview']);
            this.boardService.urlSharedBoard.next(existingBoard);
          }
        }
      });
  }

  openSharedBoardConfirmation(board: BoardResponseItem): void {
    const customerNumberSelected =
      this.selectedFilters.customer.MasterCustomerNumber;

    if (this.customerNumberUrl === customerNumberSelected) {
      const confirmationMessage: AppMessage = cloneDeep(
        MessageTemplates.BoardSharedConfirmation
      );
      confirmationMessage.body = confirmationMessage.body.map((x: string) => {
        x = x.replace('[from]', board.enterpriseId);
        x = x.replace('[boardName]', board.name.split('shared by')[0].trim());
        return x;
      });
      confirmationMessage.buttons.forEach((x: AppMessageButton) => {
        x.action = (): void => {
          if (x.text === 'Confirm') {
            this.cloneAndSaveNewBoard(board, confirmationMessage);
            this.router.navigate(['/overview']);
          } else {
            this.appMessagesService.close(confirmationMessage.id);
            this.router.navigate(['/overview']);
          }
        };
      });
      this.appMessagesService.show(confirmationMessage, { centered: true });
    } else {
      this.router.navigate(['/overview']);
    }
  }

  cloneAndSaveNewBoard(
    sharedBoard: BoardResponseItem,
    confirmationModal: AppMessage
  ): void {
    const peoplekey: string = this.sharedService.getPeopleKey();
    const enterpriseId: string = this.sharedService.getEnterpriseId();

    const newBoard: BoardResponseItem = cloneDeep(sharedBoard);
    newBoard.boardId = `${uuidv4()}#${peoplekey}`;
    newBoard.name = `${sharedBoard.name} shared by ${sharedBoard.enterpriseId}`;
    newBoard.enterpriseId = enterpriseId;

    const model: BoardSaveModel = BoardMapper.getSaveModel(newBoard);

    this.boardService
      .createBoard(model)
      .then((response: boolean) => {
        response
          ? this.appMessagesService.show(
              MessageTemplates.BoardSuccessfulSaved,
              {
                centered: true,
              }
            )
          : this.appMessagesService.show(MessageTemplates.UnexpectedError, {
              centered: true,
            });
        this.boardService.urlSharedBoard.next(newBoard);
      })
      .catch(() => {
        this.appMessagesService.show(MessageTemplates.UnexpectedError, {
          centered: true,
        });
      })
      .finally(() => {
        this.appMessagesService.close(confirmationModal.id);
      });
  }

  showErrorLinkMessage(): void {
    this.appMessagesService.show(MessageTemplates.BoardSharedLinkError, {
      centered: true,
    });
    this.router.navigate(['/overview']);
  }
}
