import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable } from 'rxjs';
import { OptionItem } from './entities/option-item';

import { OptionsListConfig } from './entities/options-list-config';

@Component({
  selector: 'app-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
})
export class OptionsListComponent implements OnChanges {
  @Input() config: OptionsListConfig;
  @Input() searchText: string;
  @Input() isDisabled: boolean;
  @Input() startSelection: Array<OptionItem>;
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Output() itemSelectionChangeEvent = new EventEmitter<Array<OptionItem>>();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.startSelection && changes.startSelection.currentValue) ||
      (changes.config && changes.config.currentValue)
    ) {
      this.onSelectedItemsChanged();
    }
  }

  onItemSelectionChanged(selectedItems: Array<OptionItem>): void {
    this.itemSelectionChangeEvent.emit(selectedItems);
  }

  onSelectedItemsChanged(): void {
    if (this.config && this.config.items && this.config.items.length > 0) {
      this.config.items.forEach((i: OptionItem) => {
        i.checked = this.startSelection?.some(
          (x: OptionItem) => x.value === i.value
        );
      });

      this.onItemSelectionChanged(
        this.config.items.filter((x: OptionItem) => x.checked)
      );
    }
  }
}
