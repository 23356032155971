import { assign } from 'lodash';

import { CheckBoxGroupHeader } from './checkbox-groups-header';
import { CheckBoxGroupValue } from './checkbox-groups-value';

class CheckBoxGroupsConfigSchema {
  headers: Array<CheckBoxGroupHeader>;
  values: Array<CheckBoxGroupValue>;
}

export class CheckBoxGroupsConfig extends CheckBoxGroupsConfigSchema {
  constructor(filterAttributes?: CheckBoxGroupsConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
