import { assign } from 'lodash';

class AnalyticsResponseItemSchema {
  groupKey1?: string;
  groupKey2?: string;
  groupKey3?: string;
  subGroupKey1?: string;
  subGroupKey2?: string;
  subGroupKey3?: string;
  referenceId?: string;
  sales?: AnalyticPeriod;
  revenue?: AnalyticPeriod;
  cci?: AnalyticPeriod;
  cciPercent?: AnalyticPeriod;
  wonCci?: AnalyticPeriod;

  salesDataset1?: AnalyticPeriod;
  revenueDataset1?: AnalyticPeriod;
  cciDataset1?: AnalyticPeriod;
  cciPercentDataset1?: AnalyticPeriod;
  wonCciDataset1?: AnalyticPeriod;

  salesDataset2?: AnalyticPeriod;
  revenueDataset2?: AnalyticPeriod;
  cciDataset2?: AnalyticPeriod;
  cciPercentDataset2?: AnalyticPeriod;
  wonCciDataset2?: AnalyticPeriod;
}

export class AnalyticsResponseItem extends AnalyticsResponseItemSchema {
  constructor(filterAttributes?: AnalyticsResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }
}

export class AnalyticPeriod {
  [timeId: string]: any;
}
