import { assign } from 'lodash';

import { TrendTileDefinition } from '../../../../entities/trend-tile-definition';
import { TrendFilters } from './trend-filters';

class TrendFiltersConfigSchema {
  componentName: string;
  definition: TrendTileDefinition;
  defaultFilters?: TrendFilters;
  forceApply?: boolean;
  showSubMetrics?: boolean = false;
  showTimeframePicker?: boolean = false;
  expandable?: boolean = true;
  exportable?: boolean = true;
  closable?: boolean = false;
  resettable?: boolean = false;
}

export class TrendFiltersConfig extends TrendFiltersConfigSchema {
  constructor(filterAttributes?: TrendFiltersConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
