import Dictionary from 'src/app/core/utils/dictionary.utils';
import { ITileResponse } from '../entities/itile-response';
import { Tile } from '../entities/tile';
import { TileItem } from '../entities/tile-item';
import {
  TileDefinition,
  TileItemDefinition,
} from '../entities/tile-definition';
import { MetricType } from 'src/app/shared/constants/metrics.constants';

export class TileUtils {
  static getItems<T extends ITileResponse>(
    tileDefinition: TileDefinition,
    source: T,
    params?: Dictionary<any>
  ): Tile {
    params = params ?? new Dictionary<any>();
    const metric: MetricType = params.getItem('metric');
    const period: string = params.getItem('period');
    const isActuals: boolean = params.getItem('isActuals');
    const isPercentage: boolean = params.getItem('isPercentage');

    const projectionTile = new Tile({
      title:
        (isActuals
          ? tileDefinition.title.actuals
          : tileDefinition.title.projection) || tileDefinition.title.common,
      subtitle: tileDefinition.subtitle,
      totals: [],
      items: [],
    });

    const data: any =
      tileDefinition?.subAttrFnc(params)?.length > 0
        ? (source as any)[tileDefinition.subAttrFnc(params)]
        : source;

    tileDefinition.items.forEach((x: TileItemDefinition) => {
      if (data.hasOwnProperty(x.id) || x.valueFnc) {
        if (x.isTotal) {
          projectionTile.totals.push(
            new TileItem({
              title: x.title,
              value: x.valueFnc ? x.valueFnc(data) : Number(data[x.id]),
              type: x.typeFnc(params),
              positiveStyleEnabled: x.positiveStyleEnabled,
              negativeStyleEnabled: x.negativeStyleEnabled,
              link: x.link,
              linkParams: x.link
                ? {
                    metric,
                    period,
                    isPercentage,
                  }
                : null,
            })
          );
        } else {
          projectionTile.items.push(
            new TileItem({
              title: x.title,
              value: x.valueFnc ? x.valueFnc(data) : Number(data[x.id]),
              type: x.typeFnc(params),
              positiveStyleEnabled: x.positiveStyleEnabled,
              negativeStyleEnabled: x.negativeStyleEnabled,
              link: x.link,
              linkParams: x.link
                ? {
                    metric,
                    period,
                    isPercentage,
                  }
                : null,
            })
          );
        }
      }
    });

    return projectionTile;
  }

  static combineTiles(tiles: Array<Tile>): Tile {
    let projectionTile: Tile;

    if (tiles) {
      if (tiles.length > 1) {
        projectionTile = tiles[0];

        for (let i = 1; i < tiles.length; i++) {
          projectionTile.totals.push(...tiles[i].totals);
          projectionTile.items.push(...tiles[i].items);
        }
      } else if (tiles.length === 1) {
        projectionTile = tiles[0];
      }
    }

    return projectionTile;
  }
}
