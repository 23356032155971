<div class="ddl-control" [ngClass]="{'disabled' : isDisabled}" [ngbTooltip]="msaTooltipContent" container="body"
  placement="right">
  <div class="ddl-input">
    <app-switch rightText="" leftText="" [checked]="isMSACvf" (switchChangeEvent)="onSwitch($event)"
      [disabled]="isDisabled">
    </app-switch>
    <div class="span-content">
      <span>USE MSA CVF CALCULATION</span>
    </div>
  </div>
</div>
<ng-template #msaTooltipContent>
  Note: Impacts CBP calculation <b>only</b>. Once enabled, MMB may show different values.
</ng-template>