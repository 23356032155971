import { assign } from 'lodash';

class MrdrPersonSchema {
  PeopleKey: number;
  PersonnelNumber: string;
  PersonalDisplayName: string;
  EnterpriseID: string;
}

export class MrdrPerson extends MrdrPersonSchema {
  constructor(filterAttributes?: MrdrPersonSchema) {
    super();
    assign(this, filterAttributes);
  }
}
