import { assign } from 'lodash';

class OptionItemSchema {
  text: string;
  value: any;
  checked?: boolean = false;
}

export class OptionItem extends OptionItemSchema {
  constructor(filterAttributes?: OptionItemSchema) {
    super();
    assign(this, filterAttributes);
  }
}
