<div class="checkbox-content">
  <div *ngFor="let item of config?.items | filterArray : searchText : 'text'; let i = index"
    class="checkbox-list-item form-check">
    <input class="form-check-input" type="checkbox" id="checkbox-item-{{i}}" [(ngModel)]="item.checked"
        (ngModelChange)="onItemSelection(item)" [disabled]="isDisabled">
    <label class="form-check-label" for="checkbox-item-{{i}}">
        {{item.text}}
    </label>
  </div>
</div>
