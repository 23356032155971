import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

import { Filters } from '../../services/entities/filters/filters';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { Timeframe } from '../../services/entities/filters/timeframe';
import {
  TimeframeGroup,
  TimeframeItem,
  TimeframeSubGroup,
} from './entities/timeframe';
import { ActiveDates } from '../../constants/filters.constants';
import { ActiveDate } from '../../services/entities/filters/active-date';
import { TimeframeUtils } from './utils/timeframe.utils';
import { TimeframeChanged } from './entities/timeframe-events';
import { TextValuePair } from '../../services/entities/common/key-value';
import { MmbDate } from '../../services/entities/filters/date';

import { FiltersService } from '../../services/filters.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-timeframe',
  templateUrl: './timeframe.component.html',
  styleUrls: ['./timeframe.component.scss'],
})
export class TimeframeComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() startSelectedTimeframe: TimeframeItem;
  @Input() onUpdateSelectedTabObs: Observable<number>;
  @Input() isModal = true;
  @Output() selectionChangeEvent = new EventEmitter<TimeframeChanged>();

  @ViewChild('timeframeNav', { static: false }) timeframeNav: NgbNav;

  subscription = new Subscription();
  filters: Filters;
  selectedFilters: SelectedFilters;
  timeframes: Array<TimeframeGroup>;
  selectedTimeframe: TimeframeItem;
  appDate: ActiveDate;

  // Custom Timeframe
  months: Array<TextValuePair>;
  fiscalYears: Array<TextValuePair>;
  customStartMonth: TextValuePair;
  customEndMonth: TextValuePair;
  customStartYear: TextValuePair;
  customEndYear: TextValuePair;
  validTimeframe: boolean;

  faCheckCircle = faCheckCircle as IconProp;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private filtersService: FiltersService
  ) {}

  ngOnInit(): void {
    if (this.onUpdateSelectedTabObs) {
      this.subscription.add(
        this.onUpdateSelectedTabObs.subscribe((x: number) => {
          this.updateSelectedTab();
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.getTimeframes();
  }

  getTimeframes(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.filters = x;
        this.selectedFilters = y;
        this.setApplicationDate(x.activeDates);
        this.setTimeframes(
          x.timeframe,
          this.startSelectedTimeframe || y.timeframe
        );
        this.updateSelectedTab();
      })
    );
  }

  setApplicationDate(activeDates: Array<ActiveDate>): void {
    this.appDate = activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
  }

  setTimeframes(timeframes: Array<Timeframe>, selected: TimeframeItem): void {
    this.months = TimeframeUtils.getCalendarMonths();
    this.fiscalYears = TimeframeUtils.getFiscalYears(
      this.filters.dates,
      this.appDate
    );
    this.timeframes = TimeframeUtils.getTimeframes(timeframes, this.appDate);
    this.selectedTimeframe =
      selected.code === 'Custom'
        ? selected
        : TimeframeUtils.getTimeframeItemByCode(this.filters, selected.code);
    if (this.selectedFilters.timeframe.code === 'Custom') {
      this.customStartMonth = this.months.find(
        (x: TextValuePair) =>
          x.value ===
          this.filters.dates.find(
            (y: MmbDate) => y.Id === this.selectedFilters.timeframe.start
          ).MonthNbr
      );
      this.customEndMonth = this.months.find(
        (x: TextValuePair) =>
          x.value ===
          this.filters.dates.find(
            (y: MmbDate) => y.Id === this.selectedFilters.timeframe.end
          ).MonthNbr
      );
      this.customStartYear = this.fiscalYears.find(
        (x: TextValuePair) =>
          x.value ===
          this.filters.dates.find(
            (y: MmbDate) => y.Id === this.selectedFilters.timeframe.start
          ).FiscalYearNbr
      );
      this.customEndYear = this.fiscalYears.find(
        (x: TextValuePair) =>
          x.value ===
          this.filters.dates.find(
            (y: MmbDate) => y.Id === this.selectedFilters.timeframe.end
          ).FiscalYearNbr
      );
    } else {
      this.customStartMonth = this.months[0];
      this.customEndMonth = this.months[11];
      this.customStartYear = this.fiscalYears[1];
      this.customEndYear = this.fiscalYears[1];
    }

    this.onTimeframeChanged(
      this.selectedTimeframe,
      false,
      this.selectedTimeframe.getRange() !== selected.getRange()
    );
  }

  getTimeframeByCode(timeframes: Array<Timeframe>, code: string): Timeframe {
    return timeframes.find((x: Timeframe) => x.TimePeriodCode === code);
  }

  updateSelectedTab(): void {
    const selectedTab: number =
      this.selectedTimeframe.code === 'Custom'
        ? 4
        : this.timeframes.findIndex((x: TimeframeGroup) =>
            x.subGroups.find((y: TimeframeSubGroup) =>
              y.items.some(
                (z: TimeframeItem) => z.code === this.selectedTimeframe.code
              )
            )
          ) + 1;

    this.timeframeNav.select(selectedTab);
    this.changeDetector.detectChanges();
  }

  onTimeframeChanged(
    timeframe: TimeframeItem,
    forceReload?: boolean,
    invalidStorage?: boolean
  ): void {
    this.selectedTimeframe = timeframe;
    this.selectionChangeEvent.emit(
      new TimeframeChanged({
        timeframe: this.selectedTimeframe,
        forceReload,
        invalidStorage,
      })
    );
  }

  onChangeSelectedCustom(selected: TextValuePair, index: string) {
    switch (index) {
      case 'startMonth':
        this.customStartMonth = selected;
        break;
      case 'endMonth':
        this.customEndMonth = selected;
        break;
      case 'startYear':
        this.customStartYear = selected;
        break;
      case 'endYear':
        this.customEndYear = selected;
        break;
    }
    this.validateTimeframe();
  }

  validateTimeframe() {
    const startTimeId = this.getCustomStartEndTime()[0];
    const endTimeId = this.getCustomStartEndTime()[1];
    this.validTimeframe = startTimeId <= endTimeId ? true : false;
    if (this.validTimeframe && !this.isModal) {
      this.onApplyCustomTimeframe();
    }
  }

  getCustomStartEndTime() {
    const startTimeId: number = this.filters.dates.find(
      (x: MmbDate) =>
        x.MonthNbr === this.customStartMonth.value &&
        x.FiscalYearNbr === this.customStartYear.value
    )?.Id;
    const endTimeId: number = this.filters.dates.find(
      (x: MmbDate) =>
        x.MonthNbr === this.customEndMonth.value &&
        x.FiscalYearNbr === this.customEndYear.value
    )?.Id;
    return [startTimeId, endTimeId];
  }

  onApplyCustomTimeframe() {
    if (this.validTimeframe) {
      const appDateTime: MmbDate = this.filters.dates.find(
        (x: MmbDate) => x.Id === this.appDate.StartTimeId
      );
      this.onTimeframeChanged(
        new TimeframeItem({
          start: this.getCustomStartEndTime()[0],
          end: this.getCustomStartEndTime()[1],
          code: 'Custom',
          mmbPeriod: 'Custom',
          title: 'Custom',
          type: 'Custom',
          fiscalYear: appDateTime.FiscalYearNbr,
          enabled: true,
          isPast: this.appDate.StartTimeId > this.getCustomStartEndTime()[1],
        }),
        true
      );
    }
  }
}
