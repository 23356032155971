// Enums
export enum FilterType {
  WMU = 1,
  FinancialCustomer = 2,
  Market = 3,
  ClientGroup = 4,
  ServiceGroup = 5,
  Industry = 6,
  Function = 7,
  GrowthPriority = 8,
  Alliance = 9,
}

export enum DropDownType {
  Link = 1,
  LinkBold = 2,
  Button = 3,
}

// Constans
export const CurrencyRates = {
  ForecastRate: 'FORECASTRATE',
  PlanRate: 'PLANRATE',
  FxAdjusted: 'FXADJUSTED',
  Erate: 'ERATE',
};

export const CustomCurrencies = {
  FxAdjusted: {
    id: CurrencyRates.FxAdjusted,
    text: 'Fx Adjusted',
  },
  Global: {
    id: 'Global',
    text: 'Global',
  },
  Constant: {
    id: CurrencyRates.PlanRate,
    text: 'Constant',
  },
};

export const TimePeriodCodes = {
  MonthToDate: 'MonthToDate',
  QuarterToDate: 'QuarterToDate',
  FiscalYearToDate: 'FiscalYearToDate',
  PreviousFY: 'PreviousFY',
  CurrentMonth: 'CurrentMonth',
  CurrentQuarter: 'CurrentQuarter',
  CurrentFY: 'CurrentFY',
  NextFY: 'NextFY',
  FourRollingQuarters: 'FourRollingQuarters',
  TwelveRollingMonths: 'TwelveRollingMonths',
  FiscalQuarters: 'FiscalQuarters',
  FiscalHalves: 'FiscalHalves',
};

export const MmbTimePeriodCodes = {
  Future: 'Future',
  Custom: 'Custom',
  Previous: 'Previous',
  Current: 'Current',
};

export const Periods = {
  Quarter: {
    id: 'Quarter',
    text: 'Quarterly',
  },
  Month: {
    id: 'Month',
    text: 'Monthly',
  },
};

export const GroupsBy = {
  Composition: {
    id: 'Composition',
    text: 'Composition',
  },
  ServiceGroup: {
    id: 'ServiceGroup',
    text: 'Service Group',
  },
  ServiceDimension: {
    id: 'BusinessDimension',
    text: 'Service Dimension',
  },
  ClientGroup: {
    id: 'ClientServiceGroup',
    text: 'Client Group',
  },
  Market: {
    id: 'Market',
    text: 'Market',
  },
  Location: {
    id: 'BeneficiaryOrg',
    text: 'Country/BenOrg',
  },
  WMU: {
    id: 'WMU',
    text: 'WMU',
  },
  Industry: {
    id: 'Industry',
    text: 'Industry',
  },
  FinancialCustomer: {
    id: 'FinancialCustomer',
    text: 'Financial Customer',
  },
  ByRecord: {
    id: 'ByRecord',
    text: 'By Record',
  },
  ReasonCode: {
    id: 'ReasonCode',
    text: 'Reason Code',
  },
};

export const SubMetrics = {
  Current: 'Current',
  Plan: 'Plan',
  Projection: 'Projection',
  Forecast: 'Forecast',
};

export const ActiveDates = {
  ApplicationDate: 'ApplicationDate',
  FinancialActualsDate: 'FinancialActualsDate',
};

export const PlanViews = {
  Standard: 'Standard',
  Plan: 'Plan',
};

export const CompositionOptions = {
  Actuals: 'Actuals',
  Backlog: 'Backlog',
  Speculative: 'Speculative',
  QualProjOpps: 'Qual Proj Opps',
  UnqualProjOpps: 'Unqual Proj Opps',
  WinsThisMonth: 'Wins (this Month)',
};

export const getTreeViewItemIconCssName = (type: number): string => {
  switch (type) {
    case 0: // UNKNOWN
      return 'dash';
    case 1: // PRECONTRACT
      return 'tree-view-icon ctp-img-wms-icons-precontract';
    case 2: // PRECONTRAK
      return 'tree-view-icon ctp-img-wms-icons-precontrak';
    case 3: // DMS_PRECONTRACT
      return 'tree-view-icon ctp-img-wms-icons-dms-precontract';
    case 4: // PRE_CONTRACT_RA
      return 'tree-view-icon ctp-img-wms-icons-dms-precontract-ra';
    case 5: // CONTRACT
      return 'tree-view-icon ctp-img-wms-icons-contract';
    case 6: // CONTRAK
      return 'tree-view-icon ctp-img-wms-icons-contrak';
    case 7: // DMS_CONTRACT
      return 'tree-view-icon ctp-img-wms-icons-dms-contract';
    case 8: // DMS_CONTRACT_RA
      return 'tree-view-icon ctp-img-wms-icons-dms-contract-ra';
    case 13: // L1_WBS
      return 'tree-view-icon ctp-img-wms-icons-l1-wbs';
    case 14: // PRECONTRACT_RA
      return 'tree-view-icon ctp-img-wms-icons-delivery-precontract-ra';
    case 15: // L2_WBS
      return 'tree-view-icon ctp-img-wms-icons-l2-wbs';
    case 16: // PRECONTRACT_CC
      return 'tree-view-icon ctp-img-wms-icons-delivery-precontract-cc';
    case 17: // ENGAGEMENT
      return 'tree-view-icon ctp-img-wms-icons-dms-engagement';
    case 171: // ENGAGEMENT_CP
      return 'tree-view-icon ctp-img-wms-icons-engagement-cp';
    case 18: // DMS_DELIVERY
      return 'tree-view-icon ctp-img-wms-icons-dms-delivery';
    default:
      return '';
  }
};
