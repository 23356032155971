import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ContentTypes, Headers } from '../constants/http.constants';
import Dictionary from './dictionary.utils';

export interface IHttpOptions {
  headers?: IHttpHeader;
}

export interface IHttpHeader {
  [header: string]: string;
}

export interface IRequestOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  observe?: any;
  params?:
    | HttpParams
    | {
        [param: string]: string | string[];
      };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
  body?: any;
}

export interface IStrictRequestOptions extends IRequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
}

export class HttpUtils {
  static getOptions(
    customHeaders: string[],
    headers?: IHttpHeader,
    contentType?: string
  ): IHttpOptions {
    const httpHeaders: IHttpHeader = {};

    if (headers) {
      for (const propertyName in headers) {
        httpHeaders[propertyName] = headers[propertyName];
      }
    }

    if (contentType) {
      httpHeaders[Headers.CONTENT_TYPE_HEADER] = contentType;
    } else {
      httpHeaders[Headers.CONTENT_TYPE_HEADER] = ContentTypes.CONTENT_TYPE_JSON;
    }

    if (customHeaders) {
      httpHeaders[Headers.TO_ADD_HEADER] = customHeaders.join(',');
    }

    return {
      headers: httpHeaders,
    };
  }

  static createRequestOptions(
    params: Dictionary<string>
  ): IStrictRequestOptions {
    const requestOptions: IStrictRequestOptions = { params: new HttpParams() };

    params.keys().forEach((k: string) => {
      this.addParam(requestOptions, k, params.getItem(k));
    });

    return requestOptions;
  }

  private static addParam(
    requestOptions: IStrictRequestOptions,
    name: string,
    value: any
  ) {
    if (value) {
      requestOptions.params = requestOptions.params.append(
        name,
        value.toString()
      );
    }
  }
}
