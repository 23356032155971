import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { Industry } from 'src/app/shared/services/entities/filters/industry';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss'],
})
export class IndustryComponent implements OnChanges {
  @Input() industries: Array<Industry> = [];
  @Input() selectedIndustries: Array<Industry> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();
  selectAllChangedEvent = new Subject<boolean>();

  industriesTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.industries && changes.industries.currentValue) {
      this.industriesTreeviewConfig = new TreeViewConfig({
        parentAttr: 'ParentEntityCode',
        textAttr: 'EntityDescription',
        valueAttr: 'EntityCode',
        countByNodes: true,
        selectByNodes: true,
        items: TreeViewUtils.getItems<Industry>(
          this.industries,
          'EntityCode',
          'EntityDescription',
          'ParentEntityCode'
        ),
      });
    }

    if (changes.selectedIndustries && changes.selectedIndustries.currentValue) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedIndustries.map(
          (x: Industry) =>
            new TreeViewItem({
              value: x.EntityCode,
              text: x.EntityDescription,
            })
        ),
      });
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onItemSelection(selectedIndustries: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.Industry,
        keyValues: selectedIndustries.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedIndustries.count,
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
