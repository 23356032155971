import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { Currency } from '../../services/entities/filters/currency';
import { Filters } from '../../services/entities/filters/filters';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { CurrencyChanged } from './entities/currency-events';

import { FiltersService } from '../../services/filters.service';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss'],
})
export class CurrencyComponent implements OnInit, OnDestroy {
  @Input() startSelectedCurrency: Currency;
  @Output() selectionChangeEvent = new EventEmitter<CurrencyChanged>();

  subscription = new Subscription();
  currencies: Array<Currency>;
  selectedCurrency: Currency;
  searchText: string;

  faCheckCircle = faCheckCircle as IconProp;
  faSearch = faSearch as IconProp;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.getCurrencies();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getCurrencies(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        const isFirstChange = !this.currencies;
        this.currencies = x.currencies;
        this.onCurrencyChanged(
          this.startSelectedCurrency || y.currency,
          false,
          isFirstChange
        );
      })
    );
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
  }

  onCurrencyChanged(
    currency: Currency,
    forceReload?: boolean,
    isFirstChange?: boolean
  ): void {
    this.selectedCurrency = currency;
    this.selectionChangeEvent.emit(
      new CurrencyChanged({
        currency: this.selectedCurrency,
        forceReload,
        isFirstChange,
      })
    );
  }
}
