import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Endpoints } from '../../constants/endpoints.constants';
import { MetricType } from '../../constants/metrics.constants';
import { RequestPayload } from '../entities/request-payload';
import { TargetsTileResponse } from '../../components/tiles/entities/targets-tile-response';
import { TextValuePair } from '../entities/common/key-value';

@Injectable({
  providedIn: 'root',
})
export class TargetTileService {
  private metricChangedObservable = new Subject<TextValuePair>();
  salesCloudURL = '?type=sales&isCloud=';
  revenueCloudURL = '?type=revenue&isCloud=';
  cciCloudURL = '?type=cci&isCloud=';

  public get metricChanged(): Subject<TextValuePair> {
    return this.metricChangedObservable;
  }

  constructor(private http: HttpClient) {}

  getTileDataByType(
    type: MetricType,
    request: RequestPayload,
    isCloud?: boolean
  ): Promise<TargetsTileResponse> {
    const uris: Array<string> = this.getUrlByMetricType(type, isCloud);
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    const observables: Array<Observable<TargetsTileResponse>> = uris.map(
      (x: string) => this.http.post<TargetsTileResponse>(x, request, options)
    );

    return forkJoin(observables)
      .pipe(
        switchMap((x: Array<TargetsTileResponse>) => {
          if (x.length === 2) {
            const v2: TargetsTileResponse = x[0];
            const v2Cache: TargetsTileResponse = x[1];

            v2.Items.Plan = v2Cache.Items.Plan;
            v2.Items.Projection = v2Cache.Items.Projection;

            return of(v2);
          }

          return EMPTY;
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private getUrlByMetricType(
    type: MetricType,
    isCloud?: boolean
  ): Array<string> {
    switch (type) {
      case MetricType.Sales:
        return [
          `${Endpoints.targetsV2}` + this.salesCloudURL + isCloud,
          `${Endpoints.targetsV2Cache}` + this.salesCloudURL + isCloud,
        ];
      case MetricType.Revenue:
        return [
          `${Endpoints.targetsV2}` + this.revenueCloudURL + isCloud,
          `${Endpoints.targetsV2Cache}` + this.revenueCloudURL + isCloud,
        ];
      case MetricType.Cci:
        return [
          `${Endpoints.targetsV2}` + this.cciCloudURL + isCloud,
          `${Endpoints.targetsV2Cache}` + this.cciCloudURL + isCloud,
        ];
      default:
        return [];
    }
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (TargetTileService)');
  }
}
