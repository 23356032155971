import { assign } from 'lodash';

import Dictionary from 'src/app/core/utils/dictionary.utils';

class TreeViewItemSchema {
  text: string;
  value: any;
  hasParent?: boolean = false;
  parent?: TreeViewItem;
  children?: Array<TreeViewItem>;
  hasCheckBox?: boolean = true;
  isOpen?: boolean = false;
  checked?: boolean = false;
  disabled?: boolean = false;
  iconCssName?: string;
  cssList?: Array<string>;
  parameters?: Dictionary<any>;
}

export class TreeViewItem extends TreeViewItemSchema {
  constructor(filterAttributes?: TreeViewItemSchema) {
    super();
    assign(this, filterAttributes);
    this.cssList = [];
    this.parameters = new Dictionary<any>();
  }

  hasChildren(): boolean {
    return this.children?.length > 0;
  }

  areAllChildrenChecked(): boolean {
    return this.hasChildren()
      ? this.children?.filter((x: TreeViewItem) => !x.checked)?.length === 0
      : true;
  }

  getCssClasses(): string {
    return this.cssList ? this.cssList.join(' ') : '';
  }
}
