<div class="horizontal-scrollbar">
  <div class="d-flex mt-3">
    <div class="mr-3 pr-0">
        <app-sales-tile></app-sales-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-targets-tile [type]="metricType.Sales"
            (openTrendModalEmitted)="onOpenTrendModalEmitted($event, metricType.Sales)"></app-targets-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-trend-tile [type]="metricType.Sales"
            [onOpenTrendModalEmittedObs]="openSalesTrendModalEmittedEvent.asObservable()"></app-trend-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-gilc-sales-tile></app-gilc-sales-tile>
    </div>
  </div>
  <div class="d-flex mt-3">
    <div class="mr-3 pr-0">
        <app-revenue-tile></app-revenue-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-targets-tile [type]="metricType.Revenue"
            (openTrendModalEmitted)="onOpenTrendModalEmitted($event, metricType.Revenue)"></app-targets-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-trend-tile [type]="metricType.Revenue"
            [onOpenTrendModalEmittedObs]="openRevenueTrendModalEmittedEvent.asObservable()"></app-trend-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-gilc-revenue-tile></app-gilc-revenue-tile>
    </div>
  </div>
  <div class="d-flex mt-3">
    <div class="mr-3 pr-0">
        <app-cci-tile></app-cci-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-targets-tile [type]="metricType.Cci"
            (openTrendModalEmitted)="onOpenTrendModalEmitted($event, metricType.Cci)"></app-targets-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-trend-tile [type]="metricType.Cci"
            [onOpenTrendModalEmittedObs]="openCciTrendModalEmittedEvent.asObservable()"></app-trend-tile>
    </div>
    <div class="mr-3 pr-0">
        <app-gilc-cci-tile></app-gilc-cci-tile>
    </div>
  </div>
</div>

