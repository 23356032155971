import { assign } from 'lodash';

class FilterSelectionKeyValueSchema {
  key: any;
  value: string;
}

export class FilterSelectionKeyValue extends FilterSelectionKeyValueSchema {
  constructor(filterAttributes?: FilterSelectionKeyValueSchema) {
    super();
    assign(this, filterAttributes);
  }
}
