import { assign } from 'lodash';

import { IContractData } from './icontract-data';

class ContractBacklogSchema implements IContractData {
  groupKey: string;
  currentRevenue: number;
  currentCci: number;
  currentCciPct: Record<string, number>;
  targetRevenue: number;
  targetCci: number;
  targetCciPct: Record<string, number>;
  revenue: number;
  cci: number;
  cciPct: Record<string, number>;
}

export class ContractBacklog extends ContractBacklogSchema {
  constructor(filterAttributes?: ContractBacklogSchema) {
    super();
    assign(this, filterAttributes);
  }
}
