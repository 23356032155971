import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { Location } from 'src/app/shared/services/entities/filters/location';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent implements OnChanges {
  @Input() markets: Array<Location> = [];
  @Input() selectedMarkets: Array<Location> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();
  selectAllChangedEvent = new Subject<boolean>();

  marketsTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markets && changes.markets.currentValue) {
      this.marketsTreeviewConfig = new TreeViewConfig({
        parentAttr: 'OrganizationId',
        textAttr: 'OrganizationName',
        valueAttr: 'ParentOrganizationId',
        countByNodes: true,
        selectByNodes: true,
        items: TreeViewUtils.getItems<Location>(
          this.markets,
          'OrganizationId',
          'OrganizationName',
          'ParentOrganizationId'
        ),
      });
    }

    if (changes.selectedMarkets && changes.selectedMarkets.currentValue) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedMarkets.map(
          (x: Location) =>
            new TreeViewItem({
              value: x.OrganizationId,
              text: x.OrganizationName,
            })
        ),
      });
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onItemSelection(selectedMarkets: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.Market,
        keyValues: selectedMarkets.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedMarkets.count,
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
