import { assign } from 'lodash';

class FinancialCustomerSchema {
  CustomerNumber: string;
  CustomerName: string;
  MasterCustomerNumber: string;
  IsMasterCustomer: boolean;
}

export class FinancialCustomer extends FinancialCustomerSchema {
  constructor(filterAttributes?: FinancialCustomerSchema) {
    super();
    assign(this, filterAttributes);
  }

  getFullName(): string {
    return `${this.CustomerNumber} - ${this.CustomerName}`;
  }
}
