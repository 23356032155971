import { assign } from 'lodash';

class CurrencySchema {
  Id: string;
  Description: string;
}

export class Currency extends CurrencySchema {
  constructor(filterAttributes?: CurrencySchema) {
    super();
    assign(this, filterAttributes);
  }
}
