import Dictionary from 'src/app/core/utils/dictionary.utils';
import { SubMetrics } from 'src/app/shared/constants/filters.constants';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { TileDefinition } from '../../entities/tile-definition';

export const GilcTileConstants = {
  Sales: new TileDefinition({
    title: {
      common: 'Sales GILC',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'sales',
        title: 'Sales',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return response.sales / 100;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
      {
        id: 'plan',
        title: SubMetrics.Plan.toUpperCase(),
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return response.plan / 100;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
      {
        id: 'variance',
        title: 'Variance',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return (response.sales - response.plan) / 100;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
      {
        id: 'bookToBillActual',
        title: 'Book to Bill',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Decimal;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
    ],
  }),
  Revenue: new TileDefinition({
    title: {
      common: 'Revenue GILC',
    },
    subtitle: '',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'revenue',
        title: 'Revenue',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return response.revenue / 100;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
      {
        id: 'plan',
        title: SubMetrics.Plan.toUpperCase(),
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return response.plan / 100;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: false,
      },
      {
        id: 'variance',
        title: 'Variance',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.Percentage;
        },
        valueFnc: (response: any) => {
          return (response.revenue - response.plan) / 100;
        },
        isTotal: false,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
  Cci: new TileDefinition({
    title: {
      common: 'Delivered CCI',
    },
    subtitle: 'YoY Growth',
    subAttrFnc: (params: Dictionary<any>) => {
      return '';
    },
    items: [
      {
        id: 'variance',
        title: 'variance',
        typeFnc: (params: Dictionary<any>) => {
          return ValueType.BPS_Rounded;
        },
        valueFnc: (response: any) => {
          return response.variance / 100;
        },
        isTotal: true,
        negativeStyleEnabled: true,
        positiveStyleEnabled: true,
      },
    ],
  }),
};

export const GilcTileErrorMessages = {
  Service504:
    "We couldn't retrieve the data in time. Try refreshing your browser to fix the issue.",
  ServiceDefault:
    'There was an issue during the process. Please, try again later.',
};
