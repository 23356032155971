import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TrendFilters } from '../../trend-tile/components/trend-filters/entities/trend-filters';

// Filters Changed
class TrendModalFiltersChangedSchema {
  tileParentId: string;
  isChartView: boolean;
  trendFilters: TrendFilters;
  type: MetricType;
  reloadRequired?: boolean = true;
  callback?: () => void;
}

export class TrendModalFiltersChanged extends TrendModalFiltersChangedSchema {
  constructor(filterAttributes?: TrendModalFiltersChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}

// ViewType Changed
class TrendModalViewTypeChangedSchema {
  tileParentId: string;
  isChartView: boolean;
}

export class TrendModalViewTypeChanged extends TrendModalViewTypeChangedSchema {
  constructor(filterAttributes?: TrendModalViewTypeChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
