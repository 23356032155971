import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, map } from 'rxjs/operators';

import { RequestPayload } from '../entities/request-payload';
import { Headers } from 'src/app/core/constants/http.constants';
import { Endpoints } from '../../constants/endpoints.constants';
import { OpportunityPhasing } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing';

import { OpportunityPhasingModalConfig } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing-modal-config';
import { PhasingSaveRequest } from '../../components/opportunity-phasing-modal/entities/opportunity-phasing-save';

@Injectable({
  providedIn: 'root',
})
export class OpportunityPhasingModalService {
  isCloud: boolean;

  private openOppPhasingModalEvent =
    new EventEmitter<OpportunityPhasingModalConfig>();
  private closeOppPhasingModalEvent = new EventEmitter<string>();
  private saveOppPhasingSuccessEvent = new EventEmitter<string>();
  public get openOppPhasingModalEmitted(): EventEmitter<OpportunityPhasingModalConfig> {
    return this.openOppPhasingModalEvent;
  }
  public get closeOppPhasingModalEmitted(): EventEmitter<string> {
    return this.closeOppPhasingModalEvent;
  }

  public get saveOppPhasingSuccessEmitted(): EventEmitter<string> {
    return this.saveOppPhasingSuccessEvent;
  }
  cloudURL = '?isCloud=';

  constructor(private http: HttpClient) {}

  getOpportunityById(
    request: RequestPayload,
    isCloud: boolean
  ): Promise<OpportunityPhasing> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<OpportunityPhasing>(
        Endpoints.phasingOpportunity + this.cloudURL + isCloud,
        request,
        options
      )
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  saveOpportunity(
    requests: Array<PhasingSaveRequest>,
    isCloud: boolean
  ): Promise<Array<{ isSuccess: boolean; requestId: string }>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return Promise.all(
      requests.map((x: PhasingSaveRequest) => {
        return this.http
          .post<OpportunityPhasing>(
            Endpoints.phasingOpportunitySave + this.cloudURL + isCloud,
            x,
            options
          )
          .pipe(
            map((response: any) => {
              return {
                isSuccess: response.isSuccess,
                requestId: response.requestId,
              };
            }),
            catchError(this.errorHandler.bind(this))
          )
          .toPromise();
      })
    );
  }

  public getUpdateStatus(opportunityRequestId: string): Observable<boolean> {
    const body: any = '"' + opportunityRequestId + '"';
    return this.http.post<boolean>(Endpoints.postgreUpdatePhasingStatus, body);
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (OpportunityPhasingModalService)');
  }
}
