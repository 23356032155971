import { Component } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-loading',
  templateUrl: './grid-loading.component.html',
  styleUrls: ['./grid-loading.component.scss'],
})
export class GridLoadingComponent implements ILoadingOverlayAngularComp {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}
