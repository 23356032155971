import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';

import { Endpoints } from '../constants/endpoints.constants';
import { CustomerSettings } from './entities/customer-settings';
import { Customer } from './entities/filters/customer';

@Injectable({
  providedIn: 'root',
})
export class CustomerSettingsService {
  constructor(private http: HttpClient) {}

  getCustomerSettings(customer: Customer): Promise<CustomerSettings> {
    return this.http
      .post<CustomerSettings>(Endpoints.customerSettings, {
        customerId: customer.MasterCustomerNumber,
      })
      .pipe(
        pluck('data'),
        map((x: Record<string, any>) => {
          if (x) {
            return new CustomerSettings({
              customerId: x.customerId,
              customerName: x.customerName,
              settings: x.settings,
              updatedBy: x.updatedBy,
              updatedDate: x.updatedDate,
            });
          }

          return CustomerSettings.empty(customer);
        })
      )
      .toPromise();
  }

  updateCustomerSettings(settings: CustomerSettings): Promise<boolean> {
    return this.http
      .post<CustomerSettings>(Endpoints.customerSettingsUpdate, settings)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        })
      )
      .toPromise();
  }
}
