import { Component, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import {
  ActiveDates,
  TimePeriodCodes,
} from '../../constants/filters.constants';

import { ActiveDate } from '../../services/entities/filters/active-date';
import { Filters } from '../../services/entities/filters/filters';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { TimeframeItem } from '../timeframe/entities/timeframe';
import { TimeframeUtils } from '../timeframe/utils/timeframe.utils';

import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-actuals-indicator',
  templateUrl: './actuals-indicator.component.html',
  styleUrls: ['./actuals-indicator.component.scss'],
})
export class ActualsIndicatorComponent implements OnInit {
  subscription = new Subscription();

  actualsLabel: string;
  showMessage: boolean;

  constructor(private filtersService: FiltersService) {}

  ngOnInit(): void {
    this.getFilters();
  }

  getFilters(): void {
    this.subscription.add(
      combineLatest([
        this.filtersService.globalFiltersChanged,
        this.filtersService.selectedFiltersChanged,
      ]).subscribe(([x, y]: [Filters, SelectedFilters]) => {
        this.setActualsLabel(x.activeDates, y.timeframe);
      })
    );
  }

  setActualsLabel(
    activeDates: Array<ActiveDate>,
    timeframe: TimeframeItem
  ): void {
    const faDate: ActiveDate = activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.FinancialActualsDate
    );
    const monthName: string = TimeframeUtils.getFormattedFromTimeId(
      faDate.EndTimeId,
      'MMM'
    );

    this.showMessage = timeframe.start <= faDate.StartTimeId;
    this.actualsLabel = `Actuals as of ${monthName}`;
  }
}
