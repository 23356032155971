import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { StorageUtils } from './core/utils/storage.utils';
import { environment } from 'src/environments/environment';
import { StorageKeys, StorageType } from './core/constants/storage.constants';

import { RebarAuthService } from './core/rebarauth/rebar.auth.service';
import { FiltersService } from './shared/services/filters.service';
import { SharedService } from './shared/services/shared.service';
import { datadogRum } from '@datadog/browser-rum';
import { AppConfigService } from './core/services/app-config.service';
import { DatadogSessionService } from './core/services/datadog-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  title = 'cbp-web';
  loadingCompleted = false;

  constructor(
    private authService: RebarAuthService,
    private filtersService: FiltersService,
    private sharedService: SharedService,
    private appConfigService: AppConfigService,
    private datadogSessionService: DatadogSessionService
  ) {}

  ngOnInit(): void {
    StorageUtils.set(
      StorageType.LocalStorage,
      StorageKeys.ENVIRONMENT_URL,
      `${environment.baseServiceUrl}gonefishing`
    );

    this.addSubscriptions();

    datadogRum.init({
      applicationId: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['appId'],
      clientToken: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['clientToken'],
      site: 'datadoghq.com',
      service: (
        this.appConfigService.config['datadog'] as Record<string, string>
      )['service'],
      env: (this.appConfigService.config['datadog'] as Record<string, string>)[
        'environment'
      ],
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [
        (this.appConfigService.config['datadog'] as Record<string, string>)[
          'allowedTracingOriginsDNS'
        ],
      ],
    });

    datadogRum.setUser({
      id: this.datadogSessionService.setDataDogSessionId(),
    });

    // this.authService.authObserver$.subscribe((authStatus: boolean) => {
    //   console.log(`user is logged in ${authStatus}`);
    // });
  }

  addSubscriptions(): void {
    if (this.authService.isUserAuthenticated()) {
      this.sharedService.loadStarted();

      this.sharedService.saveLoginForMetrics();

      this.sharedService.checkImpersonation().then(() => {
        this.filtersService.init(() => {
          this.sharedService.loadCompleted();
        });
      });

      this.subscription.add(
        this.sharedService.hasLoadingCompleted.subscribe((x: boolean) => {
          this.loadingCompleted = x;
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
