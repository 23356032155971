import { assign } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { TreeViewItem } from './tree-view-item';

class TreeViewConfigSchema {
  radioItemName?: string;
  items: Array<TreeViewItem>;
  valueAttr: string;
  textAttr: string;
  parentAttr: string;
  multipleSelect?: boolean = true;
  countByNodes?: boolean = false;
  selectByNodes?: boolean = false;
  decoupleChildrenFromParent?: boolean = false;
  decoupleParentFromChildren?: boolean = false;
}

export class TreeViewConfig extends TreeViewConfigSchema {
  constructor(filterAttributes?: TreeViewConfigSchema) {
    super();
    assign(this, filterAttributes);

    if (!this.multipleSelect && !this.radioItemName) {
      this.radioItemName = uuidv4();
    }
  }
}
