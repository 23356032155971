import { assign } from 'lodash';

import { ClientGroup } from './client-group';
import { FinancialCustomer } from './financial-customer';
import { Wmu } from './wmu';

class CustomerFiltersSchema {
  wmu: Array<Wmu>;
  financialCustomer: Array<FinancialCustomer>;
  csg: Array<ClientGroup>;
}

export class CustomerFilters extends CustomerFiltersSchema {
  constructor(filterAttributes?: CustomerFiltersSchema) {
    super();
    assign(this, filterAttributes);
  }

  setPrototypes(): void {
    this.wmu.map((x: Wmu) => Object.setPrototypeOf(x, Wmu.prototype));
    this.financialCustomer.map((x: FinancialCustomer) =>
      Object.setPrototypeOf(x, FinancialCustomer.prototype)
    );
    this.csg.map((x: ClientGroup) =>
      Object.setPrototypeOf(x, ClientGroup.prototype)
    );
  }
}
