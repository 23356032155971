import { flatMap, sortBy, uniq } from 'lodash';

import { CheckBoxGroupHeader } from '../entities/checkbox-groups-header';
import { CheckBoxGroupValue } from '../entities/checkbox-groups-value';

export class CheckBoxGroupsUtils {
  static getHeaders<T>(
    source: Array<T>,
    valuesAttr: string,
    textAttr: string,
    callback?: (x: CheckBoxGroupHeader, y: T) => void
  ): Array<CheckBoxGroupHeader> {
    const headers = new Array<CheckBoxGroupHeader>();

    if (source) {
      source = sortBy(source, textAttr);

      source.forEach((s: T) => {
        const header = new CheckBoxGroupHeader({
          text: (s as any)[textAttr],
          values: (s as any)[valuesAttr],
        });

        if (callback) {
          callback(header, s);
        }

        headers.push(header);
      });
    }

    callback = null;
    return headers;
  }

  static getValues<T>(
    source: Array<T>,
    valueAttr: string,
    textAttr: string,
    callback?: (x: Array<CheckBoxGroupValue>, y: T) => void
  ): Array<CheckBoxGroupValue> {
    const values = new Array<CheckBoxGroupValue>();

    if (source) {
      source = sortBy(source, textAttr);

      source.forEach((s: T) => {
        const value = new CheckBoxGroupValue({
          text: (s as any)[textAttr],
          value: (s as any)[valueAttr],
        });

        if (callback) {
          callback(values, s);
        }

        values.push(value);
      });
    }

    callback = null;
    return values;
  }

  static getSelectedGroupValues<T>(
    headers: Array<CheckBoxGroupHeader>
  ): Array<T> {
    if (headers) {
      const values: Array<T> = flatMap(
        headers
          .filter((x: CheckBoxGroupHeader) => x.checked)
          .map((x: CheckBoxGroupHeader) => x.values)
      );

      return uniq(values);
    }

    return [];
  }
}
