<div class="applied-filters">
    <div class="title">Applied filters:</div>
    <div class="filters-container">
        <div *ngFor="let t of appliedFilterTables" class="applied-filter">
            <div class="filter-title">
                {{t.getFullTitle()}}
            </div>
            <div class="filter-values">
                <ul class="values-list">
                    <li class="value-item" *ngFor="let v of t.values">
                        {{v}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>