<div class="search-container">
    <div class="search-input input-group" [ngClass]="{'input-group-sm' : isSmall}">
        <input type="text" class="form-control" [placeholder]="placeholder" [attr.aria-label]="placeholder"
            aria-describedby="ddl-addon" [(ngModel)]="searchText" [maxlength]="maxLength"
            (ngModelChange)="onSearchTextChanged($event)">
        <div class="input-group-append">
            <span class="input-group-text" id="ddl-addon">
                <fa-icon [icon]="faSearch"></fa-icon>
            </span>
        </div>
    </div>
</div>