import { assign } from 'lodash';
import { FavoriteResponseItem } from 'src/app/shared/services/entities/favorite-response';

class ShareFavoriteModalConfigSchema {
  favorite: FavoriteResponseItem;
}

export class ShareFavoriteModalConfig extends ShareFavoriteModalConfigSchema {
  constructor(filterAttributes?: ShareFavoriteModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
