<div ngbDropdown class="ddl-control d-inline-block" *ngIf="items && selectedItems" container="body" autoClose="outside">
    <button [id]="id" class="btn btn-ddl-control" [ngClass]="getClassName()" ngbDropdownToggle>
        {{getPlaceholder()}}
    </button>
    <div ngbDropdownMenu [attr.aria-labelledby]="id" class="ddl-control-menu">
        <button *ngFor="let i of items; let idx = index" ngbDropdownItem>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" id="item-check-{{idx}}" [checked]="isSelected(i)"
                    (change)="onValueChanged($event, i)">
                <label class="form-check-label" for="item-check-{{idx}}" (click)="onValueChanged($event, i)">
                    {{i.text}}
                </label>
            </div>
        </button>
    </div>
</div>