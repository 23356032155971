import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(list: any, filter: string, attrName?: string, args?: any): any {
    let result: Array<any>;
    list = list ? list : [];

    if (filter && filter.length > 0) {
      result = list.filter(
        (x: any) =>
          (attrName &&
            x[attrName].toLowerCase().indexOf(filter.toLowerCase()) >= 0) ||
          (!attrName && x.toLowerCase().indexOf(filter.toLowerCase()) >= 0)
      );
    } else {
      result = list;
    }

    return result;
  }
}
