import { assign } from 'lodash';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellValueChangedEvent,
  ColDef,
  RowClickedEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid';
import { GridData } from './grid-data';

class GridObservablesSchema {
  loadChanged?: Observable<boolean>;
  updateRowData?: ReplaySubject<GridData>;
  updateColDefs?: ReplaySubject<Array<ColDef>>;
  filterChanged?: Observable<string>;
  expandChanged?: Observable<boolean>;
  stopEditing?: Observable<boolean>;
  selectionChanged?: Subject<SelectionChangedEvent>;
  rowClicked?: Subject<RowClickedEvent>;
  sortChanged?: Subject<SortChangedEvent>;
  cellValueChanged?: Subject<CellValueChangedEvent>;
  cellEditingStarted?: Subject<CellEditingStartedEvent>;
  cellEditingStopped?: Subject<CellEditingStoppedEvent>;
  gridReady?: Subject<any>;
}

export class GridObservables extends GridObservablesSchema {
  constructor(filterAttributes?: GridObservablesSchema) {
    super();
    assign(this, filterAttributes);

    this.selectionChanged = new Subject<SelectionChangedEvent>();
    this.rowClicked = new Subject<RowClickedEvent>();
    this.sortChanged = new Subject<SortChangedEvent>();
    this.cellValueChanged = new Subject<CellValueChangedEvent>();
    this.cellEditingStarted = new Subject<CellEditingStartedEvent>();
    this.cellEditingStopped = new Subject<CellEditingStoppedEvent>();
    this.gridReady = new Subject<any>();
  }
}
