import { assign } from 'lodash';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';

class PhasingSavePeriodSchema {
  Id: number;
  RevenueAmount: number;
  CciAmount: number;
}

export class PhasingSavePeriod extends PhasingSavePeriodSchema {
  constructor(filterAttributes?: PhasingSavePeriodSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class PhasingSaveModelSchema {
  OpportunityId: number;
  ServiceGroupCode: string;
  PhasingSource: string;
  PhasingOn: string;
  CciFollowsRevenue?: boolean;
  Currency?: string;
  MonthlyPhasing: Array<PhasingSavePeriod>;
  OpportunityTimeframe?: Timeframe;
}

export class PhasingSaveModel extends PhasingSaveModelSchema {
  constructor(filterAttributes?: PhasingSaveModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class PhasingSaveRequestSchema {
  Payload: Record<string, any>;
  OpportunityToSave: PhasingSaveModel;
  MasterClientId: string;
  MasterClientName: string;
  OpportunityName: string;
}

export class PhasingSaveRequest extends PhasingSaveRequestSchema {
  constructor(filterAttributes?: PhasingSaveRequestSchema) {
    super();
    assign(this, filterAttributes);
  }
}
