import { capitalize, has, isNull, isNumber, isUndefined } from 'lodash';

import { ColDef, CsvExportParams, GridOptions } from 'ag-grid';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { PhasingTab } from '../../../entities/opportunity-phasing';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { PhasingEditGridConfig } from '../entities/phasing-edit-grid-config';
import { PhasingEditGridUtils } from '../utils/phasing-edit-grid.utils';

export const GridColDefs = {
  getColDefs: (
    phasingTab: PhasingTab,
    filters: Filters,
    selectedFilters: SelectedFilters,
    customerFilters: CustomerFilters
  ): Array<ColDef> => {
    const columnDefs: Array<ColDef> = GridColDefs.Shared.getBaseGridColDefs();

    return columnDefs.concat(
      GridColDefs.Shared.getPeriodColDefs(phasingTab, filters, selectedFilters)
    );
  },
  Shared: {
    getBaseGridColDefs: (): Array<ColDef> => {
      return [
        {
          headerName: 'Projection',
          minWidth: 80,
          field: 'projection',
          pinned: true,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          cellRendererParams: (params: any) => {
            return {
              align: GridCellItemAlign.Center,
            };
          },
          cellClass: 'disabled-cell',
        },
        {
          headerName: 'Total',
          minWidth: 80,
          field: 'total',
          pinned: true,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          cellRendererParams: (params: any) => {
            return {
              cellClass: {
                excelNumberFormat: params.rowIndex !== 2,
                excelPercentFormat: params.rowIndex === 2,
              },
              align: GridCellItemAlign.Right,
              round: params.rowIndex === 2,
              nullSymbol: '0',
            };
          },
          // tooltip: function (params) {
          //   return this.formatTooltip(params.value);
          // }.bind(this)
          cellClass: 'disabled-cell',
        },
        {
          headerName: 'Variance',
          minWidth: 80,
          field: 'variance',
          pinned: true,
          cellRendererSelector: GridColDefs.Shared.getRenderer,
          menuTabs: [],
          cellRendererParams: (params: any) => {
            return {
              cellClass: {
                excelNumberFormat: params.rowIndex !== 2,
                excelPercentFormat: params.rowIndex === 2,
              },
              align: GridCellItemAlign.Right,
              round: params.rowIndex === 2,
              nullSymbol: '0',
            };
          },
          // tooltip: function (params) {
          //   return this.formatTooltip(params.value);
          // }.bind(this)
          cellClass: 'disabled-cell',
        },
      ] as Array<ColDef>;
    },
    getPeriodColDefs: (
      phasingTab: PhasingTab,
      filters: Filters,
      selectedFilters: SelectedFilters
    ): Array<ColDef> => {
      const timeframe: TimeframeItem =
        PhasingEditGridUtils.getOppPhasingTimeframe(
          filters,
          selectedFilters,
          phasingTab
        );

      const columnDefs: Array<ColDef> =
        GridColDefs.Shared.addCustomRendererConfigs(
          GridUtils.getMonthlyHeaders(timeframe, filters.dates)
        );

      columnDefs.push({
        headerName: 'BEYOND',
        colId: GridColDefs.Shared.beyondColId.toString(),
        minWidth: 100,
        width: 100,
        field: GridColDefs.Shared.beyondColId.toString(),
        menuTabs: [],
        editable: GridColDefs.Shared.isEditable,
        cellEditor: 'numericEditor',
        cellRendererSelector: GridColDefs.Shared.getRenderer,
        cellRendererParams: (params: any) => {
          return {
            align: GridCellItemAlign.Right,
            nullSymbol: '0',
          };
        },
        cellClassRules: GridColDefs.Shared.customCellClassRules,
      });

      return columnDefs;
    },
    addCustomRendererConfigs: (colDefs: Array<ColDef>): Array<ColDef> => {
      colDefs = colDefs.map((x: ColDef) => ({
        ...x,
        headerName: capitalize(x.headerName),
        minWidth: 100,
        width: 100,
        field: x.colId,
        editable: GridColDefs.Shared.isEditable,
        cellEditor: 'numericEditor',
        cellRendererSelector: GridColDefs.Shared.getRenderer,
        menuTabs: [],
        cellRendererParams: (params: any) => {
          return {
            align: GridCellItemAlign.Right,
            round: params.rowIndex === 2,
            nullSymbol: '0',
          };
        },
        cellClassRules: GridColDefs.Shared.customCellClassRules,
      }));

      return colDefs;
    },
    getRenderer: (params: any): Record<string, any> => {
      let renderer: Record<string, any> = null;

      if (params.colDef.field === 'projection') {
        renderer = {
          component: 'stringCellRenderer',
        };
      } else if (params.rowIndex === 2) {
        renderer = {
          component: 'percentCellRenderer',
        };
      } else {
        renderer = {
          component: 'numberCellRenderer',
        };
      }

      return renderer;
    },
    isEditable: (params: any): boolean => {
      const isOutOfPeriod: boolean =
        params.data.extra.find(
          (x: Record<string, any>) => x.Id === Number(params.colDef.colId)
        )?.isOutOfPeriod || false;
      const editableFieldsCount: number = params.data.extra.filter(
        (x: Record<string, any>) => !x.isOutOfPeriod
      ).length;
      const config: PhasingEditGridConfig = params.context.config;

      const result: boolean =
        ((params.node.rowIndex === 0 && !isOutOfPeriod) ||
          (params.node.rowIndex === 1 &&
            !isOutOfPeriod &&
            !(
              isUndefined(config.phasingTab.CCIFollowsRevenue) ||
              config.phasingTab.CCIFollowsRevenue
            ))) &&
        editableFieldsCount > 1 &&
        (!config.isReadOnly || config.isAdmin);

      return result;
    },
    isIncorrectValue: (params: any): boolean => {
      return !isNull(params.value) && !isUndefined(params.value)
        ? isNumber(params.value)
          ? false
          : isNaN(Number(params.value.replace(/,/, '')))
        : false;
    },
    isEditedValue: (params: any, phasingTab: PhasingTab): boolean => {
      let result = false;
      const config: PhasingEditGridConfig = params.context.config;

      const value: number =
        isNull(params.value) || isUndefined(params.value)
          ? 0
          : Math.round(Number(params.value));

      if (
        !GridColDefs.Shared.isIncorrectValue(params) &&
        config.originalPhasingMonths?.rows?.length > 0
      ) {
        result = has(
          config.originalPhasingMonths.rows[params.rowIndex],
          params.colDef.colId
        )
          ? Math.round(
              Number(
                config.originalPhasingMonths.rows[params.rowIndex][
                  params.colDef.colId
                ]
              )
            ) !== value
          : value !== 0;
      }
      return result;
    },
    customCellClassRules: {
      excelNumberFormat: (params: any) => {
        return true;
      },
      'editable-cell': (params: any) => {
        return GridColDefs.Shared.isEditable(params);
      },
      'disabled-cell': (params: any) => {
        return !GridColDefs.Shared.isEditable(params);
      },
      error: (params: any) => {
        return GridColDefs.Shared.isIncorrectValue(params);
      },
      edited: (params: any) => {
        const config: PhasingEditGridConfig = params.context.config;
        return GridColDefs.Shared.isEditedValue(params, config.phasingTab);
      },
    },
    beyondColId: 999999,
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultExportParams: {
        sheetName: 'Opportunities',
        fileName: 'CBP Opportunities',
      } as CsvExportParams,
    } as GridOptions;
  },
};
