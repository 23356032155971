<div class="horizontal-scrollbar" [ngClass]="{'hidden' : isGridExpanded}">
  <div class="tile-content mr-3">
    <app-sales-tile [isCloud]="isCloud"></app-sales-tile>
  </div>
  <div class="tile-content mr-3">
    <app-trend-tile [type]="metricType.Sales" [onOpenTrendModalEmittedObs]="openTrendModalEmittedEvent.asObservable()">
    </app-trend-tile>
  </div>
  <div class="tile-content mr-3">
    <app-targets-tile [type]="metricType.Sales"
      (openTrendModalEmitted)="onOpenTrendModalEmitted($event, metricType.Sales)"></app-targets-tile>
  </div>
  <div class="tile-content mr-3">
    <app-gilc-sales-tile></app-gilc-sales-tile>
  </div>
  <div class="tile-content mr-3">
    <app-won-cci-tile></app-won-cci-tile>
  </div>
</div>
<div class="row mt-3">
  <div class="col">
    <app-wins-grid [isCloud]="isCloud" (expandChangedEvent)="onExpandChanged($event)"></app-wins-grid>
  </div>
</div>