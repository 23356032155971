import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgxSpinnerService, Size } from 'ngx-spinner';

// https://napster2210.github.io/ngx-spinner/

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnChanges {
  @Input() showSpinner = false;
  @Input() fullScreen = true;
  @Input() zIndex = 1;
  @Input() name = 'default-name';
  @Input() text = '';
  @Input() type = 'ball-beat';
  @Input() size: Size = 'default';

  @Input() bdColor = 'rgba(0, 0, 0, 0)';
  @Input() spinnerColor = '#FFF';
  @Input() textColor = '#FFF';

  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showSpinner) {
      if (changes.showSpinner.currentValue) {
        this.ngxSpinnerService.show(this.name);
      } else {
        this.ngxSpinnerService.hide(this.name);
      }
    }
  }
}
