import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import { GridToolbarControlType } from 'src/app/shared/constants/grid.constants';
import { GridToolbarConfig } from './entities/grid-toolbar-config';

@Component({
  selector: 'app-grid-toolbar',
  templateUrl: './grid-toolbar.component.html',
  styleUrls: ['./grid-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridToolbarComponent {
  @Input() config: GridToolbarConfig;
  @Input() isCloud: boolean;
  @Output() searchEvent = new EventEmitter<string>();

  public get controlType(): typeof GridToolbarControlType {
    return GridToolbarControlType;
  }

  onSearchTextChanged(searchText: string): void {
    this.searchEvent.emit(searchText);
  }
}
