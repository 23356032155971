<div ngbDropdown class="ddl-control d-inline-block" *ngIf="items && selectedItem" container="body">
    <button [id]="id" class="btn btn-ddl-control" [ngClass]="getClassName()" ngbDropdownToggle>
        {{getPlaceholder()}}
    </button>
    <div ngbDropdownMenu [attr.aria-labelledby]="id" class="ddl-control-menu">
        <button *ngFor="let i of items" (click)="onValueChanged(i)"
            [disabled]="i.params?.isDisabled && i.params?.isDisabled()" ngbDropdownItem>
            <fa-icon [icon]="faCheckCircle" *ngIf="selectedItem.value === i.value"></fa-icon>
            <span
                [ngClass]="{'not-selected' : selectedItem.value !==  i.value, 'selected' : selectedItem.value ===  i.value}">{{i.text}}</span>
        </button>
    </div>
</div>