import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import {
  ServiceGroupSet,
  ServiceGroupValues,
} from 'src/app/shared/services/entities/filters/service-group';
import { CheckBoxGroupsConfig } from '../../../base/checkbox-groups/entities/checkbox-groups-config';
import { CheckBoxGroupValue } from '../../../base/checkbox-groups/entities/checkbox-groups-value';
import { CheckBoxGroupsUtils } from '../../../base/checkbox-groups/utils/checkbox-groups.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-service-group',
  templateUrl: './service-group.component.html',
  styleUrls: ['./service-group.component.scss'],
})
export class ServiceGroupComponent implements OnChanges {
  @Input() groups: Array<ServiceGroupSet> = [];
  @Input() serviceGroups: Array<ServiceGroupValues> = [];
  @Input() selectedServiceGroups: Array<ServiceGroupValues> = [];
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  selectAllChangedEvent = new Subject<boolean>();

  sgsCheckBoxGroupsConfig: CheckBoxGroupsConfig;
  startSelection: Array<CheckBoxGroupValue>;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.groups &&
      changes.groups.currentValue &&
      changes.serviceGroups &&
      changes.serviceGroups.currentValue
    ) {
      this.sgsCheckBoxGroupsConfig = new CheckBoxGroupsConfig({
        headers: CheckBoxGroupsUtils.getHeaders<ServiceGroupSet>(
          this.groups,
          'Children',
          'GroupName'
        ),
        values: CheckBoxGroupsUtils.getValues<ServiceGroupValues>(
          this.serviceGroups,
          'ServiceGroupCd',
          'ServiceGroupNm'
        ),
      });
    }

    if (
      changes.selectedServiceGroups &&
      changes.selectedServiceGroups.currentValue
    ) {
      this.startSelection = this.selectedServiceGroups.map(
        (x: ServiceGroupValues) =>
          new CheckBoxGroupValue({
            value: x.ServiceGroupCd,
            text: x.ServiceGroupNm,
          })
      );
    }
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }

  onValuesSelection(selectedSGs: Array<CheckBoxGroupValue>): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.ServiceGroup,
        keyValues: selectedSGs.map(
          (x: CheckBoxGroupValue) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedSGs.length,
      })
    );
  }
}
