import { ChangeDetectorRef, Component } from '@angular/core';
import { Subject } from 'rxjs';

import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

import { GilcTileService } from 'src/app/shared/services/tiles/gilc-tile.service';
import { ProjectionTileService } from 'src/app/shared/services/tiles/projection-tile.service';
import { TargetTileService } from 'src/app/shared/services/tiles/target-tile.service';
import { TrendTileService } from 'src/app/shared/services/tiles/trend-tile.service';

@Component({
  selector: 'app-backlog',
  templateUrl: './backlog.component.html',
  styleUrls: ['./backlog.component.scss'],
})
export class BacklogComponent {
  openTrendModalEmittedEvent = new Subject<boolean>();
  isGridExpanded: boolean;

  public get metricType(): typeof MetricType {
    return MetricType;
  }

  constructor(
    private targetTileService: TargetTileService,
    private trendTileService: TrendTileService,
    private projectionTileService: ProjectionTileService,
    private gilcTileService: GilcTileService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  onOpenTrendModalEmitted(isChartView: boolean, type: MetricType): void {
    this.openTrendModalEmittedEvent.next(isChartView);
  }

  onMetricChanged(selected: TextValuePair): void {
    this.targetTileService.metricChanged.next(selected);
    this.trendTileService.metricChanged.next(selected);
    this.projectionTileService.metricChanged.next(selected);
    this.gilcTileService.metricChanged.next(selected);
  }

  onExpandChanged(isExpanded: boolean): void {
    this.isGridExpanded = isExpanded;
    this.changeDetectorRef.detectChanges();
  }
}
