import { assign, differenceBy, differenceWith, isEqual, omit } from 'lodash';

import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

class TrendFiltersSchema {
  period: TextValuePair;
  groupBy: TextValuePair;
  isCumulative: boolean;
  isPercentage: boolean;
  subMetric: TextValuePair;
  timeframe?: TimeframeItem;
}

export class TrendFilters extends TrendFiltersSchema {
  constructor(filterAttributes?: TrendFiltersSchema) {
    super();
    assign(this, filterAttributes);
  }

  getRequestGroupBy(): string {
    return `${this.period.value},${this.groupBy.value}`;
  }

  equals(trendFilters: TrendFilters): boolean {
    return isEqual([this], [trendFilters]);
  }

  reloadRequired(trendFilters: TrendFilters): boolean {
    const noReloadProperties: Array<string> = ['subMetric'];
    return !isEqual(
      omit(this, noReloadProperties),
      omit(trendFilters, noReloadProperties)
    );
  }
}
