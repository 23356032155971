import { assign } from 'lodash';

import { MetricType } from 'src/app/shared/constants/metrics.constants';

class TargetsTileDefinitionSchema {
  title: Record<string, string>;
  type: MetricType;
  allowPercentage?: boolean = false;
  isPercentage?: boolean = false;
}

export class TargetsTileDefinition extends TargetsTileDefinitionSchema {
  constructor(filterAttributes?: TargetsTileDefinitionSchema) {
    super();
    assign(this, filterAttributes);
  }
}
