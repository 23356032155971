export enum GridToolbarControlType {
  Dropdown = 1,
  Button = 2,
  Icon = 3,
  Switch = 4,
  MultipleDropdown = 5,
}

export enum GridCellItemAlign {
  Right = 'flex-end',
  Left = 'flex-start',
  Center = 'center',
}

export const CustomOptions = {
  None: 'None',
  NotAssigned: {
    id: '0000000000',
    text: 'Not Assigned',
  },
};

export const MetricsOptions = {
  Sales: 'Sales',
  Cci: 'CCI $',
  CciPercentage: 'CCI %',
  Revenue: 'Revenue',
  Summary: 'Summary',
  Wins: 'Wins',
  WinsThisMonth: 'Wins (this Month)',
  WonCci: 'Won CCI$',
  WonCciPercentage: 'Won CCI%',
};

export const MetricsValues = {
  Summary: 'summary',
  Revenue: 'revenue',
  Sales: 'sales',
  Cci: 'cciDollar',
  CciPercentage: 'cciPercentage',
  WonCci: 'wonCciDollar',
};

export const ViewOptions = {
  Current: 'current-projection',
  Projection: 'last-projection',
  CompareProjection: 'compare-lp',
  Plan: 'plan',
};

export const OpportunityTypes = {
  All: 'All Proj Opps',
  Qualified: 'Qual Proj Opps',
  Unqualified: 'Unqual Proj Opps',
};
