import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import {
  BacklogResponse,
  BacklogResponseItem,
} from 'src/app/shared/services/entities/grids/backlog-response';
import { ComponentNames } from '../constants/backlog-grid.constants';
import { ContractActuals } from '../entities/contract-actuals';
import { ContractBacklog } from '../entities/contract-backlog';
import { ContractTotals } from '../entities/contract-totals';

export class BacklogGridUtils {
  static mapData(
    source: Array<BacklogResponse>,
    filters: Filters,
    toolbarConfig: GridToolbarConfig,
    isCustom: boolean
  ): Array<Record<string, any>> {
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );

    return source.map((x: BacklogResponse) => {
      let row: Record<string, any> = {
        Id: x.GroupKey,
        Name: x.Name,
        Link: x.Link,
        Source: x.Source,
        LastSubmitted: x.LastSubmitted,
        LastPublished: x.LastPublished,
        Total: new ContractTotals({
          groupKey: x.GroupKey,
          currentRevenue: x.currentTotalRevenueAmt,
          currentCci: x.currentTotalCciAmt,
          currentCciPct: x.currentTotalCciPct,
          targetRevenue: x.targetTotalRevenueAmt,
          targetCci: x.targetTotalCciAmt,
          targetCciPct: x.targetTotalCciPct,
          revenue: x.totalRevenueAmt,
          cci: x.totalCciAmt,
          cciPct: x.totalCciPct,
        }),
        Actual: new ContractActuals({
          groupKey: x.GroupKey,
          currentRevenue: x.currentActualRevenueAmt,
          currentCci: x.currentActualCciAmt,
          currentCciPct: x.currentActualCciPct,
          targetRevenue: x.targetActualRevenueAmt,
          targetCci: x.targetActualCciAmt,
          targetCciPct: x.targetActualCciPct,
          revenue: x.actualRevenueAmt,
          cci: x.actualCciAmt,
          cciPct: x.actualCciPct,
        }),
        Backlog: new ContractBacklog({
          groupKey: x.GroupKey,
          currentRevenue: x.currentBacklogRevenueAmt,
          currentCci: x.currentBacklogCciAmt,
          currentCciPct: x.currentBacklogCciPct,
          targetRevenue: x.targetBacklogRevenueAmt,
          targetCci: x.targetBacklogCciAmt,
          targetCciPct: x.targetBacklogCciPct,
          revenue: x.backlogRevenueAmt,
          cci: x.backlogCciAmt,
          cciPct: x.backlogCciPct,
        }),
      };

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        row = this.mapQuarterlyData(row, x, isCustom);
      } else {
        row = this.mapMonthlyData(row, x, filters.dates);
      }

      return row;
    });
  }

  private static mapMonthlyData(
    row: Record<string, any>,
    source: BacklogResponse,
    dates: Array<MmbDate>
  ): Record<string, any> {
    source.monthly.forEach((x: BacklogResponseItem) => {
      const period: MmbDate = dates.find(
        (y: MmbDate) => y.Id.toString() === x.GroupKey
      );
      row = {
        ...row,
        [period.Id]: new ContractTotals({
          groupKey: x.GroupKey,
          currentRevenue: x.currentTotalRevenueAmt,
          currentCci: x.currentTotalCciAmt,
          currentCciPct: x.currentTotalCciPct,
          targetRevenue: x.targetTotalRevenueAmt,
          targetCci: x.targetTotalCciAmt,
          targetCciPct: x.targetTotalCciPct,
          revenue: x.totalRevenueAmt,
          cci: x.totalCciAmt,
          cciPct: x.totalCciPct,
        }),
      };
    });

    return row;
  }

  private static mapQuarterlyData(
    row: Record<string, any>,
    source: BacklogResponse,
    isCustom: boolean
  ): Record<string, any> {
    source.quarterly.forEach((x: BacklogResponseItem) => {
      const quarter: string = isCustom
        ? `q${x.GroupKey.split('-')[1]}'${x.GroupKey.split('-')[0].slice(-2)}`
        : `q${x.GroupKey.split('-')[1]}`;

      row = {
        ...row,
        [quarter]: new ContractTotals({
          groupKey: x.GroupKey,
          currentRevenue: x.currentTotalRevenueAmt,
          currentCci: x.currentTotalCciAmt,
          currentCciPct: x.currentTotalCciPct,
          targetRevenue: x.targetTotalRevenueAmt,
          targetCci: x.targetTotalCciAmt,
          targetCciPct: x.targetTotalCciPct,
          revenue: x.totalRevenueAmt,
          cci: x.totalCciAmt,
          cciPct: x.totalCciPct,
        }),
      };
    });

    return row;
  }
}
