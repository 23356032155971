<ng-template #opportunityPhasingModal let-modal>
  <div *ngIf="loaded" class="container phasing-content">
    <div class="phasing-header mt-3">
      <div class="header-left">
        <div class="row ml-2">
          <div class="col-auto pr-0 pl-0">
            <label class="mb-0">Opportunity:</label>
          </div>
          <div class="col-auto pr-0">
            <input class="form-control form-control-sm spec-id" type="text" [value]="opportunity?.Id" readonly>
          </div>
          <div class="col-auto pr-0">
            <input class="form-control form-control-sm spec-name" type="text" [value]="opportunity?.Name" readonly>
          </div>
        </div>
      </div>
      <div class="header-right mr-1">
        <button class="btn btn-link close-icon" (click)="onCloseModal()">
          <span>
            Close
            <fa-icon [icon]="faTimes">
            </fa-icon>
          </span>
        </button>
      </div>
    </div>
    <div class="phasing-body mt-3">
      <div class="row">
        <div class="phasing-input">
          <label for="totalRevenueUnweighted">Total Revenue Unweighted</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.NetRevenue | customNumber: 1" readonly>
        </div>
        <div class="phasing-input">
          <label for="totalCci$Unweighted">Total CCI$ Unweighted</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.NetCci | customNumber: 1" readonly>
        </div>
        <div class="phasing-input">
          <label for="totalCci%nweighted">Total CCI% Unweighted</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="(opportunity?.NetCciPct / 100) | customNumber: 3" readonly>
        </div>
        <div class="phasing-input">
          <label for="currency">Currency (thousands)</label>
          <input class="form-control form-control-sm" type="text" [value]="currencyDescription" readonly>
        </div>
        <div class="phasing-input">
          <label for="projection">Projection %</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="(opportunity?.ProjectionPct / 100) | customNumber: 3" readonly>
        </div>
      </div>
      <div class="row mt-3">
        <div class="phasing-input">
          <label for="totalProjectedRevenue">Total Projected Revenue</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.ProjectedRevenue | customNumber: 1" readonly>
        </div>
        <div class="phasing-input">
          <label for="totalProjectedCci$">Total Projected CCI$</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.ProjectedCci | customNumber: 1" readonly>
        </div>
        <div class="phasing-input">
          <label for="startDate">Start date</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.StartDate | date: 'shortDate'" readonly>
        </div>
        <div class="phasing-input">
          <label for="endDate">End date</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.EndDate | date: 'shortDate'" readonly>
        </div>
        <div class="phasing-input">
          <label for="duration">Duration (days)</label>
          <input class="form-control form-control-sm text-right" type="text"
            [value]="opportunity?.TotalDurations | customNumber: 1" readonly>
        </div>
      </div>
    </div>
    <div *ngIf="opportunity?.PhasingTabs?.length > 0" class="row mt-3 phasing-tabs">
      <div class="col-12">
        <ul ngbNav #nav="ngbNav" class="nav-tabs" [activeId]="selectedSg" (navChange)="onSgTabChange($event)">
          <li class="mr-2" *ngFor="let t of opportunity.PhasingTabs" [ngbNavItem]="t.ServiceGroupCode">
            <a ngbNavLink>{{ t.ServiceGroupCode }}</a>
            <ng-template ngbNavContent>
              <div class="check-container d-flex justify-content-end mb-4">
                <div class="form-check d-flex align-items-end">
                  <input class="form-check-input" type="checkbox" value="" id="cciFollowsRevenue"
                    (change)="onCciFollowsRevenueChange($event)" [checked]="cciFollowsRevenue"
                    [disabled]="config?.isReadOnly">
                  <label class="form-check-label" for="cciFollowsRevenue">
                    CCI$ follows Revenue
                  </label>
                </div>
              </div>
              <div class="row container phasing-inputs-content">
                <div class="phasing-input">
                  <label for="totalRevenueUnweighted">Total Revenue Unweighted</label>
                  <input class="form-control form-control-sm text-right" type="text"
                    [value]="t.NetRevenue | customNumber: 1" readonly>
                </div>
                <div class="phasing-input">
                  <label for="totalCci$Unweighted">Total CCI$ Unweighted</label>
                  <input class="form-control form-control-sm text-right" type="text"
                    [value]="t.NetCci | customNumber: 1" readonly>
                </div>
                <div class="phasing-input">
                  <label for="totalCci%nweighted">Total CCI% Unweighted</label>
                  <input class="form-control form-control-sm text-right" type="text"
                    [value]="(t.NetCciPct / 100) | customNumber: 3" readonly>
                </div>
                <div class="phasing-input">
                  <label for="startDate">Start date</label>
                  <input class="form-control form-control-sm text-right" type="text"
                    [value]="t.StartDate | date: 'shortDate'" readonly>
                </div>
                <div class="phasing-input">
                  <label for="endDate">End date</label>
                  <input class="form-control form-control-sm text-right" type="text"
                    [value]="t.EndDate | date: 'shortDate'" readonly>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
        <div>
          <app-phasing-edit-grid [config]="editGridConfig" (phasingChangedEvent)="onPhasingChanged($event)"
            class="modal-grid"></app-phasing-edit-grid>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3 mb-3">
      <button class="btn btn-secondary ml-3" (click)="onCloseModal()">Close</button>
      <button *ngIf="!config?.isReadOnly" class="btn btn-primary ml-3" [disabled]="!saveEnable"
        (click)="onSave()">Save</button>
      <button *ngIf="!config?.isReadOnly" class="btn btn-primary ml-3" [disabled]="!saveEnable"
        (click)="onSaveAndClose()">Save and
        Close</button>
    </div>
  </div>
  <app-spinner name="opp-phasing-modal-spinner" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
  </app-spinner>
</ng-template>