import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CustomCurrencies } from '../../constants/filters.constants';
import { Currency } from '../../services/entities/filters/currency';
import { SelectedFilters } from '../../services/entities/filters/selected-filters';
import { MessageTemplates } from '../../constants/messages.constants';
import { CurrencyChanged } from '../currency/entities/currency-events';

import { AppToastsService } from '../../services/app-toasts.service';
import { FiltersService } from '../../services/filters.service';
import { AppMessagesService } from '../../services/app-messages.service';

import { faCheckCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-currency-picker',
  templateUrl: './currency-picker.component.html',
  styleUrls: ['./currency-picker.component.scss'],
})
export class CurrencyPickerComponent implements OnInit {
  selectedCurrency: Currency;

  faCheckCircle = faCheckCircle;
  faSearch = faSearch;

  constructor(
    private filtersService: FiltersService,
    private appToastsService: AppToastsService,
    private appMessagesService: AppMessagesService
  ) {}

  ngOnInit(): void {
    this.getSelectedCurrency();
  }

  getSelectedCurrency(): void {
    const subscription = new Subscription();
    subscription.add(
      this.filtersService.selectedFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          this.selectedCurrency = x.currency;
          subscription.unsubscribe();
        }
      )
    );
  }

  onCurrencyChanged(event: CurrencyChanged): void {
    const hasChanged: boolean = this.selectedCurrency?.Id !== event.currency.Id;
    this.selectedCurrency = event.currency;

    if (hasChanged || event.isFirstChange) {
      if (event.currency.Id === CustomCurrencies.FxAdjusted.id) {
        this.appMessagesService.show(MessageTemplates.FxAdjustedCurrency, {
          centered: true,
        });
      }

      if (event.currency.Id === CustomCurrencies.Constant.id) {
        this.appMessagesService.show(MessageTemplates.ConstantCurrency, {
          centered: true,
        });
      }
    }

    if (event.forceReload && hasChanged) {
      this.filtersService.updateSelectedCurrency(event.currency);
      this.filtersService.applyFilters();
    }
  }
}
