<div class="horizontal-scrollbar" [ngClass]="{'hidden' : isGridExpanded}">
  <div class="tile-content mr-3">
    <app-revenue-tile></app-revenue-tile>
  </div>
  <div class="tile-content mr-3">
    <app-trend-tile [type]="metricType.Revenue"
      [onOpenTrendModalEmittedObs]="openTrendModalEmittedEvent.asObservable()"></app-trend-tile>
  </div>
  <div class="tile-content mr-3">
    <app-targets-tile [type]="metricType.Revenue"
      (openTrendModalEmitted)="onOpenTrendModalEmitted($event, metricType.Revenue)"></app-targets-tile>
  </div>
  <div class="tile-content mr-3">
    <app-gilc-revenue-tile></app-gilc-revenue-tile>
  </div>
  <div class="tile-content mr-3">
    <app-cci-tile></app-cci-tile>
  </div>
  <div class="tile-content mr-3">
    <app-gilc-cci-tile></app-gilc-cci-tile>
  </div>
</div>
<div class="row mt-3">
  <div class="col">
    <app-phasing-grid (expandChangedEvent)="isGridExpanded = $event" (metricChangedEvent)="onMetricChanged($event)">
    </app-phasing-grid>
  </div>
</div>