import { assign, cloneDeep } from 'lodash';
import { Filters } from './filters';

class LocationSchema {
  OrganizationId: number;
  OrganizationName: string;
  OrganizationCode: string;
  OrganizationType: string;
  ParentOrganizationId: number;
  LevelNumber: number;
  IsChecked: boolean;
  IsBenOrg: boolean;
}

export class Location extends LocationSchema {
  constructor(filterAttributes?: LocationSchema) {
    super();
    assign(this, filterAttributes);
  }

  getFullLocation(filters: Filters): FullLocation {
    const beneficiaryOrg: Location = filters.locations.find(
      (x: Location) => x.OrganizationId === this.OrganizationId
    );
    let marketUnit: Location;
    let market: Location;

    if (
      beneficiaryOrg.OrganizationType === 'Market Unit' ||
      beneficiaryOrg.OrganizationType === 'Geographic Unit'
    ) {
      marketUnit = cloneDeep(beneficiaryOrg);

      let parentOrganizationId: Location;

      if (marketUnit.OrganizationType === 'Market Unit') {
        parentOrganizationId = filters.locations.find(
          (x: Location) => x.OrganizationId === marketUnit.ParentOrganizationId
        );
      }

      market = parentOrganizationId
        ? filters.locations.find(
            (x: Location) =>
              x.OrganizationId === parentOrganizationId.ParentOrganizationId
          )
        : filters.locations.find(
            (x: Location) =>
              x.OrganizationId === marketUnit.ParentOrganizationId
          );
    } else {
      marketUnit = filters.locations.find(
        (x: Location) =>
          x.OrganizationId === beneficiaryOrg.ParentOrganizationId
      );
      market = filters.locations.find(
        (x: Location) => x.OrganizationId === marketUnit.ParentOrganizationId
      );
    }

    return new FullLocation({
      beneficiaryOrgId: beneficiaryOrg.OrganizationId.toString(),
      marketUnitId: marketUnit.OrganizationId.toString(),
      marketId: market.OrganizationId.toString(),
    });
  }
}

class FullLocationSchema {
  beneficiaryOrgId: string;
  marketUnitId: string;
  marketId: string;
}

export class FullLocation extends FullLocationSchema {
  constructor(filterAttributes?: FullLocationSchema) {
    super();
    assign(this, filterAttributes);
  }
}
