import { assign } from 'lodash';
import { Subject } from 'rxjs';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';

import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { Speculative } from '../../../entities/speculative';
import { MetricTotalChanged } from './speculative-edit-grid-events';

class SpeculativeEditGridConfigSchema {
  speculative: Speculative;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  isReadOnly: boolean;
  isAdmin: boolean;
  isDuplicate?: boolean;
  metricTotalChanged?: Subject<MetricTotalChanged>;
}

export class SpeculativeEditGridConfig extends SpeculativeEditGridConfigSchema {
  constructor(filterAttributes?: SpeculativeEditGridConfigSchema) {
    super();
    assign(this, filterAttributes);

    this.metricTotalChanged = new Subject<MetricTotalChanged>();
  }
}
