import { assign } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

class CheckBoxGroupValueSchema {
  id?: string;
  text: string;
  value?: any;
  checked?: boolean = false;
}

export class CheckBoxGroupValue extends CheckBoxGroupValueSchema {
  constructor(filterAttributes?: CheckBoxGroupValueSchema) {
    super();
    assign(this, filterAttributes);

    this.id = uuidv4();
  }
}
