import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SelectedFilters } from '../../services/entities/filters/selected-filters';

import { FiltersService } from '../../services/filters.service';
import { SecurityService } from '../../services/security.service';
import { AppStateService } from 'src/app/shared/services/app-state.service';
import { AppState } from 'src/app/shared/services/entities/app-state/app-state';
import { BetaMessageService } from 'src/app/shared/services/beta-message.service';

@Component({
  selector: 'app-msa-filter',
  templateUrl: './msa-filter.component.html',
  styleUrls: ['./msa-filter.component.scss'],
})
export class MsaFilterComponent implements OnInit {
  subscription = new Subscription();

  loaded: boolean;
  selectedFilters: SelectedFilters;
  isMSACvf = false;
  hasAccountPlan = false;
  isDisabled = false;
  isAdmin = false;
  isFlipFlopEnabled: boolean;
  isCloud: boolean;

  constructor(
    private filtersService: FiltersService,
    private securityService: SecurityService,
    private appStateService: AppStateService,
    private betaMessage: BetaMessageService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.filtersService.selectedFiltersChanged.subscribe(
        (x: SelectedFilters) => {
          this.selectedFilters = x;

          Promise.all([
            this.securityService.hasAccountPlanRole(
              this.selectedFilters.customer.MasterCustomerNumber
            ),
            this.securityService.isAdmin(),
          ]).then((z) => {
            this.hasAccountPlan = z[0];
            this.isAdmin = z[1];
            this.isDisabled = !this.hasAccountPlan && !this.isAdmin;
          });

          this.isMSACvf = x.isMSACvf;
        }
      )
    );
  }

  loadStarted(): void {
    this.loaded = false;
  }

  loadCompleted(): void {
    this.loaded = true;
  }

  onSwitch(value: boolean): void {
    this.isMSACvf = value;

    this.betaMessage.isCloudSubject$.subscribe((betaFlag) => {
      this.isCloud = betaFlag;
    });

    if (this.isCloud) {
      this.isMSACvf = false;
    }
    this.filtersService.updateSelectedMSACvf(
      this.isMSACvf,
      this.hasAccountPlan
    );
    this.filtersService.applyFilters();
  }
}
