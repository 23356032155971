import { assign } from 'lodash';

import { Currency } from 'src/app/shared/services/entities/filters/currency';

class CurrencyChangedSchema {
  forceReload: boolean;
  isFirstChange: boolean;
  currency: Currency;
}

export class CurrencyChanged extends CurrencyChangedSchema {
  constructor(filterAttributes?: CurrencyChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
