import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

export const TabControlNames = {
  wins: AppModulePaths.ieopportunity.subtabs.wins.path,
  metrics: 'metricName',
  targets: 'target',
  expand: 'expand',
  noActivity: 'noActivity',
  msaIndicator: 'msaIndicator',
};

export const TabFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.WinsThisMonth,
      value: MetricsOptions.WinsThisMonth,
    }),
    new TextValuePair({
      text: MetricsOptions.Wins,
      value: MetricsOptions.Wins,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};
