import { AppToast, ToastType } from '../services/entities/app-toast';

const DefaultHeader = 'Client Business Projection';

export const ToastTemplates: Record<string, AppToast> = {
  UnexpectedError: new AppToast({
    body: ['An unexpected error has ocurred. Please, try again later.'],
    header: DefaultHeader,
    type: ToastType.error,
    delay: 0,
  }),
  NoAccess: new AppToast({
    body: [
      'You do not have the authorization to view Client Business Projection.',
    ],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 0,
  }),
  FxAdjustedCurrency: new AppToast({
    body: [
      '• Fx Adjusted does not apply to Delivered CCI.',
      '• Fx Adjusted is not currently available at detailed level (By Industry, By Reason Code,Contracts, Opportunities or Speculative records)',
      '• Fx Adjusted does not apply to Monthly Projection.',
    ],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 0,
  }),
  ConstantCurrency: new AppToast({
    body: [
      '• CCI% is calculated based on Revenue and CCI in GLOBAL Currency.',
      '• Constant does not apply to Monthly Projection.',
    ],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 0,
  }),
  FilterDefaultsSaved: new AppToast({
    body: ['User default view has been saved successfully.'],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 3000,
  }),
  ProjectionPercentageSaved: new AppToast({
    body: ['Opportunities have been saved successfully.'],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 3000,
  }),
  MSACvfUpdated: new AppToast({
    body: ['MSA CVF status has been saved successfully.'],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 3000,
  }),
  stateDataItemUpdated: new AppToast({
    body: ['State of data has been updated'],
    header: DefaultHeader,
    type: ToastType.none,
    delay: 3000,
  }),
};
