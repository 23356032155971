import { assign } from 'lodash';

class WmuSchema {
  PID: number;
  ID: number;
  UN: string;
  status: string;
  type: number;
  hc: boolean;
}

export class Wmu extends WmuSchema {
  constructor(filterAttributes?: WmuSchema) {
    super();
    assign(this, filterAttributes);
  }
}
