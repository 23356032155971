import { assign } from 'lodash';

import { ValueType } from 'src/app/shared/constants/common.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';

class TileItemSchema {
  title: string;
  value: number;
  type?: ValueType = ValueType.Numeric;
  negativeStyleEnabled = true;
  positiveStyleEnabled = false;
  link?: string;
  linkParams?: Record<string, any>;
}

export class TileItem extends TileItemSchema {
  constructor(filterAttributes?: TileItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  isPositive(): boolean {
    return this.value >= 0;
  }

  isNegative(): boolean {
    return this.value < 0;
  }

  getValue(nullSymbol?: string): string {
    const numberPipe = new CustomNumberPipe();
    return numberPipe.transform(this.value, this.type, nullSymbol);
  }
}
