import {
  AfterViewInit,
  Component,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-numeric-editor',
  templateUrl: './numeric-editor.component.html',
  styleUrls: ['./numeric-editor.component.scss'],
})
export class NumericEditorComponent
  implements ICellEditorAngularComp, AfterViewInit
{
  value: number;
  params: any;
  cancelBeforeStart: boolean;

  @ViewChild('input', { read: ViewContainerRef }) public input;

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.input.element.nativeElement.focus();
    }, 0);
  }

  getValue(): any {
    const result = Number(this.value);
    return isNaN(result) ? null : result;
  }

  isKeyPressedNavigation(event) {
    return event.keyCode === 39 || event.keyCode === 37;
  }

  onKeyDown(event: any): void {
    if (this.isKeyPressedNavigation(event)) {
      event.stopPropagation();
    }
  }

  onSelect(event: any) {
    this.input.element.nativeElement.select();
  }
}
