import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Endpoints } from '../constants/endpoints.constants';
import {
  CleanUpItem,
  CleanUpRequest,
  CleanUpResponse,
} from './entities/clean-up';
import { Customer } from './entities/filters/customer';
import { MmbDate } from './entities/filters/date';

@Injectable({
  providedIn: 'root',
})
export class CleanUpNotificatorService {
  constructor(private http: HttpClient) {}

  getNotification(customerNumber: string): Promise<CleanUpItem> {
    return this.http
      .post(Endpoints.cleanUpNotificator, { customerNumber })
      .pipe(
        switchMap((x: CleanUpResponse) => {
          return of(x.data);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  createNotification(
    elapsedMonth: MmbDate,
    customer: Customer
  ): Promise<boolean> {
    const body: CleanUpRequest = this.mapCleanUptoRequest(elapsedMonth);
    body.customer = customer.MasterCustomerNumber;
    return this.http
      .post<CleanUpResponse>(Endpoints.cleanUpNotificator, body)
      .pipe(
        switchMap((x: CleanUpResponse) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  updateNotification(
    msgToUpdate: CleanUpItem,
    elapsedMonth: MmbDate,
    customer: Customer
  ): Promise<boolean> {
    const body: CleanUpRequest = this.mapCleanUptoRequest(
      elapsedMonth,
      msgToUpdate
    );
    body.customer = customer.MasterCustomerNumber;
    return this.http
      .put<CleanUpResponse>(Endpoints.cleanUpNotificator, body)
      .pipe(
        switchMap((x: CleanUpResponse) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private mapCleanUptoRequest(
    elapsedMonth: MmbDate,
    msgToUpdate?: CleanUpItem
  ): CleanUpRequest {
    const contract: CleanUpRequest = {};

    if (msgToUpdate) {
      contract.msgId = msgToUpdate.msgId;
    }
    if (elapsedMonth) {
      contract.elapsedDate = elapsedMonth;
    }

    return contract;
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (CleanUpNotificatorService)');
  }
}
