import { assign } from 'lodash';

import { FilterDefaults } from './filter-defaults';

class FilterDefaultsResponseSchema {
  data: Array<FilterDefaults>;
}

export class FilterDefaultsResponse extends FilterDefaultsResponseSchema {
  constructor(filterAttributes?: FilterDefaultsResponseSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class FilterDefaultsSaveResponseSchema {
  isSucessfull: boolean;
  filterDefaults: FilterDefaults;
}

export class FilterDefaultsSaveResponse extends FilterDefaultsSaveResponseSchema {
  constructor(filterAttributes?: FilterDefaultsSaveResponseSchema) {
    super();
    assign(this, filterAttributes);
  }
}
