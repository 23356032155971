import { assign } from 'lodash';

import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { TrendFilters } from './trend-filters';

class TrendFiltersChangedSchema {
  trendFilters: TrendFilters;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
}

export class TrendFiltersChanged extends TrendFiltersChangedSchema {
  constructor(filterAttributes?: TrendFiltersChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
