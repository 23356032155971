import { assign, every } from 'lodash';
import { Periods } from 'src/app/shared/constants/filters.constants';

class ActualSalesResponseItemSchema {
  Id: number;
  Sales: number;
  FiscalYearNbr?: string;
}

export class ActualSalesResponseItem extends ActualSalesResponseItemSchema {
  constructor(filterAttributes?: ActualSalesResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class ActualSalesResponseSchema {
  ContractId: string;
  ContractName: string;
  ContractLink?: string;
  QuarterlyData: Array<ActualSalesResponseItem>;
  MonthlyData: Array<ActualSalesResponseItem>;
  ActualsSales: number;
}

export class ActualSalesResponse extends ActualSalesResponseSchema {
  constructor(filterAttributes?: ActualSalesResponseSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(period: string): boolean {
    const attrName: string =
      period === Periods.Month.id ? 'MonthlyData' : 'QuarterlyData';

    const hasAllDataZero: boolean = every(
      (this as any)[attrName],
      (x: ActualSalesResponseItem) => {
        return x.Sales === 0 || !x.Sales;
      }
    );
    return (this.ActualsSales === 0 || !this.ActualsSales) && hasAllDataZero;
  }
}
