import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { interval, Observable, of, throwError } from 'rxjs';
import {
  catchError,
  concatMap,
  first,
  switchMap,
  timeout,
} from 'rxjs/operators';
import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
  GridToolbarSwitch,
} from '../../components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Endpoints } from '../../constants/endpoints.constants';
import {
  FileExporterFileNames,
  FileExporterStatus,
  FileExporterType,
} from '../../constants/file-exporter.constants';
import { FileExportRequestPayload } from '../entities/file-export-request-payload';
import { SelectedFilters } from '../entities/filters/selected-filters';
import { SpeculativeResponseItem } from '../entities/grids/speculative-response';
import { RequestPayload } from '../entities/request-payload';
import { ComponentNames } from 'src/app/modules/ieopportunity/actualsales/components/actualsales-grid/constants/actualsales-grid.constants';
import { FileExporterService } from '../file-exporter.service';
import { Customer } from '../entities/filters/customer';
@Injectable({
  providedIn: 'root',
})
export class SpeculativeGridService {
  constructor(
    private http: HttpClient,
    private fileExporterService: FileExporterService
  ) {}
  cloudURL = '?isCloud=';
  getSpeculatives(
    request: RequestPayload,
    isCloud: boolean
  ): Promise<Array<SpeculativeResponseItem>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);
    return this.http
      .post<Array<SpeculativeResponseItem>>(
        Endpoints.speculativeList + this.cloudURL + isCloud,
        request,
        options
      )
      .pipe(
        switchMap((x: Array<SpeculativeResponseItem>) => {
          return of(
            x.map(
              (y: SpeculativeResponseItem) => new SpeculativeResponseItem(y)
            )
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }
  downloadTemplate(customer: Customer, isCloud: boolean): Promise<boolean> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);
    return this.fileExporterService.export(
      FileExporterType.speculativeTemplate,
      {
        customer: {
          id: customer.MasterCustomerNumber,
          value: customer.MasterCustomerName,
        },
      },
      `${customer.MasterCustomerName} (Template).xlsx`,
      isCloud
    );
  }
  getS3PresignedUrl(payload: any): Promise<any> {
    const endpoint: string = Endpoints.speculativeS3PresignedUrl;
    return this.http.post<any>(endpoint, payload).toPromise();
  }
  uploadPayloadToS3(
    s3PresignedUrl: string,
    payload: any,
    contentType: string
  ): Promise<any> {
    const blob: Blob = new Blob([JSON.stringify(payload)], {
      type: contentType,
    });
    return this.http.put<any>(s3PresignedUrl, blob).toPromise();
  }
  uploadFile(payload: any, isCloud: boolean): Promise<any> {
    const timeInterval = 5000;
    const timeoutDuration: number = 5 * 60000;
    return this.http
      .post<{ id: string }>(
        Endpoints.speculativeUploadFile + this.cloudURL + isCloud,
        payload
      )
      .pipe(
        switchMap((uploadFileStatus) => {
          return interval(timeInterval).pipe(
            concatMap(() =>
              this.http.get<any>(
                `${Endpoints.speculativeUploadFileStatus}${uploadFileStatus.id}`
              )
            ),
            first((info) => {
              switch (info.status) {
                case FileExporterStatus.ERROR:
                  throw info;
                case FileExporterStatus.DONE:
                  return true;
                default:
                  return false;
              }
            })
          );
        }),
        timeout(timeoutDuration)
      )
      .toPromise();
  }
  export(
    selectedFilters: SelectedFilters,
    toolbarConfig: GridToolbarConfig,
    breakdowns?: Record<string, boolean>,
    isCloud?: boolean
  ): Promise<boolean> {
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );
    const noActivitySwitch: GridToolbarSwitch = toolbarConfig.findControl(
      ComponentNames.noActivity
    );
    const extraParams: Record<string, any> = {
      breakdownSG: breakdowns.isSgBreakdown,
      breakdownWMU: breakdowns.isWmuBreakdown,
      period: '',
      target: targetsDropdown.selected.value,
      noActivity: noActivitySwitch.switchValue, // Exclude No Activity
      stage: 'speculative',
    };
    const payload: FileExportRequestPayload =
      FileExportRequestPayload.createRequest(selectedFilters, extraParams);
    const fileName: string =
      breakdowns.isSgBreakdown && breakdowns.isWmuBreakdown
        ? FileExporterFileNames.SpeculativeSgWmu
        : breakdowns.isSgBreakdown
        ? FileExporterFileNames.SpeculativeSg
        : breakdowns.isWmuBreakdown
        ? FileExporterFileNames.SpeculativeWmu
        : FileExporterFileNames.Speculative;
    return this.fileExporterService.export(
      FileExporterType.speculative,
      payload,
      fileName,
      isCloud
    );
  }

  deleteSpeculatives(
    speculatives: Array<SpeculativeResponseItem>,
    selectedFilters: SelectedFilters,
    isCloud: boolean,
    request: RequestPayload
  ): Promise<void> {
    const endPoint: string = Endpoints.speculativeDelete;
    const body: Record<string, any> = {
      Data: speculatives.map((x: SpeculativeResponseItem) => {
        return { id: x.id, name: x.name, reasonCode: x.reasonCode };
      }),
      MasterClientId: selectedFilters.customer.MasterCustomerNumber,
      MasterClientName: selectedFilters.customer.MasterCustomerName,
      View: selectedFilters.planView,
      Payload: request.Payload,
    };
    return this.http
      .post<any>(endPoint + this.cloudURL + isCloud, body)
      .toPromise();
  }
  updateStatus(
    speculatives: Array<SpeculativeResponseItem>,
    status: string,
    selectedFilters: SelectedFilters,
    isCloud: boolean,
    request: RequestPayload
  ): Promise<Array<string>> {
    const body: Record<string, any> = {
      Data: speculatives.map((x: SpeculativeResponseItem) => {
        return { id: x.id, name: x.name, reasonCode: x.reasonCode };
      }),
      MasterClientId: selectedFilters.customer.MasterCustomerNumber,
      MasterClientName: selectedFilters.customer.MasterCustomerName,
      View: selectedFilters.planView,
      Payload: request.Payload,
    };
    return this.http
      .post<any>(
        `${Endpoints.speculativeUpdate}/${status}${this.cloudURL}${isCloud}`,
        body
      )
      .toPromise();
  }
  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (SpeculativeGridService)');
  }
}
