import { Component, Input, ViewEncapsulation } from '@angular/core';

import { TabConfig } from './entities/tab-config';
import { TabsControlType } from './entities/tab.constants';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TabsComponent {
  @Input() config: TabConfig;

  public get controlType(): typeof TabsControlType {
    return TabsControlType;
  }
}
