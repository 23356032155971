import { EventEmitter, Injectable } from '@angular/core';

import { AppToast } from './entities/app-toast';

@Injectable({
  providedIn: 'root',
})
export class AppToastsService {
  toasts = new Array<AppToast>();
  toastListChanged = new EventEmitter<Array<AppToast>>();

  show(toast: AppToast): void {
    this.toasts.push(toast);
    this.toastListChanged.next(this.toasts);
  }

  remove(toast: any): void {
    this.toasts = this.toasts.filter((t) => t !== toast);
    this.toastListChanged.next(this.toasts);
  }
}
