import { BoardResponseItem } from 'src/app/shared/services/entities/board-response';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import { TimeframeItem } from '../../timeframe/entities/timeframe';
import { TimeframeUtils } from '../../timeframe/utils/timeframe.utils';
import {
  BoardSaveFiltersModel,
  BoardSaveModel,
} from '../entities/board-save-model';

export class BoardMapper {
  static mapContract(
    source: BoardResponseItem,
    filters: Filters
  ): BoardResponseItem {
    if (source.filters.Currency) {
      source.filters.Currency = filters.currencies.find(
        (x: Currency) => x.Id === source.filters.Currency.Id
      );
    }
    if (source.filters.Timeframe) {
      if (!source.filters.Timeframe.TimePeriodCode.includes('Custom')) {
        const timeframe: TimeframeItem = TimeframeUtils.getTimeframeItemByCode(
          filters,
          source.filters.Timeframe.TimePeriodCode
        );

        source.filters.Timeframe = new Timeframe({
          EndTimeId: timeframe.end,
          StartTimeId: timeframe.start,
          TimePeriodCode: timeframe.code,
          Name: timeframe.title,
          Description: timeframe.getDescription(),
          FiscalYear: timeframe.fiscalYear,
        });
      } else {
        const appDate: ActiveDate = TimeframeUtils.getAppDate(filters);
        const appDateTime: MmbDate = filters.dates.find(
          (x: MmbDate) => x.Id === appDate.StartTimeId
        );
        const timeframe = new TimeframeItem({
          start: Number(source.filters.Timeframe.TimePeriodCode.split('-')[1]),
          end: Number(source.filters.Timeframe.TimePeriodCode.split('-')[2]),
          code: 'Custom',
          mmbPeriod: 'Custom',
          title: 'Custom',
          type: 'Custom',
          fiscalYear: appDateTime.FiscalYearNbr,
          enabled: true,
          isPast:
            appDate.StartTimeId >
            Number(source.filters.Timeframe.TimePeriodCode.split('-')[2]),
        });
        source.filters.Timeframe = new Timeframe({
          EndTimeId: timeframe.end,
          StartTimeId: timeframe.start,
          TimePeriodCode: 'Custom',
          Name: timeframe.title,
          Description: timeframe.getDescription(),
          FiscalYear: timeframe.fiscalYear,
        });
      }
    }

    return source;
  }

  static getSaveModel(board: BoardResponseItem): BoardSaveModel {
    return new BoardSaveModel({
      boardId: board.boardId,
      default: board.default,
      tab: board.tab,
      filters: new BoardSaveFiltersModel({
        Currency: board.filters.Currency,
        Customer: board.filters.Customer,
        Timeframe: new Timeframe({
          EndTimeId: board.filters.Timeframe.EndTimeId,
          StartTimeId: board.filters.Timeframe.StartTimeId,
          TimePeriodCode:
            board.filters.Timeframe.TimePeriodCode === 'Custom'
              ? `Custom-${board.filters.Timeframe.StartTimeId}-${board.filters.Timeframe.EndTimeId}`
              : board.filters.Timeframe.TimePeriodCode,
          FiscalYear: board.filters.Timeframe.FiscalYear,
        }),
        Alliances: board.filters.Alliances,
        ClientServiceGroups: board.filters.ClientServiceGroups,
        Wmus: board.filters.Wmus,
        Sgi: board.filters.Sgi,
        ServiceGroups: board.filters.ServiceGroups,
        Locations: board.filters.Locations,
        Industries: board.filters.Industries,
        Functions: board.filters.Functions,
        FinancialCustomer: board.filters.FinancialCustomer,
      }),
      name: board.name,
      sharedBy: board.sharedBy,
    });
  }
}
