import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Headers } from 'src/app/core/constants/http.constants';
import { Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Endpoints } from '../../constants/endpoints.constants';
import { SelectedFilters } from '../entities/filters/selected-filters';
import { AnalyticsResponseItem } from '../entities/grids/analytics-response';
import {
  FileExporterFileNames,
  FileExporterType,
} from '../../constants/file-exporter.constants';
import { AnalyticsRequestPayload } from '../entities/analytics-request-payload';
import { AnalyticsFileExportRequestPayload } from '../entities/analytics-file-export-request-payload';
import { FavoriteFilterDefaults } from 'src/app/modules/analytics/components/favorite-modal/entities/favorite-filter-defaults';
import { CustomerFilters } from '../entities/filters/customer-filters';
import { GroupsBy } from '../../constants/filters.constants';
import { Wmu } from '../entities/filters/wmu';

import { FileExporterService } from '../file-exporter.service';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsGridService {
  constructor(
    private http: HttpClient,
    private fileExporterService: FileExporterService
  ) {}

  getAnalytics(
    payload: AnalyticsRequestPayload,
    isCloud: boolean
  ): Promise<Array<AnalyticsResponseItem>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Array<AnalyticsResponseItem>>(
        Endpoints.analytics + '?isCloud=' + isCloud,
        payload,
        options
      )
      .pipe(
        switchMap((x: Array<AnalyticsResponseItem>) => {
          return of(
            x.map((y: AnalyticsResponseItem) => new AnalyticsResponseItem(y))
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  export(
    selectedFilters: SelectedFilters,
    customerFilters: CustomerFilters,
    favoriteFilterDefaults: FavoriteFilterDefaults
  ): Promise<boolean> {
    const extraParams: Record<string, any> = {
      sapCode: '-1',
      IFSlabel: '',
    };

    const serviceAttributesExtraParams: Array<Record<string, any>> = [];

    const groupsBy: Array<string> = [
      favoriteFilterDefaults.group1,
      favoriteFilterDefaults.group2,
      favoriteFilterDefaults.group3,
    ];

    if (
      selectedFilters.wmus?.length === 0 &&
      groupsBy.includes(GroupsBy.WMU.id)
    ) {
      const root: Wmu = customerFilters.wmu.find(
        (x: Wmu) => x.PID === null || x.PID === 0
      );

      if (root) {
        serviceAttributesExtraParams.push({
          Name: 'RolledUpWmuList',
          Value: customerFilters.wmu
            .filter((x: Wmu) => x.PID === root.ID)
            .map((x: Wmu) => x.ID)
            .join(','),
        });
      }
    }

    const payload: AnalyticsFileExportRequestPayload =
      AnalyticsFileExportRequestPayload.createRequest(
        selectedFilters,
        favoriteFilterDefaults,
        extraParams,
        serviceAttributesExtraParams
      );

    const fileName: string = FileExporterFileNames.Analytics;

    return this.fileExporterService.export(
      FileExporterType.analytics,
      payload,
      fileName
    );
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (AnalyticsGridService)');
  }
}
