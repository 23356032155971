import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { GrowthPriority } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-growth-priorities',
  templateUrl: './growth-priorities.component.html',
  styleUrls: ['./growth-priorities.component.scss'],
})
export class GrowthPrioritiesComponent implements OnChanges {
  @Input() growthPriorities: Array<GrowthPriority> = [];
  @Input() selectedGrowthPriorities: Array<GrowthPriority> = [];
  @Input() searchText: string;
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  @Output() searchEvent = new EventEmitter<string>();

  gpTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    this.growthPriorities.forEach((x: GrowthPriority) => {
      x.ProductId === 9952 ? (x.ProductId = 9954) : x.ProductId;
    });
    if (changes.growthPriorities && changes.growthPriorities.currentValue) {
      this.gpTreeviewConfig = new TreeViewConfig({
        parentAttr: 'ParentProductId',
        textAttr: 'ProductName',
        valueAttr: 'ProductId',
        countByNodes: true,
        selectByNodes: true,
        items: TreeViewUtils.getItems<GrowthPriority>(
          this.growthPriorities,
          'ProductId',
          'ProductName',
          'ParentProductId',
          (item: TreeViewItem, gp: GrowthPriority) => {
            item.parameters.add('ProductType', gp.ProductType);
          }
        ),
      });
    }
    this.gpTreeviewConfig.items.forEach((x: TreeViewItem) => {
      if (x && x.text === 'Product & Platform Engineering') {
        x.text = 'Client Platforms & Products';
      }
      if (x && x.text === 'Talent & Organization') {
        x.text = 'Talent & Change Management';
      }
      if (x && x.text === 'Supply Chain & Operations') {
        x.text = 'Supply Chain';
      }
      if (x && x.text === 'Enterprise & Industry Technologies') {
        x.children.forEach((y: TreeViewItem) => {
          y.text = y.text.replace('E&I - ', '');
        });
      }
    });

    if (
      changes.selectedGrowthPriorities &&
      changes.selectedGrowthPriorities.currentValue
    ) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedGrowthPriorities.map(
          (x: GrowthPriority) =>
            new TreeViewItem({
              value: x.ProductId,
              text: x.ProductName,
            })
        ),
      });
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchText = searchText;
    this.searchEvent.emit(this.searchText);
  }

  onItemSelection(selectedGPs: TreeViewSelection): void {
    this.disableItemsByProductType(selectedGPs);

    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.GrowthPriority,
        keyValues: selectedGPs.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedGPs.count,
        blockedFilters: [FilterType.Alliance, FilterType.Function],
      })
    );
  }

  disableItemsByProductType(selectedGPs: TreeViewSelection): void {
    let selectedItem: TreeViewItem;

    if (selectedGPs.selectedItems && selectedGPs.selectedItems.length > 0) {
      selectedItem = TreeViewUtils.searchItem<TreeViewItem>(
        this.gpTreeviewConfig.items,
        selectedGPs.selectedItems[0].value
      );
    }

    TreeViewUtils.iterateItems(
      this.gpTreeviewConfig.items,
      (item: TreeViewItem) => {
        item.disabled =
          selectedItem &&
          item.parameters.getItem('ProductType') !==
            selectedItem.parameters.getItem('ProductType') &&
          selectedItem.checked;
      }
    );
  }
}
