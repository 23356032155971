import { assign, cloneDeep } from 'lodash';

import { ValueType } from 'src/app/shared/constants/common.constants';
import {
  GroupsBy,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { ViewOptions } from 'src/app/shared/constants/grid.constants';
import { TrendFilters } from '../trend-tile/components/trend-filters/entities/trend-filters';
import { TrendTileDefinition } from './trend-tile-definition';

class TrendTableSchema {
  periods: Array<string>;
  sets: Array<TrendTableSet>;
}

export class TrendTable extends TrendTableSchema {
  constructor(filterAttributes?: TrendTableSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TrendTableSetSchema {
  title: string;
  records: Array<TrendTableRecord>;
  totalsByPeriod: Array<number>;
  total: number;
  valueType?: ValueType = ValueType.Numeric;
  valueSymbol?: string;
}

export class TrendTableSet extends TrendTableSetSchema {
  constructor(filterAttributes?: TrendTableSetSchema) {
    super();
    assign(this, filterAttributes);
  }

  getVariance(
    target: TrendTableSet,
    definition: TrendTileDefinition,
    trendFilters: TrendFilters
  ): TrendTableSet {
    const variance = new TrendTableSet();
    const isPlan: boolean = trendFilters.subMetric.value === SubMetrics.Plan;
    const isComposition: boolean =
      trendFilters.groupBy.value === GroupsBy.Composition.id;
    const isNulleable: boolean = isPlan && isComposition;

    variance.title = 'Variance';
    variance.valueType = trendFilters.isPercentage
      ? ValueType.BPS_Rounded
      : ValueType.Numeric;
    variance.total = this.total - target.total;
    variance.valueSymbol = trendFilters.isPercentage ? 'bps' : '';
    variance.totalsByPeriod = this.totalsByPeriod.map(
      (x: number, i: number) => {
        if (x && !target.totalsByPeriod[i]) {
          target.totalsByPeriod[i] = 0;
        } else if (!x && target.totalsByPeriod[i]) {
          this.totalsByPeriod[i] = 0;
        }

        return !x && !target.totalsByPeriod[i]
          ? null
          : (x || 0) - (target.totalsByPeriod[i] || 0);
      }
    );

    variance.records = this.records.map((x: TrendTableRecord, i: number) => {
      const record: TrendTableRecord = cloneDeep(x);
      record.total = isNulleable ? null : x.total - target.records[i].total;
      record.valuesByPeriod = x.valuesByPeriod.map((y: number, j: number) => {
        const targetRecordValue: number = target.records[i].valuesByPeriod[j];

        if (record.link && isPlan) {
          record.link = null;
          record.linkParams = null;
        } else if (record.linkParams?.target) {
          record.linkParams.target = ViewOptions.CompareProjection;
        }

        if (y && !targetRecordValue) {
          target.records[i].valuesByPeriod[j] = isNulleable ? null : 0;
        } else if (!y && targetRecordValue) {
          this.records[i].valuesByPeriod[j] = 0;
        }

        return (!y && !targetRecordValue) || isNulleable
          ? null
          : (y || 0) - (targetRecordValue || 0);
      });
      return record;
    });

    return variance;
  }
}

class TrendTableRecordSchema {
  title: string;
  valuesByPeriod: Array<number>;
  total: number;
  link?: string;
  linkParams?: Record<string, any>;
}

export class TrendTableRecord extends TrendTableRecordSchema {
  constructor(filterAttributes?: TrendTableRecordSchema) {
    super();
    assign(this, filterAttributes);
  }
}
