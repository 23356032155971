import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SecurityService } from '../../services/security.service';

@Component({
  selector: 'app-admin-indicator',
  templateUrl: './admin-indicator.component.html',
  styleUrls: ['./admin-indicator.component.scss'],
})
export class AdminIndicatorComponent implements OnInit {
  subscription = new Subscription();
  isAdmin: boolean;

  constructor(private securityService: SecurityService) {}

  ngOnInit(): void {
    this.checkAdminStatus();
  }

  checkAdminStatus(): void {
    this.securityService.isAdmin().then((x: boolean) => {
      this.isAdmin = x;
    });
  }
}
