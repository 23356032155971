import { Component } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  emailTemplate: string;
  filtersToReplace = 'No Filters';
  public showFooter = true;
  public termsOfUseLink: string;
  public privacyStatementLink: string;
  public feedbackLink: string;

  constructor() {
    this.termsOfUseLink =
      'https://4309-mmb-prod.s3.amazonaws.com/documents/ClientBusinessProjectionTermsOfUse.pdf';
    this.privacyStatementLink =
      'https://4309-mmb-prod.s3.amazonaws.com/documents/ClientBusinessProjectionPrivacyStatement.pdf';
    this.feedbackLink =
      'https://in.accenture.com/managemybusiness/contact-mmb/';
  }

  toggleFooterPicker() {
    this.showFooter = this.showFooter == false;
  }
}
