import { assign, sortBy } from 'lodash';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { FilterSelectionKeyValue } from './filter-selection-key-value';

class FilterSelectionSchema {
  type: FilterType;
  keyValues: Array<FilterSelectionKeyValue>;
  count?: number = 0;
  blockedFilters?: Array<FilterType>;
}

export class FilterSelection extends FilterSelectionSchema {
  constructor(filterAttributes?: FilterSelectionSchema) {
    super();
    assign(this, filterAttributes);
  }

  hasChanged(items: Array<any>, key: string): boolean {
    const selectedValues: Array<any> = items
      ? sortBy(items.map((x: any) => x[key]))
      : [];

    const oldValues: string = sortBy(selectedValues).join(',');
    const newValues: string = sortBy(
      this.keyValues.map((x: FilterSelectionKeyValue) => x.key)
    ).join(',');
    return oldValues !== newValues;
  }

  getKeys(): Array<any> {
    return this.keyValues
      ? this.keyValues.map((x: FilterSelectionKeyValue) => x.key)
      : [];
  }
}
