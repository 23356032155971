import { assign } from 'lodash';

import { TimeframeItem } from './timeframe';

class TimeframeChangedSchema {
  forceReload: boolean;
  timeframe: TimeframeItem;
  invalidStorage?: boolean;
}

export class TimeframeChanged extends TimeframeChangedSchema {
  constructor(filterAttributes?: TimeframeChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}
