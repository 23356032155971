<div class="modal-container">
    <div class="modal-header">
        <h4 class="modal-title">
            <fa-icon *ngIf="message?.icon" [icon]="message?.icon"></fa-icon>
            <span>{{message?.header}}</span>
        </h4>
        <button *ngIf="message.close" type="button" class="close" aria-label="Close" (click)="close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p *ngFor="let b of message.body" [innerHtml]="b | htmlSanitizer">
        </p>
    </div>
    <div *ngIf="message.buttons?.length > 0" class="modal-footer">
        <button *ngFor="let b of message.buttons" type="button" [ngClass]="getButtonCss(b)"
            (click)="b.action ? b.action() : close()">{{b.text}}</button>
    </div>
</div>