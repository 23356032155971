import { MetricType } from 'src/app/shared/constants/metrics.constants';
import { TargetsTileDefinition } from '../../entities/targets-tile-definition';

export const TargetsTileConstants: Record<string, TargetsTileDefinition> = {
  Sales: new TargetsTileDefinition({
    title: {
      actuals: 'Sales Actuals vs Targets',
      projection: 'Sales Projection vs Targets',
    },
    type: MetricType.Sales,
  }),
  Revenue: new TargetsTileDefinition({
    title: {
      actuals: 'Revenue Actuals vs Targets',
      projection: 'Revenue Projection vs Targets',
    },
    type: MetricType.Revenue,
  }),
  Cci: new TargetsTileDefinition({
    title: {
      actuals: 'Delivered CCI Actuals vs Targets',
      projection: 'Delivered CCI Projection vs Targets',
    },
    type: MetricType.Cci,
    allowPercentage: true,
    isPercentage: true,
  }),
};

export const TargetsTileErrorMessages = {
  Service504:
    "We couldn't retrieve the data in time. Try refreshing your browser to fix the issue.",
  ServiceDefault:
    'There was an issue during the process. Please, try again later.',
};
