import { assign } from 'lodash';

import { DropDownType } from 'src/app/shared/constants/filters.constants';
import { TabsControlType } from './tab.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';

export interface ITab {
  Name: string;
  SubTabName?: string;
  Controls?: Array<any>;
}

// Config

class TabConfigSchema {
  name: string;
  subTabName?: string;
  controls?: Array<any>;
}

export class TabConfig extends TabConfigSchema {
  constructor(filterAttributes?: TabConfigSchema) {
    super();
    assign(this, filterAttributes);
  }

  findControl<T extends ITabControl>(controlName: string): T {
    return this.controls?.find((x: T) => x.controlName === controlName);
  }
}

// Tab Control Interface

export interface ITabControl {
  get controlType(): TabsControlType;
  controlName: string;
}

// Dropdown

class TabDropdownSchema {
  controlName: string;
  type?: DropDownType = DropDownType.Button;
  selected: TextValuePair;
  isVisible?: (x: TabConfig) => boolean;
  isDisabled?: (x: TabConfig) => boolean;
  items: Array<TextValuePair>;
  onChange: (x: TextValuePair, y: TabDropdown) => void;
}

export class TabDropdown extends TabDropdownSchema implements ITabControl {
  get controlType(): TabsControlType {
    return TabsControlType.Dropdown;
  }

  constructor(filterAttributes?: TabDropdownSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TabMultipleDropdownSchema {
  controlName: string;
  placeholder?: string;
  type?: DropDownType = DropDownType.Button;
  selected: Array<TextValuePair>;
  isVisible?: (x: TabConfig) => boolean;
  isDisabled?: (x: TabConfig) => boolean;
  items: Array<TextValuePair>;
  onChange: (x: Array<TextValuePair>, y: TabMultipleDropdown) => void;
}

export class TabMultipleDropdown
  extends TabMultipleDropdownSchema
  implements ITabControl
{
  get controlType(): TabsControlType {
    return TabsControlType.MultipleDropdown;
  }

  constructor(filterAttributes?: TabMultipleDropdownSchema) {
    super();
    assign(this, filterAttributes);
  }
}

// Switch

class TabSwitchSchema {
  controlName: string;
  text: string;
  switchValue?: boolean = false;
  isVisible?: (x: TabConfig) => boolean;
  isDisabled?: (x: TabConfig) => boolean;
  onSwitch: (x: boolean, y: TabSwitch) => void;
}

export class TabSwitch extends TabSwitchSchema implements ITabControl {
  get controlType(): TabsControlType {
    return TabsControlType.Switch;
  }

  constructor(filterAttributes?: TabSwitchSchema) {
    super();
    assign(this, filterAttributes);
  }
}
