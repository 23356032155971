import { assign } from 'lodash';

class CustomerSchema {
  MasterCustomerNumber: string;
  MasterCustomerName: string;
  IsFullAccess: boolean;
}

export class Customer extends CustomerSchema {
  constructor(filterAttributes?: CustomerSchema) {
    super();
    assign(this, filterAttributes);
  }
}
