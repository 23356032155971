import { assign } from 'lodash';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';

import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ReasonCode } from 'src/app/shared/services/entities/reason-code';

class SpeculativeModalConfigSchema {
  speculativeId: string;
  dataSet: string;
  isReadOnly: boolean;
  isAdmin: boolean;
  isDuplicate?: boolean;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  reasonCodes: Array<ReasonCode>;
}

export class SpeculativeModalConfig extends SpeculativeModalConfigSchema {
  constructor(filterAttributes?: SpeculativeModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }

  isNew(): boolean {
    return !this.speculativeId || this.speculativeId === '';
  }
}
