import { assign } from 'lodash';

import { SelectedFilters } from './filters/selected-filters';
import { ProductHierarchy } from './filters/product-hierarchy';
import { ServiceGroupValues } from './filters/service-group';
import { Wmu } from './filters/wmu';
import { ClientGroup } from './filters/client-group';
import { Industry } from './filters/industry';
import { FinancialCustomer } from './filters/financial-customer';
import { Location } from './filters/location';

class MmbRequestPayloadSchema {
  ServiceAttributes: Array<Record<string, any>>;
  Timeframe: Record<string, any>;
}

export class MmbRequestPayload extends MmbRequestPayloadSchema {
  constructor(filterAttributes?: MmbRequestPayloadSchema) {
    super();
    assign(this, filterAttributes);
  }

  static createRequest(
    selectedFilters: SelectedFilters,
    extraParams?: Record<string, any>,
    serviceAttributesExtraParams?: Array<Record<string, any>>
  ): MmbRequestPayloadSchema {
    let request = new MmbRequestPayload({
      ServiceAttributes: [
        {
          Name: 'CustomerNumber',
          Value: selectedFilters.customer.MasterCustomerNumber,
        },
        {
          Name: 'PortfolioId',
          Value: '',
        },
        {
          Name: 'ServiceGroupList',
          Value: this.getServiceGroups(selectedFilters),
        },
        {
          Name: 'OrganizationList',
          Value: this.getMarkets(selectedFilters),
        },
        {
          Name: 'TargetCurrency',
          Value: selectedFilters.getTargetCurrency(),
        },
        {
          Name: 'CurrencyType',
          Value: selectedFilters.getCurrencyType(),
        },
        {
          Name: 'RolledUpWmuList',
          Value: this.getWmus(selectedFilters),
        },
        {
          Name: 'ClientServiceGroupList',
          Value: this.getClientGroups(selectedFilters),
        },
        {
          Name: 'IndustryFilter',
          Value: this.getIndustries(selectedFilters),
        },
        {
          Name: 'FinancialCustomer',
          Value: this.getFinancialCustomers(selectedFilters),
        },
        {
          Name: 'IsCumulative',
          Value: '',
        },
        {
          Name: 'AttributesList',
          Value: this.getAttributes(selectedFilters),
        },
        {
          Name: 'View',
          Value: '',
        },
        {
          Name: 'GroupBy',
          Value: '',
        },
        {
          Name: 'DataSet',
          Value: '',
        },
        {
          Name: 'SapCode',
          Value: '',
        },
      ],
      Timeframe: {
        StartTimeId: selectedFilters.timeframe.start,
        EndTimeId: selectedFilters.timeframe.end,
        TimePeriodCode: selectedFilters.timeframe.mmbPeriod,
      },
    });

    if (serviceAttributesExtraParams) {
      serviceAttributesExtraParams.forEach((x: Record<string, any>) => {
        const attr: Record<string, any> = request.ServiceAttributes.find(
          (y: Record<string, any>) => y.Name === x.Name
        );

        if (attr) {
          attr.Value = x.Value;
        } else {
          request.ServiceAttributes.push(x);
        }
      });
    }

    if (extraParams) {
      request = Object.assign({}, request, extraParams);
    }

    return request;
  }

  private static getAttributes(selectedFilters: SelectedFilters): string {
    const attributes: Array<ProductHierarchy> = [
      ...(selectedFilters.alliances ? selectedFilters.alliances : []),
      ...(selectedFilters.functions ? selectedFilters.functions : []),
      ...(selectedFilters.growthPriorities
        ? selectedFilters.growthPriorities
        : []),
    ];

    return (
      attributes.map((x: ProductHierarchy) => x.ProductCode).join(',') || ''
    );
  }

  private static getServiceGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.serviceGroups
        ?.map((x: ServiceGroupValues) => x.ServiceGroupCd)
        .join(',') || ''
    );
  }

  private static getWmus(selectedFilters: SelectedFilters): string {
    return selectedFilters.wmus?.map((x: Wmu) => x.ID).join(',') || '';
  }

  private static getClientGroups(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.clientGroups
        ?.map((x: ClientGroup) => x.ClientGroupCd)
        .join(',') || ''
    );
  }

  private static getIndustries(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.industries
        ?.map((x: Industry) => x.EntityCode)
        .join(',') || ''
    );
  }

  private static getFinancialCustomers(
    selectedFilters: SelectedFilters
  ): string {
    return (
      selectedFilters.financialCustomers
        ?.map((x: FinancialCustomer) => x.CustomerNumber)
        .join(',') || ''
    );
  }

  private static getMarkets(selectedFilters: SelectedFilters): string {
    return (
      selectedFilters.markets
        ?.map((x: Location) => x.OrganizationId)
        .join(',') || ''
    );
  }
}
