import { cloneDeep, sumBy } from 'lodash';
import {
  MetricsOptions,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { ColDef, CsvExportParams, GridOptions } from 'ag-grid';
import {
  GridToolbarConfig,
  GridToolbarDropdown,
} from 'src/app/shared/components/base/grid/components/grid-toolbar/entities/grid-toolbar-config';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SortColum } from 'src/app/shared/components/base/grid/entities/grid-config';
import { Periods } from 'src/app/shared/constants/filters.constants';
import { GridCellIcon } from 'src/app/shared/components/base/grid/entities/grid-cell-icon';
import { TimeframeItem } from 'src/app/shared/components/timeframe/entities/timeframe';
import { GridUtils } from 'src/app/shared/components/base/grid/utils/grid.utils';
import { SpeculativeResponseItem } from 'src/app/shared/services/entities/grids/speculative-response';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';

export const ComponentNames = {
  opportunities: 'speculatives',
  metrics: 'metricName',
  periods: 'period',
  targets: 'target',
  export: 'export',
  expand: 'expand',
  compress: 'compress',
  add: 'add',
  hold: 'hold',
  delete: 'delete',
  downloadTemplate: 'downloadTemplate',
  uploadTemplate: 'uploadTemplate',
  noActivity: 'noActivity',
};

export const GridFilters = {
  Metrics: [
    new TextValuePair({
      text: MetricsOptions.Summary,
      value: MetricsOptions.Summary,
    }),
    new TextValuePair({
      text: MetricsOptions.Sales,
      value: MetricsOptions.Sales,
    }),
    new TextValuePair({
      text: MetricsOptions.Revenue,
      value: MetricsOptions.Revenue,
    }),
    new TextValuePair({
      text: MetricsOptions.Cci,
      value: MetricsOptions.Cci,
    }),
    new TextValuePair({
      text: MetricsOptions.CciPercentage,
      value: MetricsOptions.CciPercentage,
    }),
  ],
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};

export const GridColDefs = {
  getColDefs: (
    toolbarConfig: GridToolbarConfig,
    filters: Filters,
    selectedFilters: SelectedFilters,
    icons?: Array<GridCellIcon>
  ): Array<ColDef> => {
    let columnDefs: Array<ColDef> = [];

    const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.metrics
    );
    const periodsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.periods
    );
    const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
      ComponentNames.targets
    );

    const isCustomTimeframe: boolean =
      selectedFilters.timeframe.title === 'Custom';

    const timeframe: TimeframeItem = cloneDeep(selectedFilters.timeframe);
    // timeframe.end = customTimeframe ? timeframe.end : timeframe.start + 11;

    if (metricsDropdown.selected.value !== MetricsOptions.Summary) {
      columnDefs = GridColDefs.Shared.getColDefsForMetrics(toolbarConfig);

      if (periodsDropdown.selected.value === Periods.Quarter.id) {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getQuarterlyHeaders(
              timeframe,
              filters.dates,
              isCustomTimeframe
            ),
            metricsDropdown.selected.value,
            targetsDropdown.selected.value
          )
        );
      } else {
        columnDefs = columnDefs.concat(
          GridColDefs.Shared.addCustomRendererConfigs(
            GridUtils.getMonthlyHeaders(timeframe, filters.dates),
            metricsDropdown.selected.value,
            targetsDropdown.selected.value
          )
        );
      }
    } else {
      columnDefs = GridColDefs.Shared.getColDefsForSummary(
        toolbarConfig,
        filters.dates
      );
    }

    return GridColDefs.Shared.getBaseGridColDefs(icons).concat(columnDefs);
  },
  Shared: {
    getBaseGridColDefs: (cellIcons?: Array<GridCellIcon>): Array<ColDef> => {
      return [
        {
          headerName: 'SPECULATIVE ID',
          colId: 'speculativeId',
          field: 'link',
          type: 'linkColumn',
          width: 175,
          minWidth: 175,
          pinned: true,
          sort: GridColDefs.Shared.paramSort('speculativeId'),
          valueGetter: (params: any) => {
            return params.data.id && params.data.name
              ? params.data.id
              : undefined;
          },
          cellRendererParams: (params: any) => {
            let icons: Array<GridCellIcon> = [];

            if (cellIcons) {
              icons = cellIcons.filter(
                (x: GridCellIcon) =>
                  !x.showIconByRow || x.showIconByRow(params.data)
              );
            }

            return {
              icons,
            };
          },
          pinnedRowCellRendererParams: { forceTotals: true },
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: true });
          },
        },
        {
          headerName: 'SPECULATIVE NAME',
          colId: 'speculativeName',
          field: 'link',
          type: 'linkColumn',
          width: 250,
          minWidth: 200,
          pinned: true,
          sort: GridColDefs.Shared.paramSort('speculativeName'),
          valueGetter: (params: any) => {
            return params.data.id && params.data.name
              ? params.data.name
              : undefined;
          },
          comparator: (valueA: string, valueB: string): number => {
            return valueA.localeCompare(valueB, 'en-US', { numeric: false });
          },
        },
        {
          headerName: 'REASON CODE',
          colId: 'reasoncode',
          field: 'reasonCode.Description',
          type: 'textColumn',
          minWidth: 180,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('reasoncode'),
        },
      ] as Array<ColDef>;
    },
    getColDefsForSummary: (
      toolbarConfig: GridToolbarConfig,
      dates: Array<MmbDate>
    ): Array<ColDef> => {
      const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.targets
      );

      return [
        {
          headerName: 'RESPONSIBLE',
          field: 'responsible',
          colId: 'responsible',
          type: 'textColumn',
          minWidth: 150,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('responsible'),
        },
        {
          headerName: 'STATUS',
          colId: 'status',
          field: 'status',
          type: 'textColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('status'),
          valueGetter: (params: any) => {
            return params.data.status === 'OnHold'
              ? 'On Hold'
              : params.data.status;
          },
        },
        {
          headerName: 'LAST UPDATED',
          field: 'lastUpdated',
          colId: 'lastUpdated',
          type: 'dateColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('lastUpdated'),
        },
        {
          headerName: 'START DATE',
          field: 'startDate',
          colId: 'startDate',
          type: 'dateColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('startDate'),
          valueGetter: (params: any) => {
            if (params.data.startDate) {
              return dates.find(
                (x: MmbDate) => x.Id.toString() === params.data.startDate
              ).DateTime;
            }

            return '-';
          },
        },
        {
          headerName: 'END DATE',
          field: 'endDate',
          colId: 'endDate',
          type: 'dateColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('endDate'),
          valueGetter: (params: any) => {
            if (params.data.endDate) {
              return dates.find(
                (x: MmbDate) => x.Id.toString() === params.data.endDate
              ).DateTime;
            }

            return '-';
          },
        },
        {
          headerName: 'SALES',
          field: 'sales',
          colId: 'sales',
          type: 'numberColumn',
          minWidth: 105,
          pinned: false,
          aggFunc: 'sum',
          sort: GridColDefs.Shared.paramSort('sales'),
        },
        {
          headerName: 'WON CCI%',
          field: 'wonCCIPercentage',
          colId: 'wonCCIPercentage',
          type: 'percentColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('wonCCIPercentage'),
          aggFunc:
            targetsDropdown.selected.value === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumWonCciPercentageCompare
              : GridColDefs.Shared.sumWonCciPercentage,
        },
        {
          headerName: 'REVENUE',
          field: 'netRevenue',
          colId: 'netRevenue',
          type: 'numberColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('netRevenue'),
          aggFunc: 'sum',
        },
        {
          headerName: 'DELIVERED CCI$',
          field: 'deliveredCCIDollar',
          colId: 'deliveredCCIDollar',
          type: 'numberColumn',
          minWidth: 105,
          pinned: false,
          sort: GridColDefs.Shared.paramSort('deliveredCCIDollar'),
          aggFunc: 'sum',
        },
        {
          headerName: 'DELIVERED CCI%',
          colId: 'deliveredCCIPercentage',
          field: 'deliveredCCIPercentage.cciPercent',
          type: 'percentColumn',
          sort: GridColDefs.Shared.paramSort('deliveredCCIPercentage'),
          minWidth: 105,
          pinned: false,
          aggFunc:
            targetsDropdown.selected.value === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumDeliveredCciPercentageCompare
              : GridColDefs.Shared.sumDeliveredCciPercentage,
        },
      ];
    },
    getColDefsForMetrics: (toolbarConfig: GridToolbarConfig): Array<ColDef> => {
      const metricsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.metrics
      );
      const targetsDropdown: GridToolbarDropdown = toolbarConfig.findControl(
        ComponentNames.targets
      );

      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(metricsDropdown.selected.value);

      return [
        {
          headerName: `TOTAL ${metricsDropdown.selected.value.toUpperCase()}`,
          colId: 'fullLife',
          field: `fullLife.${attrs.attrName}`,
          type: attrs.columnType,
          minWidth: 105,
          pinned: false,
          menuTabs: ['filterMenuTab'],
          filter: '',
          sort: GridColDefs.Shared.paramSort('fullLife'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
        },
        {
          headerName: metricsDropdown.selected.value.toUpperCase(),
          colId: 'total',
          field: `total.${attrs.attrName}`,
          type: attrs.columnType,
          minWidth: 105,
          pinned: false,
          menuTabs: ['filterMenuTab'],
          filter: '',
          sort: GridColDefs.Shared.paramSort('total'),
          aggFunc:
            metricsDropdown.selected.value === MetricsOptions.CciPercentage
              ? targetsDropdown.selected.value === ViewOptions.CompareProjection
                ? GridColDefs.Shared.sumCciPercentageCompare
                : GridColDefs.Shared.sumCciPercentage
              : 'sum',
        },
      ];
    },
    addCustomRendererConfigs: (
      periodColDefs: Array<ColDef>,
      type: string,
      view: string
    ): Array<ColDef> => {
      const attrs: Record<string, string> =
        GridColDefs.Shared.getMetricAttributes(type);

      periodColDefs = periodColDefs.map((x: ColDef) => ({
        ...x,
        colId: x.field,
        minWidth: 65,
        field: `${x.field}.${attrs.attrName}`,
        sort: GridColDefs.Shared.paramSort(x.field),
        aggFunc:
          type === MetricsOptions.CciPercentage
            ? view === ViewOptions.CompareProjection
              ? GridColDefs.Shared.sumCciPercentageCompare
              : GridColDefs.Shared.sumCciPercentage
            : 'sum',
        type: attrs.columnType,
      }));

      return periodColDefs;
    },
    getMetricAttributes: (type: string): Record<string, string> => {
      const result: Record<string, string> = {
        attrName: '',
        columnType: 'numberColumn',
      };

      switch (type) {
        case MetricsOptions.Sales:
          result.attrName = 'sales';
          break;
        case MetricsOptions.Revenue:
          result.attrName = 'revenue';
          break;
        case MetricsOptions.Cci:
          result.attrName = 'cci';
          break;
        case MetricsOptions.CciPercentage:
          result.attrName = 'cciPercentage.cciPercent';
          result.columnType = 'percentColumn';
          break;
      }

      return result;
    },
    sumWonCciPercentage: (values: Array<any>): number => {
      const totalWonCci: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI
            ? x.wonCCI
            : 0;
        }
      );
      const totalSalesForWon: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI ? x.sales : 0;
        }
      );
      const result: number = totalWonCci / totalSalesForWon || 0;
      return isFinite(result) ? result * 100 : 0;
    },
    sumWonCciPercentageCompare: (values: Array<any>): number => {
      const totalWonCciCurrent: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI
            ? x.currentWonCCI
            : 0;
        }
      );
      const totalSalesForWonCurrent: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI
            ? x.currentSales
            : 0;
        }
      );
      const totalWonCciTarget: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI
            ? x.targetWonCCI
            : 0;
        }
      );
      const totalSalesForWonTarget: number = sumBy(
        values,
        (x: SpeculativeResponseItem) => {
          return x && x.reasonCode && x.reasonCode.ImpactedWonCCI
            ? x.targetSales
            : 0;
        }
      );

      let totalWonCIIPerCurrent: number =
        (totalWonCciCurrent / totalSalesForWonCurrent || 0) * 100;
      let totalWonCIIPerTarget: number =
        (totalWonCciTarget / totalSalesForWonTarget || 0) * 100;
      totalWonCIIPerCurrent = isFinite(totalWonCIIPerCurrent)
        ? totalWonCIIPerCurrent
        : 0;
      totalWonCIIPerTarget = isFinite(totalWonCIIPerTarget)
        ? totalWonCIIPerTarget
        : 0;
      return totalWonCIIPerCurrent - totalWonCIIPerTarget;
    },
    sumDeliveredCciPercentage: (values: Array<any>): number => {
      const cci: number = sumBy(values, 'deliveredCCIPercentage.cci');
      const netRevenue: number = sumBy(
        values,
        'deliveredCCIPercentage.revenue'
      );
      const result: number = cci / Math.abs(netRevenue) || 0;
      return isFinite(result) ? result * 100 : 0;
    },
    sumDeliveredCciPercentageCompare: (values: Array<any>): number => {
      const currentCci: number = sumBy(
        values,
        'currentDeliveredCCIPercentage.cci'
      );
      const currentNetRevenue: number = sumBy(
        values,
        'currentDeliveredCCIPercentage.revenue'
      );
      const targetDeliveredCci: number = sumBy(
        values,
        'targetDeliveredCCIPercentage.cci'
      );
      const targetNetRevenue: number = sumBy(
        values,
        'targetDeliveredCCIPercentage.revenue'
      );

      let currentPercentage: number =
        currentCci / Math.abs(currentNetRevenue) || 0;
      let targetPercentage: number =
        targetDeliveredCci / Math.abs(targetNetRevenue) || 0;
      currentPercentage = isFinite(currentPercentage) ? currentPercentage : 0;
      targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

      return (currentPercentage - targetPercentage) * 100;
    },
    sumCciPercentage: (values: Array<any>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const totalCciAmount: number = sumBy(
          values,
          `${colDef.colId}.cciPercentage.cci`
        );
        const totalRevenueAmount: number = sumBy(
          values,
          `${colDef.colId}.cciPercentage.revenue`
        );

        if (typeof (totalCciAmount || totalRevenueAmount) === 'number') {
          result = (totalCciAmount / totalRevenueAmount || 0) * 100;
        }
      }

      return isFinite(result) ? result : 0;
    },
    sumCciPercentageCompare: (values: Array<any>, colDef?: ColDef): number => {
      let result = 0;

      if (colDef) {
        const currentCci: number = sumBy(
          values,
          `${colDef.colId}.currentCciPercentage.cci`
        );
        const currentRevenue: number = sumBy(
          values,
          `${colDef.colId}.currentCciPercentage.revenue`
        );
        const targetCci: number = sumBy(
          values,
          `${colDef.colId}.targetCciPercentage.cci`
        );
        const targetRevenue: number = sumBy(
          values,
          `${colDef.colId}.targetCciPercentage.revenue`
        );

        if (
          typeof (
            currentCci ||
            currentRevenue ||
            targetCci ||
            targetRevenue
          ) === 'number'
        ) {
          let currentPercentage: number =
            currentCci / Math.abs(currentRevenue) || 0;
          let targetPercentage: number =
            targetCci / Math.abs(targetRevenue) || 0;
          currentPercentage = isFinite(currentPercentage)
            ? currentPercentage
            : 0;
          targetPercentage = isFinite(targetPercentage) ? targetPercentage : 0;

          result = (currentPercentage - targetPercentage) * 100;
        }
      }

      return result;
    },
    paramSort: (colId: string): string => {
      const sortColumsConfig: Array<SortColum> = [
        { colId: 'total', sort: 'desc' },
        { colId: 'sales', sort: 'desc' },
      ];
      const sortColum = sortColumsConfig.find(
        (x: SortColum) => x.colId === colId
      );
      return sortColum ? sortColum.sort : '';
    },
  },
  getExtraGridOptions: (): GridOptions => {
    return {
      defaultExportParams: {
        sheetName: 'Speculatives',
        fileName: 'CBP Speculatives',
      } as CsvExportParams,
      rowClassRules: {
        'incomplete-stage': (params: any) => {
          return (
            params.data.status === 'OnHold' && !params.data.onHoldByElapsedTime
          );
        },
        'spec-elapsed-dates': (params: any) => {
          return params.data.onHoldByElapsedTime;
        },
      },
    } as GridOptions;
  },
};
