import { minBy, sumBy } from 'lodash';
import {
  ActiveDates,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { ActiveDate } from 'src/app/shared/services/entities/filters/active-date';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SpeculativePhasingPeriod } from 'src/app/shared/services/entities/grids/speculative-response';
import { Speculative } from '../../../entities/speculative';
import { EditGridMonth, MetricSet } from '../entities/speculative-edit-grid';

export class SpeculativeEditGridUtils {
  private static months: Array<EditGridMonth>;
  private static beyond: EditGridMonth;
  private static priorFy: EditGridMonth;

  static mapData(
    source: Speculative,
    filters: Filters
  ): Array<Record<string, any>> {
    this.getVisibleMonths(source, filters.dates, filters.activeDates);
    this.getBeyondMonth(source, filters.activeDates);
    this.getPriorTotals(filters.dates, filters.activeDates);

    return this.mapSpeculativeMonths(source);
  }

  static getMonths(filters: Filters): Array<MmbDate> {
    const appDate: ActiveDate = filters.activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
    return filters.dates.filter(
      (x: MmbDate) =>
        x.Id >= appDate.StartTimeId && x.Id <= appDate.StartTimeId + 23
    );
  }

  private static getVisibleMonths(
    source: Speculative,
    dates: Array<MmbDate>,
    activeDates: Array<ActiveDate>
  ): void {
    this.months = [];

    const appDate: ActiveDate = activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
    const editableMonths: Array<MmbDate> = dates.filter(
      (x: MmbDate) =>
        x.Id >= appDate.StartTimeId && x.Id <= appDate.StartTimeId + 23
    );

    editableMonths.forEach((x: MmbDate) => {
      const phasingPeriod = source
        ? source.phasingMonthly.find(
            (y: SpeculativePhasingPeriod) => y.timeId === x.Id
          )
        : null;

      const phasingValues: MetricSet = {
        sales: phasingPeriod?.sales || 0,
        revenue: phasingPeriod?.revenue || 0,
        cci: phasingPeriod?.cci || 0,
        cciPercentage:
          phasingPeriod?.revenue && phasingPeriod?.revenue > 0
            ? (phasingPeriod.cci / Math.abs(phasingPeriod.revenue)) * 100
            : 0,
      };

      const projectionPeriod = source.projectedData
        ? source.projectedData.find(
            (y: Record<string, number>) => y.timeId === x.Id
          )
        : null;

      const projectionValues: MetricSet = {
        sales: projectionPeriod?.sales || 0,
        revenue: projectionPeriod?.revenue || 0,
        cci: projectionPeriod?.cci || 0,
        cciPercentage:
          projectionPeriod?.revenue && projectionPeriod?.revenue > 0
            ? (projectionPeriod.cci / Math.abs(projectionPeriod.revenue)) * 100
            : 0,
      };

      const columnMonth: EditGridMonth = {
        timeId: x.Id,
        phasingMonth: phasingValues,
        projectionMonth: projectionValues,
      };

      this.months.push(columnMonth);
    });
  }

  private static getBeyondMonth(
    source: Speculative,
    activeDates: Array<ActiveDate>
  ): void {
    this.beyond = null;
    const appDate: ActiveDate = activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
    const beyondMonth: number = appDate.StartTimeId + 24;

    const phasingValues: MetricSet = {
      sales: 0,
      revenue: 0,
      cci: 0,
      cciPercentage: 0,
    };

    const projectionValues: MetricSet = {
      sales: 0,
      revenue: 0,
      cci: 0,
      cciPercentage: 0,
    };

    if (source) {
      source.phasingMonthly.forEach((x: SpeculativePhasingPeriod) => {
        if (x.timeId >= beyondMonth) {
          phasingValues.sales += x.sales || 0;
          phasingValues.revenue += x.revenue || 0;
          phasingValues.cci += x.cci || 0;
        }
      });
    }

    source.projectedData.forEach((x: Record<string, number>) => {
      if (x.timeId >= beyondMonth) {
        projectionValues.sales += x.sales || 0;
        projectionValues.revenue += x.revenue || 0;
        projectionValues.cci += x.cci || 0;
      }
    });

    phasingValues.cciPercentage = phasingValues.revenue
      ? (phasingValues.cci / Math.abs(phasingValues.revenue)) * 100
      : 0;
    projectionValues.cciPercentage = projectionValues.revenue
      ? (projectionValues.cci / Math.abs(projectionValues.revenue)) * 100
      : 0;

    const columnMonth: EditGridMonth = {
      timeId: beyondMonth,
      phasingMonth: phasingValues,
      projectionMonth: projectionValues,
    };

    this.beyond = columnMonth;
  }

  private static getPriorTotals(
    dates: Array<MmbDate>,
    activeDates: Array<ActiveDate>
  ): void {
    this.priorFy = null;

    const appDate: ActiveDate = activeDates.find(
      (x: ActiveDate) => x.DateTypeCd === ActiveDates.ApplicationDate
    );
    const cfy: number = dates.find(
      (x: MmbDate) => x.Id === appDate.StartTimeId
    ).FiscalYearNbr;
    const startNfy: MmbDate = minBy(
      dates.filter((x: MmbDate) => x.FiscalYearNbr === cfy + 1),
      'Id'
    );

    const phasingValues: MetricSet = {
      sales: 0,
      revenue: 0,
      cci: 0,
      cciPercentage: 0,
    };

    const projectionValues: MetricSet = {
      sales: 0,
      revenue: 0,
      cci: 0,
      cciPercentage: 0,
    };

    this.months.forEach((x: EditGridMonth) => {
      if (x.timeId < startNfy.Id) {
        phasingValues.sales += x.phasingMonth.sales || 0;
        phasingValues.revenue += x.phasingMonth.revenue || 0;
        phasingValues.cci += x.phasingMonth.cci || 0;

        projectionValues.sales += x.projectionMonth.sales || 0;
        projectionValues.revenue += x.projectionMonth.revenue || 0;
        projectionValues.cci += x.projectionMonth.cci || 0;
      }
    });

    phasingValues.cciPercentage = phasingValues.revenue
      ? (phasingValues.cci / Math.abs(phasingValues.revenue)) * 100
      : 0;
    projectionValues.cciPercentage = projectionValues.revenue
      ? (projectionValues.cci / Math.abs(projectionValues.revenue)) * 100
      : 0;

    const columnPrior: EditGridMonth = {
      phasingMonth: phasingValues,
      projectionMonth: projectionValues,
    };

    this.priorFy = columnPrior;
  }

  private static mapSpeculativeMonths(
    source: Speculative
  ): Array<Record<string, any>> {
    let result: Array<Record<string, any>> = [];
    result = result.concat(this.mapMetricRows('sales', source));
    result = result.concat(this.mapMetricRows('revenue', source));
    result = result.concat(this.mapMetricRows('cci', source));
    result = result.concat(this.mapMetricRows('cciPercentage', source));
    return result;
  }

  private static mapMetricRows(
    metric: string,
    source: Speculative
  ): Array<Record<string, any>> {
    const attrs: Record<string, string> = this.getMetricAttributes(metric);

    let phasingData: Record<string, any> = {
      group: attrs.label,
      metric: metric,
      name: source
        ? `Speculative ${source.id} ${attrs.label}`
        : `Speculative ${attrs.label}`,
      total: source[attrs.attrName] ? source[attrs.attrName] : 0,
      variance:
        metric === 'cciPercentage'
          ? null
          : this.calculateVariance(metric, source),
      prior: this.priorFy.phasingMonth[metric],
      beyond: this.beyond.phasingMonth[metric],
    };

    let projectionData: Record<string, any> = {
      group: attrs.label,
      metric: metric,
      name: `${attrs.label} Projection`,
      total: null,
      variance: null,
      prior: this.priorFy.projectionMonth[metric],
      beyond: this.beyond.projectionMonth[metric],
    };

    this.months.forEach((x: EditGridMonth) => {
      const newPhasingMonth: Record<string, any> = {};
      newPhasingMonth[x.timeId] = x.phasingMonth[metric];
      phasingData = { ...phasingData, ...newPhasingMonth };

      const newProjectionMonth: Record<string, any> = {};
      newProjectionMonth[x.timeId] = x.projectionMonth[metric];
      projectionData = { ...projectionData, ...newProjectionMonth };
    });

    return [phasingData, projectionData];
  }

  private static calculateVariance(metric: string, source: Speculative) {
    const allPeriods: Array<EditGridMonth> = this.months.concat(this.beyond);
    const total: number = source[metric];
    const sum: number = sumBy(allPeriods, (x: EditGridMonth) => {
      return x.phasingMonth[metric] || 0;
    });

    return Math.round(total - sum) || 0;
  }

  private static getMetricAttributes(metric: string): Record<string, string> {
    switch (metric) {
      case 'sales':
        return {
          label: 'Sales',
          attrName: 'sales',
        } as Record<string, string>;
      case 'revenue':
        return {
          label: 'Revenue',
          attrName: 'netRevenue',
        } as Record<string, string>;
      case 'cci':
        return {
          label: 'CCI$',
          attrName: 'deliveredCCIDollar',
        } as Record<string, string>;
      case 'cciPercentage':
        return {
          label: 'CCI%',
          attrName: 'deliveredCCIPercentage',
        } as Record<string, string>;
    }

    return null;
  }
}
