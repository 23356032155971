import { Component } from '@angular/core';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { GridCellItemAlign } from 'src/app/shared/constants/grid.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';

@Component({
  selector: 'app-numeric-cell',
  templateUrl: './numeric-cell.component.html',
  styleUrls: ['./numeric-cell.component.scss'],
})
export class NumericCellComponent {
  params: any;
  data: Record<string, any> = {
    cellStyleTextAlign: GridCellItemAlign.Right,
    cellStyleFontWeight: 'normal',
    cellClass: '',
    numberFormat: new CustomNumberPipe(),
    value: '',
    roundFloor: false,
    thousands: false,
    nullSymbol: '-',
  };

  agInit(params: any): void {
    this.params = params;

    if (this.params) {
      if (this.params.align) {
        this.data.cellStyleTextAlign = this.params.align;
      }

      if (this.params.isBold) {
        this.data.cellStyleFontWeight = 'bold';
      }

      if (this.params.cellClass) {
        this.data.cellClass = this.params.cellClass;
      }

      if (this.params.round) {
        this.data.roundFloor = true;
      }

      if (this.params.thousands) {
        this.data.thousands = true;
      }

      if (this.params.nullSymbol) {
        this.data.nullSymbol = this.params.nullSymbol;
      }

      this.data.value = this.format(params.value);
    }
  }

  refresh(params: any): void {
    this.agInit(params);
  }

  private format(value: any): string {
    let result = this.formatNumber(value);
    if (!result) {
      result = this.data.nullSymbol;
    }
    return result;
  }

  private formatNumber(value: any) {
    if (value === null) {
      return undefined;
    } else if (typeof value === 'number') {
      value = this.data.thousands ? value / 1000 : value;
      value = this.data.roundFloor ? Math.floor(value) : value;

      return this.data.numberFormat.transform(
        value,
        ValueType.Numeric,
        this.data.nullSymbol
      );
    } else if (typeof value === 'string') {
      return this.data.numberFormat.transform(
        undefined,
        ValueType.Numeric,
        this.data.nullSymbol
      );
    }

    return value;
  }
}
