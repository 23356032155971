import { assign } from 'lodash';

import { TreeViewItem } from './tree-view-item';

class TreeViewSelectionSchema {
  selectedItems: Array<TreeViewItem>;
  count?: number;
}

export class TreeViewSelection extends TreeViewSelectionSchema {
  constructor(filterAttributes?: TreeViewSelectionSchema) {
    super();
    assign(this, filterAttributes);

    if (!this.count) {
      this.count = this.selectedItems ? this.selectedItems.length : 0;
    }
  }
}
