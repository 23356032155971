<ng-template #trendModal let-modal>
  <div class="trend-modal-header">
    <div class="trend-modal-title">
      <app-drop-down [items]="types" [selectedItem]="selectedType" (itemSelectionChangeEvent)="onTypeChanged($event)">
      </app-drop-down>
    </div>
    <div class="trend-modal-filters">
      <app-trend-filters [config]="trendFiltersConfig"
        (filtersSelectionChangedEvent)="onFiltersSelectionChanged($event)" (exportFileEvent)="onFileExport($event)"
        (closeEvent)="onCloseModal()" (resetEvent)="onReset()">
      </app-trend-filters>
    </div>
  </div>
  <div *ngIf="loaded && (showIndustryAlert || showWmuAlert)" class="alert-content">
    <ngb-alert class="trend-modal-alert" *ngIf="showWmuAlert" (closed)="showWmuAlert = false">
      <fa-icon class="mr-2" [icon]="faExclamationCircle"></fa-icon>
      Plan may not be available by WMU. Please note that only records with assignments to the selected filter options
      will display.Projection may not tie back to totals.
    </ngb-alert>
    <ngb-alert class="trend-modal-alert" *ngIf="showIndustryAlert" (closed)="showIndustryAlert = false">
      <fa-icon class="mr-2" [icon]="faExclamationCircle"></fa-icon>
      Plan is not available by Industry. Please note that only records with assignments to the selected filter options
      will display. Projection may not tie back to totals.
    </ngb-alert>
  </div>
  <div *ngIf="config.isChartView && loaded" class="modal-body-chart">
    <app-trend-chart [config]="trendChartConfig"></app-trend-chart>
  </div>
  <div *ngIf="!config.isChartView && loaded" class="modal-body-table">
    <app-trend-table [config]="trendTableConfig" (closeModalEvent)="onCloseModal()"></app-trend-table>
  </div>
  <div class="trend-modal-footer">
    <button class="btn btn-link link-view" (click)="onViewTypeChanged()">{{ config.isChartView ? 'TABLE VIEW' : 'CHART
      VIEW' }}</button>
  </div>
  <app-spinner name="trend-modal-spinner" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
  </app-spinner>
</ng-template>