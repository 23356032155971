import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { Endpoints } from '../constants/endpoints.constants';
import { Observable, of, throwError } from 'rxjs';
import { switchMap, catchError, filter } from 'rxjs/operators';
import { Headers } from 'src/app/core/constants/http.constants';

import { Filters } from './entities/filters/filters';
import { FavoriteResponseItem } from './entities/favorite-response';
import { FavoriteSaveModel } from 'src/app/modules/analytics/components/favorite-modal/entities/favorite-save-model';
import { FavoriteMapper } from 'src/app/modules/analytics/components/favorite-modal/utils/favorite-modal-utils';

@Injectable({
  providedIn: 'root',
})
export class FavoriteService {
  constructor(private http: HttpClient) {}

  getFavorites(
    customerNumber: string,
    filters: Filters
  ): Promise<Array<FavoriteResponseItem>> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<Array<FavoriteResponseItem>>(
        Endpoints.favoritesCustomer,
        { customerNumber },
        options
      )
      .pipe(
        switchMap((x: any) => {
          const z = x.data;
          return of(
            z.map((y: FavoriteResponseItem) =>
              FavoriteMapper.mapContract(y, filters)
            )
          );
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  markDefault(favorite: FavoriteResponseItem): Promise<boolean> {
    favorite.default = !favorite.default;
    return this.updateFavorite(favorite);
  }

  createFavorite(favorite: FavoriteSaveModel): Promise<boolean> {
    return this.http
      .post<FavoriteSaveModel>(Endpoints.favorites + '/create', favorite)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  updateFavorite(favorite: FavoriteSaveModel): Promise<boolean> {
    return this.http
      .put<FavoriteResponseItem>(Endpoints.favorites + '/update', favorite)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  deleteFavorite(favoriteId: string, customerNumber: string): Promise<boolean> {
    const payload: Record<string, any> = {
      favoriteId,
      customerNumber,
    };

    return this.http
      .post<FavoriteResponseItem>(Endpoints.favorites + '/delete', payload)
      .pipe(
        switchMap((x: Record<string, any>) => {
          return of(x.isSucessfull);
        }),
        catchError(this.errorHandler.bind(this))
      )
      .toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (FavoriteService)');
  }
}
