import { assign } from 'lodash';

class WinsResponseSchema {
  wonCciAmount: number;
  wonCciAmountCurrent: number;
  wonCciAmountTarget: number;
  wonRevenueAmount: number;
  wonRevenueAmountCurrent: number;
  wonRevenueAmountTarget: number;
  groupKey: string;
  id: number;
  name: string;
  opportunityLink: string;
  reportingStatus: string;
  stage: string;
  clientServiceGroupDesc: string;
  marketUnitDesc: string;
  marketUnitId: string;
  director: string;
  consultingStartDate: string;
  consultingEndDate: string;
  contractId: string;
  expectedSignDate: string;
  winProbability: number;
  cCITargetPercentage: number;
  projectionPct: number;
  cCIPercentage: number;
  statusSinceDate: Date;
  actualSignDate: Date;
  updatedDate: Date;
  outsourcingStartDate: Date;
  outsourcingEndDate: Date;
  msaIndicator?: boolean;
}

export class WinsResponse extends WinsResponseSchema {
  constructor(filterAttributes?: WinsResponseSchema) {
    super();
    assign(this, filterAttributes);
  }

  isInactive(): boolean {
    return (
      (Math.round(this.wonRevenueAmount) === 0 || !this.wonRevenueAmount) &&
      (Math.round(this.wonCciAmount) === 0 || !this.wonCciAmount) &&
      (Math.round(this.cCIPercentage) === 0 || !this.cCIPercentage)
    );
  }
}
