import { assign } from 'lodash';

class AppToastSchema {
  header: string;
  body: Array<string>;
  delay: number;
  type: ToastType;
}

export enum ToastType {
  primary = 1,
  secondary = 2,
  success = 3,
  error = 4,
  warning = 5,
  info = 6,
  light = 7,
  dark = 8,
  none = 9,
}

export class AppToast extends AppToastSchema {
  constructor(filterAttributes?: AppToastSchema) {
    super();
    assign(this, filterAttributes);
  }

  getStyles(): string {
    switch (this.type) {
      case ToastType.error:
        return 'toast bg-danger text-light';
      case ToastType.success:
        return 'toast bg-success text-light';
      case ToastType.primary:
        return 'toast bg-primary text-light';
      case ToastType.secondary:
        return 'toast bg-secondary text-light';
      case ToastType.warning:
        return 'toast bg-warning text-dark';
      case ToastType.info:
        return 'toast bg-info text-light';
      case ToastType.light:
        return 'toast bg-light text-light';
      case ToastType.dark:
        return 'toast bg-dark text-light';
    }

    return 'toast';
  }
}
