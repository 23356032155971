import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, throwError } from 'rxjs';
import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';

import { Endpoints } from '../constants/endpoints.constants';
import { AppState } from './entities/app-state/app-state';
import { DataRefresh } from './entities/app-state/data-refresh';
import { StateOfDataItem } from './entities/app-state/update-frequency-item';

import Dictionary from 'src/app/core/utils/dictionary.utils';
import { catchError, switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private appStateObservable = new ReplaySubject<AppState>(1);

  public get appStateChanged(): ReplaySubject<AppState> {
    return this.appStateObservable;
  }

  constructor(private http: HttpClient) {}

  getAppState(overrideCode?: string): Observable<AppState> {
    const params = new Dictionary<string>();
    params.add('overrideCode', overrideCode);

    return this.http
      .get<AppState>(Endpoints.appstate, HttpUtils.createRequestOptions(params))
      .pipe(
        switchMap((x: AppState) => {
          this.appStateObservable.next(x);
          return of(x);
        }),
        catchError(this.errorHandler.bind(this))
      );
  }

  getDataRefresh(): Promise<DataRefresh> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .get<DataRefresh>(Endpoints.dataRefresh, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  updateDataRefresh(dataItem: StateOfDataItem): Promise<boolean> {
    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<boolean>(Endpoints.updateDataRefresh, dataItem, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (AppStateService)');
  }
}
