import { assign } from 'lodash';

import { ClientGroup } from 'src/app/shared/services/entities/filters/client-group';
import { Currency } from 'src/app/shared/services/entities/filters/currency';
import { Customer } from 'src/app/shared/services/entities/filters/customer';
import { FinancialCustomer } from 'src/app/shared/services/entities/filters/financial-customer';
import { Industry } from 'src/app/shared/services/entities/filters/industry';
import { Location } from 'src/app/shared/services/entities/filters/location';
import { ProductHierarchy } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { ServiceGroupValues } from 'src/app/shared/services/entities/filters/service-group';
import { Timeframe } from 'src/app/shared/services/entities/filters/timeframe';
import { Wmu } from 'src/app/shared/services/entities/filters/wmu';

class BoardSaveModelSchema {
  boardId?: string;
  tab?: BoardSaveTabsModel;
  name?: string;
  sharedBy?: string;
  filters?: BoardSaveFiltersModel;
  default?: boolean;
}

export class BoardSaveModel extends BoardSaveModelSchema {
  constructor(filterAttributes?: BoardSaveModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}
class BoardSaveFiltersModelSchema {
  Industries?: Array<Industry>;
  Alliances?: Array<ProductHierarchy>;
  Sgi?: Array<ProductHierarchy>;
  Functions?: Array<ProductHierarchy>;
  ServiceGroups?: Array<ServiceGroupValues>;
  Locations?: Array<Location>;
  ClientServiceGroups?: Array<ClientGroup>;
  Wmus?: Array<Wmu>;
  FinancialCustomer?: Array<FinancialCustomer>;
  Currency?: Currency;
  Customer?: Customer;
  Timeframe?: Timeframe;
}

export class BoardSaveFiltersModel extends BoardSaveFiltersModelSchema {
  constructor(filterAttributes?: BoardSaveFiltersModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class BoardSaveTabsModelSchema {
  Name: string;
  SubTabName?: string;
  Controls?: Array<any>;
}

export class BoardSaveTabsModel extends BoardSaveTabsModelSchema {
  constructor(filterAttributes?: BoardSaveTabsModelSchema) {
    super();
    assign(this, filterAttributes);
  }
}
