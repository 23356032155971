<div *ngIf="config" class="tab-controls">
    <ng-container *ngFor="let c of config.controls" [ngSwitch]="c.controlType">
        <ng-container *ngSwitchCase="controlType.MultipleDropdown">
          <div class="mt-3">
            <app-drop-down-multiple *ngIf="!c.isVisible || c.isVisible(config)" [placeholder]="c.placeholder"
                [items]="c.items" [selectedItems]="c.selected" (itemsSelectionChangeEvent)="c.onChange($event, c)"
                [type]="c.type">
            </app-drop-down-multiple>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="controlType.Dropdown">
          <div class="mt-3">
            <app-drop-down *ngIf="!c.isVisible || c.isVisible(config)" [items]="c.items" [selectedItem]="c.selected"
                (itemSelectionChangeEvent)="c.onChange($event, c)" [type]="c.type">
            </app-drop-down>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="controlType.Switch">
            <div class="switch-container mt-3" *ngIf="!c.isVisible || c.isVisible(config)">
                <span>{{c.text}}</span>
                <app-switch rightText="" leftText="" [checked]="c.switchValue"
                    (switchChangeEvent)="c.onSwitch($event, c)">
                </app-switch>
            </div>
        </ng-container>
    </ng-container>
</div>
