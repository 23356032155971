import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Endpoints } from '../constants/endpoints.constants';
import { AccountPlanResponse } from './entities/security/account-plan-response';
import { AppState } from './entities/app-state/app-state';

import { AppStateService } from './app-state.service';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private customerNbr: string;
  private hasAccountPlanRoleAux: boolean;

  constructor(
    private http: HttpClient,
    private sharedService: SharedService,
    private appStateService: AppStateService
  ) {}

  hasAccountPlanRole(customerNbr: string): Promise<boolean> {
    if (this.customerNbr !== customerNbr) {
      return this.http
        .post<AccountPlanResponse>(Endpoints.accountPlanRole, {
          customerNumber: Number(customerNbr),
        })
        .pipe(
          switchMap((x: AccountPlanResponse) => {
            x = new AccountPlanResponse(x);
            this.customerNbr = customerNbr;
            this.hasAccountPlanRoleAux = x.hasAccountPlanRole(
              this.sharedService.getPeopleKey()
            );
            return of(this.hasAccountPlanRoleAux);
          }),
          catchError(this.errorHandler.bind(this))
        )
        .toPromise();
    }

    return Promise.resolve(this.hasAccountPlanRoleAux);
  }

  isReadOnly(): Promise<boolean> {
    return new Promise((resolve: (x: boolean) => void): void => {
      this.appStateService.appStateChanged.subscribe((y: AppState) => {
        resolve(
          y.readOnlyModeEnabled &&
            !y.readOnlyModeUserList
              .split(',')
              .includes(this.sharedService.getEnterpriseId())
        );
      });
    });
  }

  isAdmin(): Promise<boolean> {
    return new Promise((resolve: (x: boolean) => void): void => {
      this.appStateService.appStateChanged.subscribe((y: AppState) => {
        resolve(
          y.adminUserList
            .split(',')
            .includes(this.sharedService.getEnterpriseId())
        );
      });
    });
  }

  isReadOnlyMode(): Promise<boolean> {
    return new Promise((resolve: (x: boolean) => void): void => {
      this.appStateService.appStateChanged.subscribe((y: AppState) => {
        resolve(y.readOnlyModeEnabled);
      });
    });
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    this.customerNbr = null;
    this.hasAccountPlanRoleAux = false;
    return throwError(error || 'Server error (SecurityService)');
  }
}
