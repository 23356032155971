import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Headers } from 'src/app/core/constants/http.constants';
import { HttpUtils, IHttpOptions } from 'src/app/core/utils/http.utils';
import { TrendTileResponse } from '../../components/tiles/entities/trend-tile-response';
import { Endpoints } from '../../constants/endpoints.constants';
import { MetricType } from '../../constants/metrics.constants';
import { TextValuePair } from '../entities/common/key-value';
import { RequestPayload } from '../entities/request-payload';

@Injectable({
  providedIn: 'root',
})
export class TrendTileService {
  private metricChangedObservable = new Subject<TextValuePair>();
  salesCloudURL = '?type=sales&isCloud=';
  revenueCloudURL = '?type=revenue&isCloud=';

  public get metricChanged(): Subject<TextValuePair> {
    return this.metricChangedObservable;
  }

  constructor(private http: HttpClient) {}

  getTileDataByType(
    type: MetricType,
    request: RequestPayload,
    isPercentage?: boolean,
    isCloud?: boolean
  ): Promise<TrendTileResponse> {
    const uri: string = this.getUrlByMetricType(type, isPercentage, isCloud);

    const options: IHttpOptions = HttpUtils.getOptions([
      Headers.CLIENTAPPID_HEADER,
      Headers.IMPERSONATION_USER_HEADER,
      Headers.CORRELATION_ID_HEADER,
    ]);

    return this.http
      .post<TrendTileResponse>(uri, request, options)
      .pipe(catchError(this.errorHandler.bind(this)))
      .toPromise();
  }

  private getUrlByMetricType(
    type: MetricType,
    isPercentage?: boolean,
    isCloud?: boolean
  ): string {
    switch (type) {
      case MetricType.Sales:
        return `${Endpoints.trend}` + this.salesCloudURL + isCloud;
      case MetricType.Revenue:
        return `${Endpoints.trend}` + this.revenueCloudURL + isCloud;
      case MetricType.Cci:
        return (
          `${Endpoints.trend}?type=${
            isPercentage
              ? encodeURIComponent('cci%')
              : encodeURIComponent('delivered cci')
          }` +
          `&isCloud=` +
          isCloud
        );
      default:
        return '';
    }
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    return throwError(error || 'Server error (TrendTileService)');
  }
}
