import { assign } from 'lodash';

class ClientGroupSchema {
  Id: string;
  ClientGroupCd: string;
  ClientGroupDesc: string;
  ClientGroupType: string;
  ProfitCenterCd: string;
  ProfitCenterDesc: string;
}

export class ClientGroup extends ClientGroupSchema {
  constructor(filterAttributes?: ClientGroupSchema) {
    super();
    assign(this, filterAttributes);
  }
}
