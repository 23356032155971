import {
  AppMessage,
  ButtonType,
  MessageType,
} from '../services/entities/app-message';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

const DefaultHeader = 'Client Business Projection';

export const MessageTemplates: Record<string, AppMessage> = {
  UnexpectedError: new AppMessage({
    body: ['An unexpected error has ocurred. Please, try again later.'],
    header: 'Error',
    type: MessageType.error,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  NoAccess: new AppMessage({
    body: [
      'You do not have the authorization to view Client Business Projection.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  FxAdjustedCurrency: new AppMessage({
    body: [
      '• Fx Adjusted does not apply to Delivered CCI.',
      '• Fx Adjusted is not currently available at detailed level (By Industry, By Reason Code,Contracts, Opportunities or Speculative records)',
      '• Fx Adjusted does not apply to Monthly Projection.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  ConstantCurrency: new AppMessage({
    body: [
      '• CCI% is calculated based on Revenue and CCI in GLOBAL Currency.',
      '• Constant does not apply to Monthly Projection.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  WmuBreakdownWarning: new AppMessage({
    body: [
      '• Plan may not be available by WMU.',
      '• You may want to refine your view by selecting WMU nodes in WMU filter.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    showOnce: true,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  IndustryBreakdownWarning: new AppMessage({
    body: [
      '• Plan is not available by Industry.',
      '• Please note that only records with assignments to the selected filter options will display. Projection may not tie back to totals.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    showOnce: true,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  AttributeFiltersWarning: new AppMessage({
    body: [
      '• Plan is not available for all attribute selections.',
      '• Please note that only records with assignments to the selected filter options will display. Projection may not tie back to totals.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    showOnce: true,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  FileExporterInit: new AppMessage({
    body: ['We are creating your file. This could take a few moments.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Close',
        type: ButtonType.primary,
      },
    ],
  }),
  ExportSgBreakdown: new AppMessage({
    body: ['Would you like to export with breakdown by SG?'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  ExportWmuBreakdown: new AppMessage({
    body: [
      'Would you like to export with breakdown by WMU (*)?',
      '* Export may take up to 2 mins to download',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  ProjectionPercentageResetConfirm: new AppMessage({
    body: [
      'You are about to reset your Projection % editions back to the Win Probability %.',
      'Would you like to confirm this action?',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  OpportunitiesEditionExeceed: new AppMessage({
    body: [
      'Hmmm... we can only send [maxEditedRecords] updated records at a time to MMS.',
      'Try saving in your records in smaller amounts.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  OpportunitiesEditionInvalid: new AppMessage({
    body: ['Opportunity values can be only full numbers between 0 and 100.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  OpportunitiesElapsedDates: new AppMessage({
    body: [
      'Alert:',
      'One or more Opportunity records have been highlighted in yellow due to elapsed sign and/or start dates.',
      'Please review your Opportunity records and adjust as needed.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    showOnce: true,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeSuccessfulSaved: new AppMessage({
    body: ['Your changes have been successfully saved.'],
    header: 'Successful Save',
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeTemplateDownload: new AppMessage({
    body: ['We are creating your file. This could take a few minutes.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileInvalidTemplate: new AppMessage({
    body: ['You have selected an invalid template.'],
    header: 'Upload Failure',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileInvalidTemplateCustomer: new AppMessage({
    body: [
      'Selected template does not correspond to selected customer in CBP.',
    ],
    header: 'Upload Failure',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileStarted: new AppMessage({
    body: ['We are processing your file. This could take a few moments.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadDataSave: new AppMessage({
    body: ['We are saving your data. This could take a few moments.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileError: new AppMessage({
    body: ['Please try to upload again.'],
    header: 'Upload Failure',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileValidationsErrors: new AppMessage({
    body: ['Please try to upload again.'],
    header: 'Upload Failure',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeUploadFileSuccess: new AppMessage({
    body: ['Speculative record/s were successfully created.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeDeleteConfirm: new AppMessage({
    body: [
      'Are you sure you want to delete the selected speculative(s)? You cannot restore deleted speculatives.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  SpeculativeSuccessfulDelete: new AppMessage({
    body: ['Speculative record was successfully deleted in CBP and MMB.'],
    header: 'Successful Delete',
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativesElapsedDates: new AppMessage({
    body: [
      'REQUIRED ACTION:',
      'One or more Speculative records have been put on hold and highlighted in red due to elapsed start dates.',
      'This means that speculative records financials have been excluded from projection. Please review your Speculative records and adjust as needed.',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    showOnce: true,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeSuccessfulHold: new AppMessage({
    body: ['Speculative record was successfully put on hold in CBP and MMB.'],
    header: 'Successful Hold',
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeSuccessfulActivate: new AppMessage({
    body: ['Speculative record was successfully put active in CBP and MMB.'],
    header: 'Successful Active',
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  SpeculativeBillonAlert: new AppMessage({
    body: [
      'Please review and confirm you have properly entered amounts in Thousand. Your entry exceeds 1 billion in the selected currency',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Continue',
        type: ButtonType.primary,
      },
      {
        text: 'Cancel',
        type: ButtonType.secondary,
      },
    ],
  }),
  SpeculativeUnsavedDataToSaveConfirm: new AppMessage({
    body: ['Would you like to save the changes?'],
    header: 'Unsaved Changes',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  PhasingUnsavedDataToSaveConfirm: new AppMessage({
    body: ['Would you like to save the changes?'],
    header: 'Unsaved Changes',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  PhasingUnsavedDataContinueConfirm: new AppMessage({
    body: ['You have unsaved changes. Would you like to close this window?'],
    header: 'Unsaved Changes',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  PhasingAllocateVarianceConfirm: new AppMessage({
    body: ['Would you like the variance(s) to be allocated to the last month?'],
    header: 'Variances must be Zero',
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  PhasingSuccessfulSaved: new AppMessage({
    body: ['Adjusted Phasing was successfully saved in CBP and MMB.'],
    header: 'Successful Save',
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Ok',
        type: ButtonType.primary,
      },
    ],
  }),
  BoardDeleteConfirm: new AppMessage({
    body: ['Are you sure you want to delete this board?'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  FavoriteDeleteConfirm: new AppMessage({
    body: ['Are you sure you want to delete this favorite?'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'Yes',
        type: ButtonType.primary,
      },
      {
        text: 'No',
        type: ButtonType.secondary,
      },
    ],
  }),
  BoardSuccessfulShared: new AppMessage({
    body: ['Your board has been shared.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  BoardSuccessfulSaved: new AppMessage({
    body: ['Your new board has been saved.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  BoardSharedConfirmation: new AppMessage({
    body: [
      '<b>[from]</b> is going to share the <b>[boardName]</b> board.',
      'Would you want to receive it?',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'Confirm',
        type: ButtonType.primary,
      },
      {
        text: 'Cancel',
        type: ButtonType.secondary,
      },
    ],
  }),
  BoardSharedLinkError: new AppMessage({
    body: ['The board does not exist.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faExclamationTriangle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  FavoriteSuccessfulShared: new AppMessage({
    body: ['Your favorite has been shared.'],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp,
    buttons: [
      {
        text: 'OK',
        type: ButtonType.primary,
      },
    ],
  }),
  FavoriteSharedConfirmation: new AppMessage({
    body: [
      '<b>[from]</b> is going to share the <b>[favoriteName]</b> favorite.',
      'Would you want to receive it?',
    ],
    header: DefaultHeader,
    type: MessageType.info,
    close: false,
    icon: faInfoCircle as IconProp as IconProp,
    buttons: [
      {
        text: 'Confirm',
        type: ButtonType.primary,
      },
      {
        text: 'Cancel',
        type: ButtonType.secondary,
      },
    ],
  }),
};
