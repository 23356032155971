import { assign } from 'lodash';

import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';

class OpportunityPhasingModalConfigSchema {
  opportunityId: number;
  dataSet: string;
  isReadOnly: boolean;
  isAdmin: boolean;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
}

export class OpportunityPhasingModalConfig extends OpportunityPhasingModalConfigSchema {
  constructor(filterAttributes?: OpportunityPhasingModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
