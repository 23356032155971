import { Periods } from 'src/app/shared/constants/filters.constants';
import { ViewOptions } from 'src/app/shared/constants/grid.constants';
import { AppModulePaths } from 'src/app/shared/constants/navbar.constants';
import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

export const TabControlNames = {
  actualSales: AppModulePaths.ieopportunity.subtabs.actualsales.path,
  periods: 'period',
  targets: 'target',
  expand: 'expand',
  noActivity: 'noActivity',
};

export const TabFilters = {
  Periods: [
    new TextValuePair({
      text: Periods.Quarter.text,
      value: Periods.Quarter.id,
    }),
    new TextValuePair({
      text: Periods.Month.text,
      value: Periods.Month.id,
    }),
  ],
  TargetOptions: (selectedFilters: SelectedFilters) => {
    return [
      new TextValuePair({
        text: 'Projection',
        value: ViewOptions.Current,
      }),
      new TextValuePair({
        text: selectedFilters.projection.getText(),
        value: ViewOptions.Projection,
      }),
      new TextValuePair({
        text: `Projection vs ${selectedFilters.projection.getText()} Variance`,
        value: ViewOptions.CompareProjection,
      }),
    ];
  },
};
