import { assign } from 'lodash';

import { TargetsTile } from '../../../../entities/targets-tile';

class TargetsChartConfigSchema {
  tile: TargetsTile;
  allowPercentage: boolean;
  isPercentage: boolean;
}

export class TargetsChartConfig extends TargetsChartConfigSchema {
  constructor(filterAttributes?: TargetsChartConfigSchema) {
    super();
    assign(this, filterAttributes);
  }
}
