import { assign } from 'lodash';
import { GridData } from 'src/app/shared/components/base/grid/entities/grid-data';

class MetricTotalChangedSchema {
  metricName: string;
  value: number;
}

export class MetricTotalChanged extends MetricTotalChangedSchema {
  constructor(filterAttributes?: MetricTotalChangedSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class SpeculativePhasingChangedSchema {
  hasErrors: boolean;
  hasVarianceAllocated: boolean;
  hasChanged: boolean;
  phasingMonths: GridData;
}

export class SpeculativePhasingChanged extends SpeculativePhasingChangedSchema {
  constructor(filterAttributes?: SpeculativePhasingChangedSchema) {
    super();
    assign(this, filterAttributes);
  }

  isValid(): boolean {
    return !this.hasErrors && !this.hasVarianceAllocated;
  }
}
