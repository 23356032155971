import { assign, cloneDeep, intersectionBy, isUndefined } from 'lodash';
import { TimeframeItem } from '../../components/timeframe/entities/timeframe';

import { TimeframeUtils } from '../../components/timeframe/utils/timeframe.utils';
import { CustomCurrencies } from '../../constants/filters.constants';
import { ActiveDate } from './filters/active-date';
import { ClientGroup } from './filters/client-group';
import { Currency } from './filters/currency';
import { Customer } from './filters/customer';
import { CustomerFilters } from './filters/customer-filters';
import { MmbDate } from './filters/date';
import { Filters } from './filters/filters';
import { FinancialCustomer } from './filters/financial-customer';
import { Industry } from './filters/industry';
import { Location } from './filters/location';
import { ProductHierarchy } from './filters/product-hierarchy';
import { SelectedFilters } from './filters/selected-filters';
import { ServiceGroupValues } from './filters/service-group';
import { Timeframe } from './filters/timeframe';
import { Wmu } from './filters/wmu';

class FavoriteResponseItemSchema {
  favoriteId: string;
  name: string;
  filters: FavoriteFilters;
  default?: boolean;
  sharedBy?: string;
  metric: string;
  period: string;
  group1: string;
  group2?: string;
  group3?: string;
  target1?: string;
  target2?: string;
}

export class FavoriteResponseItem extends FavoriteResponseItemSchema {
  constructor(filterAttributes?: FavoriteResponseItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  isNew(): boolean {
    return this.favoriteId === '' || isUndefined(this.favoriteId);
  }
}

class FavoriteFiltersSchema {
  Customer: Customer;
  Currency: Currency;
  Timeframe: Timeframe;
  Sgi?: Array<ProductHierarchy>;
  Functions?: Array<ProductHierarchy>;
  Alliances?: Array<ProductHierarchy>;
  Industries?: Array<Industry>;
  ServiceGroups?: Array<ServiceGroupValues>;
  Locations?: Array<Location>;
  ClientServiceGroups?: Array<ClientGroup>;
  Wmus?: Array<Wmu>;
  FinancialCustomer?: Array<FinancialCustomer>;
}

export class FavoriteFilters extends FavoriteFiltersSchema {
  constructor(filterAttributes?: FavoriteFiltersSchema) {
    super();
    assign(this, filterAttributes);
  }

  setFilters(selectedFilters: SelectedFilters): void {
    const copy: SelectedFilters = cloneDeep(selectedFilters);
    this.Customer = copy.customer;
    this.Currency = copy.currency;
    this.Timeframe = new Timeframe({
      EndTimeId: copy.timeframe.end,
      StartTimeId: copy.timeframe.start,
      TimePeriodCode: copy.timeframe.code,
      Name: copy.timeframe.title,
      Description: copy.timeframe.getDescription(),
      FiscalYear: copy.timeframe.fiscalYear,
    });
    this.Sgi = copy.growthPriorities;
    this.Functions = copy.functions;
    this.Alliances = copy.alliances;
    this.Industries = copy.industries;
    this.ServiceGroups = copy.serviceGroups;
    this.Locations = copy.markets;
    this.ClientServiceGroups = copy.clientGroups;
    this.Wmus = copy.wmus;
    this.FinancialCustomer = copy.financialCustomers;
  }

  toSelectedFilters(
    filters: Filters,
    selectedFilters: SelectedFilters,
    customerFilters: CustomerFilters,
    favorite: FavoriteResponseItem
  ): SelectedFilters {
    const copy: SelectedFilters = cloneDeep(selectedFilters);
    const appDate: ActiveDate = TimeframeUtils.getAppDate(filters);
    const appDateTime: MmbDate = filters.dates.find(
      (x: MmbDate) => x.Id === appDate.StartTimeId
    );
    copy.currency = this.Currency;
    copy.timeframe = !favorite.filters.Timeframe.TimePeriodCode.includes(
      'Custom'
    )
      ? TimeframeUtils.getTimeframeItemByCode(
          filters,
          this.Timeframe.TimePeriodCode
        )
      : new TimeframeItem({
          start: favorite.filters.Timeframe.StartTimeId as number,
          end: favorite.filters.Timeframe.EndTimeId as number,
          code: 'Custom',
          mmbPeriod: 'Custom',
          title: 'Custom',
          type: 'Custom',
          fiscalYear: appDateTime.FiscalYearNbr,
          enabled: true,
          isPast: appDate.StartTimeId > favorite.filters.Timeframe.EndTimeId,
        });

    copy.growthPriorities = intersectionBy(
      filters.sgi,
      this.Sgi,
      'ProductCode'
    );
    copy.functions = intersectionBy(
      filters.functions,
      this.Functions,
      'ProductCode'
    );
    copy.alliances = intersectionBy(
      filters.alliances,
      this.Alliances,
      'ProductCode'
    );
    copy.industries = intersectionBy(
      filters.industry,
      this.Industries,
      'EntityCode'
    );
    copy.serviceGroups = intersectionBy(
      filters.serviceGroups.ServiceGroups,
      this.ServiceGroups,
      'ServiceGroupCd'
    );
    copy.markets = intersectionBy(
      filters.locations,
      this.Locations,
      'OrganizationId'
    );
    copy.clientGroups = intersectionBy(
      customerFilters.csg,
      this.ClientServiceGroups,
      'ClientGroupCd'
    );
    copy.wmus = intersectionBy(customerFilters.wmu, this.Wmus, 'ID');
    copy.financialCustomers = intersectionBy(
      customerFilters.financialCustomer,
      this.FinancialCustomer,
      'CustomerNumber'
    );
    return copy;
  }

  setDefaultFilters(filters: Filters, selectedFilters: SelectedFilters): void {
    this.Customer = selectedFilters.customer;

    this.Timeframe = new Timeframe({
      EndTimeId: selectedFilters.defaultTimeframe.end,
      StartTimeId: selectedFilters.defaultTimeframe.start,
      TimePeriodCode: selectedFilters.defaultTimeframe.code,
      Name: selectedFilters.defaultTimeframe.title,
      Description: selectedFilters.defaultTimeframe.getDescription(),
    });

    this.Currency = filters.currencies.find(
      (x: Currency) => x.Id === CustomCurrencies.Global.id
    );
  }
}
