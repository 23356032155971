<div class="ddl-control d-inline-block" ngbDropdown #boardsDropdown="ngbDropdown" placement="bottom-center">
    <div class="board-icon" id="ddlBoards" ngbDropdownToggle>
        <fa-icon [icon]="faEdit">
        </fa-icon>
        <span>MY BOARDS ({{boards ? boards.length - 1 : 0}})</span>
    </div>
    <div ngbDropdownMenu aria-labelledby="ddlBoards" class="ddl-content">
        <app-spinner name="board-picker-spinner" [showSpinner]="!loaded" [fullScreen]="false" spinnerColor="#3e4851">
        </app-spinner>
        <div *ngIf="loaded" class="ddl-header"><span>Default</span></div>
        <div *ngIf="loaded" class="ddl-inputs">
            <ul class="boards-items">
                <li *ngFor="let b of boards" class="boards-item">
                    <div class="board-item">
                        <div class="board-header" (click)="onSelect(b)">
                            <fa-icon [icon]="b.default ? faStarPainted : faStar" id="favorite"
                                (click)="onDefaultChanged(b)"></fa-icon>
                            <span>{{b.name}}</span>
                        </div>
                        <div *ngIf="b.name !== noneBoardName" class="board-actions">
                            <fa-icon [icon]="faShare" title="Share Board" id="share" class="mr-2" (click)="onShare(b)">
                            </fa-icon>
                            <fa-icon [icon]="faEdit" title="Edit Board" id="edit" class="mr-2" (click)="onEdit(b)">
                            </fa-icon>
                            <fa-icon [icon]="faTrash" title="Delete Board" id="delete" class="mr-2"
                                (click)="onDelete(b)">
                            </fa-icon>
                            <fa-icon [icon]="faCopy" title="Duplicate Board" id="content_copy"
                                (click)="onEdit(b, true)"></fa-icon>
                        </div>
                    </div>
                </li>
            </ul>
            <div>
                <button class="btn btn-primary" (click)="onOpenBoardModal()">Add Board</button>
            </div>
        </div>
    </div>
</div>
<app-share-board-modal></app-share-board-modal>
