import { assign } from 'lodash';

import {
  BoardFilters,
  BoardTab,
} from 'src/app/shared/services/entities/board-response';
import { CustomerFilters } from 'src/app/shared/services/entities/filters/customer-filters';
import { Filters } from 'src/app/shared/services/entities/filters/filters';
import { SelectedFilters } from 'src/app/shared/services/entities/filters/selected-filters';

class BoardModalConfigSchema {
  boardId: string;
  name: string;
  tab?: BoardTab;
  filters: Filters;
  selectedFilters: SelectedFilters;
  customerFilters: CustomerFilters;
  boardFilters: BoardFilters;
  // currency: Currency;
  // timeframe: any;
  default?: boolean;
}

export class BoardModalConfig extends BoardModalConfigSchema {
  constructor(filterAttributes?: BoardModalConfigSchema) {
    super();
    assign(this, filterAttributes);
  }

  isNew(): boolean {
    return !this.boardId || this.boardId === '';
  }
}
