import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { TreeViewConfig } from './entities/tree-view-config';
import { TreeViewItem } from './entities/tree-view-item';
import { TreeViewSelection } from './entities/tree-view-selection';
import { TreeViewUtils } from './utils/tree-view.utils';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
})
export class TreeViewComponent implements OnInit, OnChanges, OnDestroy {
  subscription = new Subscription();
  @Input() config: TreeViewConfig;
  @Input() searchText: string;
  @Input() onSelectAllChangedObs: Observable<boolean>;
  @Input() startSelection: TreeViewSelection;
  @Output() itemSelectionChangeEvent = new EventEmitter<TreeViewSelection>();
  searchItems: Array<TreeViewItem>;
  itemSelectionEmitterTimeout: any;

  ngOnInit(): void {
    this.addSubscriptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.searchText &&
      changes.searchText.currentValue !== changes.searchText.previousValue
    ) {
      this.onSearchTextChanged();
    }

    if (
      (changes.startSelection && changes.startSelection.currentValue) ||
      (changes.config && changes.config.currentValue)
    ) {
      this.onSelectedItemsChanged();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addSubscriptions(): void {
    if (this.onSelectAllChangedObs) {
      this.subscription.add(
        this.onSelectAllChangedObs.subscribe((x: boolean) => {
          TreeViewUtils.iterateItems(this.config.items, (i: TreeViewItem) => {
            i.checked = x;
          });
          this.itemSelectionChangeEvent.emit(
            new TreeViewSelection({
              selectedItems: this.getSelectedItems(),
              count: this.getSelectedCount(),
            })
          );
        })
      );
    }
  }

  onItemSelection(item?: TreeViewItem): void {
    clearTimeout(this.itemSelectionEmitterTimeout);
    this.itemSelectionEmitterTimeout = setTimeout(() => {
      this.itemSelectionChangeEvent.emit(
        new TreeViewSelection({
          selectedItems: this.getSelectedItems(),
          count: this.getSelectedCount(),
        })
      );
    }, 100);
  }

  onSearchTextChanged(): void {
    if (this.searchText?.length === 0) {
      this.searchItems = null;
      this.searchItems = this.config.items;
    } else {
      this.searchItems = TreeViewUtils.search(
        this.config.items,
        this.searchText
      );
    }
  }

  getSelectedCount(): number {
    let count = 0;

    if (this.config.countByNodes) {
      count = TreeViewUtils.getSelectedCountByNodes(this.config.items);
    } else {
      TreeViewUtils.iterateItems(this.config.items, (x: TreeViewItem) => {
        if (x.checked) {
          count++;
        }
      });
    }

    return count;
  }

  getSelectedItems(): Array<TreeViewItem> {
    let result: Array<TreeViewItem> = [];

    if (this.config.selectByNodes) {
      result = TreeViewUtils.getSelectedNodes(this.config.items);
    } else {
      TreeViewUtils.iterateItems(this.config.items, (x: TreeViewItem) => {
        if (x.checked) {
          result.push(x);
        }
      });
    }

    return result;
  }

  onSelectedItemsChanged(): void {
    if (this.config && this.config.items && this.config.items.length > 0) {
      TreeViewUtils.iterateItems(this.config.items, (x: TreeViewItem) => {
        x.checked = this.startSelection?.selectedItems?.some(
          (i: TreeViewItem) => i.value === x.value
        );

        if (x.checked) {
          if (x.hasChildren() && !this.config.decoupleParentFromChildren) {
            TreeViewUtils.iterateItems(x.children, (c: TreeViewItem) => {
              c.checked = true;
            });
          }

          TreeViewUtils.expandNode(x, true);
        }
      });

      this.onItemSelection();
    }
  }
}
