import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnChanges {
  @Input() searchText: string;
  @Input() placeholder = '';
  @Input() maxLength = 50;
  @Input() isSmall = true;
  @Output() searchEvent = new EventEmitter<string>();

  faSearch = faSearch as IconProp;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.searchText && changes.searchText.currentValue) {
      this.onSearchTextChanged(changes.searchText.currentValue);
    }
  }

  onSearchTextChanged(searchText: string): void {
    this.searchEvent.emit(searchText);
  }
}
