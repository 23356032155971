<div>
    <div>
        <select class="ag-filter-select" [(ngModel)]="selectedFilter" (ngModelChange)="onFilterChanged()">
            <option value="equals">Equals</option>
            <option value="notEqual">Not equal</option>
            <option value="lessThan">Less than</option>
            <option value="lessThanOrEqual">Less than or equals</option>
            <option value="greaterThan">Greater than</option>
            <option value="greaterThanOrEqual">Greater than or equals</option>
            <option value="inRange">In range</option>
        </select>
    </div>
    <div class="ag-filter-body">
        <input class="ag-filter-filter" type="text" (ngModelChange)="onFirstNumberChanged($event)" [ngModel]="firstNum"
            placeholder="Filter...">
        <input *ngIf="selectedFilter === 'inRange'" class="ag-filter-filter" type="text"
            (ngModelChange)="onSecondNumberChanged($event)" [ngModel]="secondNum" placeholder="Filter...">
    </div>
</div>