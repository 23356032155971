import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TabGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const tabName: string = route.data.tabName;
    const entry: [string, boolean] = Object.entries(
      environment.tabsAvailability
    ).find((x: [string, boolean]) => x[0] === tabName);
    return of(entry ? entry[1] : false);
  }
}
