import {
  cloneDeep,
  flatMap,
  isNull,
  isUndefined,
  remove,
  sortBy,
  uniq,
} from 'lodash';
import {
  CompositionOptions,
  GroupsBy,
  SubMetrics,
} from 'src/app/shared/constants/filters.constants';
import { ValueType } from 'src/app/shared/constants/common.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import {
  TrendTable,
  TrendTableRecord,
  TrendTableSet,
} from '../entities/trend-table-data';
import { TrendTileDefinition } from '../entities/trend-tile-definition';
import {
  TrendTileResponse,
  TrendTileResponseData,
  TrendTileResponseSubMetric,
  TrendTileResponseValue,
} from '../entities/trend-tile-response';
import { TrendFilters } from '../trend-tile/components/trend-filters/entities/trend-filters';
import { TrendUtils } from './trend.utils';
import { MetricType } from 'src/app/shared/constants/metrics.constants';
import {
  OpportunityTypes,
  ViewOptions,
} from 'src/app/shared/constants/grid.constants';
import { CalcUtils } from 'src/app/core/utils/calc.utils';

export class TrendTableUtils {
  static getTableData(
    definition: TrendTileDefinition,
    source: TrendTileResponse,
    trendFilters: TrendFilters,
    dates: Array<MmbDate>
  ): TrendTable {
    const table = new TrendTable();
    const tableData: TrendTileResponse = cloneDeep(source);
    const current: TrendTileResponseSubMetric = tableData.Current;
    const target: TrendTileResponseSubMetric = (tableData as any)[
      trendFilters.subMetric.value
    ];
    const isPlan: boolean = trendFilters.subMetric.value === SubMetrics.Plan;

    table.periods = TrendUtils.transformLabels<TrendTileResponseValue>(
      current.totalsByPeriods,
      'name',
      trendFilters,
      dates
    ).map((x: TrendTileResponseValue) => x.name);

    table.sets = table.sets ?? [];
    const currentSet: TrendTableSet = this.getTableDataSet(
      current,
      definition,
      trendFilters,
      true,
      isPlan
    );
    const targetSet: TrendTableSet = this.getTableDataSet(
      target,
      definition,
      trendFilters,
      false,
      isPlan
    );
    const varianceSet: TrendTableSet = currentSet.getVariance(
      targetSet,
      definition,
      trendFilters
    );

    table.sets.push(...[currentSet, targetSet, varianceSet]);

    this.cleanTable(table);

    return table;
  }

  private static getTableDataSet(
    subMetricData: TrendTileResponseSubMetric,
    definition: TrendTileDefinition,
    trendFilters: TrendFilters,
    isCurrent: boolean,
    isPlan: boolean
  ): TrendTableSet {
    const isComposition: boolean =
      trendFilters.groupBy.value === GroupsBy.Composition.id;
    const periods: Array<string> = sortBy(
      subMetricData.totalsByPeriods,
      'name'
    ).map((x: TrendTileResponseValue) => x.name);

    const set = new TrendTableSet();
    set.title = isCurrent ? SubMetrics.Projection : trendFilters.subMetric.text;
    set.totalsByPeriod = sortBy(subMetricData.totalsByPeriods, 'name').map(
      (x: TrendTileResponseValue) => x.value
    );

    set.total = subMetricData.totalsAll.find(
      (x: TrendTileResponseValue) => x.name === 'Totals'
    )?.value;
    set.records = set.records ?? [];
    set.valueType = trendFilters.isPercentage
      ? ValueType.Percentage
      : ValueType.Numeric;

    if (isComposition) {
      const setData: Array<TrendTileResponseValue> = flatMap(
        sortBy(subMetricData.data, 'GroupKey').map(
          (x: TrendTileResponseData) => x.data
        )
      );
      uniq(setData.map((x: TrendTileResponseValue) => x.name)).map(
        (x: string) => {
          let total: number =
            isPlan && !isCurrent
              ? null
              : subMetricData.totalsAll.find(
                  (y: TrendTileResponseValue) => y.name === x
                )?.value;

          total = total
            ? trendFilters.isPercentage &&
              !TrendUtils.needsMultiply(x) &&
              trendFilters.isCumulative
              ? total / 100
              : total
            : null;

          const valuesByPeriod: Array<number> = setData
            .filter((y: TrendTileResponseValue) => y.name === x)
            .map((y: TrendTileResponseValue) =>
              y.value && (isCurrent || !isPlan)
                ? trendFilters.isPercentage && !TrendUtils.needsMultiply(y.name)
                  ? y.value / 100
                  : y.value
                : null
            );

          const linkConfig: Record<string, any> = this.getCompositionOptionLink(
            x,
            definition,
            trendFilters,
            isCurrent
          );

          set.records.push(
            new TrendTableRecord({
              title: x,
              total,
              valuesByPeriod,
              link: linkConfig.link,
              linkParams: linkConfig.linkParams,
            })
          );
        }
      );
    } else {
      let setData: Array<TrendTileResponseData> = flatMap(subMetricData.data);

      if (this.alphabeticallyOrderRequired(trendFilters.groupBy.value)) {
        setData = sortBy(subMetricData.data, 'GroupKey');
      }

      uniq(setData.map((x: TrendTileResponseData) => x.GroupKey)).map(
        (x: string) => {
          const values: Array<number> = periods.map((y: string) => {
            const response: TrendTileResponseData = setData.find(
              (z: TrendTileResponseData) =>
                z.GroupKey === x && z.ParentGroupKey === y && z.data?.length > 0
            );
            return response?.data.find((z: TrendTileResponseValue) =>
              z.name.includes(
                TrendUtils.getSubMetricKpiKey(
                  definition.type,
                  trendFilters.isPercentage
                )
              )
            )?.value;
          });

          const totalResponse: TrendTileResponseValue =
            subMetricData.totalsAll.find(
              (y: TrendTileResponseValue) => y.name === x
            );

          const total: number =
            trendFilters.isPercentage && trendFilters.isCumulative
              ? CalcUtils.calculateCustomPercentage(
                  totalResponse?.value.find((y: TrendTileResponseValue) =>
                    y.name.includes(
                      TrendUtils.getSubMetricKpiKey(MetricType.Cci, false)
                    )
                  )?.value,
                  totalResponse?.value.find((y: TrendTileResponseValue) =>
                    y.name.includes(
                      TrendUtils.getSubMetricKpiKey(MetricType.Revenue, false)
                    )
                  )?.value
                ) / 100
              : totalResponse?.value;

          set.records.push(
            new TrendTableRecord({
              title: x.split('---')[0],
              total: total,
              valuesByPeriod:
                trendFilters.isPercentage && !TrendUtils.needsMultiply(x)
                  ? values.map((y: number) => (y ? y / 100 : null))
                  : values,
            })
          );
        }
      );
    }

    return set;
  }

  private static cleanTable(table: TrendTable): void {
    if (table.sets?.length > 0) {
      table.sets[0].records
        .map((w: TrendTableRecord) => w.title)
        .forEach((w: string) => {
          if (
            table.sets.every((x: TrendTableSet) =>
              x.records
                .find((y: TrendTableRecord) => y.title === w)
                .valuesByPeriod.every(
                  (z: number) => z === 0 || isUndefined(z) || isNull(z)
                )
            )
          ) {
            table.sets.forEach((x: TrendTableSet) => {
              remove(x.records, (y: TrendTableRecord) => y.title === w);
            });
          }
        });
    }
  }

  private static alphabeticallyOrderRequired(groupBy: string): boolean {
    const groupsBy: Array<string> = [
      GroupsBy.ClientGroup.id,
      GroupsBy.Market.id,
      GroupsBy.Location.id,
      GroupsBy.WMU.id,
      GroupsBy.Industry.id,
    ];
    return groupsBy.includes(groupBy);
  }

  private static getCompositionOptionLink(
    option: string,
    definition: TrendTileDefinition,
    trendFilters: TrendFilters,
    isCurrent: boolean
  ): Record<string, any> {
    const linkConfig: Record<string, any> = {};

    if (trendFilters.subMetric.value !== SubMetrics.Plan || isCurrent) {
      linkConfig.linkParams = {
        metric: definition.type,
        period: trendFilters.period.value,
        isPercentage: trendFilters.isPercentage,
        target: isCurrent ? ViewOptions.Current : ViewOptions.Projection,
      };

      switch (option) {
        case CompositionOptions.Actuals:
          linkConfig.link =
            definition.type === MetricType.Sales
              ? '/ieopportunity/actualsales'
              : '/backlog';
          break;
        case CompositionOptions.WinsThisMonth:
          linkConfig.link = '/ieopportunity/wins';
          break;
        case CompositionOptions.Backlog:
          linkConfig.link = '/backlog';
          break;
        case CompositionOptions.QualProjOpps:
          linkConfig.link =
            definition.type === MetricType.Sales
              ? '/ieopportunity/opportunities'
              : '/phasing';
          linkConfig.linkParams.stage = OpportunityTypes.Qualified;
          break;
        case CompositionOptions.UnqualProjOpps:
          linkConfig.link =
            definition.type === MetricType.Sales
              ? '/ieopportunity/opportunities'
              : '/phasing';
          linkConfig.linkParams.stage = OpportunityTypes.Unqualified;
          break;
        case CompositionOptions.Speculative:
          linkConfig.link = '/speculative';
          break;
      }
    } else {
      linkConfig.link = null;
      linkConfig.linkParams = null;
    }

    return linkConfig;
  }
}
