import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomAuthGuard } from './core/guards/custom-auth.guard';
import { AnalyticsComponent } from './modules/analytics/analytics.component';
import { BacklogComponent } from './modules/backlog/backlog.component';
import { ActualSalesComponent } from './modules/ieopportunity/actualsales/actualsales.component';
import { IeopportunityComponent } from './modules/ieopportunity/ieopportunity.component';
import { OpportunitiesComponent } from './modules/ieopportunity/opportunities/opportunities.component';
import { WinsComponent } from './modules/ieopportunity/wins/wins.component';
import { ImpersonationComponent } from './modules/impersonation/impersonation.component';
import { OverviewComponent } from './modules/overview/overview.component';
import { PhasingComponent } from './modules/phasing/phasing.component';
import { SpeculativeComponent } from './modules/speculative/speculative.component';
import { ShareBoardComponent } from './shared/components/board-picker/components/share-board/share-board.component';
import { AppModulePaths } from './shared/constants/navbar.constants';
import { MaintenanceModeGuard } from './shared/guards/maintenance-mode.guard';
import { TabGuard } from './shared/guards/tab.guard';

const routes: Routes = [
  {
    path: AppModulePaths.overview.path,
    component: OverviewComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.overview.id },
  },
  {
    path: AppModulePaths.analytics.path,
    component: AnalyticsComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.analytics.id },
  },
  {
    path: AppModulePaths.backlog.path,
    component: BacklogComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.backlog.id },
  },
  {
    path: AppModulePaths.ieopportunity.path,
    component: IeopportunityComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.ieopportunity.path },
    canActivateChild: [MaintenanceModeGuard, CustomAuthGuard],
    children: [
      {
        path: AppModulePaths.ieopportunity.subtabs.opportunities.path,
        component: OpportunitiesComponent,
      },
      {
        path: AppModulePaths.ieopportunity.subtabs.actualsales.path,
        component: ActualSalesComponent,
      },
      {
        path: AppModulePaths.ieopportunity.subtabs.wins.path,
        component: WinsComponent,
      },
      {
        path: '',
        redirectTo: AppModulePaths.ieopportunity.subtabs.opportunities.path,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: AppModulePaths.phasing.path,
    component: PhasingComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.phasing.id },
  },
  {
    path: AppModulePaths.speculative.path,
    component: SpeculativeComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard, TabGuard],
    data: { tabName: AppModulePaths.speculative.id },
  },
  {
    path: 'user/:user',
    component: ImpersonationComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard],
  },
  {
    path: 'remove-user/:remove-user',
    component: ImpersonationComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard],
  },
  {
    path: 'share-board/:peopleKey/:customer/:boardId',
    component: ShareBoardComponent,
    canActivate: [MaintenanceModeGuard, CustomAuthGuard],
  },
  {
    path: '',
    redirectTo: AppModulePaths.overview.path,
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: AppModulePaths.overview.path,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
