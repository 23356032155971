import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { TextValuePair } from 'src/app/shared/services/entities/common/key-value';
import { DropDownType } from 'src/app/shared/constants/filters.constants';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnChanges {
  @Input() items: Array<TextValuePair> = [];
  @Input() selectedItem: TextValuePair;
  @Input() type: DropDownType = DropDownType.LinkBold;
  @Input() placeholder = '';
  @Output() itemSelectionChangeEvent = new EventEmitter<TextValuePair>();

  id: string = uuidv4();

  faCheckCircle = faCheckCircle as IconProp;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items && changes.items.currentValue) {
      this.initializeComponent();
    }
  }

  onValueChanged(value: TextValuePair): void {
    this.selectedItem = value;
    this.itemSelectionChangeEvent.emit(value);
  }

  initializeComponent(): void {
    if (this.items && this.items.length > 0 && !this.selectedItem) {
      this.selectedItem = this.items[0];
    }
  }

  getClassName(): string {
    switch (this.type) {
      case DropDownType.Button:
        return 'btn-secondary';
      case DropDownType.Link:
        return 'btn-link link';
      case DropDownType.LinkBold:
        return 'btn-link link-bold';
      default:
        return '';
    }
  }

  getPlaceholder(): string {
    if (this.selectedItem) {
      if (this.placeholder) {
        return this.placeholder.replace('#value', this.selectedItem.text);
      }

      return this.selectedItem.text;
    }

    return '';
  }
}
