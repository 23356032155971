import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ReplaySubject, Subscription } from 'rxjs';
import { isNull, isNumber, replace } from 'lodash';

import { CellValueChangedEvent, ColDef, GridOptions } from 'ag-grid';
import { GridConfig } from '../../../base/grid/entities/grid-config';
import { GridObservables } from '../../../base/grid/entities/grid-observables';
import { GridColDefs } from './constants/speculative-edit-grid.constants';
import { SpeculativeEditGridConfig } from './entities/speculative-edit-grid-config';
import { SpeculativeEditGridUtils } from './utils/speculative-edit-grid.utils';
import { GridData } from '../../../base/grid/entities/grid-data';
import { SubMetrics } from 'src/app/shared/constants/filters.constants';
import { MmbDate } from 'src/app/shared/services/entities/filters/date';
import {
  MetricTotalChanged,
  SpeculativePhasingChanged,
} from './entities/speculative-edit-grid-events';
import { SpeculativePhasingPeriod } from 'src/app/shared/services/entities/grids/speculative-response';

@Component({
  selector: 'app-speculative-edit-grid',
  templateUrl: './speculative-edit-grid.component.html',
  styleUrls: ['./speculative-edit-grid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpeculativeEditGridComponent implements OnChanges, OnDestroy {
  @Input() config: SpeculativeEditGridConfig;
  @Output() phasingChangedEvent = new EventEmitter<SpeculativePhasingChanged>();

  subscription = new Subscription();

  updateRowDataEvent = new ReplaySubject<GridData>(1);
  updateColDefsEvent = new ReplaySubject<Array<ColDef>>(1);

  editGridConfig: GridConfig;
  gridData: GridData;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && changes.config.currentValue) {
      this.initializeGridConfig();
      this.getData();

      if (changes.config.firstChange) {
        this.phasingChangedEvent.emit(
          new SpeculativePhasingChanged({
            phasingMonths: this.gridData,
            hasChanged: this.config.isDuplicate,
            hasErrors: false,
            hasVarianceAllocated: false,
          })
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initializeGridConfig(): void {
    const options: GridOptions = GridColDefs.getExtraGridOptions(
      this.config.speculative
    );
    options.context = this;

    this.editGridConfig =
      this.editGridConfig ??
      new GridConfig({
        columns: [],
        options,
        observables: new GridObservables({
          updateColDefs: this.updateColDefsEvent,
          updateRowData: this.updateRowDataEvent,
        }),
        expandable: false,
      });

    this.addSubscriptions();
    this.updateColDefs();
  }

  updateColDefs(): void {
    this.updateColDefsEvent.next(
      GridColDefs.getColDefs(
        this.config.filters,
        this.config.selectedFilters,
        this.config.customerFilters
      )
    );
  }

  addSubscriptions(): void {
    this.subscription.add(
      this.editGridConfig.observables.cellValueChanged.subscribe(
        (x: CellValueChangedEvent) => {
          this.onCellValueChanged(x);
        }
      )
    );

    if (this.config.metricTotalChanged) {
      this.subscription.add(
        this.config.metricTotalChanged.subscribe((x: MetricTotalChanged) => {
          this.onMetricTotalChanged(x);
        })
      );
    }
  }

  getData(): void {
    this.gridData = new GridData({
      rows: SpeculativeEditGridUtils.mapData(
        this.config.speculative,
        this.config.filters
      ),
      pinnedTop: [],
    });

    this.updateRowDataEvent.next(this.gridData);
  }

  sumRowData(row: Record<string, any>): number {
    if (row) {
      const months: Array<MmbDate> = SpeculativeEditGridUtils.getMonths(
        this.config.filters
      );
      let sum = 0;

      months.forEach((x: MmbDate) => {
        sum += isNumber(row[x.Id.toString()]) ? row[x.Id.toString()] : 0;
      });

      return sum + row.beyond;
    }

    return 0;
  }

  setRowDataValue(row: Record<string, any>, value: number): number {
    if (row) {
      const months: Array<MmbDate> = SpeculativeEditGridUtils.getMonths(
        this.config.filters
      );

      months.forEach((x: MmbDate) => {
        row[x.Id.toString()] = value;
      });

      row.beyond = value;
    }

    return 0;
  }

  getRow(metric: string): Record<string, any> {
    return this.gridData.rows.find(
      (x: Record<string, any>) =>
        x.metric === metric && !x.name.includes(SubMetrics.Projection)
    );
  }

  getGridStatusEvent(): SpeculativePhasingChanged {
    const event = new SpeculativePhasingChanged({
      phasingMonths: this.gridData,
      hasChanged: false,
      hasErrors: false,
      hasVarianceAllocated: false,
    });

    const metrics: Array<string> = ['sales', 'revenue', 'cci'];
    const months: Array<MmbDate> = SpeculativeEditGridUtils.getMonths(
      this.config.filters
    );

    metrics.forEach((x: string) => {
      const row: Record<string, any> = this.getRow(x);
      event.hasChanged =
        this.config.isDuplicate ||
        event.hasChanged ||
        this.hasRowChanged(row, months);
      event.hasErrors = event.hasErrors || this.hasRowWithErrors(row, months);
      event.hasVarianceAllocated =
        event.hasVarianceAllocated || this.hasRowWithVarianceAllocated(row);
    });

    return event;
  }

  hasRowChanged(row: Record<string, any>, months: Array<MmbDate>): boolean {
    for (let i = 0; i < months.length; i++) {
      const month: MmbDate = months[i];
      const original: SpeculativePhasingPeriod =
        this.config.speculative.phasingMonthly.find(
          (x: SpeculativePhasingPeriod) => x.timeId === month.Id
        );

      if (
        (!original && Number(row[month.Id.toString()]) !== 0) ||
        (original && original[row.metric] !== Number(row[month.Id.toString()]))
      ) {
        return true;
      }
    }

    return false;
  }

  hasRowWithErrors(row: Record<string, any>, months: Array<MmbDate>): boolean {
    for (let i = 0; i < months.length; i++) {
      const month: MmbDate = months[i];
      const value = Number(row[month.Id.toString()]);

      if (
        !isNull(value) &&
        (!isNumber(value) || isNaN(Number(replace(value?.toString(), /,/, ''))))
      ) {
        return true;
      }
    }

    return false;
  }

  hasRowWithVarianceAllocated(row: Record<string, any>): boolean {
    return row.variance !== 0;
  }

  onCellValueChanged(event: CellValueChangedEvent): void {
    if (event && Number(event.oldValue) !== Number(event.newValue)) {
      const row: Record<string, any> = this.getRow(event.data.metric);

      if (row) {
        // Calculate CCI% by period (column by column)
        if (['revenue', 'cci'].includes(row.metric)) {
          const revenueRow: Record<string, any> = this.getRow('revenue');
          const cciRow: Record<string, any> = this.getRow('cci');
          const cciPercentageRow: Record<string, any> =
            this.getRow('cciPercentage');
          const months: Array<MmbDate> = SpeculativeEditGridUtils.getMonths(
            this.config.filters
          );

          months.forEach((x: MmbDate) => {
            cciPercentageRow[x.Id.toString()] =
              Number(revenueRow[x.Id.toString()]) !== 0
                ? (Number(cciRow[x.Id.toString()]) /
                    Math.abs(Number(revenueRow[x.Id.toString()]))) *
                  100
                : 0;
          });
        }

        const sum: number = this.sumRowData(row);
        row.variance = row.total - sum;
        this.updateRowDataEvent.next(this.gridData);
        this.phasingChangedEvent.emit(this.getGridStatusEvent());
      }
    }
  }

  onMetricTotalChanged(total: MetricTotalChanged): void {
    const row: Record<string, any> = this.getRow(total.metricName);

    if (total.value === 0) {
      this.setRowDataValue(row, 0);
    }

    if (['revenue', 'cci'].includes(total.metricName)) {
      // Calculate Total CCI%
      const revenueRow: Record<string, any> = this.getRow('revenue');
      const cciRow: Record<string, any> = this.getRow('cci');
      const cciPercentageRow: Record<string, any> =
        this.getRow('cciPercentage');

      const revenueValue: number =
        total.metricName === 'revenue' ? total.value : revenueRow.total;

      const cciValue: number =
        total.metricName === 'cci' ? total.value : cciRow.total;

      cciPercentageRow.total =
        revenueValue !== 0 ? (cciValue / Math.abs(revenueValue)) * 100 : 0;
    }

    const sum: number = this.sumRowData(row);
    row.total = total.value;
    row.variance = row.total - sum;
    this.updateRowDataEvent.next(this.gridData);
    this.phasingChangedEvent.emit(this.getGridStatusEvent());
  }
}
