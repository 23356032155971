export const Headers = {
  CORRELATION_ID_HEADER: 'CorrelationId',
  AUTHORIZATION_TOKEN_HEADER: 'authorizationToken',
  IMPERSONATION_USER_HEADER: 'impersonateduser',
  CLIENTAPPID_HEADER: 'clientappid',
  CONTENT_TYPE_HEADER: 'Content-Type',
  TO_ADD_HEADER: 'toAdd',
};

export const ContentTypes = {
  CONTENT_TYPE_JSON: 'application/json',
  CONTENT_TYPE_FILEEXPORTER:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
