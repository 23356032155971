export enum TabsControlType {
  Dropdown = 1,
  Switch = 2,
  MultipleDropdown = 3,
}

export const MetricsOptions = {
  Sales: 'Sales',
  Cci: 'CCI $',
  CciPercentage: 'CCI %',
  Revenue: 'Revenue',
  Summary: 'Summary',
  Wins: 'Wins',
  WinsThisMonth: 'Wins (this Month)',
  WonCci: 'Won CCI$',
  WonCciPercentage: 'Won CCI%',
};

export const MetricsValues = {
  Summary: 'summary',
  Revenue: 'revenue',
  Sales: 'sales',
  Cci: 'cciDollar',
  CciPercentage: 'cciPercentage',
  WonCci: 'wonCciDollar',
};

export const ViewOptions = {
  Current: 'current-projection',
  Projection: 'last-projection',
  CompareProjection: 'compare-lp',
  Plan: 'plan',
};

export const OpportunityTypes = {
  All: 'All Proj Opps',
  Qualified: 'Qual Proj Opps',
  Unqualified: 'Unqual Proj Opps',
};
