import { assign } from 'lodash';

class AccountPlanResponseSchema {
  TaxingAddressPostalCode: string;
  TaxingAddressCountryDesc: string;
  TaxingAddressCity: string;
  TaxAddressState: string;
  SubClientServiceGroupCD?: string;
  StatusDesc: string;
  SalesTerritoryCD?: string;
  Role: Array<AccountPlanRole>;
}

export class AccountPlanResponse extends AccountPlanResponseSchema {
  constructor(filterAttributes?: AccountPlanResponseSchema) {
    super();
    assign(this, filterAttributes);
  }

  hasAccountPlanRole(peopleKey: string): boolean {
    return this.Role
      ? this.Role.some(
          (x: AccountPlanRole) =>
            x.PeopleKey === peopleKey && x.AccountPlanTeamInd
        )
      : false;
  }
}

class AccountPlanRoleSchema {
  RoleTypeCD: string;
  PersonnelNumber: string;
  PeopleKey: string;
  AccountPlanTeamInd: boolean;
}

export class AccountPlanRole extends AccountPlanRoleSchema {
  constructor(filterAttributes?: AccountPlanRoleSchema) {
    super();
    assign(this, filterAttributes);
  }
}
