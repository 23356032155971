import { assign } from 'lodash';

class AppliedFilterTableSchema {
  title: string;
  values: Array<string>;
  count: number;
}

export class AppliedFilterTable extends AppliedFilterTableSchema {
  constructor(filterAttributes?: AppliedFilterTableSchema) {
    super();
    assign(this, filterAttributes);
  }

  getFullTitle(): string {
    return `${this.title} (${this.count})`;
  }
}
