import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subject } from 'rxjs';

import { FilterType } from 'src/app/shared/constants/filters.constants';
import { Alliance } from 'src/app/shared/services/entities/filters/product-hierarchy';
import { TreeViewConfig } from '../../../base/tree-view/entities/tree-view-config';
import { TreeViewItem } from '../../../base/tree-view/entities/tree-view-item';
import { TreeViewSelection } from '../../../base/tree-view/entities/tree-view-selection';
import { TreeViewUtils } from '../../../base/tree-view/utils/tree-view.utils';
import { FilterSelection } from '../../entities/filter-selection';
import { FilterSelectionKeyValue } from '../../entities/filter-selection-key-value';

@Component({
  selector: 'app-alliances',
  templateUrl: './alliances.component.html',
  styleUrls: ['./alliances.component.scss'],
})
export class AlliancesComponent implements OnChanges {
  @Input() alliances: Array<Alliance> = [];
  @Input() selectedAlliances: Array<Alliance> = [];
  @Output() itemSelectionChangeEvent = new EventEmitter<FilterSelection>();
  selectAllChangedEvent = new Subject<boolean>();

  alliancesTreeviewConfig: TreeViewConfig;
  startSelection: TreeViewSelection;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alliances && changes.alliances.currentValue) {
      this.alliancesTreeviewConfig = new TreeViewConfig({
        parentAttr: 'ParentProductId',
        textAttr: 'ProductName',
        valueAttr: 'ProductId',
        countByNodes: true,
        selectByNodes: true,
        items: TreeViewUtils.getItems<Alliance>(
          this.alliances,
          'ProductId',
          'ProductName',
          'ParentProductId'
        ),
      });
    }

    if (changes.selectedAlliances && changes.selectedAlliances.currentValue) {
      this.startSelection = new TreeViewSelection({
        selectedItems: this.selectedAlliances.map(
          (x: Alliance) =>
            new TreeViewItem({
              value: x.ProductId,
              text: x.ProductName,
            })
        ),
      });
    }
  }

  onItemSelection(selectedAlliances: TreeViewSelection): void {
    this.itemSelectionChangeEvent.emit(
      new FilterSelection({
        type: FilterType.Alliance,
        keyValues: selectedAlliances.selectedItems.map(
          (x: TreeViewItem) =>
            new FilterSelectionKeyValue({
              key: x.value,
              value: x.text,
            })
        ),
        count: selectedAlliances.count,
        blockedFilters: [FilterType.Function, FilterType.GrowthPriority],
      })
    );
  }

  onAllSelectionChanged(selectAll: boolean): void {
    this.selectAllChangedEvent.next(selectAll);
  }
}
