import { assign } from 'lodash';

import { ValueType } from 'src/app/shared/constants/common.constants';
import { CustomNumberPipe } from 'src/app/shared/pipes/custom-number.pipe';

class TargetsTileTargetSchema {
  title: string;
  progress: number;
  progressType?: ValueType = ValueType.Percentage;
  target: number;
  targetType?: ValueType = ValueType.Numeric;
  variance: number;
  varianceType?: ValueType = ValueType.Numeric;
}

export class TargetsTileTarget extends TargetsTileTargetSchema {
  constructor(filterAttributes?: TargetsTileTargetSchema) {
    super();
    assign(this, filterAttributes);
  }

  getProgressText(): string {
    const customNumberPipe = new CustomNumberPipe();
    return customNumberPipe.transform(this.progress, this.progressType);
  }

  getVarianceText(): string {
    const customNumberPipe = new CustomNumberPipe();
    return customNumberPipe.transform(this.variance, this.varianceType, '-');
  }

  getTargetText(): string {
    const customNumberPipe = new CustomNumberPipe();
    return customNumberPipe.transform(this.target, this.targetType, '-');
  }
}
