import { Component, EventEmitter, Input, Output } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() leftText = '%';
  @Input() rightText = '$';
  @Output() switchChangeEvent = new EventEmitter<boolean>();

  id: string = uuidv4();

  onSwitch() {
    this.switchChangeEvent.emit(this.checked);
  }
}
