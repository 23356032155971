<div class="tile card">
    <div class="tile-content card-body">
        <div class="tile-header">
            <div class="tile-title">{{projectionTile ? projectionTile.title : tmpTitle}}</div>
            <div class="tile-switch mt-2">
                <app-switch *ngIf="allowPercentage" (switchChangeEvent)="onValueTypeSwitchChanged($event)"
                    [checked]="!isPercentage">
                </app-switch>
            </div>
            <span class="beta-icon" class="droplet_position"  *ngIf="isCloud"><app-mmb-feature-toggle></app-mmb-feature-toggle></span>  
        </div>
        <div class="tile-value mt-3" class="{{'char-' + getTotal()?.getValue().length}}"
            [ngClass]="{'negative' : getTotal()?.isNegative() && getTotal()?.negativeStyleEnabled, 'positive' : getTotal()?.isPositive() && getTotal()?.positiveStyleEnabled}">
            {{getTotal()?.getValue()}}
        </div>
        <div *ngIf="loaded" class="tile-items">
            <div *ngFor="let item of getItems()" class="tile-item">
                <div class="item-title">
                    {{item.title}}
                </div>
                <div class="item-value" class="{{'item-char-' + item.getValue().length}}" (click)="onLinkClicked(item)"
                    [ngClass]="{'negative' : item.isNegative() && item.negativeStyleEnabled, 'positive' : item.isPositive() && item.positiveStyleEnabled, 'linkable' : item.link}">
                    {{item.getValue()}}
                </div>
            </div>
        </div>
        <div *ngIf="!loaded" class="tile-items">
            <div *ngFor="let item of tmpItems" class="tile-item">
                <div class="item-title">
                    {{item}}
                </div>
                <div class="item-value">
                    0
                </div>
            </div>
        </div>
        <div *ngIf="loaded && hasErrors" class="tile-error text-center">
            <span class="tile-error-header">Sorry!</span>
            <span class="tile-error-body d-block">{{errorMessage}}</span>
        </div>
        <app-spinner name="proj-tile-spinner-{{type}}" [showSpinner]="!loaded" [fullScreen]="false"
            spinnerColor="#3e4851">
        </app-spinner>
    </div>
</div>