import { assign } from 'lodash';

import { FilterType } from 'src/app/shared/constants/filters.constants';

class BlockedFilterSchema {
  blocker: FilterType;
  blocked: Array<FilterType>;
}

export class BlockedFilter extends BlockedFilterSchema {
  constructor(filterAttributes?: BlockedFilterSchema) {
    super();
    assign(this, filterAttributes);
  }
}
