<div *ngIf="dataRefresh" ngbDropdown class="ddl-control d-inline-block" display="dynamic">
    <button id="ddlDataRefresh" class="btn btn-link" placement="bottom" ngbTooltip="Data Refresh Schedule"
        ngbDropdownToggle>
        <fa-icon [icon]="faClock">
        </fa-icon>
        <sup>({{completedStateOfDataCount}})</sup>
    </button>
    <div ngbDropdownMenu aria-labelledby="ddlDataRefresh" [ngClass]="{'ddl-content-admin': isAdmin}" class="ddl-content">
        <ul ngbNav #dataRefreshNav="ngbNav" [activeId]="1" class="nav-tabs">
            <li [ngbNavItem]="1" class="nav-tab">
                <a ngbNavLink>STATE OF DATA</a>
                <ng-template ngbNavContent>
                  <table class="table m-0">
                    <tbody>
                      <tr *ngFor="let state of dataRefresh.stateOfDataItems" class="data-refresh-item">
                        <td>
                          <div class="data-refresh-notes">
                            <input *ngIf="ordinal === state.ordinal; else showStateData" type="text" class="form-control d-inline" (ngModelChange)="onChange($event)" [(ngModel)]="state.name">
                            <ng-template #showStateData><p class="data-refresh-title">{{ state.name }}</p></ng-template>
                            <div [ngStyle]="{'display': ordinal === state.ordinal ? 'none' : 'block'}" class="data-refresh-subtitle">
                              <span *ngIf="!state.isCompleted && state.childCompletionDate">
                                {{ state.subtitle }}
                              </span>
                              <span *ngIf="!state.isCompleted && state.childCompletionDate" class="completed">
                                  Completed!
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div [ngClass]="{'data-refresh-values-admin': isAdmin}" class="data-refresh-values pr-2">
                            <div *ngIf="state.isCompleted" class="d-inline">
                              <input *ngIf="ordinal === state.ordinal; else showStateDate" type="text" class="form-control d-inline" (ngModelChange)="onChange($event)" [(ngModel)]="state.completionDate">
                              <ng-template #showStateDate><p  class="data-refresh-date d-inline">{{ state.completionDate }}</p></ng-template>
                            </div>
                            <div *ngIf="!state.isCompleted" class="d-inline">
                              <input *ngIf="ordinal === state.ordinal; else showStateDate" type="text" class="form-control d-inline" (ngModelChange)="onChange($event)" [(ngModel)]="state.childCompletionDate">
                              <ng-template #showStateDate><p class="data-refresh-date d-inline">{{ state.childCompletionDate }}</p></ng-template>
                            </div>
                            <fa-icon [ngStyle]="{'display': ordinal === state.ordinal ? 'none' : 'inline'}" *ngIf="state.isCompleted && state.completionDate" [icon]="faCheck" class="completed">
                            </fa-icon>
                            <fa-icon [ngStyle]="{'display': ordinal === state.ordinal ? 'none' : 'inline'}" *ngIf="!state.isCompleted && state.childCompletionDate" [icon]="faSync" class="in-progress">
                            </fa-icon>
                          </div>
                        </td>
                        <td *ngIf="isAdmin" class="p-0">
                          <button
                            [ngClass]="{'hide-button': ordinal !== state.ordinal}"
                            class="save-button" (click)="onSaveState(state)"
                            ngbTooltip="Save"
                            [disabled]="this.btnDisabled"
                          >
                            <fa-icon [icon]="faSave"></fa-icon>
                          </button>
                          <button
                            [ngClass]="{'hide-button': ordinal !== state.ordinal}"
                            class="edit-button" (click)="onCancelState(state);"
                            ngbTooltip="Cancel"
                          >
                            <fa-icon [icon]="faWindowClose"></fa-icon>
                          </button>
                          <button
                            class="edit-button"
                            (click)="onEditState(state)"
                            ngbTooltip="Edit"
                          >
                            <fa-icon [icon]="faPencilAlt"></fa-icon>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ng-template>
            </li>
            <li [ngbNavItem]="2" class="nav-tab">
                <a ngbNavLink>UPDATE FREQUENCY</a>
                <ng-template ngbNavContent>
                    <div *ngFor="let frequency of dataRefresh.updateFrequencyItems" class="frequency-item">
                        <div class="frequency-title"><span>{{frequency.name}}</span></div>
                        <div class="frequency-subtitle">{{frequency.subtitle}} </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="dataRefreshNav" class="data-refresh-container"></div>
    </div>
</div>
