import { assign } from 'lodash';

import { TimeframeUtils } from '../utils/timeframe.utils';

class TimeframeGroupSchema {
  title: string;
  subGroups: Array<TimeframeSubGroup>;
  enabled?: boolean = true;
}

export class TimeframeGroup extends TimeframeGroupSchema {
  constructor(filterAttributes?: TimeframeGroupSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TimeframeSubGroupSchema {
  title?: string;
  items: Array<TimeframeItem>;
}

export class TimeframeSubGroup extends TimeframeSubGroupSchema {
  constructor(filterAttributes?: TimeframeSubGroupSchema) {
    super();
    assign(this, filterAttributes);
  }
}

class TimeframeItemSchema {
  title: string;
  start: number;
  end: number;
  fiscalYear: number;
  mmbPeriod: string;
  code: string;
  type: string;
  enabled?: boolean = true;
  isPast?: boolean = false;
}

export class TimeframeItem extends TimeframeItemSchema {
  constructor(filterAttributes?: TimeframeItemSchema) {
    super();
    assign(this, filterAttributes);
  }

  getRange(): string {
    return `${this.title}-${this.start}-${this.end}-${this.fiscalYear}`;
  }

  getFullName(): string {
    return `${this.title} (${this.getDescription()})`;
  }

  getDescription(): string {
    if (this.start > 0 && this.end > 0) {
      if (this.start === this.end) {
        const date: Date = TimeframeUtils.getDateByTimeId(this.start);
        return `${TimeframeUtils.getFormattedFromDate(
          date,
          'MMM'
        )} ${TimeframeUtils.getFormattedFromDate(date, 'YYYY')}`.toUpperCase();
      } else {
        const from: Date = TimeframeUtils.getDateByTimeId(this.start);
        const to: Date = TimeframeUtils.getDateByTimeId(this.end);
        return `${TimeframeUtils.getFormattedFromDate(
          from,
          'MMM'
        )} ${TimeframeUtils.getFormattedFromDate(
          from,
          'YYYY'
        )} - ${TimeframeUtils.getFormattedFromDate(
          to,
          'MMM'
        )} ${TimeframeUtils.getFormattedFromDate(to, 'YYYY')}`.toUpperCase();
      }
    }

    return '';
  }
}
