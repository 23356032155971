import { assign } from 'lodash';
import { Customer } from './filters/customer';

class CustomerSettingsSchema {
  customerId: string;
  customerName: string;
  updatedBy: string;
  settings: string;
  updatedDate?: string;
}

export class CustomerSettings extends CustomerSettingsSchema {
  constructor(filterAttributes?: CustomerSettingsSchema) {
    super();
    assign(this, filterAttributes);
  }

  static empty(customer?: Customer): CustomerSettings {
    return new CustomerSettings({
      customerId: customer?.MasterCustomerNumber || '',
      customerName: customer?.MasterCustomerName || '',
      settings: '{}',
      updatedBy: '',
      updatedDate: '',
    });
  }

  updateSetting(key: string, value: any): void {
    if (this.settings) {
      const settings: Record<string, any> = JSON.parse(this.settings);
      settings[key] = value;
      this.settings = JSON.stringify(settings);
    }
  }
}
