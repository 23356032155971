import { assign } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

class CheckBoxGroupHeaderSchema {
  id?: string;
  text: string;
  values?: Array<any>;
  checked?: boolean = false;
}

export class CheckBoxGroupHeader extends CheckBoxGroupHeaderSchema {
  constructor(filterAttributes?: CheckBoxGroupHeaderSchema) {
    super();
    assign(this, filterAttributes);

    this.id = uuidv4();
  }
}
