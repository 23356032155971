<ng-template #favoriteModal let-modal>
  <div class="modal-header">
    <div class="d-flex justify-content-between">
      <div class="form-group d-flex mb-0">
        <label for="favoriteName" class="d-flex align-items-center mr-3 mb-0"><strong>Favorites:*</strong></label>
        <input type="board" class="form-control mr-3" id="favoriteName" [(ngModel)]="favoriteName"
            aria-describedby="favoriteName" placeholder="Insert favorite Name" maxlength="50">
        <small class="font-weight-bold d-flex align-items-center">{{ favoriteName.length }}/50</small>
      </div>
      <button class="btn btn-link close-icon" (click)="onCloseModal()">
          <span>
              Close
              <fa-icon [icon]="faTimes"></fa-icon>
          </span>
      </button>
    </div>

    <div class="breadcrumb-container mt-3">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb m-0">
          <li class="breadcrumb-item ml-2">Filters ({{ totalFilters ?? 0 }})</li>
          <li class="breadcrumb-item">{{ selectedTimeframe.title }}</li>
          <li class="breadcrumb-item">{{ selectedCurrency.Description }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.metric ? selectedBreadcrumbs.metric : '-' }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.period }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.group1 }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.group2 }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.group3 }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.target1 }}</li>
          <li class="breadcrumb-item">{{ selectedBreadcrumbs.target2 }}</li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="modal-body">
    <div class="d-flex justify-content-between container">
      <ul ngbNav #nav="ngbNav" class="nav-pills" orientation="vertical" [destroyOnHide]="false">
        <li ngbNavItem="Filter">
          <a class="modal-options" ngbNavLink>Filter</a>
          <ng-template ngbNavContent>
            <app-filters-accordion (filtersSelectionChangedEvent)="setFilterManager($event)"></app-filters-accordion>
          </ng-template>
        </li>
        <li ngbNavItem="Timeframe">
          <a class="modal-options" ngbNavLink>Timeframe</a>
          <ng-template ngbNavContent>
            <app-timeframe (selectionChangeEvent)="onChangeTimeframe($event)"
              [startSelectedTimeframe]="selectedTimeframe" [isModal]="false">
            </app-timeframe>
          </ng-template>
        </li>
        <li ngbNavItem="Currency">
          <a class="modal-options" ngbNavLink>Currency</a>
          <ng-template ngbNavContent>
            <app-currency (selectionChangeEvent)="onChangeCurrency($event)"
              [startSelectedCurrency]="selectedCurrency">
            </app-currency>
          </ng-template>
        </li>
        <li ngbNavItem="Metrics">
          <a class="modal-options" ngbNavLink>Metrics</a>
          <ng-template ngbNavContent>
            <app-options-list class="metrics-options" [config]="favoriteFiltersConfig?.metricListConfig"
              [startSelection]="selectedMetrics" (itemSelectionChangeEvent)="onMetricChanged($event)">
            </app-options-list>
          </ng-template>
        </li>
        <li ngbNavItem="Mon/Qua/Non">
          <a class="modal-options" ngbNavLink>Mon/Qua/Non</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.periods"
              [selectedItem]="selectedPeriod" (itemSelectionChangeEvent)="onPeriodChanged($event)">
            </app-unordered-list>
          </ng-template>
        </li>
        <li ngbNavItem="Breakdown Criteria 1">
          <a class="modal-options" ngbNavLink>Breakdown Criteria 1</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.groups1"
                [selectedItem]="selectedGroup1" (itemSelectionChangeEvent)="onGroupChanged($event, 1)">
            </app-unordered-list>
          </ng-template>
        </li>
        <li ngbNavItem="Breakdown Criteria 2">
          <a class="modal-options" ngbNavLink>Breakdown Criteria 2</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.groups2"
                [selectedItem]="selectedGroup2" (itemSelectionChangeEvent)="onGroupChanged($event, 2)">
            </app-unordered-list>
          </ng-template>
        </li>
        <li ngbNavItem="Breakdown Criteria 3">
          <a class="modal-options" ngbNavLink>Breakdown Criteria 3</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.groups3"
                [selectedItem]="selectedGroup3" (itemSelectionChangeEvent)="onGroupChanged($event, 3)">
            </app-unordered-list>
          </ng-template>
        </li>
        <li ngbNavItem="Breakdown Criteria 4">
          <a class="modal-options" ngbNavLink>Breakdown Criteria 4</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.datasets1"
                [selectedItem]="selectedTarget1"
                (itemSelectionChangeEvent)="onDatasetChanged($event, 1)">
              </app-unordered-list>
          </ng-template>
        </li>
        <li ngbNavItem="Breakdown Criteria 5">
          <a class="modal-options" ngbNavLink>Breakdown Criteria 5</a>
          <ng-template ngbNavContent>
            <app-unordered-list [items]="favoriteFiltersConfig?.datasets2"
                [selectedItem]="selectedTarget2"
                (itemSelectionChangeEvent)="onDatasetChanged($event, 2)">
              </app-unordered-list>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="ms-4"></div>
    </div>
  </div>

  <div class="modal-footer mt-1">
    <div class="d-flex justify-content-end align-items-center m-1">
      <button class="btn btn-primary ml-3"
        [disabled]="favoriteName.length === 0 || favorite.metric.length === 0"
        (click)="onSaveAndClose()">
        Save and close
      </button>
      <button class="btn btn-primary ml-3"
        [disabled]="favoriteName.length === 0 || favorite.metric.length === 0"
        (click)="onSave()">
        Save
      </button>
    </div>
  </div>
</ng-template>
