export const StorageKeys = {
  IMPERSONATED_USER_KEY: 'impersonated_user_cbp',
  OVERRIDE_CODE: 'override_code',
  OVERRIDE_VALID: 'override_valid',
  ENVIRONMENT_URL: 'environment_url',
};

export enum StorageType {
  LocalStorage = 1,
  SessionStorage = 2,
}
